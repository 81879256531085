import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import TvIcon from '@material-ui/icons/Tv';
import RefusePopup from './RefusePopup';
import Alert from '../../components/Alert'
import { apiFetch } from '../../service/apiFetch';
import { TransitionLeft, translateType } from '../../helpers/utils';
import ChatIcon from '@material-ui/icons/Chat';
import MoreClientDetailsPopup from './MoreClientDetailsPopup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreDetailsPopup from './MoreDetailsPopup';
import { useStylesFutureInterventionsTable } from '../../css/Style';
import InformationVisioPopup from './InformationVisioPopup';

export default function FurureInterventionsTable({ rows, futureRow, setVisioId, setChatRoom, isClient }) {
    const classes = useStylesFutureInterventionsTable();
    const [open, setOpen] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [showPopupVisio, setShowPopupVisio] = useState(false)
    const [showClientPopup, setShowClientPopup] = useState(false)
    const [text, setText] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [transition, setTransition] = useState(false)
    const [comment, setComment] = useState(false)
    const [error, setError] = useState(false)
    const [selected, setSelected] = useState(false)

    const handleAccept = async (e, row) => {
        const acceptConsultation = await apiFetch(row.acceptUrl, {
            method: 'GET'
        }, true)

        if (acceptConsultation.success) {
            setTransition(() => TransitionLeft)
            setText(acceptConsultation.message)
            setSeverity('success')
            setIsUpdate(true)
            setChatRoom && setChatRoom({room : {specialistToken: acceptConsultation.token, roomName: acceptConsultation.roomName}, isFromUpdate: true, roomIdentity: acceptConsultation.roomIdentity})
        } else {
            setTransition(() => TransitionLeft)
            setText(acceptConsultation.message)
            setSeverity('error')
            setIsUpdate(true)
        }
        setTimeout(() => {
            !acceptConsultation.token && window.location.reload()
        }, 2000)
    }

    const handleRefuse = async () => {
        if (!comment && open.intervention && open.intervention.type === 'Intervention') return setError('Ce champ ne doit pas être vide')

        const url = open.refuseUrl || open.cancelUrl
        let refuseConsultation = null
        if (open.refuseUrl) {
            refuseConsultation = await apiFetch(url, {
                method: 'PUT',
                body: JSON.stringify({message: comment})
            }, true)
        } else if (open.cancelUrl) {
            refuseConsultation = await apiFetch(url, {
                method: 'GET'
            }, true)
        }
        setOpen(false)

        if (refuseConsultation && refuseConsultation.success) {
            setTransition(() => TransitionLeft)
            setText(refuseConsultation.message)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setTransition(() => TransitionLeft)
            setText('Désolé une erreur est survenue. Veuillez réessayer plus tard.')
            setSeverity('error')
            setIsUpdate(true)
        }
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }

    const handleChat = async (e, row) => {
        const chat = await apiFetch(row.chatUrl)
        if (chat && chat.roomName) {
            if (isClient) {
                setChatRoom && setChatRoom({room : {clientToken: chat.token, roomName: chat.roomName}, roomIdentity: chat.roomIdentity, isFromUpdate: true, id: row.id, specialist: {user: {firstName: row.specialist.user.firstName}}})
            } else {
                setChatRoom && setChatRoom({room : {specialistToken: chat.token, roomName: chat.roomName}, isFromUpdate: true, id: row.id, roomIdentity: chat.roomIdentity, client: {user: {firstName: row.client.user.firstName}}})
            }   
        } else {
            setTransition(() => TransitionLeft)
            setText('Désolé une erreur est survenue. Veuillez réessayer plus tard.')
            setSeverity('error')
            setIsUpdate(true)
        }
    }

    const handlePopup = (e, row) => {
        isClient ? setShowClientPopup(row) : setShowPopup(row)
    }

    const handleVisio = (e, rowId) => {
        setShowPopupVisio(true)
        setSelected(rowId)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell} style={{color: 'white'}}>Type</TableCell>
                        <TableCell className={classes.tableCell} style={{color: 'white'}} align="right">{isClient ? 'Psychologue' : 'Patient'}</TableCell>
                        <TableCell className={classes.tableCell} style={{color: 'white'}} align="right">Date de la consultation</TableCell>
                        <TableCell className={classes.tableCell} style={{color: 'white'}} align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length ? rows.map((row) => (
                        <TableRow key={row['@id']}>
                            <TableCell component="th" scope="row">
                              {row.consultation ? translateType(row.consultation.type) : row.intervention ? translateType(row.intervention.type) : translateType(row.type)}
                            </TableCell>
                            <TableCell align="right">{isClient ? row.specialist ? row.specialist.user.firstName : row.intervention ? row.intervention.specialist.user.firstName : row.consultation.specialist.user.firstName : row.client ? row.client.user.firstName : row.intervention ? row.intervention.client.user.firstName : row.consultation.client.user.firstName}</TableCell>
                            <TableCell align="right">{row.consultation ? moment(row.consultation.startDate).format('LLLL') : row.intervention ? moment(row.intervention.startDate).format('LLLL') : moment(row.startDate).format('LLLL')}</TableCell>
                            <TableCell align="right">
                                {!futureRow ? <ButtonGroup size="small" variant="contained" color="primary" aria-label="button group">
                                    {row.acceptUrl && <Button onClick={(e) => handleAccept(e, row)}>Accepter</Button>}
                                    {row.refuseUrl && <Button onClick={() => setOpen(row)}>Refuser</Button>}
                                    {((isClient && (row.type === 'Intervention' || row.type === 'Cabinet')) || (!isClient && row.type === 'Intervention')) && <Button aria-label="show" onClick={(e) => handlePopup(e, row)}>
                                        <VisibilityIcon fontSize="small" />
                                    </Button>}
                                </ButtonGroup> : row.cancelUrl ? <Button onClick={() => setOpen(row)} size="small" variant="contained" color="primary" >Annuler</Button> : 
                                row.visioUrl ? <IconButton onClick={(e) => handleVisio(e, row.id)} aria-label="delete" className={classes.margin}>
                                    <TvIcon />
                                </IconButton> : row.chatUrl && <IconButton onClick={(e) => handleChat(e, row)} aria-label="delete" className={classes.margin}>
                                    <ChatIcon />
                                </IconButton>}
                                {row.status === "Approved" && ((isClient && (row.type === 'Intervention' || row.type === 'Cabinet')) || (!isClient && row.type === 'Intervention')) && <IconButton aria-label="show" onClick={(e) => handlePopup(e, row)}>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>}
                            </TableCell>
                        </TableRow>
                    )) : 
                        <TableRow>
                            <TableCell align="right" component="th" scope="row"></TableCell>
                            <TableCell align="right" ></TableCell>
                            <TableCell align="center">Pas de consultations pour le moment...</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <RefusePopup error={error} comment={comment} setComment={setComment} open={open} setOpen={setOpen} handleRefuse={handleRefuse} />
            <Alert isOpen={isUpdate} transition={transition} message={text} setIsUpdate={setIsUpdate} severity={severity} />
            {showPopup && <MoreDetailsPopup showPopup={showPopup} setShowPopup={setShowPopup} />}
            {showClientPopup && <MoreClientDetailsPopup showPopup={showClientPopup} setShowPopup={setShowClientPopup} />}
            {showPopupVisio && <InformationVisioPopup selected={selected} showPopup={showPopupVisio} setShowPopup={setShowPopupVisio} setVisioId={setVisioId} />}
        </TableContainer>
    );
}