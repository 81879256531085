import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useStylesChatMessages } from "../../../css/Style";
import hasRoles from "../../../service/security/hasRoles";
import { CLIENT, SPECIALIST } from "../../../Constant/mainConstant";

const ChatMessages = ({ avatar, messages, side, isLastItem, date, author }) => {
  const classes = useStylesChatMessages();
  const attachClass = (index) => {
    if (index === 0) {
      return classes[`${side}First`];
    }
    if (index === messages.length - 1) {
      return classes[`${side}Last`];
    }
    return "";
  };
  
  return (
    <Grid
      container
      spacing={2}
      justify={side === "right" ? "flex-end" : "flex-start"}
      style={{padding: 10}}
    >
        <Grid item>
          <Avatar className={classes.avatar} src={avatar || ''} />
        </Grid>
      <Grid>
        {messages.map((msg, i) => (
          <div key={i} className={classes[`${side}Row`]} style={{paddingBottom: isLastItem.message === msg ? 50 : 0}}>
              <Typography variant="caption" gutterBottom display={"block"}>
                {`${author.firstName} `}
                {(hasRoles() === 'admin' || hasRoles() === 'super_admin') && `${author.lastName} `}
                <span style={{fontStyle: 'italic'}}>{(hasRoles() === 'admin' || hasRoles() === 'super_admin') && `(${formatRoles(author.roles[0])})`}</span>
            </Typography>
            <Typography
              align={"left"}
              className={`${classes.msg} ${classes[side]} ${attachClass(i)}`}
              style={{wordBreak: 'normal'}}
              display={"block"}
            >
              {msg}
            </Typography>
            <Typography variant="caption" gutterBottom display={"block"}>
                {moment(date).format('llll')}
            </Typography>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

ChatMessages.defaultProps = {
  avatar: "",
  messages: [],
  side: "left"
};
ChatMessages.metadata = {
  name: "Chat Messages"
};

const formatRoles = (role) => {
  return role === CLIENT ? 'Client' : role === SPECIALIST ? 'Spécialiste' : 'Admin'
}

export default ChatMessages;
