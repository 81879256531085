import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_SPECIALIST_OF_MONTH = 'FETCHING_SPECIALIST_OF_MONTH'
const SET_SPECIALIST_OF_MONTH = 'SET_SPECIALIST_OF_MONTH'
const ADD_SPECIALIST_OF_MONTH = 'ADD_SPECIALIST_OF_MONTH'
const UPDATE_SPECIALIST_OF_MONTH = 'UPDATE_SPECIALIST_OF_MONTH'
const DELETE_SPECIALIST_OF_MONTH = 'DELETE_SPECIALIST_OF_MONTH'

function specialistOfMonthReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_SPECIALIST_OF_MONTH:
            return { ...state, loading: true }
        case SET_SPECIALIST_OF_MONTH:
            return {...state, specialistOfMonth: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_SPECIALIST_OF_MONTH:
            return {...state, specialistOfMonth: [action.payload, ...state.specialistOfMonth]}
        case UPDATE_SPECIALIST_OF_MONTH:
            return {...state, specialistOfMonth: action.payload }
        case DELETE_SPECIALIST_OF_MONTH:
            return {...state, specialistOfMonth: state.specialistOfMonth.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useSpecialistOfMonth() {
    const [state, dispatch] = useReducer(specialistOfMonthReducer, {    
        specialistOfMonth: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        specialistOfMonth: state.specialistOfMonth,
        totalItems: state.totalItems,
        view: state.view,
        fetchSpecialistOfMonth: useCallback(async function (route) {
            if ((state.specialistOfMonth || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_SPECIALIST_OF_MONTH' })
            const specialistOfMonth = await apiFetch(route || '/cms_specialist_stars', {method: 'GET'})
            if (specialistOfMonth) {
                dispatch({type: 'SET_SPECIALIST_OF_MONTH', payload: specialistOfMonth})
            }
            return specialistOfMonth
        }, [state]),
        createSpecialistOfMonth: useCallback(async function(data, forceUpdate) {
            const newSpecialistOfMonth = await apiFetch('/cms_specialist_stars', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            const specialistOfMonth = await apiFetch('/cms_specialist_stars', {method: 'GET'})
            if (!newSpecialistOfMonth.violations && specialistOfMonth) {
                dispatch({type: 'SET_SPECIALIST_OF_MONTH', payload: specialistOfMonth})
            }
            return newSpecialistOfMonth
        }, []),
        updateSpecialistOfMonth: useCallback(async function(specialistOfMonth, data, forceUpdate = false) {
            const newSpecialistOfMonth = await apiFetch('/cms_specialist_stars/' + specialistOfMonth, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceUpdate)
            const specialistOfMonthReload = await apiFetch('/cms_specialist_stars', {method: 'GET'})
            if (!newSpecialistOfMonth.violations && specialistOfMonthReload) {
                dispatch({type: 'SET_SPECIALIST_OF_MONTH', payload: specialistOfMonthReload})
            }
            return newSpecialistOfMonth
        }, []),
        deleteSpecialistOfMonth: useCallback(async function (specialistId) {
            const specialistOfMonthDelete = await apiFetch('/cms_specialist_stars/' + specialistId, {
                method: 'DELETE'
            }, true)
            if (specialistOfMonthDelete && !specialistOfMonthDelete['hydra:description']) {
                dispatch({type: 'DELETE_SPECIALIST_OF_MONTH', payload: specialistId})
            }
            return specialistOfMonthDelete
        }, []),
    }
}