import React, { useEffect, useState } from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { apiFetch } from '../../../service/apiFetch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TransitionLeft } from '../../../helpers/utils'

export default function AddSpecialist({ handleCloseMenu, updateSpecialistOfMonth, selected, open, handleClose, createSpecialistOfMonth, setIsUpdate, setText, setSeverity, setTransition }) {
    const [defaultProps, setDefaultProps] = useState(false)
    const [specialist, setSpecialist] = useState(false)
    const [description, setDescription] = useState(false)
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(false)
    const [isCheckboxUpdated, setIsCheckboxUpdated] = useState(false)

    const handleChange = (event) => {
        setIsCheckboxUpdated(true)
        setDisable(false)
        setActive(event.target.checked);
    };

    useEffect(() => {
        (async () => {
            if (selected) {
                setDescription(selected.description)
                setActive(selected.star)
            } else {
                const specialist = await apiFetch('/specialists?pagination=false')
                if (specialist['hydra:member']) {
                    setDefaultProps({options: specialist['hydra:member'], getOptionLabel: (option) => `${option.user.firstName} ${option.user.lastName}`})
                }
            }
        })()
    }, [selected])

    const handleSubmit = async () => {
        handleCloseMenu()
        setDisable(true)
        setLoading(true)
        const createSpecialist = !selected ? await createSpecialistOfMonth({
            specialist: specialist['@id'],
            description: description,
            star: active
        }) : await updateSpecialistOfMonth(selected.id, {
            description: description,
            star: active
        })
        if (createSpecialist) {
            setText('Le psychologue a été ajouté')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText('Erreur lors de l\'ajout du psychologue')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
        setDisable(false)
        setLoading(false)
        handleClose()
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value)
        setDisable(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="title">
            <DialogTitle id="title">Ajouter un psychologue</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pour ajouter un psychologue il faut le sélectionner et entrer sa description 
                </DialogContentText>
                {defaultProps && !selected ? <Autocomplete
                    {...defaultProps}
                    id="specialist"
                    debug
                    onChange={(e, value) => setSpecialist(value)}
                    /* onChange={this.handleSpecialistSearch} */
                    renderInput={(params) => <TextField {...params} label="Spécialistes" margin="normal" />}
                /> : 
                !selected ? <LinearProgress /> :
                <Typography style={{marginBottom: 20}}>
                    {selected.specialist.fullName}
                </Typography>
                }
                {(specialist || selected) && <><TextField  
                    required
                    label="Description"
                    multiline
                    rows={4}
                    style={{width: '100%'}}
                    onChange={handleChangeDescription}
                    defaultValue={selected ? selected.description : ''}
                />
                
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={selected && !isCheckboxUpdated ? selected.star : active}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'checkbox' }}
                        />
                    }
                    label="Choisir ce psychologue pour le mois"
                /></>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={disable}>
                    Enregistrer
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </Dialog>
    )
}