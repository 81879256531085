import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import React from 'react'
import chatIcon from '../src/img/tchat.png'
import phoneIcon from '../src/img/telephone-picto.png'
import CloseIcon from '@material-ui/icons/Close';
import { useStylesConsultPopup } from './css/Style';

export default function ConsultPopup({ open, handleClose, handleNext, text, title, disable, type, matches}) {
    const classes = useStylesConsultPopup();

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{paper: classes.voicePaper}}
            >
                {(type || title) && <DialogTitle id="alert-dialog-title">{title || "PAR " + type.toUpperCase()} <span style={{float: 'right'}}><IconButton onClick={handleClose} ><CloseIcon/></IconButton></span></DialogTitle>}
                <DialogContent>
                    {text && <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>}
                    {type === "Chat" ? <img src={chatIcon} alt="icon"></img> : type === 'Audio' && <img class="fit-picture" src={phoneIcon} alt="icon" width='301px' height='267px'></img> }<br />
                    {type === 'Chat' && <>• Pour une expérience optimale, assurez-vous d'avoir une bonne connexion internet avant de lancer le tchat.</>}<br />{(type === "Chat" || type === 'Audio') && <>• Si vous acceptez, la consultation vous coûtera 1 séance.< br />
                    • Vos conversations sont entièrement confidentielles et elles ne sont pas conservées par notre système.</>}
                </DialogContent>
                <DialogActions style={{margin: 'auto'}}>
                    {type === 'Chat' ? <Button onClick={handleNext} variant="contained" color="primary" autoFocus disabled={disable}>
                        LANCER LE {type && type.toUpperCase()}
                    </Button> : type === 'Audio' ? <Button onClick={handleNext} variant="contained" color="primary" autoFocus disabled={disable}>
                        LANCER L' {type && type.toUpperCase()}
                    </Button> : <Button onClick={handleNext} variant="contained" color="primary" autoFocus disabled={disable}>
                        Quitter la discussion
                    </Button>}
                </DialogActions>
            </Dialog>
    </div>
    )
}
