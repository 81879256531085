import React from 'react'
import { withStyles, Paper, Typography, Divider, Button, ButtonGroup, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TransitionLeft } from '../../helpers/utils';
import Alert from '../../components/Alert';
import { apiFetch } from '../../service/apiFetch';
import { motion } from 'framer-motion'
import { useStylesChangePassword } from '../../css/Style';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}))(Tooltip);

export default function ChangePassword(props) {
    const classes = useStylesChangePassword();
    const [passwordError, setPasswordError] = React.useState({})
    const [error, setError] = React.useState({})
    const [matchPass, setMatchPass] = React.useState(false)
    const [disable, setDisable] = React.useState(false)
    const [text, setText] = React.useState(false)
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [severity, setSeverity] = React.useState(false)
    const [transition, setTransition] = React.useState(false)
    let history = useHistory()
    const [newValues, setNewValues] = React.useState({
        password: '',
        showPassword: false,
    });
    
    const [ConfirmValues, setConfirmValues] = React.useState({
      password: '',
      showPassword: false,
    });

    const [password, setPassword] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleSubmit = async () => {
        setDisable(true)
        setMatchPass(false)
        setError({})
        setPasswordError({})

        if (!password.password || !newValues.password) {
            if (!password.password) setPasswordError({password: 'Veuillez renseigner ce champ'})
            if (!newValues.password) setError({newPassword: 'Veuillez renseigner ce champ'})
            setDisable(false)
            return
        } else if (newValues.password !== ConfirmValues.password) {
            setMatchPass('Le nouveau mot de passe ne correspond pas avec la confirmation')
            setDisable(false)
            return
        }

        const dataObject = {
            oldPassword: password.password,
            newPassword: newValues.password,
        }

        const changePassword = await apiFetch('/users/update/password', {
            method: 'POST',
            body: JSON.stringify(dataObject)
        }, true)

        if (changePassword.error) {
            setText(changePassword.error)
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
        } else {
            setText(changePassword.success)
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
            setTimeout(() => {
                history.push('/profil')
            }, 2000)
        }
        setDisable(false)
    }    
    
    const handleBack = () => {
        history.push('/profil')
    }

    const handleChangePass = (prop) => (event) => {
        setNewValues({ ...newValues, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setNewValues({ ...newValues, showPassword: !newValues.showPassword });
    };

    const handlePassword = (prop) => (event) => {
        setPassword({ ...password, [prop]: event.target.value });
    };

    const handleClicPassword = () => {
        setPassword({ ...password, showPassword: !password.showPassword });
    };

    const handleConfirmPass = (prop) => (event) => {
        setConfirmValues({ ...ConfirmValues, [prop]: event.target.value });
    };

    const handleClickShowConfirmPassword = () => {
        setConfirmValues({ ...ConfirmValues, showPassword: !ConfirmValues.showPassword });
    };
    
    return (
        <Paper className={classes.paper}>
            <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} >
            <div className={classes.padding}>
                <Typography variant="h5" gutterBottom color='primary' className={classes.avatar}>
                    Changer de mot de passe
                </Typography>
                <Divider className={classes.divider}/>
            </div>
            <div style={{textAlign: 'center'}}>
                <FormControl style={{width: '400px'}}>
                    <InputLabel htmlFor="password" required error={!!passwordError && !!passwordError.password}>Mot de passe</InputLabel>
                    <Input
                        required
                        id="password"
                        name="password"
                        error={!!passwordError && !!passwordError.password}
                        type={password.showPassword ? 'text' : 'password'}
                        value={password.password}
                        onChange={handlePassword('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClicPassword}
                            >
                              {password.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }
                    />
                </FormControl>
                <FormHelperText style={{color: 'red', marginBottom: '40px', textAlign: 'center'}}>{passwordError && passwordError.password}</FormHelperText>              
                <HtmlTooltip
                    title={
                        <>
                            <Typography color="inherit">Votre mot de passe doit contenir au moins: </Typography>
                            <ul>
                                <li><b>{"Une majuscule"}</b></li>
                                <li><b>{"Une minuscule"}</b></li>
                                <li><b>{"Un chiffre"}</b></li>
                                <li><b>{"8 caractères minimum"}</b></li>
                            </ul>
                        </>
                    }
                >
                    <FormControl style={{width: '400px'}}>
                        <InputLabel htmlFor="newPassword" required error={!!error && !!error.newPassword}>Nouveau mot de passe</InputLabel>
                        <Input
                            required
                            id="newPassword"
                            name="newPassword"
                            error={!!error && !!error.newPassword}
                            type={newValues.showPassword ? 'text' : 'password'}
                            value={newValues.password}
                            onChange={handleChangePass('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {newValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </HtmlTooltip>
                    <FormHelperText style={{color: 'red', marginBottom: '40px', textAlign: 'center'}}>{error && error.newPassword}</FormHelperText>
                    <FormControl style={{width: '400px'}}>
                        <InputLabel htmlFor="password" required error={!!matchPass}>Confirmer votre mot de passe</InputLabel>
                        <Input
                            required
                            id="confirmPass"
                            name="confirmPass"
                            error={!!matchPass}
                            type={ConfirmValues.showPassword ? 'text' : 'password'}
                            value={ConfirmValues.password}
                            onChange={handleConfirmPass('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                >
                                  {ConfirmValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                          }
                        />
                    </FormControl>
                    <FormHelperText style={{color: 'red', marginBottom: '40px', textAlign: 'center'}}>{matchPass}</FormHelperText>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" style={{marginBottom: 40, marginTop: 40}} >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                    >
                            Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={handleSubmit}
                    >
                            Enregistrer
                    </Button>
                </ButtonGroup>
            </div>
            <Alert isOpen={isUpdate} transition={transition} message={text} setIsUpdate={setIsUpdate} severity={severity} />
            </motion.div>
        </Paper>
    )
}