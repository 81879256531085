import React, { useEffect } from 'react'
import * as mainConstant from '../Constant/mainConstant'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { NewMessage } from './MessageItem/NewMessage';
import { MessageTable } from './MessageItem/MessageTable';
import Alert from '../components/Alert';
import { useMessage } from '../hooks/messages';
import TestShowMessage from './MessageItem/ShowMessage';
import hasRoles from '../service/security/hasRoles';
import { motion } from "framer-motion";
import { useStylesMessages } from '../css/Style';

export default function Messages({ userData, open }) {
    document.title = mainConstant.MESSAGES
    const classes = useStylesMessages();
    const [newMessageOpen, setNewMessageOpen] = React.useState(false)
    const [receiveMessageOpen, setReceiveMessageOpen] = React.useState(true)
    const [showMessage, setShowMessage] = React.useState(false)
    const [grid, setGrid] = React.useState(2)
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [transition, setTransition] = React.useState(undefined)
    const [severity, setSeverity] = React.useState(undefined)
    const [text, setText] = React.useState('')
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        messages,
        totalItems,
        view,
        fetchMessages,
        createMessages,
        deleteMessages,
        createDiscussion,
    } = useMessage()

    useEffect(() => {
        if (userData) {
            //todo : care from account 'accounting'
            fetchMessages(`/discussions`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    const handleNewMessage = () => {
        setGrid(4)
        setNewMessageOpen(true)
        setReceiveMessageOpen(false)
        setShowMessage([])
    }

    const handleReceiveMessage = () => {
        if (!receiveMessageOpen) {
            setGrid(2)
            setReceiveMessageOpen(true)
            setNewMessageOpen(false)
            setShowMessage([])
        }
    }

    return (
        <>
        <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} >
        <Grid container spacing={matches ? 1 : 3}>
            <Grid item xs={matches ? 12 : grid}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Ma boite mail
                    </ListSubheader>
                    }
                    className={classes.root}
                >
                    <ListItem button onClick={handleReceiveMessage}>
                        <ListItemIcon>
                            <DraftsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Discussion(s)" />
                    </ListItem>
                    {(hasRoles() === 'client' || hasRoles() === 'specialist') && <ListItem button onClick={handleNewMessage}>
                        <ListItemIcon>
                            <SendIcon />
                        </ListItemIcon>
                        <ListItemText primary="Nouveau message" />
                    </ListItem>}
                </List>
            </Grid>
        <Grid item xs={matches ? 12 : grid === 2 ? 10 : 8} className={classes.grid} >
            {hasRoles() === 'client' ? <span style={{fontSize: 17, color: '#05057c'}}>Bonjour ! Un problème technique ? L’équipe Geo-Psy est là pour vous !</span> : 
            hasRoles() === 'specialist' && newMessageOpen && <span style={{fontSize: 17, color: '#05057c'}}>Ici, vous pouvez échanger avec les équipes Geo-Psy. Un souci technique ou une information à nous transmettre ? Envoyez-nous un message et nous vous répondrons dès que possible</span>}
            { newMessageOpen && <NewMessage createMessages={createMessages} createDiscussion={createDiscussion} userData={userData} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} handleReceiveMessage={handleReceiveMessage}/> }
            { (receiveMessageOpen) && <MessageTable showMessage={showMessage} fetchMessages={fetchMessages} view={view} totalItems={totalItems} menuOpen={open} setShowMessage={setShowMessage} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} deleteMessages={deleteMessages} type={receiveMessageOpen} createMessageRows={createMessageRows} rows={createMessageRows(messages, showMessage)} discussions={messages} />}
        </Grid>
    </Grid>
    {showMessage > 0 && <TestShowMessage setShowMessage={setShowMessage} createMessages={createMessages} userData={userData} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} rows={messages} selectedMessage={showMessage} switchMessage={receiveMessageOpen}/>}
    <Alert isOpen={isUpdate} transition={transition} message={text || 'Votre profil a bien été modifié'} setIsUpdate={setIsUpdate} severity={severity} />
    </motion.div>
    </>
    )
}

function createMessageRows(messages) {
    let tab = []
    messages && messages.length && messages.map((discussion) => {
        if (discussion) {
            return tab.push(discussion)
        }
        return null
    })
    return tab
}
