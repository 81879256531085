import {
    CircularProgress,
    Divider,
    Grid,
    Paper,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import * as mainConstant from '../Constant/mainConstant'
import { apiFetch } from '../service/apiFetch';
import hasRoles from '../service/security/hasRoles';
import FoundSpecialists from './DashboardItem/foundSpecialists';
import SpecialistCard from './VisitItem/SpecialistCard';
import {Waypoint} from "react-waypoint";
import SkeletonCard from '../components/SkeletonCard';
import SkeletonFilterPaper from '../components/SkeletonFilterPaper';
import { useForceUpdate } from '../hooks/forceUpdate';
import { removeLastAmpersand, TransitionLeft } from '../helpers/utils'
import Alert from '../components/Alert'
import { motion } from 'framer-motion'
import { useStylesVisit } from '../css/Style';
import moment from 'moment'

let globalSpecialists = null
export function Visit(props) {
    const classes = useStylesVisit();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [spinner, setSpinner] = useState(false)
    const [disable, setDisable] = useState(false)
    const [allSpecialists, setAllSpecialist] = React.useState(null)
    const [view, setView] = React.useState(null)
    const [apiConsultationTypes, setApiConsultationTypes] = React.useState(null)
    const [apiSpecialities, setApiSpecialities] = React.useState(null)
    const [consultationTypes, setConsultationTypes] = React.useState('')
    const [availability, setAvailability] = React.useState('')
    const [specialities, setSpecialites] = React.useState('')
    const [foundByName, setFoundByName] = React.useState(false)
    const [value, setValue] = React.useState('');
    const forceUpdate = useForceUpdate();
    const [newCoordonate, setNewCoordonnate] = React.useState({
      lat: null,
      lng: null
    })
    const [transition, setTransition] = React.useState(false);
    const [severity, setSeveriry] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    useEffect(() => {
      if (hasRoles() === 'client') {
        (async () => {
          props.eventSource.onmessage = event => {
              // Will be called every time an update is published by the server
              updateAllSpecialists(JSON.parse(event.data).data)
          }
          document.title = mainConstant.RDV
          const specialists = await apiFetch('/specialists')
          setAllSpecialist(specialists['hydra:member'])
          globalSpecialists = specialists['hydra:member']
          setView(specialists['hydra:view'])

          apiFetch("/specialities", {
            method: "GET",
          })
          .then(res => {
            return setApiSpecialities(res['hydra:member']);
          })

          apiFetch("/consultation_types", {
            method: "GET",
          })
          .then(res => {
            return setApiConsultationTypes(res['hydra:member']);
          })
        })()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateAllSpecialists = (data) => {
      globalSpecialists.map((specialist) => {
        if (data && specialist.id === data.id) {
          return specialist.platformStatus = data.platformStatus
        }
        return null
      })
      setAllSpecialist(globalSpecialists)
      forceUpdate()
    }

    const enterHandler = async() => {
        setSpinner(true)
        const specialists = view && view['hydra:next'] && await apiFetch(view['hydra:next'])
        if (specialists) {
          setView(specialists['hydra:view'])
          setAllSpecialist((specialists['hydra:member'] && allSpecialists.concat(specialists['hydra:member'])) || allSpecialists)
          globalSpecialists = (specialists['hydra:member'] && allSpecialists.concat(specialists['hydra:member'])) || allSpecialists
        }
        setSpinner(false)
    };

    const handleSearch = async () => {
      setDisable(true)
      const latLng = newCoordonate.lat ? `currentLatLng=${newCoordonate.lat},${newCoordonate.lng}&` : ''
      const chosenSpecialities = specialities ? `specialities=${specialities}&` : ''
      const firstName = foundByName ? `user.firstName=${foundByName}&` : ''
      const chosenAvailability = availability ? `availabilities.startDate[strictly_after]=${moment().format('YYYY-MM-DD')}&availabilities.startDate[strictly_before]=${availability === 'today' ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().add(7, 'days').format('YYYY-MM-DD')}&` : ''
      const chosenConsultationTypes = consultationTypes ? `specialistHasConsultationsType.consultationType=${consultationTypes}&` : ''
      const url = removeLastAmpersand(`/specialists?${latLng}${chosenSpecialities}${firstName}${chosenConsultationTypes}${chosenAvailability}`)
      const filterSpecialist = await apiFetch(url)
      if (filterSpecialist['hydra:member']) {
          setView(filterSpecialist['hydra:view'])
          setAllSpecialist(filterSpecialist['hydra:member'])
          globalSpecialists = filterSpecialist['hydra:member']
      } else {
          setSeveriry('error')
          setTransition(() => TransitionLeft)
          setIsOpen(true)
      }
      setDisable(false)
    }

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const handleChangeName = (e) => {
      setFoundByName(e.target.value)
    }

    return (
        <Paper className={classes.paper}>
            <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} >
            <Typography variant="h5" gutterBottom color='primary' className={classes.title}>
                Prendre un rendez-vous avec un psychologue
            </Typography>
            <Divider />
            <Grid container spacing={3} style={{marginTop: 20}}>
                <Grid item xs={matches ? 10 : 8} container justify="space-between" className={classes.grid}>
                    <Paper elevation={0}>
                        {!matches && <Typography variant="body1" color='primary' className={classes.typoTitle} align={'center'}>
                            La plateforme vous permet d'avoir un psychologue instantanément par téléphone ou par tchat. Si vous préférez la visioconsultation, la consultation à domicile ou la consultation en cabinet, référez-vous au planning du psychologue pour prendre RDV. <br /> Dans la liste ci-dessous découvrez nos psychologues. Filtrez-la en fonction du type de consultation que vous souhaitez, de votre domicile ou de vos besoins.
                        </Typography>}
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes.top}>
              {apiSpecialities && apiConsultationTypes ? <FoundSpecialists availability={availability} setAvailability={setAvailability} handleChangeName={handleChangeName} disable={disable} consultationTypes={consultationTypes} specialities={specialities} setNewCoordonnate={setNewCoordonnate} apiConsultationTypes={apiConsultationTypes} apiSpecialities={apiSpecialities} value={value} setConsultationTypes={setConsultationTypes} setSpecialites={setSpecialites} classes={classes} matches={matches} handleChange={handleChange} handleSearch={handleSearch} /> : <SkeletonFilterPaper classes={classes} matches={matches} />}
              {apiSpecialities && apiConsultationTypes && allSpecialists ? allSpecialists.map((specialist, index) => (
                <React.Fragment key={index}>
                  {index === allSpecialists.length - 1 && <Waypoint onEnter={() => enterHandler(index)} />}
                  <SpecialistCard matches={matches} specialist={specialist} isFromDashboard={true} />
                </React.Fragment>
              )) : <SkeletonCard matches={matches} />}
              {/* <HorizontalStepper client={props.user} /> */}
              {spinner && <div style={{marginTop: '50px', textAlign: 'center'}} ><CircularProgress /></div>}
              <Alert isOpen={isOpen} transition={transition} message={'Désolé, votre recherche n\' a pas pu aboutir'} setIsUpdate={setIsOpen} severity={severity} />
            </div>
            </motion.div>
        </Paper>
    )
}
