import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import hasRoles from '../security/hasRoles'


export const AdminRoute = ({component: Component, ...rest}) => {
    if (rest.classes) {
        return (
            <Route {...rest} render= {(props) => (
                hasRoles() === 'super_admin' || hasRoles() === 'admin' ? <Component breadcrumb={rest.breadcrumb} setBreadcrumb={rest.setBreadcrumb} isSpecialRoute={true} setNewSideBarInfo={rest.setProfileUser} setText={rest.setText} setSeverity={rest.setSeverity} setTransition={rest.setTransition} setIsUpdate={rest.setIsUpdate} classes={rest.classes} userData={null} /> : <Redirect to='/dashboard'/>
              )}/>
        )
    } else {
        return (
            <Route {...rest} render= {(props) => (
                hasRoles() === 'super_admin' || hasRoles() === 'admin' ? <Component {...props} props={rest} matches={rest.matches} profileUser={rest.profileUser} toggleProfile={rest.toggleProfile} userData={rest.profileUser} setProfileUser={rest.setProfileUser} isSpecialist={rest.isSpecialist} hasToFetch={rest.hasToFetch} breadcrumb={rest.breadcrumb} setBreadcrumb={rest.setBreadcrumb} /> : <Redirect to='/dashboard'/>
              )}/>
        )
    }
    
}