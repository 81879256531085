import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, Slide } from '@material-ui/core'
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MoreDetailsPopup({ showPopup, setShowPopup }) {

    return (
        <Dialog
            open={!!showPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setShowPopup(null)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Détails de l'intervention"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Nom : </span>{showPopup.intervention ? showPopup.intervention.client.user.firstName : showPopup.client.user.firstName}<br />
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Prénom : </span>{showPopup.intervention ? showPopup.intervention.client.user.lastName : showPopup.client.user.lastName}<br />
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Adresse : </span>{showPopup.intervention ? showPopup.intervention.address : showPopup.address}<br />
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Motif : </span>{showPopup.intervention ? showPopup.intervention.motif : showPopup.motif}<br />
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Date et heure de la consultation : </span>{showPopup.intervention ? moment(showPopup.intervention.startDate).format('dddd Do MMMM YYYY à HH:mm') : moment(showPopup.startDate).format('dddd Do MMMM YYYY à HH:mm')}<br />
                    {!showPopup.intervention && <span style={{fontWeight: 'bold', fontSize: 19}}>Téléphone : </span>}{showPopup.client && showPopup.client.user.phone}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}