import { Snackbar } from '@material-ui/core';
import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { useStylesAlert } from '../css/Style';

export default function Alert({ isOpen, transition, message, setIsUpdate, severity }) {
    const classes = useStylesAlert();

    const handleClose = () => {
        setIsUpdate(false)
    };
    
    return (
        <>
        {isOpen && message && <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={isOpen}
            autoHideDuration={10000}
            onClose={handleClose}
            TransitionComponent={transition}
            key={transition ? transition.name : ''}
            style={{marginTop: 100}}
            classes={{root: classes.padding}}
        >
            <MuiAlert classes={{icon: classes.icon}} style={{padding: 10, fontSize: 20}} elevation={6} variant="filled" severity={severity || "success"} >{message}</MuiAlert>
        </Snackbar>}
        </>
    )
}