import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useStylesClosePopup } from '../../css/Style';

export default function ClosePopup({ open, handleClose}) {
    const classes = useStylesClosePopup();

    return (
        <div style={{zIndex: 9999999}}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{paper: classes.paper}}
            >
                <DialogTitle id="alert-dialog-title">Quitter la visio</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez-vous mettre fin à la consultation ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{margin: 'auto'}}>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        Annuler
                    </Button>
                    <Button onClick={(e) => handleClose(e, true)} variant="contained" color="primary" autoFocus>
                        terminer la consultation
                    </Button>
                </DialogActions>
            </Dialog>
    </div>
    )
}
