import React, { useEffect, useState } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import SpecialistOfMonth from './ContentManagementItem/SpecialistOfMonth'
import QuoteOfWeek from './ContentManagementItem/QuoteOfWeek'
import BlogArticle from './ContentManagementItem/BlogArticle'
import News from './ContentManagementItem/News'
import Alert from '../components/Alert'
import { useStylesContentManagement } from '../css/Style'
import { useSpecialistOfMonth } from '../hooks/specialistOfMonth'
import { useQuoteOfWeek } from '../hooks/quoteOfWeek'
import { useNews } from '../hooks/news'
import { useBlogPosts } from '../hooks/blog'
import { useTheme } from '@material-ui/core/styles';

export default function ContentManagement() {
    const classes = useStylesContentManagement()
    const [isUpdate, setIsUpdate] = useState(false)
    const [text, setText] = useState(false)
    const [transition, setTransition] = useState(false)
    const [severity, setSeverity] = useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        specialistOfMonth,
        fetchSpecialistOfMonth,
        createSpecialistOfMonth,
        deleteSpecialistOfMonth,
        updateSpecialistOfMonth,
    } = useSpecialistOfMonth()

    const {
        quoteOfWeek,
        fetchQuoteOfWeek,
        createQuoteOfWeek,
        deleteQuoteOfWeek,
        updateQuoteOfWeek,
    } = useQuoteOfWeek()

    const {
        news,
        fetchNews,
        createNews,
        deleteNews,
        updateNews,
    } = useNews()

    const {
        blogPosts,
        fetchBlogPosts,
        createBlogPosts,
        deleteBlogPosts,
        updateBlogPosts,
    } = useBlogPosts()

    useEffect(() => {
        (async () => {
            await fetchSpecialistOfMonth()
            await fetchQuoteOfWeek()
            await fetchNews()
            await fetchBlogPosts()
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container spacing={3}>
            <Grid item xs={matches ? 12 : 6}>
                <SpecialistOfMonth updateSpecialistOfMonth={updateSpecialistOfMonth} deleteSpecialistOfMonth={deleteSpecialistOfMonth} setIsUpdate={setIsUpdate} setText={setText} setSeverity={setSeverity} setTransition={setTransition} classes={classes} fetchSpecialistOfMonth={fetchSpecialistOfMonth} createSpecialistOfMonth={createSpecialistOfMonth} specialistOfMonth={specialistOfMonth} />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
                <QuoteOfWeek classes={classes} setIsUpdate={setIsUpdate} setText={setText} setSeverity={setSeverity} setTransition={setTransition} updateQuoteOfWeek={updateQuoteOfWeek} deleteQuoteOfWeek={deleteQuoteOfWeek} fetchQuoteOfWeek={fetchQuoteOfWeek} createQuoteOfWeek={createQuoteOfWeek} quoteOfWeek={quoteOfWeek} />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
                <BlogArticle classes={classes} setIsUpdate={setIsUpdate} setText={setText} setSeverity={setSeverity} setTransition={setTransition} updateBlogPosts={updateBlogPosts} deleteBlogPosts={deleteBlogPosts} fetchBlogPosts={fetchBlogPosts} createBlogPosts={createBlogPosts} blogPosts={blogPosts} />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
                <News classes={classes} setIsUpdate={setIsUpdate} setText={setText} setSeverity={setSeverity} setTransition={setTransition} updateNews={updateNews} deleteNews={deleteNews} fetchNews={fetchNews} createNews={createNews} news={news} />
            </Grid>
            <Alert isOpen={isUpdate} setIsUpdate={setIsUpdate} message={text} severity={severity} transition={transition} />
      </Grid>
    )
}