import React, {useEffect} from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';


export function ActiveBreadcrumb({ breadcrumb, tab, setBreadcrumb, handleBack }) {
    let history = useHistory()

    useEffect(() => {
        if (history.location.pathname.startsWith('/specialist/profil/') || history.location.pathname.startsWith('/client/profil/')) {
            setBreadcrumb(["Profil", "", "", "Voir"])
        }
    }, [history, setBreadcrumb])

    const handleClick = (event) => {
        event.preventDefault();
        switch(event.currentTarget.innerHTML) {
            case 'Profil':
                handleBack && handleBack()
                setBreadcrumb(['Profil'])
                break
            case 'Ajouter':
                setBreadcrumb(['Profil', 'Ajouter'])
                break
            case 'Modifier':
                setBreadcrumb(['Profil', 'Modifier'])
                break
            case 'Voir':
                setBreadcrumb(['Profil', 'Voir'])
                break
            case 'Map':
                setBreadcrumb(['Profil', 'Map'])
                break
            default:
                console.warn('wrong action')
    
        }
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '30px'}}>
            {tab.map((el, index) => {
                if (el === breadcrumb[index]) {
                    return (
                        <Link key={index} color="inherit" href='/' onClick={handleClick}>
                            {el}
                        </Link>
                    )
                }
                return null
            })}
        </Breadcrumbs>
    );
}