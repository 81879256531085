import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core/';
import moment from 'moment'

export default function OngoingConsultation({ rows }) {

  return (
    <> 
    <Typography variant="h5" gutterBottom style={{textTransform: 'uppercase', color: "#05057c", fontSize: "23px", textAlign: "center"}}>
        Consultations en cours
    </Typography>
    <TableContainer component={Paper}>
        <Table aria-label="consultation table">
            <TableHead>
                <TableRow>
                    <TableCell style={{fontSize: 16, color: '#05057C'}}>Type</TableCell>
                    <TableCell style={{fontSize: 16, color: '#05057C'}} align="right">Client</TableCell>
                    <TableCell style={{fontSize: 16, color: '#05057C'}} align="right">Psychologue</TableCell>
                    <TableCell style={{fontSize: 16, color: '#05057C'}} align="right">Date de début</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows && !!rows.length ? rows.map((row) => (
                    <TableRow key={row['@id']}>
                        <TableCell align="right">{row.type}</TableCell>
                        <TableCell align="right">{row.client.user.firstName} {row.client.user.lastName}</TableCell>
                        <TableCell align="right">{row.specialist.user.firstName} {row.specialist.user.lastName}</TableCell>
                        <TableCell align="right">{moment(row.startDate).format('LLL')}</TableCell>
                    </TableRow>
                )) : 
                    <TableRow>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">Pas de consultation en ce moment...</TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    </TableContainer>
    </>
  );
}