import React from 'react'
import {  Typography, IconButton } from '@material-ui/core';
import articleBlog from "../../img/article-blog.png";
import dictionnairePsy from "../../img/dictionaire-psy.png";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";

export default function ToolboxCard({ classes, matches }) {
    return (
        <div className={classes.toolboxCard}>
            <Typography variant="h5" gutterBottom>
                ma boîte à outils
            </Typography><br />
            <div style={matches ? {marginLeft: 60, textAlign: 'left'} : {}}>
                <Tooltip title="Blog Geo-Psy">
                    <IconButton aria-label="assign" className={classes.toolShadowIcon}>
                        <Link href="https://www.geo-psy.fr/blog/" target="_blank">
                            <img className="fit-picture" src={articleBlog} alt="icon" width='60px' height='60px'></img>
                        </Link>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Le dictionnaire de la santé mentale">
                    <IconButton aria-label="assign" className={classes.toolShadowIcon}>
                        <Link href="https://www.geo-psy.fr/le-lexique-de-la-sante-mentale/" target="_blank">
                            <img className="fit-picture" src={dictionnairePsy} alt="icon" width='60px' height='60px'></img>
                        </Link>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}
