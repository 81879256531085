import React from 'react'
import * as mainConstant from '../Constant/mainConstant' 
import ConsultTab from './ConsultItem/ConsultTab';
import { motion } from "framer-motion";

export default function Consults({ userData, setVisioId }) {
    document.title = mainConstant.CONSULTS
    
    return (
      <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} ><ConsultTab userData={userData} setVisioId={setVisioId}/></motion.div>
    )
}