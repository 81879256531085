import React from 'react'
import {  Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

export default function LastConsultCard({ user, classes, last, isSpecialist}) {
    let history = useHistory();

    const handleClick = (isSpecialist) => {
        isSpecialist ? history.push(`incoming-consultation/${user.specialist.id}`) : history.push(`/psychologue/${last.specialist.id}`)
    }

    return (
        <div className={classes.lastConsultCard}>
            <Typography variant="h5" gutterBottom>
                {isSpecialist ? 'MES DEMANDES EN COURS' : 'MES dernieres consultations'}
            </Typography><br />
            {!isSpecialist ? <Typography variant="body1" gutterBottom style={{marginBottom: 30, marginTop: 30, textTransform: 'none'}} component={'div'}>
                {last && !!last.id && last.specialist ? <div style={{background: '#D1DCEF 0% 0% no-repeat padding-box', borderRadius: '7px', padding: '10px 60px', width: 300, margin: 'auto', font: 'normal normal bold 25px/29px Arial'}}>{last.room && <span style={{font: 'normal normal normal 20px/29px Arial'}}>Durée : {last.room.duration}</span>} avec {last.specialist.user.firstName} par {last.type}</div> : 'Pas encore de consultations...'}
            </Typography> : 
            last && !!last.length ? last.map((consult) => (
                <Typography key={consult['@id']} variant="body1" gutterBottom style={{textTransform: 'none'}} component={'p'}>
                    {moment(consult.createdAt).format('dddd Do MMMM YYYY à HH:mm')} - {consult.type}
                </Typography>
            )) : 'Pas de demandes en cours...'
            }
            {last && !!last.id && !isSpecialist ? <Button
                className={classes.lastConsultButton}
                onClick={handleClick}
            >
                JE CONSULTE CE PSY
            </Button> : 
            last && !!last.length && <Button
                className={classes.lastConsultButton}
                style={{marginTop: 30}}
                onClick={(e) => handleClick(e, true)}
            >
                accéder à mes demandes
            </Button>
            }
        </div>
    )
}