import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { List, ListItem, ListItemText, Collapse, IconButton } from '@material-ui/core'

export default function CustomCollapse({ open, handleOpenCollapse, data, title, style, find }) {

    return (
        <div style={style}>
            {find ? data.find((consultationType) => consultationType.consultationType.name === 'Cabinet') ? <span style={{fontWeight: 'bold'}}>{title}</span> : <></> : <span style={{fontWeight: 'bold'}}>{title}</span>} 
            {find ? data.find((consultationType) => consultationType.consultationType.name === 'Cabinet') ?
            <List dense={true} style={{maringTop: 0, paddingTop: 0}}>
                {data.find((consultationType) => consultationType.consultationType.name === 'Cabinet').forfaits.map((speciality, index) => {
                    if (index < 2) {
                        return <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                            <ListItemText primary={`• ${speciality.type} ${speciality.price}€`} style={{marginBottom: 0, paddingBottom: 0}}/>
                    </ListItem>
                    }
                    return null
                })}
                <Collapse in={open} timeout="auto" unmountOnExit>
                {data.find((consultationType) => consultationType.consultationType.name === 'Cabinet').forfaits.map((speciality, index) => {
                    if (index >= 2) {
                        return <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                        <ListItemText primary={`• ${speciality.type} ${speciality.price}€`} style={{marginBottom: 0, paddingBottom: 0}}/>
                    </ListItem>
                    }
                    return null
                })}
                </Collapse>
            </List>
            : <></> :
            <List dense={true} style={{maringTop: 0, paddingTop: 0}}>
                {data.map((speciality, index) => {
                    if (index < 2) {
                        return <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                        <ListItemText primary={`• ${speciality.name ? speciality.name : speciality.consultationType.name}`} style={{marginBottom: 0, paddingBottom: 0}}/>
                    </ListItem>
                    }
                    return null
                })}
                <Collapse in={open} timeout="auto" unmountOnExit>
                {data.map((speciality, index) => {
                    if (index >= 2) {
                        return <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                        <ListItemText primary={`• ${speciality.name ? speciality.name : speciality.consultationType.name}`} style={{marginBottom: 0, paddingBottom: 0}}/>
                    </ListItem>
                    }
                    return null
                })}
                </Collapse>
            </List>}
            {find ? data.find((consultationType) => consultationType.consultationType.name === 'Cabinet') ?
            <IconButton variant="contained" size='small' onClick={(e) => handleOpenCollapse(e, title)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton> : <></> : <IconButton variant="contained" size='small' onClick={(e) => handleOpenCollapse(e, title)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>}
        </div>
    )
}