module.exports = {
    DASHBOARD: 'Tableau de bord',
    PROFILE: 'Profil',
    MESSAGES: 'Mes messages',
    CONSULTS: 'Mes consultations',
    CHAT: 'Chat',
    PLANNING: 'Mon planning',
    SETTINGS: 'Paramètres',
    ERROR: 'ERREUR',
    LOGIN: 'Connexion',
    RDV: 'Prise de rendez-vous',
    PAYMENT: 'Mes paiements',
    RECHARGE: 'Recharger mon compte',
    USER: 'ROLE_USER',
    ADMIN: 'ROLE_ADMIN',
    SPECIALIST: 'ROLE_SPECIALIST',
    SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    CLIENT: 'ROLE_CLIENT',
    MAP: 'Map',
    FUTURECONSULTATION: 'Mes demandes en cours'
}
