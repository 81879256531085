import { createMuiTheme, LinearProgress, Paper, Table, TableContainer, TablePagination, ThemeProvider, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react'
import { EnhancedTableBody } from '../../components/EnhancedTableBody';
import { EnhancedTableHead } from '../../components/EnhancedTableHead';
import { EnhancedTableToolbar } from '../../components/EnhancedTableToolbar';
import * as locales from '@material-ui/core/locale';
import { ActiveBreadcrumb } from '../../components/Breadcrumb'
import { AddSpecialist } from './Specialist/AddSpecialist';
import ShowSpecialistMap from './Specialist/ShowSpecialistMap';
import { useStylesSuperAdminProfile } from '../../css/Style';
import { apiFetch } from '../../service/apiFetch';
import { download, TransitionLeft } from '../../helpers/utils';
import moment from "moment";
import DatePickerPopup from '../../components/DatePickerPopup';
import { isAuth } from '../../service/security/isAuth';
import { useHistory } from 'react-router-dom';

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nom Prénom' },
    { id: 'mobile', numeric: true, disablePadding: false, label: 'Téléphone' },
    { id: 'address', numeric: true, disablePadding: false, label: 'Adresse' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'edit', numeric: true, disablePadding: false, label: 'Action' },
];

const breadcrumbTab = [
  'Profil',
  'Ajouter',
  'Modifier',
  'Voir',
  'Map',
];

export function SuperAdminProfile({ foundByName, setFoundByName, fetchClients, currentProfile, fetchSpecialists, view, totalItems, users, defaultClasses, setTransition, setIsUpdate, setText, createSpecialist, setBreadcrumb, breadcrumb, setSeverity, isSpecialist }) {
    const classes = useStylesSuperAdminProfile();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [disable, setDisable] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [openDatePickerPopup, setOpenDatePickerPopup] = React.useState(false);
    const [fees, setFees] = React.useState(false);
    const [downloadLoading, setDownloadLoading] = React.useState(false);
    const [actif, setActif] = React.useState('');
    const [locale] = React.useState('frFR');
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    let history = useHistory();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        currentProfile === 'specialists' && property === 'name' && fetchSpecialists(`/specialists?order[user.firstName]=${isAsc ? 'desc' : 'asc'}`)
        currentProfile !== 'specialists' && property === 'name' && fetchClients(`/clients?order[user.firstName]=${isAsc ? 'desc' : 'asc'}`)
      };
    
    const handleChangePage = async (event, newPage) => {
      setLoading(true)
      if (newPage > page) {
        currentProfile === 'specialists' ?
        await fetchSpecialists(view['hydra:next']) :
        await fetchClients(view['hydra:next'])
      } else if (newPage < page) {
        currentProfile === 'specialists' ?
        await fetchSpecialists(view['hydra:previous']) :
        await fetchClients(view['hydra:previous'])
      }
      window.scrollTo(0,0)
      setLoading(false)
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleOnEdit = async (event, row) => {
      setBreadcrumb(['Profil', '', 'Modifier'])
    }

    const handleOnShow = async (event, row) => {
      setBreadcrumb(['Profil', '', '', 'Voir'])
    }

    useEffect(() => {
      setBreadcrumb(['Profil'])
      setYear('')
      setMonth('')
    }, [setRowsPerPage, users, setBreadcrumb])

    const exportFiles = async (e, onPopup, fees) => {
      if (fees) {
        setFees(true)
        exportFees(onPopup)
      } else {
        exportUsers(onPopup)
      }
    }

    const exportFees = async (onPopup) => {
      if (!onPopup) {
        setOpenDatePickerPopup(!openDatePickerPopup)
      } else {
        setDownloadLoading(true)
        const fees = await apiFetch(`/accounting/specialists/resume/zip${year && month ? `?year=${year}&month=${month}` : year ? `?year=${year}` : month ? `?month=${month}` : ''}`, {}, false, false, true)

        if (fees.ok) {
          const exportFees = await fees.blob()
          download(exportFees, `Honoraires-${moment().format('LL')}.zip`)
        } else {
          const response = await fees.json()
          setText(response.message)
          setSeverity('error')
          setTransition(() => TransitionLeft)
          setIsUpdate(true)
        }
        setDownloadLoading(false)
        setOpenDatePickerPopup(!openDatePickerPopup)
        setFees(false)
        setMonth(null)
        setYear(null)
      }
    }

    const exportUsers = async (onPopup) => {
      if (!onPopup) {
        setOpenDatePickerPopup(!openDatePickerPopup)
      } else {
        setDownloadLoading(true)
        let user = null
        if (isSpecialist) {
          user = await apiFetch(`/accounting/specialists/resume${year && month ? `?year=${year}&month=${month}` : year ? `?year=${year}` : month ? `?month=${month}` : ''}`, {}, false, false, true)
        } else {
          user = await apiFetch(`/accounting/clients/resume${year && month ? `?year=${year}&month=${month}` : year ? `?year=${year}` : month ? `?month=${month}` : ''}`, {}, false, false, true)
        }
        if (user.ok) {
          const exportClient = await user.blob()
          download(exportClient, `${isSpecialist ? 'Specialists-' : 'Client-' }${moment().format('LL')}.csv`)
        }
        setDownloadLoading(false)
        setOpenDatePickerPopup(!openDatePickerPopup)
        setMonth(null)
        setYear(null)
      }
    }

    const onLoginAs = async (e, row) => {
      const loginAs = await apiFetch(`/impersonate?_switch_user=${row.user.email}`)
      if (loginAs && loginAs.token) {
        localStorage.setItem('REACT_TOKEN_AUTH_ORIGIN', JSON.stringify(isAuth()))
        localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(loginAs))
        history.push('/dashboard')
        window.location.reload()
      }
    }

    const handleSearch = async (e, isSpecialist) => {
      setDisable(true)
      if (foundByName) {
        isSpecialist ? await fetchSpecialists(`/specialists?user.firstName=${foundByName}${actif && actif !== 'all' ? `&active=${actif === 'inactif' ? 'false' : 'true'}` : ''}`) : await fetchClients(`/clients?user.firstName=${foundByName}`)
      } else {
        isSpecialist ? await fetchSpecialists(`/specialists?order[user.firstName]=asc${actif && actif !== 'all' ? `&active=${actif === 'inactif' ? 'false' : 'true'}` : ''}`) : await fetchClients(`/clients?order[user.firstName]=asc`)
      }
    }

    const handleChangeName = (e) => {
      setDisable(false)
      setFoundByName(e.target.value)
    }

    const handleChangeActif = (e) => {
      setDisable(false)
      setActif(e.target.value)
    }

    return (
    <div className={classes.root}>
        <ActiveBreadcrumb breadcrumb={breadcrumb} tab={breadcrumbTab} setBreadcrumb={setBreadcrumb} />
        {(!breadcrumb[1] && breadcrumb[1] !== '') && <Paper className={classes.paper}>
            <EnhancedTableToolbar title={'Profils'} disable={disable} actif={actif} handleChangeActif={handleChangeActif} handleChangeName={handleChangeName} handleSearch={handleSearch} handleChange={true} setBreadcrumb={setBreadcrumb} isSpecialist={isSpecialist} exportFiles={exportFiles} isAdmin={true} />
            {users ? <><TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                  aria-label="enhanced table"
                >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={users.length}
                      headCells={headCells}
                    />
                    <EnhancedTableBody
                      order={order}
                      orderBy={orderBy}
                      rows={users}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onEdit={handleOnEdit}
                      onShow={handleOnShow}
                      specialistTable={isSpecialist}
                      modifyOption={true}
                      emptyMessage='Pas de spécialistes...'
                      onLoginAs={onLoginAs}
                    />
                </Table>
            </TableContainer>
            {!!totalItems && <ThemeProvider theme={(outerTheme) => createMuiTheme(outerTheme, locales[locale])}>
                <TablePagination
                  labelRowsPerPage=''
                  nextIconButtonProps={{ disabled: loading || page >= (totalItems / rowsPerPage) - 1}}
                  backIconButtonProps={{ disabled: loading || page === 0 }} 
                  rowsPerPageOptions={[]}
                  component="div"
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ThemeProvider>}</>  : <LinearProgress /> }
            {loading && <LinearProgress />}
      </Paper>}
      {breadcrumb[1] === 'Ajouter' && <AddSpecialist matches={matches} createSpecialist={createSpecialist} classes={defaultClasses} setBreadcrumb={setBreadcrumb} setTransition={setTransition} setIsUpdate={setIsUpdate} setSeverity={setSeverity} setText={setText}/>}
      {breadcrumb[4] === 'Map' && <ShowSpecialistMap matches={matches}/>}
      <DatePickerPopup classes={classes} year={year} month={month} setYear={setYear} setMonth={setMonth} downloadLoading={downloadLoading} fees={fees} exportFiles={exportFiles} openDatePickerPopup={openDatePickerPopup} setOpenDatePickerPopup={setOpenDatePickerPopup} />
    </div>
  )
}