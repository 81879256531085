import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Tooltip, IconButton, Card, CardContent, Avatar, Typography, Badge} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink } from 'react-router-dom';
import TvIcon from '@material-ui/icons/Tv';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import ChatIcon from '@material-ui/icons/Chat';
import clsx from 'clsx';
import { useStylesSpecialistCard } from '../../css/Style';
import PricePopup from '../../components/PricePopup';
import moment from "moment"

export default function SpecialistCard({ specialist, specialistChoose, matches, isFromDashboard, isFromStepper }) {
  const StyledBadge = withStyles((theme) => ({
        badge: {
          backgroundColor: (`${specialist.platformStatus === 'online' ? '#6EDEBB' : (specialist.platformStatus === 'busy' || specialist.platformStatus === 'waiting-call') ? '#F19244' : '#C4C8D2' }`),
          color: (`${specialist.platformStatus === 'online' ? '#6EDEBB' : (specialist.platformStatus === 'busy' || specialist.platformStatus === 'waiting-call') ? '#F19244' : '#C4C8D2' }`),
          width: 25,
          height: 25,
          borderRadius: '50%',
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: isFromDashboard ? -1 : 0,
            left: isFromDashboard ? -1 : 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }))(Badge);
  const classes = useStylesSpecialistCard();
  const [openPricePopup, setOpenPricePopup] = useState(false)
  const handleClick = (e) => {
    specialistChoose && specialistChoose(e)
  }

  const handleNavClick = (e, type) => {
    if (!hasConsultationType(type)) e.preventDefault()
  }

  const hasConsultationType = (type) => {
    return specialist.readableConsultationType.find((consultationType) => {
      return consultationType.name === type
    })
  }

  return (
    <Card className={classes.root} onClick={() => handleClick(specialist)} >
      <CardContent style={matches ? {fontSize: '15px'} : {}}>
        <Grid container justify="space-between" className={classes.grid}>
          <Grid item xs={matches ? 12 : 2}>
                {specialist.readableConsultationType.find((type) => type.name === 'Plateforme') ? <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                <Avatar aria-label="photo" src={`${process.env.REACT_APP_BASE_URL}${specialist.photo.contentUrl}`} className={classes.avatar} style={{width: 150, height: 150}} />
                </StyledBadge> : <Avatar aria-label="photo" src={`${process.env.REACT_APP_BASE_URL}${specialist.photo.contentUrl}`} className={classes.avatar} style={{width: 150, height: 150}} />}
          </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <Grid container className={classes.grid}>
            <Grid item xs={matches ? 12 : 9}>
              <div style={{marginLeft: 20}}>
                <Typography variant="body1" color="textSecondary" component="p" className={clsx(classes.leftTypography, classes.name)}>
                  {specialist.user.firstName}
                  <span style={{marginLeft: 30}}>{specialist.languages.map((type) => {
                      return <Tooltip key={type.id} title={type.name} style={{fontSize: 8, marginLeft: 5}}>
                        <img src={`${process.env.REACT_APP_BASE_URL}${type.filePath}`} alt='' width='28px' height='20px' />
                      </Tooltip>
                  })}</span>
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" className={clsx(classes.leftTypography, classes.city)}>
                  {specialist.city} {specialist.readableConsultationType.find((type) => type.name === 'Intervention') && 
                  <span style={{fontWeight: 400, marginLeft: 30}}>Intervient à domicile dans un rayon de {specialist.interventionDistance} km</span>}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" className={classes.rightTypography} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                  <span style={{fontWeight: 'bold'}}>Spécialité(s) : </span> {specialist.specialities.map((type) => {
                    return `${type.name}, `
                  })}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={matches ? 12 : 3}>
              {!isFromStepper && <NavLink to={`/psychologue/${specialist.id}`} style={{textDecoration: 'none'}} >
                <Button
                  size="large"
                  color="primary"
                  className={classes.button}
                  endIcon={<ArrowForwardIcon />}
                >
                  Voir la fiche complète
                </Button>
              </NavLink>}
            </Grid>
          </Grid>
          <Typography variant="body1" color="textSecondary" component="p" className={classes.rightTypography} style={{marginLeft: 20, marginTop: 10}}>
              {specialist.shortDescription}
          </Typography>
        </Grid>
        </Grid>
        <Grid container className={classes.grid} style={{marginTop: 30}}>
          <Grid item xs={matches ? 12 : 5}>
            {matches ?  
            <div style={{textAlign: 'center', marginBottom: 10}}>
              <Typography variant='body1' color='primary' className={classes.title} >
                Sans RDV - Consultez dès maintenant
              </Typography>
              <React.Fragment >
                  <div style={{display: 'flex', marginBottom: 5}}>
                  <NavLink onClick={(event) => handleNavClick(event, 'Plateforme')} to={{pathname: `/psychologue/${specialist.id}`, state: 'phone' }} style={{textDecoration: 'none'}} ><IconButton aria-label="phone" id='phone' className={classes.IconButton} style={!hasConsultationType('Plateforme') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#07057C 0% 0% no-repeat padding-box', color: 'white'}}>
                    <PhoneIcon />
                  </IconButton></NavLink>
                    <span style={{marginTop: 10}}>
                      {specialist.platformStatus === 'online' && hasConsultationType('Plateforme') ? 'Actuellement disponible' : hasConsultationType('Plateforme') && specialist.nextPlatformSlot ? `Prochaine disponibilité : ${moment(specialist.nextPlatformSlot.startDate).format('DD MMMM - HH:mm')}` : hasConsultationType('Plateforme') && 'Pas de prochaine disponibilité'}
                    </span>
                  </div>
                  <div style={{display: 'flex', marginBottom: 5}}>
                  <NavLink onClick={(event) => handleNavClick(event, 'Plateforme')} to={{pathname: `/psychologue/${specialist.id}`, state: 'phone' }} style={{textDecoration: 'none'}} ><IconButton aria-label="phone" id='phone' className={classes.IconButton} style={!hasConsultationType('Plateforme') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#79B0E0 0% 0% no-repeat padding-box', color: 'white'}}>
                    <ChatIcon />
                  </IconButton></NavLink>
                    <span style={{marginTop: 10}}>
                      {specialist.platformStatus === 'online' && hasConsultationType('Plateforme') ? 'Actuellement disponible' : hasConsultationType('Plateforme') && specialist.nextPlatformSlot ? `Prochaine disponibilité : ${moment(specialist.nextPlatformSlot.startDate).format('DD MMMM - HH:mm')}` : hasConsultationType('Plateforme') && 'Pas de prochaine disponibilité'}
                    </span>
                  </div>
              </React.Fragment>
            </div>
             : 
            <>
              <Typography variant='body1' color='primary' className={classes.title}>
                  Sans RDV - Consultez dès maintenant
              </Typography>
                  <React.Fragment>
                    <div style={{display: 'flex'}}>
                      <Tooltip title={!hasConsultationType('Plateforme') ? "Ce psychologue ne propose pas ce service" : ""}>
                        <NavLink onClick={(event) => handleNavClick(event, 'Plateforme')} to={{pathname: `/psychologue/${specialist.id}`, state: 'phone' }} style={{textDecoration: 'none'}} ><Button className={classes.topButton} style={!hasConsultationType('Plateforme') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#07057C 0% 0% no-repeat padding-box', color: 'white'}}>
                          PAR APPEL
                        </Button></NavLink>
                      </Tooltip>
                      <span style={{marginTop: 10}}>
                      {specialist.platformStatus === 'online' && hasConsultationType('Plateforme') ? 'Actuellement disponible' : hasConsultationType('Plateforme') && specialist.nextPlatformSlot ? `Prochaine disponibilité : ${moment(specialist.nextPlatformSlot.startDate).format('DD MMMM - HH:mm')}` : hasConsultationType('Plateforme') && 'Pas de prochaine disponibilité'}
                      </span>
                    </div>
                    <div style={{display: 'flex'}}>
                      <Tooltip title={!hasConsultationType('Plateforme') ? "Ce psychologue ne propose pas ce service" : ""}>
                        <NavLink onClick={(event) => handleNavClick(event, 'Plateforme')} to={{pathname: `/psychologue/${specialist.id}`, state: 'phone' }} style={{textDecoration: 'none'}} ><Button className={classes.topButton} style={!hasConsultationType('Plateforme') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#79B0E0 0% 0% no-repeat padding-box', color: '#07057C'}}>
                          par tchat
                        </Button></NavLink>
                      </Tooltip>
                      <span style={{marginTop: 10}}>
                      {specialist.platformStatus === 'online' && hasConsultationType('Plateforme') ? 'Actuellement disponible' : hasConsultationType('Plateforme') && specialist.nextPlatformSlot ? `Prochaine disponibilité : ${moment(specialist.nextPlatformSlot.startDate).format('DD MMMM - HH:mm')}` : hasConsultationType('Plateforme') && 'Pas de prochaine disponibilité'}
                      </span>
                    </div>
                  </React.Fragment>
              </>}
          </Grid>
          <Grid item xs={matches ? 12 : 7} style={{paddingLeft: 10}}>
          {matches ?  
            <div style={{textAlign: 'center', marginBottom: 10}}>
              <React.Fragment >
                  <Typography variant='body1' color='primary' className={classes.title} >
                    Prendre RDV
                  </Typography>
                  <div style={{display: 'flex', marginBottom: 5}}>
                  <NavLink onClick={(event) => handleNavClick(event, 'Cabinet')} to={{pathname: `/psychologue/${specialist.id}`, state: 'office' }} style={{textDecoration: 'none'}} ><IconButton aria-label="office" id='office' className={classes.IconButton} style={!hasConsultationType('Cabinet') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#D6F5F3 0% 0% no-repeat padding-box', color: '#07057C'}}>
                      <BusinessIcon />
                  </IconButton></NavLink>
                    <span style={{marginTop: 10}}>
                    { hasConsultationType('Cabinet') && specialist.nextCabinetSlot ? 'Prochaine disponibilité : ' + moment(specialist.nextCabinetSlot.startDate).format('DD MMMM - HH:mm') : hasConsultationType('Cabinet') && 'Pas de prochaine disponibilité'}
                    </span>
                  </div>
                  <div style={{display: 'flex', marginBottom: 5}}>
                  <NavLink onClick={(event) => handleNavClick(event, 'Intervention')} to={{pathname: `/psychologue/${specialist.id}`, state: 'intervention' }} style={{textDecoration: 'none'}} ><IconButton aria-label="home" id='intervention' className={classes.IconButton} style={!hasConsultationType('Intervention') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#D1DCEF 0% 0% no-repeat padding-box', color: '#07057C'}}>
                      <HomeIcon />
                  </IconButton></NavLink>
                    <span style={{marginTop: 10}}>
                    { hasConsultationType('Intervention') && specialist.nextHomeSlot ? 'Prochaine disponibilité : ' + moment(specialist.nextHomeSlot.startDate).format('DD MMMM - HH:mm') : hasConsultationType('Intervention') && 'Pas de prochaine disponibilité'}
                    </span>
                  </div>
                  <div style={{display: 'flex', marginBottom: 5}}>
                  <NavLink onClick={(event) => handleNavClick(event, 'Visio')} to={{pathname: `/psychologue/${specialist.id}`, state: 'visio' }} style={{textDecoration: 'none'}} ><IconButton aria-label="tv" id='visio' className={classes.IconButton} style={!hasConsultationType('Visio') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#79E0DB 0% 0% no-repeat padding-box', color: 'white'}}>
                      <TvIcon />
                  </IconButton></NavLink>
                    <span style={{marginTop: 10}}>
                    {hasConsultationType('Visio') && specialist.nextVisioSlot ? 'Prochaine disponibilité : ' + moment(specialist.nextVisioSlot.startDate).format('DD MMMM - HH:mm') : hasConsultationType('Visio') && 'Pas de prochaine disponibilité'}
                    </span>
                  </div>
              </React.Fragment>
            </div>
             : 
            <>
                  <React.Fragment>
                    <Typography variant='body1' color='primary' className={classes.title} >
                      Prendre RDV
                    </Typography>
                    <div style={{display: 'flex'}}>
                    <Tooltip title={!hasConsultationType('Cabinet') ? "Ce psychologue ne propose pas ce service" : ""}>
                      <NavLink onClick={(event) => handleNavClick(event, 'Cabinet')} to={{pathname: `/psychologue/${specialist.id}`, state: 'office' }} style={{textDecoration: 'none'}} ><Button className={classes.topButton} style={!hasConsultationType('Cabinet') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#D6F5F3 0% 0% no-repeat padding-box', color: '#07057C'}}>
                          en cabinet
                      </Button></NavLink>
                    </Tooltip>
                    <span style={{marginTop: 10}}>
                    { hasConsultationType('Cabinet') && specialist.nextCabinetSlot ? 'Prochaine disponibilité : ' +  moment(specialist.nextCabinetSlot.startDate).format('DD MMMM - HH:mm') : hasConsultationType('Cabinet') && 'Pas de prochaine disponibilité'}
                    </span>
                    </div>
                    <div style={{display: 'flex'}}>
                    <Tooltip title={!hasConsultationType('Intervention') ? "Ce psychologue ne propose pas ce service" : ""}>
                      <NavLink onClick={(event) => handleNavClick(event, 'Intervention')} to={{pathname: `/psychologue/${specialist.id}`, state: 'intervention' }} style={{textDecoration: 'none'}} ><Button className={classes.topButton} style={!hasConsultationType('Intervention') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#D1DCEF 0% 0% no-repeat padding-box', color: '#07057C'}}>
                          à domicile
                      </Button></NavLink>
                    </Tooltip>
                    <span style={{marginTop: 10}}>
                    { hasConsultationType('Intervention') && specialist.nextHomeSlot ?  'Prochaine disponibilité : ' + moment(specialist.nextHomeSlot.startDate).format('DD MMMM - HH:mm') : hasConsultationType('Intervention') && 'Pas de prochaine disponibilité'}
                    </span>
                    </div>
                    <div style={{display: 'flex'}}>
                    <Tooltip title={!hasConsultationType('Visio') ? "Ce psychologue ne propose pas ce service" : ""}>
                      <NavLink onClick={(event) => handleNavClick(event, 'Visio')} to={{pathname: `/psychologue/${specialist.id}`, state: 'visio' }} style={{textDecoration: 'none'}} ><Button className={classes.topButton} style={!hasConsultationType('Visio') ? {background: '#c9c9c9 0% 0% no-repeat padding-box', color: 'black', cursor: 'not-allowed'} : {background: '#79E0DB 0% 0% no-repeat padding-box', color: 'white'}}>
                          visioconsultation
                      </Button></NavLink>
                    </Tooltip>
                    <span style={{marginTop: 10}}>
                    { hasConsultationType('Visio') && specialist.nextVisioSlot ? 'Prochaine disponibilité : ' + moment(specialist.nextVisioSlot.startDate).format('DD MMMM - HH:mm') : hasConsultationType('Visio') && 'Pas de prochaine disponibilité'}
                    </span>
                    </div>
                  </React.Fragment>
              </>}
          </Grid>
        </Grid>
        <PricePopup specialist={specialist} setOpen={setOpenPricePopup} open={openPricePopup} />
      </CardContent>
    </Card>
  );
}
