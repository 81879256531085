import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { TransitionLeft } from '../helpers/utils';
import { apiFetch } from '../service/apiFetch';
import Alert from '../components/Alert';
import { useHistory } from 'react-router-dom'
import { useTheme, useMediaQuery, Typography, CircularProgress, Button } from '@material-ui/core'
import NavBar from './NavBar';
import Footer from '../components/Footer';
import { isAuth } from '../service/security/isAuth';

export function EmailValidation() {
    const { id } = useParams();
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [transition, setTransition] = React.useState(undefined)
    const [severity, setSeverity] = React.useState(undefined)
    const [text, setText] = React.useState('')
    let history = useHistory();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        (async () => {
            if (!isAuth()) {
                const validation = await apiFetch(`/users/mail/verification/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                if (validation) {
                    setIsUpdate(true)
                    setTransition(() => TransitionLeft)
                    setSeverity('success')
                    setText('Votre email a bien été validé')
                } else {
                    setIsUpdate(true)
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setText('Echec lors de la validation de votre email')
                }
            } else {
                history.push('/dashboard')
            }
        })()
    }, [id, history])

    const handleClick = () => {
        history.push('/')
    }

    return (
        <>
            <NavBar matches={matches} />
            <div style={{textAlign: 'center'}}>
            {!severity && <CircularProgress style={{marginTop:200}}/>}
            {severity === 'success' && <div style={{marginTop:200, marginBottom: 100}}>
                <Typography color='primary' variant="h2" gutterBottom>
                    Bienvenue !
                </Typography>
                <Typography color='secondary' variant="h4" gutterBottom>
                    Votre compte est activé.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Vous pouvez dès maintenant échanger avec le psychologue de votre choix.
                </Typography>
            </div>}
            {severity === 'error' &&  <div style={{marginTop:200, marginBottom: 100}}><Typography variant="body1" gutterBottom>
                    Votre compte n'a pas pu être validé !
                </Typography></div>}
            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
            >
                retourner à l'accueil
            </Button>
            </div>
            <div style={{bottom: 0, width: '100%', marginTop: '50px'}}>
                <Footer />
            </div>
            <Alert isOpen={isUpdate} transition={transition} message={text} setIsUpdate={setIsUpdate} severity={severity} />
        </>
    )
}