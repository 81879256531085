import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
    CardElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";
import "../css/payment.css"
import { apiFetch } from '../service/apiFetch';
import { TransitionLeft } from '../helpers/utils';
import { Box, Checkbox, Typography } from '@material-ui/core'

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export function Payment({ setStepper, customStyle = {}, setNbConsultation, setPayment, setTransition, setPaymentValidate, type, quantity, setSeveriry, setText, setOpen, id, restocking, body, handleValidation }) {

  return (
        <div className="App" style={customStyle}>
            <Elements stripe={promise}>
                <CheckoutForm setStepper={setStepper} setNbConsultation={setNbConsultation} handleValidation={handleValidation} body={body} restocking={restocking} id={id} setOpen={setOpen} setPayment={setPayment} setTransition={setTransition} setPaymentValidate={setPaymentValidate} type={type} quantity={quantity} setSeveriry={setSeveriry} setText={setText} />
            </Elements>
        </div>
      );
}

function CheckoutForm({ setStepper, setNbConsultation, handleValidation, setPayment, setTransition, setPaymentValidate, type, quantity, setSeveriry, setText, setOpen, id, restocking, body }) {
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [processing, setProcessing] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [clientSecret, setClientSecret] = React.useState('');
  const [checked, setChecked] = React.useState(false)
  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (restocking) {
      apiFetch(`/payment_intents`, {
        method: "POST",
        body: JSON.stringify(body)
      }, true)
      .then(res => {
        if (res['hydra:description']) {
          setText(res['hydra:description'])
          setTransition && setTransition(() => TransitionLeft);
          setSeveriry && setSeveriry('error')
          setNbConsultation(null)
          setPayment(true)
        }
        setClientSecret(res)
        return res;
      })
    } else {
      apiFetch(`/interventions/${id}/payment`, {
        method: "GET",
      }, true)
      .then(res => {
        if (res['hydra:description']) {
          setText(res['hydra:description'])
          setTransition && setTransition(() => TransitionLeft);
          setSeveriry && setSeveriry('error')
          setPayment(true)
          setTimeout(() => {
            setStepper && setStepper(false)
          }, 5000)
        }
        setClientSecret(res)
        return res;
      })
      .catch(() => {
        setText('Désolé, il y a un problème avec le service de paiement, veuillez réessayer plus tard')
        setTransition && setTransition(() => TransitionLeft);
        setSeveriry && setSeveriry('error')
        setPayment(true)
        setTimeout(() => {
          setStepper && setStepper(false)
        }, 5000)
      })
    }
  }, [type, quantity, id, restocking, body, setNbConsultation, setText, setSeveriry, setPayment, setTransition, setStepper]);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async ev => {
    ev.preventDefault();
    if (!checked) {
      setSuccess(null)
      setPayment(true)
      setTransition && setTransition(() => TransitionLeft);
      setSeveriry && setSeveriry('error')
      setText && setText(`Veuillez accepter les conditions générales d'utilisation et de vente`)
      setProcessing(false);
      return
    }
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    if (payload.error) {
      setSuccess(null)
      setPayment(true)
      setTransition && setTransition(() => TransitionLeft);
      setSeveriry && setSeveriry('error')
      setText && setText(`Le paiement a échoué : ${payload.error.message}`)
      setError(`Le paiement a échoué : ${payload.error.message}`);
      setProcessing(false);
    } else {
      setSuccess('Votre paiement a bien été pris en compte')
      setError(null);
      setProcessing(false);
      handleValidation && handleValidation()
      setTransition && setTransition(() => TransitionLeft);
      setSeveriry && setSeveriry('success')
      setText && setText('Merci, votre paiement à bien été pris en compte, vous pouvez commencer à consulter')
      setPaymentValidate && setPaymentValidate(true)
      setPayment(true)
      setOpen && setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  };

  const handleChangeCheck = () => {
    setChecked(!checked);
  };

  return (
    <form className="form" id="payment-form" onSubmit={handleSubmit}>
      {clientSecret.amount && `Montant: ${clientSecret.amount/100} €`}
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        className="button" 
        disabled={processing || disabled}
        id="submit"
      >
        <span id="button-text"> 
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Payer"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" style={{color: 'red'}} role="alert">
          {error}
        </div>
      )}
      {success && (
        <div className="card-error" style={{color: 'green'}} role="alert">
          {success}
        </div>
      )}
      <p>* Paiement sécurisé</p>
      <Box
          mt={2}
          display={'flex'}
          alignItems={'center'}
      >
          <Checkbox
              checked={checked}
              onChange={handleChangeCheck}
              name="termofuse"
              color="primary"
          />
          <Typography style={{fontSize: 12}}>
              J'ai lu et j'accepte les <a rel="noopener noreferrer" target='_blank' style={{textDecoration: 'none'}} href="https://www.geo-psy.fr/conditions-generales-de-vente/"><u>conditions générales d'utilisation et de vente</u></a>
          </Typography>
      </Box>
    </form>
  );
}
