 class AuthService {
    login(identifier) {
        return fetch(
            process.env.REACT_APP_BASE_URL + '/authentication_token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(identifier)
            }
        )
    }

    logout() {
        localStorage.removeItem('REACT_TOKEN_AUTH')
        localStorage.removeItem('REACT_TOKEN_AUTH_ORIGIN')
        localStorage.removeItem('REACT_ROLE')
        localStorage.removeItem('LAST_URL')
        window.location.reload()
    }
}

 export default new AuthService()
