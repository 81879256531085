import React from 'react'
import {  Typography, Avatar, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export default function SpecialistOfMonthCard({ classes, consult }) {
    let history = useHistory();

    const handleClick = () => {
        history.push(`/psychologue/${consult && consult.psyStar && consult.psyStar.specialist.id}`)
    }

    return (
        <>
            {consult && consult.psyStar && <div className={classes.specialistOfMonthCard}>
                <Typography variant="h5" gutterBottom style={{textAlign: 'center', marginBottom: 35}}>
                    LE PSY DU MOIS
                </Typography>
                <Avatar style={{width: 170, height: 170, margin: 'auto', marginBottom: 60}} alt="Aurélia" src={`${process.env.REACT_APP_BASE_URL}${consult.psyStar.specialist.photo.contentUrl}`} />
                <Typography variant="h6" gutterBottom style={{textAlign: 'left', marginLeft: 15, font: 'normal normal bold 25px/18px Arial', textTransform: 'uppercase'}}>
                    {consult.psyStar.specialist.user.firstName}
                </Typography><br />
                <Typography variant="body1" gutterBottom style={{textAlign: 'left', marginBottom: 35, marginLeft: 15, marginRight: 15, fontWeight: 'bold', font: 'normal normal bold 16px/18px Arial',}}>
                    {consult.psyStar.description}
                </Typography>
                <Button
                    className={classes.specialistOfMonthButton}
                    onClick={handleClick}
                    endIcon={<ArrowRightIcon />}
                >
                    JE CONSULTE CE PSY
                </Button>
            </div>}
        </>
    )
}
