import React, { useEffect } from 'react'
import {TabsLogin} from './Scenes/TabsLogin'
import MainMenu from './Scenes/Menu'
import { Redirect, Route, useLocation } from 'react-router-dom'
import {isAuth} from './service/security/isAuth'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { ChangePassword } from './Scenes/LoginFormItem/ChangePassword'
import Error from './Scenes/Error'
import { Home } from './Scenes/Home'
import { routes } from './Constant/routes'
import { isKnownRoutes, isRoutesAuthorizedBeforeConnection } from './helpers/utils'
import { EmailValidation } from './Scenes/EmailValidation'
import { ValidSpecialistProfile } from './Scenes/ProfileItem/Specialist/ValidSpecialistProfile'
import ConsultSpecialistProfile from './Scenes/ConsultSpecialistProfile'
import ScrollToTop from './components/ScrollToTop'

let isConnected = false
function App() {
  const [loginPage, setLoginPage] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [voiceCall, setVoiceCall] = React.useState(false)
  const [handleConnection, setHandleConnection] = React.useState()
  const [chatRoom, setChatRoom] = React.useState(false)
  const [eventSource, setEventSource] = React.useState(false)
  const [theme, setTheme] = React.useState({
    typography: {
      fontFamily: [
        'Quicksand',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      body1: {
        fontWeight: 500,
      },
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
      subtitle1: {
        fontWeight: 500,
      },
      subtitle2: {
        fontWeight: 500,
      },
      body2: {
        fontWeight: 500,
      },
      button: {
        fontWeight: 500,
      },
      caption: {
        fontWeight: 500,
      },
      overline: {
        fontWeight: 500,
      },
    },
    palette: {
      type: 'light',
      primary: {
        light: '#4c31ac',
        main: '#05057c',
        dark: '#00004f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#adffff',
        main: '#79e0db',
        dark: '#43aea9',
        contrastText: '#000',
      },
    },
  });

  const toggleDarkTheme = () => {
    let newPaletteType = theme.palette.type === "light" ? "dark" : "light";
    setTheme({
      typography: {
        fontFamily: [
          'Quicksand',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        body1: {
          fontWeight: 500,
        },
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700,
        },
        h3: {
          fontWeight: 700,
        },
        h4: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 700,
        },
        h6: {
          fontWeight: 700,
        },
        subtitle1: {
          fontWeight: 500,
        },
        subtitle2: {
          fontWeight: 500,
        },
        body2: {
          fontWeight: 500,
        },
        button: {
          fontWeight: 500,
        },
        caption: {
          fontWeight: 500,
        },
        overline: {
          fontWeight: 500,
        },
      },
      palette: {
        type: newPaletteType,
        primary: {
          light: '#4c31ac',
          main: '#05057c',
          dark: '#00004f',
          contrastText: '#fff',
        },
        secondary: {
          light: '#adffff',
          main: '#79e0db',
          dark: '#43aea9',
          contrastText: '#000',
        },
      },
    });
  };

  const muiTheme = createMuiTheme(theme);
  let location = useLocation()
  window.location.pathname !== '/' && window.location.pathname !== '/login' && window.location.pathname !== '/dashboard' && localStorage.setItem('LAST_URL', window.location.pathname)
  
  useEffect(() => {
    if (!isConnected) {
        if (!isAuth()) {
          isConnected = true
          setEventSource(new EventSource(process.env.REACT_APP_MERCURE_SUBSCRIBE_URL + '?topic=' + encodeURIComponent('https://geo-psy.fr/public')));
        }
    }
  }, [])

  const shouldShowHome = () => {
    return isAuth()
  }

  return (
      <MuiThemeProvider theme={muiTheme}>
          <ScrollToTop />
          {!isAuth() && <Route path={[routes.login, routes.loginRedirect, routes.loginFocus]} component={TabsLogin}></Route>}
          <Route path={routes.resetPasswordId} exact children={<ChangePassword />} />
          {eventSource && <Route path={routes.ShowSpecialistCard} ><ConsultSpecialistProfile setHandleConnection={setHandleConnection} menuOpen={open} setVoiceCall={setVoiceCall} setChatRoom={setChatRoom} eventSource={eventSource} chatRoom={chatRoom} /></Route>}
          <Route path={routes.initPasswordId} exact children={<ValidSpecialistProfile />} />
          <Route path={routes.emailValidationId} exact children={<EmailValidation />} />
              {isAuth() ? 
                <MainMenu setEventSource={setEventSource} handleConnection={handleConnection} setHandleConnection={setHandleConnection} open={open} setOpen={setOpen} onToggleDark={toggleDarkTheme} setVoiceCall={setVoiceCall} voiceCall={voiceCall} setChatRoom={setChatRoom} chatRoom={chatRoom} switchTheme={theme} onConnect={setLoginPage} /> :
                !loginPage && 
                <Route path={routes.home} exact>
                  {eventSource && <Home setLoginPage={setLoginPage} eventSource={eventSource} />}
                </Route>}
          <Route path={routes.resetPassword} exact component={Error} />
          <Route path={routes.emailValidation} exact component={Error} />
          <Route path={routes.initPassword} exact component={Error} />
          {!isKnownRoutes(location.pathname) ? <Route path={'/'}>
            <Error setLoginPage={setLoginPage} shouldShowHome={!shouldShowHome} />
          </Route> : (isAuth() || isRoutesAuthorizedBeforeConnection(location)) ? <Redirect to={location.pathname} /> : !location.pathname.startsWith('/psychologue/') && !location.pathname.startsWith('/login/show/') && <Redirect to={'/login?tab=login'} />}
      </MuiThemeProvider>
  );
}

export default App;
