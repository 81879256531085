import { Avatar, Badge, Card, CardContent, CardHeader, LinearProgress, Typography, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import moment from "moment";
import { apiFetch } from '../../service/apiFetch';
import ReactTimeslotCalendar from '../../components/TimeSlot/js/react-timeslot-calendar';
import Alert from '../../components/Alert'
import { TransitionLeft } from '../../helpers/utils';
import { useStylesMakeAppointment } from '../../css/Style';

let dateToStartCalendar = null
export function MakeAppointment({ sessionTypeSelected, motifs, setStepper, specialist, setAppointment, appointment, error, option, matches, appointments, interventionId, formatDate, params }) {
    const classes = useStylesMakeAppointment();
    const [slots, setSlots] = useState(false);
    const [date, setDate] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false)
    const [sessionType, setSessionType] = useState('')
    const [nextSessions, setNextSessions] = useState(false)
    const [transition, setTransition] = useState(false)
    const [textAlert, setTextAlert] = useState(false)
    const StyledBadge = withStyles((theme) => ({
        badge: {
          backgroundColor: (`${specialist.active ? '#44b700' : '#d32f2f' }`),
          color: (`${specialist.active ? '#44b700' : '#d32f2f' }`),
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
    }))(Badge);

    const handleSetAppointment = (appointment) => {
      const slot = findSlots(appointment, slots)
      apiFetch(`/interventions/${interventionId}/slot`, {
        method: 'PUT',
        body: JSON.stringify({
            slots: `/slots/${slot.id}`
        })
      }, true)
      .then((res) => {
        if (res['hydra:description']) {
          setIsUpdate(true)
          setTransition(() => TransitionLeft)
          setTextAlert(res['hydra:description'])
          setAppointment({})
          setTimeout(() => {
            setStepper(false)
          }, 5000)
        } else {
          setAppointment(slot ? {date: slot.startDate, id: slot.id, time: slot.startDate, availability: slot} : {})
        }
      })
    }

    useEffect(() => {
      if (appointment.id) {
        setSessionType(sessionTypeSelected)
        apiFetch(`/availabilities/${specialist.id}/cabinet/type/seance`)
        .then((res) => {
          setNextSessions(res)
        })
        apiFetch(`/interventions/${interventionId}/slot`, {
          method: 'PUT',
          body: JSON.stringify({
              slots: `/slots/${appointment.id}`
          })
        }, true)
        .then((res) => {
          if (res['hydra:description']) {
            setIsUpdate(true)
            setTransition(() => TransitionLeft)
            setTextAlert(res['hydra:description'])
            setAppointment({})
            setTimeout(() => {
              setStepper(false)
            }, 5000)
          } else {
            setAppointment({date: appointment.start, id: appointment.id, time: appointment.time, availability: appointment})
            setDate({end : moment(appointment.availability.endDate).set('second', 0), start: moment(appointment.availability.startDate).set('second', 0)})
            fetchSlots(moment(appointment.availability.startDate).set('second', 0))
          }
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchSlots = async (date) => {
      const { firstDayOfNextWeek, lastDayOfNextWeek, dateToStart } = formatDate(false, false, false, true, date)
      dateToStartCalendar = dateToStart
      const slotsItems = specialist && await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=${findConsultationType(params && params.name)}&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}${params.name === 'Cabinet' ? `&availability.typeSeance=${sessionTypeSelected}` : ''}`) 

      if (slotsItems['hydra:member']) setSlots(slotsItems['hydra:member'])
    }

    const changeWeek = async (week, next) => {
      setSlots(null)
      const { firstDayOfNextWeek, lastDayOfNextWeek, dateToStart } = formatDate(week[0], week[6], next)
      dateToStartCalendar = dateToStart

      const slotsItems = specialist && await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=${findConsultationType(params && params.name)}&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}${params.name === 'Cabinet' ? `&availability.typeSeance=${sessionType}` : ''}`) 

      if (slotsItems && slotsItems['hydra:member']) setSlots(slotsItems['hydra:member'])
    }

    const handleSessionType = async (e) => {
      setSessionType('')
      setTimeout(async () => {
          const { firstDayOfNextWeek, lastDayOfNextWeek } = formatDate(false, false, false, true)
          const slotsItems = specialist && await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=1&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}&availability.typeSeance=${e.target.value}`) 
          
          if (slotsItems && slotsItems['hydra:member']) setSlots(slotsItems['hydra:member'])
          setSessionType(e.target.value)
      }, 500)
  }

    return (
        <div className={classes.center}>
            {specialist && appointments && <Card className={classes.root} >
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                      <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                    <Avatar aria-label="photo" src={`${process.env.REACT_APP_BASE_URL}${specialist.photo.contentUrl}`} className={classes.avatar} />
                    </StyledBadge>
                  }
                  title={matches ? <Typography variant="body1" component='p' style={{fontSize: matches ? '13px' : 'inherit', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap",}}>{`${specialist.user.firstName}  ${specialist.user.lastName}`}</Typography> : `${specialist.user.firstName}`}
                  subheader={matches ? <Typography variant="body1" component='p' style={{fontSize: matches ? '13px' : 'inherit', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap",}}>
                  {specialist.user.email}
                  </Typography> : specialist.user.email}
                />
                <CardContent>
                  <Typography variant="body1" color="primary" component="p" style={{marginBottom: '5px'}}>
                    <span style={{fontWeight: 'bold'}}>Disponibilité : </span>
                  </Typography>
                  {specialist && specialist.displayableAvailabilities.find((availability) => {
                    if (availability.consultationType.name === params.name) {
                        return params.name === 'Cabinet'
                    }
                    return false
                })  && 
                <FormControl style={{width: 200, marginBottom: 20}}>
                <InputLabel id="select-label">Type de séance</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      value={sessionType}
                      onChange={handleSessionType}
                    >
                        {nextSessions && nextSessions.map((session, index) => {
                            return (
                                <MenuItem key={index} value={session.type}>{session.type} - prochaine séance le : {moment(session.nextSlot).format('DD MMMM YYYY à HH:mm')}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                }           
                  {slots && date && dateToStartCalendar ? 
                  <ReactTimeslotCalendar initialDate = { dateToStartCalendar } timeslots={formattedSlots(slots)}
                      handleSetAppointment={handleSetAppointment}
                      changeWeek={changeWeek}
                      selectedTimeslots={[{endDate: date.end, startDate: date.start }]}
                  /> : <LinearProgress />
                }
                </CardContent>
            </Card>}
            <Alert isOpen={isUpdate} transition={transition} message={textAlert} setIsUpdate={setIsUpdate} severity={'error'} />
        </div>
    )
}

function findConsultationType(state) {
  switch(state.toLowerCase()) {
    case 'cabinet':
      return '1'
    case 'intervention':
      return '2'
    case 'visio':
      return '3'
    default:
      return '4'
  }
}

function formattedSlots(slots) {
  let a = []

  slots.map((slot) => {
      a.push(
      slot.map((timeSlot) => {
          return [moment(timeSlot.startDate).format('LT'), moment(timeSlot.endDate).format('LT')]
      }))
      return null
  })
  return a
}

function findSlots(appointment, slots) {
  let slotItem = null
  appointment.length && slots.map((slot) => {
      slot.map((item) => {
          if (moment(item.startDate).format('YYYY-MM-DDTHH:mm') === appointment[0].startDate.format('YYYY-MM-DDTHH:mm')) {
              slotItem = item
          }
          return slotItem
      })
      return null
  })
  return slotItem
}
