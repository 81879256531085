import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_BLOG_POSTS = 'FETCHING_BLOG_POSTS'
const SET_BLOG_POSTS = 'SET_BLOG_POSTS'
const ADD_BLOG_POSTS = 'ADD_BLOG_POSTS'
const UPDATE_BLOG_POSTS = 'UPDATE_BLOG_POSTS'
const DELETE_BLOG_POSTS = 'DELETE_BLOG_POSTS'

function blogPostsReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_BLOG_POSTS:
            return { ...state, loading: true }
        case SET_BLOG_POSTS:
            return {...state, blogPosts: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_BLOG_POSTS:
            return {...state, blogPosts: [action.payload, ...state.blogPosts]}
        case UPDATE_BLOG_POSTS:
            return {...state, blogPosts: action.payload }
        case DELETE_BLOG_POSTS:
            return {...state, blogPosts: state.blogPosts.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useBlogPosts() {
    const [state, dispatch] = useReducer(blogPostsReducer, {    
        blogPosts: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        blogPosts: state.blogPosts,
        totalItems: state.totalItems,
        view: state.view,
        fetchBlogPosts: useCallback(async function (route) {
            if ((state.blogPosts || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_BLOG_POSTS' })
            const blogPosts = await apiFetch(route || '/cms_blog_posts', {method: 'GET'})
            if (blogPosts) {
                dispatch({type: 'SET_BLOG_POSTS', payload: blogPosts})
            }
        }, [state]),
        createBlogPosts: useCallback(async function(data, forceUpdate) {
            const newBlogPosts = await apiFetch('/cms_blog_posts', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)

            const blogPosts = await apiFetch('/cms_blog_posts', {method: 'GET'})
            if (newBlogPosts && !newBlogPosts.violations && blogPosts) {
                dispatch({type: 'SET_BLOG_POSTS', payload: blogPosts})
            }
            return newBlogPosts
        }, []),
        updateBlogPosts: useCallback(async function(blogPosts, data, forceUpdate = false) {
            const newBlogPosts = await apiFetch('/cms_blog_posts/' + blogPosts, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceUpdate)

            const newBlogPostsReload = await apiFetch('/cms_blog_posts', {method: 'GET'})
            if (newBlogPosts && !newBlogPosts.violations && newBlogPostsReload) {
                dispatch({type: 'SET_BLOG_POSTS', payload: newBlogPostsReload})
            }
            return newBlogPosts
        }, []),
        deleteBlogPosts: useCallback(async function (messageId) {
            const blogPostsDelete = await apiFetch('/cms_blog_posts/' + messageId, {
                method: 'DELETE'
            })
            if (blogPostsDelete) {
                dispatch({type: 'DELETE_BLOG_POSTS', payload: messageId})
            }
            return blogPostsDelete
        }, []),
    }
}