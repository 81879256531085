import React, { useEffect, useState } from 'react'
import { apiFetch } from '../service/apiFetch'
import { TableRow, 
    TableCell, 
    IconButton, 
    Collapse, 
    Box, 
    Table, 
    Typography, 
    TableHead, 
    TableBody,
    TableContainer,
    Paper,
    ThemeProvider,
    TablePagination,
    createMuiTheme,
} from '@material-ui/core/';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import * as locales from '@material-ui/core/locale';
import moment from 'moment'

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {moment(row.paidAt).format('DD MMMM YYYY à HH:mm')}
                </TableCell>
                <TableCell align="right">{row.amount} €</TableCell>
                <TableCell align="right">{row.status}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                              Client
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight: 900, fontSize: 15}}>Email</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 15}}>Prénom Nom</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {row.client.user.email}
                                        </TableCell>
                                        <TableCell>{row.client.user.firstName} {row.client.user.lastName}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function AdminPayments() {
    const [payments, setPayments] = useState(false)
    const [view, setView] = useState(false)
    const [totalItems, setTotalItems] = useState(false)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [locale] = React.useState('frFR');
    const [rowsPerPage, setRowsPerPage] = React.useState(30);

    useEffect(() => {
        (async () => {
            const transactions = await apiFetch('/transactions')
            if (!transactions['hydra:description']) {
                setPayments(transactions['hydra:member'])
                setView(transactions['hydra:view'])
                setTotalItems(transactions['hydra:totalItems'])
            }
        })()
    }, [])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = async (event, newPage) => {
        setLoading(true)
        if (newPage > page) {
            const newData = await apiFetch(view['hydra:next'])
            setPayments(newData['hydra:member'])
            setView(newData['hydra:view'])
            } else if (newPage < page) {
            const newData = await apiFetch(view['hydra:previous'])
            setPayments(newData['hydra:member'])
            setView(newData['hydra:view'])
        }
        setLoading(false)
        setPage(newPage);
    };

    return (
        <Paper>
        <TableContainer>
            {payments && <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell style={{fontWeight: 900, fontSize: 17}}>Date</TableCell>
                        <TableCell style={{fontWeight: 900, fontSize: 17}} align="right">Prix</TableCell>
                        <TableCell style={{fontWeight: 900, fontSize: 17}} align="right">Statut</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.map((row) => (
                        <Row key={row['@id']} row={row} />
                    ))}
                </TableBody>
            </Table>}
        </TableContainer>
            {!!totalItems && <ThemeProvider theme={(outerTheme) => createMuiTheme(outerTheme, locales[locale])}>
                <TablePagination
                    labelRowsPerPage=''
                    nextIconButtonProps={{ disabled: loading || page >= (totalItems / rowsPerPage) - 1}}
                    backIconButtonProps={{ disabled: loading || page === 0 }} 
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ThemeProvider>}
        </Paper>
    )
}
