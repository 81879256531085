import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import hasRoles from '../security/hasRoles'


export const ClientRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render= {(props) => (
            hasRoles() === 'user' || hasRoles() === 'client' ? <Component {...props} user={rest.user} eventSource={rest.eventSource} setChatRoom={rest.setChatRoom} setVisioId={rest.setVisioId} menuOpen={rest.open}  credit={rest.credit}/> : <Redirect to='/dashboard'/>
          )}/>
    )
}