import React from 'react'
import {  Typography } from '@material-ui/core';
import moment from 'moment'
import hasRoles from '../../service/security/hasRoles';

export default function NotificationCard({ classes, next, isSpecialist, matches }) {
    return (
        <div className={classes.notificationCard} style={matches ? {marginTop: 30} : {}}>
            {hasRoles() !== 'super_admin' ? <>
                <Typography variant="h5" gutterBottom>
                    {isSpecialist ? 'MES PROCHAINES CONSULTATIONS' : 'MES ALERTES'}
                </Typography><br />
                {!isSpecialist && <Typography variant="h6" gutterBottom >
                    PROCHAINE CONSULTATION :
                </Typography>}<br />
                {!isSpecialist ? <Typography variant="h6" gutterBottom style={{textAlign: 'center', marginLeft: 10, font: 'normal normal bold 20px/24px Quicksand'}}>
                {next && next.id && next.specialist ? `${moment(next.startDate).format('dddd Do MMMM YYYY - HH:mm')} - ${next.type} avec ${next.specialist.user.firstName}` : 'Pas de prochaine consultation...'}
                </Typography> : 
                next && next.length ? next.map((consult) => (
                <Typography key={consult['@id']} variant="h6" gutterBottom style={{textAlign: 'center', marginLeft: 10, font: 'normal normal bold 20px/24px Quicksand'}}>
                    {moment(consult.startDate).format('dddd Do MMMM YYYY à HH:mm')} - {consult.type}
                </Typography>
                )) : 'Pas de consultations à venir'}
            </> :
            <>
                <Typography variant="h5" gutterBottom>
                    REVERSEMENT AUX PSYCHOLOGUES
                </Typography><br />
                <Typography variant="h5" gutterBottom>
                    {next.specialistFees} €
                </Typography>
            </>
            }
        </div>
    )
}