import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_CONSULTS = 'FETCHING_CONSULTS'
const SET_CONSULTS = 'SET_CONSULTS'
const ADD_CONSULT = 'ADD_CONSULT'
const UPDATE_CONSULT = 'UPDATE_CONSULT'
const DELETE_CONSULT = 'DELETE_CONSULT'

function consultsReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_CONSULTS:
            return { ...state, loading: true }
        case SET_CONSULTS:
            return {...state, consults: action.payload['hydra:member'], loading: false, totalConsultItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_CONSULT:
            return {...state, consults: [action.payload, ...state.consults]}
        case UPDATE_CONSULT:
            return {...state, consults: action.payload }
        case DELETE_CONSULT:
            return {...state, consults: state.consults.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useConsult() {
    const [state, dispatch] = useReducer(consultsReducer, {    
        view: null,
        totalConsultItems: 0,
        consults: null,
        loading: false,
    })

    return {
        consults: state.consults,
        consultView: state.view,
        totalConsultItems: state.totalConsultItems,
        fetchConsults: useCallback(async function (route = null) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_CONSULTS' })
            const consults = await apiFetch(route || '/consultations?order[startDate]=desc', {method: 'GET'})
            if (consults['hydra:member']) {
                dispatch({type: 'SET_CONSULTS', payload: consults})
            }
        }, [state]),
        createConsults: useCallback(async function(data, forceUpdate) {
            const newConsults = await apiFetch('/consultations', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            if (!newConsults.violations) {
                dispatch({type: 'SET_CONSULTS', payload: newConsults})
            }
            return newConsults
        }, []),
        updateConsults: useCallback(async function(consults, data, forceUpdate = false) {
            const newConsults = await apiFetch('/consultations/' + consults, {
                method: 'PUT',
                body: data,
            }, forceUpdate)
            dispatch({type: 'UPDATE_CONSULT', payload: newConsults, target: consults })
            return newConsults
        }, []),
        deleteConsults: useCallback(async function (consultationId) {
            const discussionDelete = await apiFetch('/consultations/' + consultationId, {
                method: 'DELETE'
            })
            if (discussionDelete) {
                dispatch({type: 'DELETE_CONSULT', payload: consultationId})
            }
            return discussionDelete
        }, []),
    }
}