import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import Alert from '../../components/Alert'
import { useStylesAppointmentCity } from '../../css/Style';

/* const searchOptions = {
    types: ['(cities)'],
    componentRestrictions: {country: "fr"}
} */

export function AppointmentCity({ setStepper, error, motif, setMotif, motifs, id }) {
    const classes = useStylesAppointmentCity();
    const [isUpdate, setIsUpdate] = useState(false)
    const [transition, setTransition] = useState(false)
    const [textAlert, setTextAlert] = useState(false)

    const handleSelectMotifChange = (event) => {
        apiFetch(`/interventions/${id}/detail`, {
            method: 'PUT',
            body: JSON.stringify({
                motif: event.target.value
            })
        }, true)
        .then((res) => {
            if (res.id) {
                setMotif(event.target.value);
            } else {
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                setTextAlert(res['hydra:description'])
                setTimeout(() => {
                    setStepper(false)
                }, 5000)
            }
        })
    };

    return (
        <div className={classes.center}>
            <FormControl required className={classes.option} error={error.motif}>
            <InputLabel >Motif</InputLabel>
            {motifs && <Select
                value={motif}
                displayEmpty
                onChange={handleSelectMotifChange}
                inputProps={{ 'aria-label': 'Without label' }}
                style={{textAlign: 'left'}}
            >
                {motifs.map((motif, index) => {
                    return <MenuItem key={index} value={motif}>{motif}</MenuItem>
                })}
            </Select>}
            {error && error.motif && <FormHelperText>Veulliez selectionner un motif</FormHelperText>}
            </FormControl><br /><br />
            <Alert isOpen={isUpdate} transition={transition} message={textAlert} setIsUpdate={setIsUpdate} severity={'error'} />
        </div>
    )
}