import React from 'react'
import {  Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import hasRoles from '../../service/security/hasRoles';

export default function CreditCard({ classes, user, credit }) {
    let history = useHistory();

    const handleClick = () => {
        history.push('creditAccount')
    }

    return (
        <div className={classes.creditCard}>
            {hasRoles() !== 'super_admin' ? <>
                <Typography variant="h5" gutterBottom>
                    MON SOLDE
                </Typography><br />
                <Typography variant="h6" gutterBottom style={{textAlign: 'left', marginLeft: 40, marginBottom: 20}}>
                    SOLDE ACTUEL : <br />{credit || user.client.nbCredits} séances restantes
                </Typography>
                <Button
                    variant="contained"
                    className={classes.creditButton}
                    onClick={handleClick}
                >
                    JE RECHARGE MON COMPTE
                </Button>
            </> :
            <>
                <Typography variant="h5" gutterBottom>
                    CHIFFRE D'AFFAIRES
                </Typography><br />
                <Typography variant="h5" gutterBottom>
                    {credit.sales} €
                </Typography>
            </>}
        </div>
    )
}