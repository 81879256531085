import React, { useState, useEffect } from 'react'
import { apiFetch } from '../service/apiFetch'
import FutureInterventionsTable from './IncomingConsultationItem/FutureIntevertentionsTable'
import { Typography } from '@material-ui/core'
import * as mainConstant from '../Constant/mainConstant' 
import { motion } from 'framer-motion'
import { useStylesIncomingConsultation } from '../css/Style'

export default function IncomingConsultation({ setVisioId, setChatRoom, profileUser }) {
    document.title = mainConstant.FUTURECONSULTATION
    const classes = useStylesIncomingConsultation();
    const [events, setEvents] = useState(false)

    useEffect(() => {
        apiFetch('/events')
        .then((res) => {
            setEvents(res)
        })

    }, [])

    return (
        <>
            {events && <div style={{textAlign: 'center'}} >
                <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} >
                    {profileUser && profileUser.specialist && <><Typography color='primary' variant='h5' className={classes.title} >
                        Mes consultations tchat à valider
                    </Typography>
                    <FutureInterventionsTable rows={events.consultationRequests} setChatRoom={setChatRoom} />
                    <Typography color='primary' variant='h5' className={classes.title}>
                        Mes consultations à domicile à valider
                    </Typography>
                    <FutureInterventionsTable rows={events.interventionRequests} /></>}
                    {profileUser && <><Typography color='primary' variant='h5' className={classes.title}>
                        Mes consultations à venir
                    </Typography>
                    <FutureInterventionsTable rows={events.futureInterventions} setVisioId={setVisioId} futureRow={true} isClient={profileUser.client} />
                    <Typography color='primary' variant='h5' className={classes.title}>
                        Mes consultations en cours
                    </Typography>
                    <FutureInterventionsTable rows={events.runningConsultations} setChatRoom={setChatRoom} setVisioId={setVisioId} futureRow={true} isClient={profileUser.client} /></>}
                </motion.div>
            </div>}
        </>
    )
}