import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Typography } from '@material-ui/core/';

const colors = ['#D1DCEF', '#79E0DB', '#D6F5F3', '#05057C', '#05057C'];

export default class ConsultationPerTypes extends PureComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          data: this.props.data,
        };
    }
  
    render() {
        return (
            <div style={{width: '100%', marginTop: 30}}>
                <Typography variant="h5" gutterBottom style={{textTransform: 'uppercase', color: "#05057c", fontSize: "23px", textAlign: "center"}}>
                    Nombre de consultations par type
                </Typography>
                <ResponsiveContainer height={this.state.data.length * 50 + 10} width="100%">
                    <BarChart
                        width={1500}
                        height={600}
                        data={this.state.data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                        barSize={120}
                    >
                        <XAxis dataKey="type" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" label={{ position: 'top' }}>
                            {this.state.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}