import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { serratedTabsStylesHook } from '@mui-treasury/styles/tabs'
import Intervention from './availabilityTab/Intervention';
import Alert from '../../../../components/Alert'
import { displayableAddress } from '../../../../helpers/utils';

export default function ClientGrid({ cardStyles, matches,userData }) {
    const [tabIndex, setTabIndex] = React.useState(0);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [transition, setTransition] = React.useState(false);
    const tabsStyles = serratedTabsStylesHook.useTabs();
    const tabItemStyles = serratedTabsStylesHook.useTabItem({
      bgColor: '#79E0DB',
      selectedBgColor: '#ffffff',
    });
    const [appointment, setAppointment] = React.useState({
        date: null,
        time: null,
        id: null,
    }) 

    return (
        <>
          <Box
            mt={2}
            display={matches? 'block' : 'flex'}
            alignItems={'center'}
          >
          <Typography variant="body2" color="textSecondary" component="div">
              <span style={{fontWeight: 'bold'}}>Spécialité(s) :</span>
              <List dense={true} style={{maringTop: 0, paddingTop: 0}}>
                  {userData.specialities.map((speciality, index) => 
                      <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                          <ListItemText primary={`• ${speciality.name}`} style={{marginBottom: 0, paddingBottom: 0}}/>
                      </ListItem>
                  )}
              </List>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="div" style={matches ? {marginLeft: 0, marginTop: 20} : {marginLeft: 300}}>
              <span style={{fontWeight: 'bold'}}>Type(s) de consultation :</span>
              <List dense={true} style={{maringTop: 0, paddingTop: 0}}>
                  {userData.specialistHasConsultationsType.map((consultationType, index) => 
                      <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                          <ListItemText primary={`• ${consultationType.consultationType.name}`} style={{marginBottom: 0, paddingBottom: 0}}/>
                      </ListItem>
                  )}
              </List>
          </Typography>
          </Box><br />
          <Typography variant="body2" color="textSecondary" component="div">
              <span style={{fontWeight: 'bold'}}>Langue(s) parlée(s) :</span>
              <List dense={true} style={{maringTop: 0, paddingTop: 0}}>
                  {userData.languages.map((language, index) => 
                      <ListItem key={index} style={{marginBottom: 0, paddingBottom: 0}}>
                          <ListItemText primary={`• ${language.name}`} style={{marginBottom: 0, paddingBottom: 0}}/>
                      </ListItem>
                  )}
              </List>
          </Typography>
          <Divider />
          <Grid container alignItems="center" className={cardStyles.grid}>
            <Grid item xs={matches ? 12 : 7}>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Adresse du cabinet :</span> {(userData.office && userData.office.address && `${displayableAddress(userData.office.address)}`) || 'Pas de cabinet'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Téléphone fixe :</span> {userData.phone || 'Pas de téléphone fixe'}
                </Typography>
            </Grid>
          <Grid item xs={matches ? 12 : 5} style={matches ? {paddingLeft: 0} : {paddingLeft: '80px'}}>
            <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                <span style={{fontWeight: 'bold'}}>Distance :</span> {userData.distance} km
            </Typography>
          </Grid>
          </Grid>
          <Divider />
          <Typography variant="h6" color="textSecondary" component="div" style={{marginTop: 10, marginBottom: 10}}>
                Disponibilité(s) : 
          </Typography>
          <AppBar
            position={'static'}
            elevation={0}
            style={{ backgroundColor: '#FFF' }}
          >
            <Toolbar
              // you need to set override hidden in toolbar
              style={{ overflow: 'hidden', minHeight: 72 }}
            >
                <Tabs
                  style={{ alignSelf: 'flex-end' }}
                  classes={tabsStyles}
                  value={tabIndex}
                  onChange={(e, index) => setTabIndex(index)}
                >
                  <Tab style={{width: '180px'}} classes={tabItemStyles} disableRipple label={'A domicile'} />
                  <Tab style={{width: '180px'}} classes={tabItemStyles} disableRipple label={'En cabinet'} />
                  <Tab style={{width: '180px'}} classes={tabItemStyles} disableRipple label={'Plateforme'} />
                  <Tab style={{width: '180px'}} classes={tabItemStyles} disableRipple label={'En visio'} />
                </Tabs>
            </Toolbar>
          </AppBar>
          <div style={{paddingRight: 32, paddingLeft: 32, paddingTop: 12}}><Intervention setTransition={setTransition} setIsUpdate={setIsUpdate} index={tabIndex} specialist={userData} matches={matches} appointment={appointment} setAppointment={setAppointment} /></div>
          <Alert isOpen={isUpdate} transition={transition} message={'Vous devez vous connecter pour prendre un RDV, vous allez être redirigé dans 5 sec'} setIsUpdate={setIsUpdate} severity={'error'} />
          </>
    )
}