import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import { Button, Slide } from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeletePricesPopup({ open, handleClose, deletePrice }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                open={!!open}
                onClose={handleClose}
                aria-labelledby="dialog"
                fullScreen={fullScreen}
                TransitionComponent={Transition}
            >
                <DialogTitle id="prices-dialog-title">Supprimer un tarif</DialogTitle>
                <DialogContent>
                    Voulez-vous vraiment supprimer ce tarif ?
                    <Container style={{textAlign: 'center', marginTop: '20px'}}>
                        <div>
                            <Button onClick={handleClose} size='small' variant="contained" color="primary" style={{marginRight: 20}}>
                                Annuler
                            </Button>
                            <Button onClick={deletePrice} size='small' variant="contained" color="primary">
                                Confirmer
                            </Button>
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}