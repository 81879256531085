import { Divider, Grid, LinearProgress, useMediaQuery, useTheme, IconButton, Collapse } from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import mainConstant from '../../../Constant/mainConstant';
import { useHistory, useParams } from 'react-router-dom';
import { apiFetch } from '../../../service/apiFetch';
import { ActiveBreadcrumb } from '../../../components/Breadcrumb';
import { useStylesShowUserProfile } from '../../../css/Style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { displayableAddress } from '../../../helpers/utils';

const breadcrumbTab = [
    'Profil',
    'Ajouter',
    'Modifier',
    'Voir',
    'Map',
  ];

export function ShowUserProfile(props) {
    document.title = mainConstant.PROFILE
    const classes = useStylesShowUserProfile();
    const { id } = useParams()
    const [userData, setUserData] = useState(null)
    const [transactions, setTransactions] = useState(null)
    const history = useHistory()  
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        (async () => {
            const user = await apiFetch(`/clients/${id}`, {method: 'GET'})
            const transactions = await apiFetch(`/transactions?client=/clients/${id}`, {method: 'GET'})
            if (transactions['hydra:member']) {
                setTransactions(transactions['hydra:member'])
              }
            setUserData(user)
        })()
    }, [id])

    const handleBack = () => {
        history.push('/clients')
        window.location.reload();        
    }

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
        <ActiveBreadcrumb breadcrumb={props.props.breadcrumb} tab={breadcrumbTab} setBreadcrumb={props.props.setBreadcrumb} handleBack={handleBack} />
        {userData ? 
        <Card className={classes.root}>
            <CardHeader
                title={`${userData.user.firstName}  ${userData.user.lastName}`}
                subheader={userData.user.email}
            />
            <CardContent>
                <Grid container alignItems="center" className={classes.grid}>
                    <Grid item xs={matches ? 12 : 7}>
                        <Typography className={classes.typography} variant="body2" color="textSecondary" component="div">
                            <span style={{fontWeight: 'bold'}}>Adresse :</span> {userData.user.address && `${displayableAddress(userData.user.address)}`}
                        </Typography>
                        <Typography className={classes.typography} variant="body2" color="textSecondary" component="div">
                            <span style={{fontWeight: 'bold'}}>Téléphone portable :</span> {userData.user.mobile}
                        </Typography>
                        <Typography className={classes.typography} variant="body2" color="textSecondary" component="div">
                            <span style={{fontWeight: 'bold'}}>Téléphone fixe :</span> {userData.user.phone}
                        </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={matches ? 12 : 4} style={matches ? {paddingLeft: '0px', minWidth: '300px'} : {paddingLeft: '40px', minWidth: '300px'}}>
                        <Typography className={classes.typography} variant="body2" color="textSecondary" component="div">
                            <span style={{fontWeight: 'bold'}}>Date de naissance :</span> {moment(userData.user.birthdate).format("DD.MM.YYYY")}
                        </Typography>
                        <Typography className={classes.typography} variant="body2" color="textSecondary" component="div">
                            <span style={{fontWeight: 'bold'}}>Nombre de crédit(s) :</span> {userData.nbCredits || 0}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography className={classes.typography} variant="body2" color="textSecondary" component="div">
                    <span style={{fontWeight: 'bold'}}>Transactions :</span> 
                </Typography>
                <div>
                    {transactions.map((transaction, index) => {
                        if (index < 2) {
                            return <Typography key={transaction.id} className={classes.typography} variant="body2" color="textSecondary" component="div" style={{marginLeft: 50}}>
                                <span style={{fontWeight: 'bold', fontSize: 12}}> • Date : </span> {transaction.paidAt ? moment(transaction.paidAt).format('DD-MM-YYYY') + ', ' : 'Date non renseigné, '}
                                <span style={{fontWeight: 'bold', fontSize: 12}}>Montant : </span> {`${transaction.amount} ${transaction.currency}`}
                            </Typography>
                        }
                        return null
                    })}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    {transactions.map((transaction, index) => {
                        if (index >= 2) {
                            return <Typography key={transaction.id} className={classes.typography} variant="body2" color="textSecondary" component="div" style={{marginLeft: 50}}>
                                    <span style={{fontWeight: 'bold', fontSize: 12}}> • Date : </span> {transaction.paidAt ? moment(transaction.paidAt).format('DD-MM-YYYY') + ', ' : 'Date non renseigné, '}
                                    <span style={{fontWeight: 'bold', fontSize: 12}}>Montant : </span> {`${transaction.amount} ${transaction.currency}`}
                                </Typography>
                        }
                        return null
                    })}
                    </Collapse>
                    <IconButton variant="contained" size='small' onClick={handleClick}>
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </CardContent>
        </Card> : <LinearProgress />}
        </>
    )
}