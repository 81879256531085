export function handleFormError(requestResult, formTab) {
    let el = {}
    requestResult.violations.forEach((violation, index) => {
        const field = findRightField(violation, formTab)
        if (violation.propertyPath === field)  el[field.split('.')[1] || field] = violation.message
    })
    return el
}

function findRightField(violation, tab) {
    let field = null 
    tab.map((el) => {
        if (el === violation.propertyPath) return field = violation.propertyPath 
        return null
    })
    return field
}