import { Button, Grid } from '@material-ui/core'
import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useStylesFooter } from '../css/Style';

export default function Footer() {
    const classes = useStylesFooter();

    return (
        <div className={classes.footer}>
            <Grid className={classes.grid} container spacing={1}>
                <Grid item xs={4}>
                    <a href="https://prosconsultegroup.com/actualites/#retour-presse" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                        <Button color="primary" className={classes.Typography}>Revue de presse</Button><br />
                    </a>
                    <a href="https://www.pros-consulte.com/carriere/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>Nous rejoindre</Button><br />
                    </a>
                    <a href="mailto:contact@geo-psy.com" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>contact@geo-psy.com</Button>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a href="https://www.prosconsultegroup.com/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>Pros-consulte group</Button><br />
                    </a>
                    <a href="https://www.geo-psy.fr/centredassistance/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>FAQ</Button><br />
                    </a>
                    <a href="https://www.geo-psy.fr/charte-de-confidentialite/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>charte de confidentialité</Button>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a href="https://www.geo-psy.fr/conditions-generales-de-vente/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>Conditions générales de vente</Button><br />
                    </a>
                    <a href="https://www.geo-psy.fr/mentions-legales/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>Mentions légales</Button><br />
                    </a>
                    <a href="https://www.geo-psy.fr/politique-des-cookies/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <Button color="primary" className={classes.Typography}>Politique des cookies</Button>
                    </a>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.gridIcon}
            >
                <a href="https://www.instagram.com/geopsyfrance/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <InstagramIcon className={classes.icon} />
                </a>
                <a href="https://www.facebook.com/geopsyfrance/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <FacebookIcon className={classes.icon} />
                </a>
                <a href="https://fr.linkedin.com/company/geo-psy" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <LinkedInIcon className={classes.icon} />
                </a>
                <a href="https://twitter.com/Geo_Psy_France" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                    <TwitterIcon className={classes.icon} />
                </a>
            </Grid>
        </div>
    )
}