import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom';
import { TransitionLeft } from '../../../../../helpers/utils';
import { isAuth } from '../../../../../service/security/isAuth';
import moment from "moment";

const availabilitiesOptions = { weekday: 'long', month: 'long', day: 'numeric' };

export default function Intervention({ specialist, matches, setAppointment, appointment, index, setIsUpdate, setTransition}) {
    let history = useHistory()

    const handleClick = (id, dayStart, start, availability) => {
        if (isAuth()) {
            history.push('/visit')
          } else {
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
            setTimeout(() => {
                history.push('/login')
            }, 4000)
          }
    }

    return (
        <Grid container spacing={1}> {specialist.displayableAvailabilities.map((availability) => (
            <React.Fragment key={availability.id}>
              {availability.consultationType.name === getCurrentTab(index) && <><Grid key={availability.id} item xs={matches ? 12 : 3} style={matches ? {marginTop: '5px'} : {marginTop: '20px'}}>
                  <Typography key={availability.id} variant="body1" color="textSecondary" style={matches ? {marginLeft: '5px'} : {marginLeft: '50px'}}>
                    <span key={availability.id} style={{fontSize: 15}}>{new Date(availability.start).toLocaleDateString('fr-FR', availabilitiesOptions)} : </span>
                  </Typography>
              </Grid>
              <Grid item xs={matches ? 12 : 9}>
                  {availability.slots.map((slot) => {
                    if (!slot.client) {
                      return appointment.id === slot.id && appointment.date === availability.start ? <Button variant="contained" key={slot.id} tabIndex={slot.id} style={{margin: '10px 0px 10px 20px'}} color='primary' onClick={() => handleClick(slot.id, availability.start)}>{moment(slot.debut).format('LT')}</Button> :
                      <Button variant="contained" key={slot.id} tabIndex={slot.id} style={{margin: '10px 0px 10px 20px'}} onClick={() => handleClick(slot.id, availability.start, slot.debut, availability)}>{moment(slot.debut).format('LT')}</Button>
                    }
                    return null
                  })}
              </Grid></>}
            </React.Fragment>
            ))}
            {specialist.displayableAvailabilities.find((availability) => availability.consultationType.name !== getCurrentTab(index))  && 'Pas de crénaux disponible pour ce type de consultation. '} 
        </Grid>
    )
}

function getCurrentTab(tab) {
    switch (tab) {
        case 0:
            return 'Intervention'
        case 1:
            return 'Cabinet'
        case 2:
            return 'Plateforme'
        case 3:
            return 'Visio'
        default:
            return null
    }
}
