import React, { useEffect, useState } from 'react'
import { LinearProgress, FormHelperText, Grid, Checkbox, FormControlLabel, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Avatar } from '@material-ui/core';
import { TransitionLeft } from '../../../helpers/utils'
import UploadFile from '../../ProfileItem/UploadItem';
import { apiFetch } from '../../../service/apiFetch';

export default function AddBlogArticle({ handleCloseMenu, updateBlogPosts, selected, open, handleClose, createBlogPosts, setIsUpdate, setText, setSeverity, setTransition }) {
    const [url, setUrl] = useState(false)
    const [urlError, setUrlError] = useState(false)
    const [titleError, setTitleError] = useState(false)
    const [photoError, setPhotoError] = useState(false)
    const [title, setTitle] = useState(false)
    const [photo, setPhoto] = useState(false)
    const [isCheckboxUpdated, setIsCheckboxUpdated] = useState(false)
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(false)
    const [changePhoto, setChangePhoto] = useState(false)

    const handleChangeTitle = (event) => {
        setDisable(false)
        setTitle(event.target.value);
    };

    const handleChange = (event) => {
        setIsCheckboxUpdated(true)
        setDisable(false)
        setActive(event.target.checked);
    };

    useEffect(() => {
        (async () => {
            if (selected) {
                setUrl(selected.url)
                setActive(selected.active)
                setTitle(selected.content)
                setPhoto(selected.photo)
            }
        })()
    }, [selected])

    const handleSubmit = async () => {
        if (!title) return setTitleError('Ce champ ne doit pas être vide')
        if (!url) return setUrlError('Ce champ ne doit pas être vide')
        if (!photo) return setPhotoError('Ce champ ne doit pas être vide')
        setDisable(true)
        setLoading(true)
        handleCloseMenu()

        let createPhoto = null
        if (photo && !photo.contentUrl) {
            const dataPhoto = new FormData()
            dataPhoto.append('file', photo && photo[0])
    
            createPhoto = await apiFetch('/media_objects', {
                method: 'POST',
                body: dataPhoto
            }, true, true)
        }

        const data = {
            url: url,
            content: title,
            active: active,
            photo: createPhoto && createPhoto['@id'], 
        }
        if (!createPhoto) {
            delete data.photo
        }

        const createNewBlogArticle = !selected ? await createBlogPosts({
            url: url,
            content: title,
            active: active,
            photo: createPhoto['@id'],
        }) :
        await updateBlogPosts(selected.id, data)

        if (createNewBlogArticle) {
            setText('Le psychologue a été ajouté')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText('Erreur lors de l\'ajout du psychologue')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
        setTitleError(false)
        setUrlError(false)
        setPhoto(false)
        setDisable(false)
        setLoading(false)
        handleClose()
    }

    const handleChangeUrl = (e) => {
        setUrl(e.target.value)
        setDisable(false)
    }

    const handleChangePhoto = (e) => {
        setDisable(false)
        setChangePhoto(true)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="title">
            <DialogTitle id="title">Ajouter un article</DialogTitle>
            <DialogContent>
                {selected && selected.photo && !changePhoto ? 
                <Grid container spacing={2} >
                    <Grid item xs={3}>
                        <Avatar alt="Name" src={`${process.env.REACT_APP_BASE_URL}${selected.photo.contentUrl}`} style={{width: '80px', height: '80px', marginBottom: 20}} /> 
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Button onClick={handleChangePhoto} size='small' variant='contained' color="primary">
                            Changer la photo
                        </Button>
                    </Grid>
                </Grid>
                : 
                <UploadFile setPhoto={setPhoto} title={'Choisir une photo *'} />}
                <FormHelperText style={{color: 'red'}}>{photoError}</FormHelperText>
                <TextField  
                    required
                    label="Titre"
                    style={{width: '100%'}}
                    onChange={handleChangeTitle}
                    defaultValue={selected ? selected.content : ''}
                    helperText={titleError}
                    error={!!titleError}
                />
                <TextField  
                    required
                    label="Url"
                    style={{width: '100%'}}
                    onChange={handleChangeUrl}
                    defaultValue={selected ? selected.url : ''}
                    helperText={urlError}
                    error={!!urlError}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={selected && !isCheckboxUpdated ? selected.active : active}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'checkbox' }}
                        />
                    }
                    label="Rendre cet article actif"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={disable}>
                    Enregistrer
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </Dialog>
    )
}