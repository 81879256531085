import React from 'react'
import {  Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function ConsultCard({ classes }) {
    let history = useHistory();

    const handleClick = () => {
        history.push('visit')
    }

    return (
        <div className={classes.consultCard}>
            <Typography variant="h5" gutterBottom>
                    JE CONSULTE MAINTENANT
                </Typography><br />
                <Button
                    variant="contained"
                    color='secondary'
                    className={classes.consultButton}
                    onClick={handleClick}
                >
                    JE CHOISIS MON PSY
                </Button>
        </div>
    )
}
