import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { AppointmentCity } from '../Scenes/VisitItem/AppointmentCity';
import { AppointmentSpecialist } from '../Scenes/VisitItem/AppointmentSpecialist';
import { MakeAppointment } from '../Scenes/VisitItem/MakeAppointment';
import { AppointmentOverview } from '../Scenes/VisitItem/AppointmentOverview';
import { capitalize, StepContent, Typography, useMediaQuery } from '@material-ui/core';
import { AppointmentPayment } from '../Scenes/VisitItem/AppointmentPayment';
import { useStylesHorizontalStepper } from '../css/Style';

function getSteps() {
  return ['Validez votre Psychologue', 'Choisissez un motif', 'Choissisez un créneau', 'Récapitulatif', 'Paiement'];
}

export default function HorizontalStepper(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStylesHorizontalStepper();
  const [activeStep, setActiveStep] = React.useState(0);
  const [cityCoordonate, setCityCoordonate] = React.useState(null)
  const [error, setError] = React.useState({
    field: false,
    option: false
  })
  const [text, setText] = React.useState(false)
  const [paymentValidate, setPaymentValidate] = React.useState(false)
  const [price, setPrice] = React.useState(null)
  const [payment, setPayment] = React.useState(null)
  const [specialist, setSpecialist] = React.useState({})
  const [transition, setTransition] = React.useState(undefined)
  const [motif, setMotif] = React.useState('')
  const [specialistError, setSpecialistError] = React.useState('')
  const steps = getSteps();

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <AppointmentSpecialist matches={matches} cityCoordonate={cityCoordonate} setSpecialist={setSpecialist} specialist={props.specialist} option={props.option} />;
      case 1:
        return <AppointmentCity setStepper={props.setStepper} id={props.intervention.id} matches={matches} motifs={props.motifs} client={props.client} error={error} option={props.option} motif={motif} setMotif={setMotif} text={text} setCityCoordonate={setCityCoordonate} />;
      case 2:
        return <MakeAppointment sessionTypeSelected={props.sessionType} motifs={props.motifs} setStepper={props.setStepper} params={props.params} formatDate={props.formatDate} interventionId={props.intervention.id} matches={matches} specialist={props.specialist} setAppointment={props.setAppointment} appointments={props.appointments} appointment={props.appointment} option={props.option}/>;
      case 3:
        return <AppointmentOverview officePrice={props.officePrice} setStepper={props.setStepper} intervention={props.intervention} matches={matches} motif={motif} specialist={props.specialist} appointment={props.appointment} option={props.option} price={price} payment={payment} city={props.city} />;
      case 4:
        return <AppointmentPayment setStepper={props.setStepper} payment={payment} transition={transition} id={props.intervention.id} matches={matches} option={props.option} price={price} setPayment={setPayment} setTransition={setTransition} setPaymentValidate={setPaymentValidate} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    if ((props.specialist && activeStep === 0) || (Object.keys(props.specialist).length > 0 && props.specialist.constructor === Object && activeStep === 1 && motif ) || (props.appointment.id && activeStep === 2) || activeStep === 3 || (payment && activeStep === 4)) {
      setError(false)
      setSpecialistError('')
      setText('')
      getPrice()
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (!props.option || !motif) {
      setError({option: !props.option, motif: !motif})
      setText('')
    } 

    if (!props.appointment.id && activeStep === 2) {
      setSpecialistError('Veuillez selectionner une date')
    }
    if (!payment && activeStep === 3) {
      if (props.option === 'Cabinet' || props.option === 'Visio') {
        setPayment(true)
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } 
    }
    if (!payment && activeStep === 4) {
      setSpecialistError('Vous devez payer pour finalisez votre prise de rendez-vous')
    }
  };

  const handleBack = () => {
    if (activeStep === 1 ) {
      setMotif('')
      setSpecialistError('')
    }

    if (activeStep === 2 ) {
      setSpecialistError('')
      setSpecialist({})
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getPrice = () => {
    if (Object.keys(specialist).length > 0) {
      specialist.specialistHasConsultationsType.map((consultation) => {
        if (getOption() === consultation.consultationType.name) {
          return setPrice(consultation.forfait && consultation.forfait.price)
        }
        return null
      })
    }
  }

  const getOption = () => {
    if (props.option === 'domicile') {
      return 'Intervention'
    } 
    return capitalize(props.option)
  }

  return (
    <div className={classes.root}>
      <Typography style={{margin: 'auto', textAlign: 'center', color: '07057C'}}>Complétez les différentes étapes pour valider votre RDV</Typography>
      <Stepper activeStep={activeStep} alternativeLabel={!matches} orientation={matches ? 'vertical' : 'horizontal'} style={theme.palette.type === 'light' ? {background: '#FAFAFA'} : {background: '#303030'}}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            {matches && <StepContent>
              <div>{getStepContent(index)}</div>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Retour
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
                  </Button>
                </div>
              </div>
            </StepContent>}
          </Step>
        ))}
      </Stepper>
      {activeStep !== 5 && !matches && <div style={{textAlign: 'right', marginTop: '50px', marginRight: '200px'}}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Retour
              </Button>
              {!(activeStep === 4 && props.option !== 'cabinet') && <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Valider le RDV' : 'Suivant'}
              </Button>}
      </div>}
      <div style={{margin: 'auto', textAlign: 'center', justifyContent: 'center', color: 'red', fontSize: '19px'}}>
      {specialistError}
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <AppointmentOverview officePrice={props.officePrice} setStepper={props.setStepper} idClient={props.client.id} setTransition={setTransition} id={props.intervention.id} cityCoordonate={cityCoordonate} client={props.client} motif={motif} specialist={props.specialist} appointment={props.appointment} city={props.city} option={props.option} price={price} payment={payment} transition={transition} setPaymentValidate={setPaymentValidate} paymentValidate={paymentValidate} />
          </div>
        ) : !matches && (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
          </div>
        )}
        {(props.option === 'Intervention' || props.option === 'Visio') && payment && handleNext()}
      </div>
    </div>
  );
}