import React, { useEffect, useCallback, useState } from "react"
import moment from 'moment'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core';

const headHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13 ,14 ,15 ,16 ,17 ,18 ,19, 20, 21, 22, 23]

export default function PlatformPlanning({ availability }) {
    const [planningArray, setPlanningArray] = useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const handleWeekArray = useCallback(() => {
        let t = []
        availability.map((ava) => {
            if (moment(ava.startDate).format('DD') !== moment(ava.endDate).format('DD')) {
                t.push({startDate: ava.startDate, endDate: moment(ava.startDate).format('YYYY-MM-DD 00:00')})
                return t.push({startDate: moment(ava.endDate).format('YYYY-MM-DD 00:00'), endDate: ava.endDate})
            } else {
                return t.push(ava)
            }
        })
        const days = []
        const dateStart = moment()
        const dateEnd = moment().add(6, 'days')
        const quarterHours = ["00", "30"];

        while (dateEnd.diff(dateStart, 'days') >= 0) {
            const times = [];
            const findDay = t.filter((ava) => moment(ava.startDate).format('ddd Do MMMM') === dateStart.format('ddd Do MMMM'))

            for(let i = 0; i < 24; i++){
                for(let j = 0; j < 2; j++){
                    let time = i + ":" + quarterHours[j];
                    if(i < 10){
                        time = "0" + time;
                    }

                    if (findDay.find((day) => time >= moment(day.startDate).format('HH:mm') && time < format(moment(day.endDate).format('HH:mm')))) {
                        times.push({time: time, booked: true});
                    } else {
                        times.push({time: time, booked: false});
                    }
                }
            }
            
            days.push({day: dateStart.format('ddd Do MMMM'), hours: times})
            dateStart.add(1, 'days')
        }

        setPlanningArray(days)
    }, [availability])

    useEffect(() => {

        handleWeekArray()

    }, [handleWeekArray])
    
    const format = (str) => {
        if (str === '00:00') {
            return '24:00'
        } else {
            return str
        }
    }
    return (
        <div style={matches ? {overflow: 'scroll', marginRight: 5} : {}}>
            <table style={{borderSpacing: '0px 1px', margin: 'auto'}}>
                <thead>
                    <tr>
                        <th></th>
                        {headHours.map((i) => {
                            return <th key={i} colSpan="2" style={{textAlign: 'left'}}>{i}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {planningArray && planningArray.map((day) => {
                            return <tr key={day.day}>
                                        <th key={day.day} style={{minWidth: 120}}>{day.day} : </th>
                                        {
                                            day.hours.map((hour) => {
                                                if (hour.time.endsWith('30')) {
                                                    if (hour.booked) {
                                                        return <th key={hour.time} style={{width: 20, backgroundColor: '#05057C', margin: 0, padding: 0}}></th>
                                                    }
                                                    return <th key={hour.time} style={{width: 20, backgroundColor: '#F3F3F3', margin: 0, padding: 0}}></th>
                                                } else {
                                                    if (hour.booked) {
                                                        return <th key={hour.time} style={{width: 20, backgroundColor: '#05057C', borderLeft: 'thin #ddd solid', margin: 0, padding: 0}}></th>
                                                    }
                                                    return <th key={hour.time} style={{width: 20, backgroundColor: '#F3F3F3', borderLeft: 'thin #ddd solid', margin: 0, padding: 0}}></th>
                                                }

                                            })
                                        }
                            </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}