import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

export default function SkeletonFilterPaper({ classes, matches }) {

    return (
        <Paper className={classes.filter} style={matches? {marginTop: -230, borderRadius: '70px'} : {}}>
            <Grid container justify="space-between" className={classes.grid}>
                <Grid item xs={matches ? 12 : 8} align="center" style={{marginTop: 10}}>
                    <TypographySkeleton variant={'h5'} margin={'inherit'} paddingBottom={"30px"} width={"100%"}/>
                    <Grid container justify="space-between" className={classes.grid}>
                      <Grid item xs={matches ? 12 : 4} align="center">
                        <TypographySkeleton variant={'h5'} margin={'inherit'} paddingBottom={"30px"} width={"80%"} />
                      </Grid>
                      <Grid item xs={matches ? 12 : 4} align="center">
                        <TypographySkeleton variant={'h5'} margin={'inherit'} paddingBottom={"30px"} width={"80%"} />
                      </Grid>
                      <Grid item xs={matches ? 12 : 4} align="center">
                        <TypographySkeleton variant={'h5'} margin={'inherit'} paddingBottom={"30px"} width={"80%"} />
                      </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={matches ? 12 : 2} align="center" style={{paddingLeft: 10}}>
                    <TypographySkeleton variant={'h2'} width={200} />
                    <TypographySkeleton variant={'h5'} margin={'inherit'} paddingBottom={"30px"} width={"100%"} />
                </Grid>
                <Grid item xs={matches ? 12 : 2}>
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                        <Skeleton animation="wave" variant="circle" width={matches ? 12 : 35} height={matches ? 12 : 35} style={{marginLeft: 25}} />
                        <TypographySkeleton variant={'caption'} width={'100%'} divWidth={"60%"} margin='left' marginTop='-15px' marginLeft='35px' />
                  </Box>    
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                        <Skeleton animation="wave" variant="circle" width={matches ? 12 : 35} height={matches ? 12 : 35} style={{marginLeft: 25}} />
                        <TypographySkeleton variant={'caption'} width={'100%'} divWidth={"70%"} margin='left' marginTop='-15px' marginLeft='35px' /> 
                  </Box>             
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                        <Skeleton animation="wave" variant="circle" width={matches ? 12 : 35} height={matches ? 12 : 35} style={{marginLeft: 25}} />
                        <TypographySkeleton variant={'caption'} width={'100%'} divWidth={"65%"} margin='left' marginTop='-15px' marginLeft='35px' />
                  </Box>         
                </Grid>
              </Grid>
        </Paper>
    )
}

function TypographySkeleton({ variant, width, marginTop, marginLeft, paddingBottom, margin = 'auto', divWidth = '100%' }) {
  
    return (
      <div style={{width: divWidth }}>
            <Typography component="div" variant={variant} align='center' style={{marginLeft: marginLeft, marginTop: marginTop, paddingBottom: paddingBottom}}>
                <Skeleton animation="wave" width={width || "60%"} style={{ marginBottom: 6, margin: margin }}/>
            </Typography>
      </div>
    );
  }