import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, Slide } from '@material-ui/core'
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MoreClientDetailsPopup({ showPopup, setShowPopup }) {

    return (
        <Dialog
            open={!!showPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setShowPopup(null)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Détails de l'intervention"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Nom : </span>{showPopup.specialist.user.firstName}<br />
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Prénom : </span>{showPopup.specialist.user.lastName}<br />
                    {showPopup.type === 'Cabinet' && <span style={{fontWeight: 'bold', fontSize: 19}}>Adresse : </span>}{showPopup.type === 'Cabinet' && showPopup.address}{showPopup.type === 'Cabinet' && <br />} 
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Motif : </span>{showPopup.motif}<br />
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Date et heure de la consultation : </span>{moment(showPopup.startDate).format('dddd Do MMMM YYYY à HH:mm')}<br />
                    {showPopup.type === 'Cabinet' && <span style={{fontWeight: 'bold', fontSize: 19}}>Prix : </span>}{showPopup.type === 'Cabinet' && `${showPopup.billingTtc} €`}{showPopup.type === 'Cabinet' && <br />} 
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}