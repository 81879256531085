import { Box, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import CustomCollapse from '../../../../components/CustomCollapse';
import { displayableAddress } from '../../../../helpers/utils';

export default function AdminGrid({ cardStyles, matches,userData }) {
    const [openSpecialities, setOpenSpecialities] = React.useState(false);
    const [openConsultationTypes, setOpenConsultationTypes] = React.useState(false);
    const [openLanguages, setOpenLanguages] = React.useState(false);
    const [openForfaits, setOpenForfaits] = React.useState(false);

    const handleOpenCollapse = (e, params) => {
        switch(params) {
            case 'Spécialité(s) :':
                setOpenSpecialities(!openSpecialities)
                break
            case 'Type(s) de consultation :':
                setOpenConsultationTypes(!openConsultationTypes)
                break
            case 'Langue(s) parlée(s) :':
                setOpenLanguages(!openLanguages)
                break
            default:
                setOpenForfaits(!openForfaits)
        }
    };

    return (
        <>
        <Grid container alignItems="center" className={cardStyles.grid}>
            <Grid item xs={matches ? 12 : 7}>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Adresse :</span> {userData.user.address && `${displayableAddress(userData.user.address)}`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Téléphone portable :</span> {userData.user.mobile}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Adresse du cabinet :</span> {(userData.office && userData.office.address && `${displayableAddress(userData.office.address)}`) || 'Pas de cabinet'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Téléphone fixe :</span> {userData.phone || 'Pas de téléphone fixe'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Siret :</span> {userData.siret}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                    <span style={{fontWeight: 'bold'}}>Code :</span> {userData.code}
                </Typography>
            </Grid>
          <Grid item xs={matches ? 12 : 5} style={matches ? {paddingLeft: 0} : {paddingLeft: '80px'}}>
            <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                <span style={{fontWeight: 'bold'}}>Adeli :</span> {userData.adeli}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                <span style={{fontWeight: 'bold'}}>Rayon d'intervention :</span> {userData.interventionDistance} km
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                <span style={{fontWeight: 'bold'}}>Iban :</span> {userData.iban}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                <span style={{fontWeight: 'bold'}}>BIC :</span> {userData.bic}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div" style={{marginBottom: 20}}>
                <span style={{fontWeight: 'bold'}}>Actif :</span> {userData.active ? 'Oui' : 'Non'}
            </Typography>
          </Grid>
          </Grid>
          <Divider />
          <Box
            mt={2}
            display={matches? 'block' : 'flex'}
            alignItems={'center'}
          >
                <CustomCollapse data={userData.specialities} open={openSpecialities} handleOpenCollapse={handleOpenCollapse} title={'Spécialité(s) :'} style={matches ? {} : {width: 150}} />
                <CustomCollapse data={userData.specialistHasConsultationsType} open={openConsultationTypes} handleOpenCollapse={handleOpenCollapse} title={'Type(s) de consultation :'} style={matches ? {marginLeft: 0, marginTop: 20} : {marginLeft: 395}} />
          </Box><br />
          <Box
            mt={2}
            display={matches? 'block' : 'flex'}
            alignItems={'center'}
          >
                <CustomCollapse data={userData.languages} open={openLanguages} handleOpenCollapse={handleOpenCollapse} title={'Langue(s) parlée(s) :'} style={matches ? {} : {width: 150}}/>
                <CustomCollapse find={true} data={userData.specialistHasConsultationsType} open={openForfaits} handleOpenCollapse={handleOpenCollapse} title={'Tarif(s) en cabinet :'} style={matches ? {marginLeft: 0, marginTop: 20} : {marginLeft: 400}} />
          </Box>
          </>
    )
}