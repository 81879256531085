import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress'
import { TransitionLeft } from '../../../helpers/utils';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPricesPopup from './PricesItem/AddPricesPopup';
import { usePrices } from '../../../hooks/forfaits'
import { handleFormError } from '../../../service/security/error';
import DeletePricesPopup from './PricesItem/DeletePricesPopup'
import { apiFetch } from '../../../service/apiFetch';

export default function Prices({ userData, setText, setSeverity, setTransition, setIsUpdate, setNewSideBarInfo }) {
    const [openAddPopup, setOpenAddPopup] = React.useState(false)
    const [openDeletePopup, setOpenDeletePopup] = React.useState(false)
    const [type, setType] = React.useState('')
    const [price, setPrice] = React.useState('')
    const [duration, setDuration] = React.useState('')
    const [error, setError] = React.useState({})

    const {
        fetchPrices,
        createPrices,
        deletePrices,
        prices
    } = usePrices()

    React.useEffect(() => {
        (async () => {
            const pricesFetch = await fetchPrices(`/specialists/${userData.specialist.id}/forfaits`)
            if (!pricesFetch) {
                setText('Erreur lors de la récupération des forfaits')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setIsUpdate(true)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, setTransition, setText, setSeverity, setIsUpdate])

    const handleOpenAddPopup = () => {
        setOpenAddPopup(!openAddPopup)
    }

    const handleOpenDeletePopup = (id) => {
        setOpenDeletePopup(openDeletePopup ? false : id)
    }

    const addPrices = async () => {
        setError({})
        const addPrice = await createPrices({price: price, type: type, duration: duration, specialistHasConsultationType: userData.specialist.specialistHasConsultationsType.find((i) => i.consultationType.name === 'Cabinet')['@id']}, `/specialists/${userData.specialist.id}/forfaits`, true)
        if (addPrice && !addPrice.violations) {
            setOpenAddPopup(false)
            setText('Le forfait a bien été ajouté')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
            const profil = await apiFetch('/users/me')
            if (profil && profil.id) {
                setNewSideBarInfo(profil)
            }
        } else {
            const errorField = handleFormError(addPrice, ['price', 'type', 'duration'])
            setError(errorField)
            setText('Echec lors de la création du forfait')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
        }
    }

    const deletePrice = async () => {
        const deletedPrice = await deletePrices(openDeletePopup)
        if (deletedPrice && !deletedPrice.violations) {
            setText('Le forfait a bien été supprimé')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
            const profil = await apiFetch('/users/me')
            if (profil && profil.id) {
                setNewSideBarInfo(profil)
            }
        } else {
            setText('Echec lors de la suppression')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setIsUpdate(true)
        }
        setOpenDeletePopup(false)
    }

    return (
        <TableContainer component={Paper} style={{marginTop: 30}}>
            <Tooltip title="Ajouter un tarif">
                <Button variant='contained' color={'primary'} style={{margin: 5, float: 'right'}} onClick={handleOpenAddPopup}>Ajouter un tarif</Button>
            </Tooltip>
            {prices ? <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Nom</TableCell>
                        <TableCell style={{fontWeight: 'bold'}} align="right">Durée</TableCell>
                        <TableCell style={{fontWeight: 'bold'}} align="right">Prix</TableCell>
                        <TableCell style={{fontWeight: 'bold'}} align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prices.length ? prices.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.type}
                            </TableCell>
                            <TableCell align="right">{row.duration} min</TableCell>
                            <TableCell align="right">{row.price} €</TableCell>
                            <TableCell align="right"><IconButton onClick={() => handleOpenDeletePopup(row.id)}><DeleteIcon /></IconButton></TableCell>
                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell align="right">Pas de tarifs pour le moment...</TableCell>
                    </TableRow>}
                </TableBody>
            </Table> : <LinearProgress />}
            <AddPricesPopup error={error} setType={setType} setPrice={setPrice} addPrices={addPrices} open={openAddPopup} handleClose={handleOpenAddPopup} duration={duration} setDuration={setDuration} />
            <DeletePricesPopup deletePrice={deletePrice} open={openDeletePopup} handleClose={handleOpenDeletePopup}/>
        </TableContainer>
    );
}