import { Avatar, Button, Checkbox, Divider, FormHelperText, Grid, InputAdornment, LinearProgress, Paper, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import AutoCompleteAddress from '../../../components/AutoCompleteAddress'
import { apiFetch } from '../../../service/apiFetch'
import UploadFile from '../UploadItem'
import { 
    formatAddress,
    isEquivalent,
    TransitionLeft,
    formatIban,
    findConsultationTypes,
    formatConsultationTypesBody,
    displayableAddress
} from '../../../helpers/utils'
import hasRoles from '../../../service/security/hasRoles'
import { useAddresses } from '../../../hooks/adresses'
import { useSpecialists } from '../../../hooks/specialist'
import MultipleSelect from '../../../components/MultipleSelect'
import { handleFormError } from '../../../service/security/error'
import ConfirmationPopup from '../../../components/ConfirmationPopup'
import { useHistory, useParams } from 'react-router-dom'
import mainConstant from '../../../Constant/mainConstant'
import { ActiveBreadcrumb } from '../../../components/Breadcrumb'

export function SpecialistProfile({ classes, userData, setIsUpdate, setTransition, onDelete, setText, setNewSideBarInfo, setSelectedRow, setSeverity, isSpecialRoute, breadcrumb, setBreadcrumb }) {
    document.title = mainConstant.PROFILE
    const [photo, setPhoto] = React.useState(null)
    const [disable, setDisable] = React.useState(true)
    const [profileUser, setProfileUser] = React.useState(null)
    const [newAddress, setNewAddress] = React.useState('')
    const [newOfficeAddress, setOfficeNewAddress] = React.useState('')
    const [idAddress, setIdAddress] = React.useState('')
    const [error, setError] = React.useState(false)
    const [checked, setChecked] = React.useState(false)
    const [languages, setLanguages] = React.useState('')
    const [consultationTypes, setConsultationTypes] = React.useState('')
    const [specialities, setSpecialites] = React.useState('')
    const [apiLanguages, setApiLanguages] = React.useState(null)
    const [apiConsultationTypes, setApiConsultationTypes] = React.useState(null)
    const [apiSpecialities, setApiSpecialities] = React.useState(null)
    const { id } = useParams()
    const history = useHistory()
    const [newCoordonate, setNewCoordonnate] = React.useState({
        lat: null,
        lng: null
    })
    const [newOfficeCoordonate, setNewOfficeCoordonnate] = React.useState({
        lat: null,
        lng: null
    })
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    React.useEffect(() => {
        apiFetch("/specialities", {
            method: "GET",
          })
          .then(res => {
            return setApiSpecialities(res['hydra:member']);
          })

        apiFetch("/languages", {
          method: "GET",
        })
        .then(res => {
          return setApiLanguages(res['hydra:member']);
        })

        apiFetch("/consultation_types", {
          method: "GET",
        })
        .then(res => {
          return setApiConsultationTypes(formatConsultationTypesNames(res['hydra:member']));
        })

    }, []);
    const {
        updateAddresses,
    } = useAddresses()
    const {
        updateSpecialist,
    } = useSpecialists()

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const inputs = React.useRef({})
    React.useEffect(() => {
        (async () => {
            if (userData) {
                inputs.current.firstName = userData.firstName
                inputs.current.lastName = userData.lastName
                inputs.current.address = userData.address && `${displayableAddress(userData.address)}`
                inputs.current.officeAddress = (userData.specialist.office && userData.specialist.office.address && `${displayableAddress(userData.specialist.office.address)}`) || ''
                inputs.current.mobile = userData.mobile
                inputs.current.phone = userData.phone
                inputs.current.adeli = userData.specialist.adeli
                inputs.current.interventionDistance = userData.specialist.interventionDistance
                inputs.current.iban = userData.specialist.iban
                inputs.current.bic = userData.specialist.bic
                inputs.current.enableTVA = userData.specialist.enableTVA
                inputs.current.siret = userData.specialist.siret
                inputs.current.description = userData.specialist.description
                inputs.current.languages = userData.specialist.languages
                inputs.current.specialistHasConsultationsType = userData.specialist.specialistHasConsultationsType
                inputs.current.specialities = userData.specialist.specialities
                inputs.current.urssafAddress = userData.specialist.urssafAddress
                inputs.current.photo = `${process.env.REACT_APP_BASE_URL}${userData.specialist.photo.contentUrl}`
                setChecked(userData.specialist && userData.specialist.enableTVA)
                setProfileUser(userData)
                setNewAddress(userData.address && `${displayableAddress(userData.address)}`)
                setOfficeNewAddress((userData.specialist.office && userData.specialist.office.address && `${displayableAddress(userData.specialist.office.address)}`) || '')
                setIdAddress(userData.address && userData.address.id)
            } else if (id) {
                const user = await apiFetch(`/specialists/${id}`, {method: 'GET'})
                inputs.current.firstName = user.user.firstName
                inputs.current.lastName = user.user.lastName
                inputs.current.address = user.user.address && `${displayableAddress(user.user.address)}`
                inputs.current.officeAddress = (user.office && user.office.address && `${displayableAddress(user.office.address)}`) || ''
                inputs.current.mobile = user.user.mobile
                inputs.current.phone = user.user.phone
                inputs.current.adeli = user.adeli
                inputs.current.interventionDistance = user.interventionDistance
                inputs.current.iban = user.iban
                inputs.current.bic = user.bic
                inputs.current.enableTVA = user.enableTVA
                inputs.current.siret = user.siret
                inputs.current.description = user.description
                inputs.current.languages = user.languages
                inputs.current.specialistHasConsultationsType = user.specialistHasConsultationsType
                inputs.current.specialities = user.specialities
                inputs.current.urssafAddress = user.urssafAddress
                inputs.current.photo = `${process.env.REACT_APP_BASE_URL}${user.photo.contentUrl}`
                setChecked(user && user.enableTVA)
                setProfileUser(user)
                setNewAddress(user.user.address && `${displayableAddress(user.user.address)}`)
                setOfficeNewAddress((user.office && user.office.address && `${displayableAddress(user.office.address)}`) || '')
                setIdAddress(user.user.address && user.user.address.id)
            }
        })()
    }, [userData, id])
    
    const handleSubmit = async (e) => {
        setDisable(true)
        setError({})
        e.preventDefault()
        
        let data = new FormData(e.target)
        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/media_objects', {
                method: 'POST',
                body: dataPhoto
            }, true, true)
        }

        const dataObject =  {
            firstName : data.get('username'),
            lastName : data.get('lastName'),
            address : data.get('addressPro'),
            officeAddress : newOfficeAddress,
            mobile : data.get('numPro'),
            phone : data.get('numFixPro'),
            languages : languages,
            specialistHasConsultationsType : consultationTypes,
            specialities : specialities,
            adeli : data.get('adeliNum'),
            siret : data.get('siret'),
            description : data.get('description'),
            iban : data.get('iban').replace(/ /g,''),
            bic : data.get('bic'),
            enableTVA : checked,
            interventionDistance : findConsultationTypes(consultationTypes, '/consultation_types/2') && (parseInt(data.get('intervention')) || 0),
            coordonate: newCoordonate,
            officeCoordonate : newOfficeCoordonate,
            photo: createPhoto ? createPhoto['@id'] : inputs.current.photo,
            urssafAddress: hasRoles() === 'super_admin' ? data.get('urssafAddress') : inputs.current.urssafAddress
        }
        
        const isEquivalentData = dataObject
        delete dataObject.coordonate
        delete dataObject.officeCoordonate
        const modifyInputs = isEquivalent(inputs.current, isEquivalentData)

        if (modifyInputs.address) {
            const address = await updateAddresses(idAddress, JSON.stringify(formatAddress(newAddress, newCoordonate)), true)
            if (address.trace) {
                setDisable(false)
                return setError({address: 'Veuillez entrer une adresse correct'})
            } else {
                setSeverity('success')
                setText('Votre profil a bien été modifié')
                setIsUpdate(true)
                setTransition(() => TransitionLeft) 
            }
            delete modifyInputs.address
        } 
    

        if (!modifyInputs.address) {
            const requestBody = JSON.stringify(handleBodyProperties(modifyInputs, profileUser, dataObject, isSpecialRoute, newOfficeCoordonate))
            const specialist = await updateSpecialist(profileUser.specialist ? profileUser.specialist.id : profileUser.id, requestBody, true)
            if (!specialist.updatedSpecialist.violations) {
                setError({})
                setSeverity('success')
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                if (hasRoles() === 'super_admin') {
                    setText('Compte mis à jour !')
                    setTimeout(() => {
                        setSelectedRow && setSelectedRow(null)
                        history.push('/specialists')
/*                         document.location.reload(); */
                    }, 1000)
                } else {
                    setText('Votre profil a été mis à jour !')
                    setTimeout(() => {
                        setNewSideBarInfo(specialist.updatedUser)
                    }, 1000)
                }
            } else {
                if (hasRoles() === 'super_admin') {
                    setText('Désolé, votre compte n\' a pas pu être crée !')
                } else {
                    setText('Désolé, la modification n\' pas pu être pris en compte !')
                }
                setSeverity('error')
                const errorField = handleFormError(specialist.updatedSpecialist, ['photo', 'user.firstName', 'user.lastName', 'user.address', 'user.mobile', 'description', 'siret', 'langues', 'specialistHasConsultationsType', 'specialistHasConsultationsType[2]', 'specialities', 'interventionDistance', 'adeli', 'iban', 'bic', 'enableTVA', 'user.phone'])
                setError(errorField)
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                setDisable(false)
            }
        }
    }

    const handleChange = () => {
        setDisable(false)
    }

    const handleDelete = () => {
        apiFetch(`/specialists/${profileUser.specialist.id}`, {method: 'DELETE'})
        .then((res) => {
            if (res) {
                setSeverity('success')
                setSelectedRow(null)
                setText('Le profil a bien été supprimé')
                setIsUpdate(true)
                setTransition(() => TransitionLeft);
            } else {
                setSeverity('error')
                setText('Echec lors de la suppression du profil')
                setIsUpdate(true)
                setTransition(() => TransitionLeft);
            }
            setOpen(false)
        })
    }

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleIbanChange = (e) => {
        if (e.keyCode === 8 || e.keyCode === 37 || (e.target && !e.target.value)) {
            return e.target.value
        }
        const cleanIban = (e.target && e.target.value)
        .replace(/\s\s+/g, ' ')
        .replace(/[^0-9a-zA-Z]/gi, '')
        .toUpperCase()
        const parts = []
        
        if (cleanIban.length > 0) {
          parts.push(cleanIban.substring(0, 4))
        }
      
        if (cleanIban.length > 4) {
          parts.push(cleanIban.substring(4, 8))
        }
      
        if (cleanIban.length > 8) {
          parts.push(cleanIban.substring(8, 12))
        }
      
        if (cleanIban.length > 12) {
          parts.push(cleanIban.substring(12, 16))
        }
      
        if (cleanIban.length > 16) {
          parts.push(cleanIban.substring(16, 20))
        }
      
        if (cleanIban.length > 20) {
          parts.push(cleanIban.substring(20, 24))
        }
    
        if (cleanIban.length > 24) {
          parts.push(cleanIban.substring(24, 26))
        }
      
        e.target.value = parts.join(' ')
    }

    const breadcrumbTab = [
        'Profil',
        'Ajouter',
        'Modifier',
        'Voir',
        'Map',
    ];

    const handleBack = () => {
        history.push('/specialists')
    }

    const handleModify = () => {
        setDisable(false)
    }
    
    return (
        <main>
            {isSpecialRoute && <ActiveBreadcrumb breadcrumb={breadcrumb} tab={breadcrumbTab} setBreadcrumb={setBreadcrumb} handleBack={handleBack} />}
            {apiLanguages && apiSpecialities && apiConsultationTypes && inputs.current.lastName ? <Paper className={classes.paper}>
                <Typography variant="h5" gutterBottom color='primary' className={classes.avatar}>
                    Mon profil
                </Typography>
                <Divider className={classes.divider}/>
                {profileUser ? <>
                <Avatar alt="Remy Sharp" src={inputs.current.photo} className={`inner-img ${classes.avatar}`} style={{width: '100px', height: '100px'}}/>
                {disable && <Button
                    variant="contained"
                    color="primary"
                    className={classes.right}
                    onClick={handleModify}
                >
                    Modifier mes informations
                </Button>}
                <form autoComplete="off" onChange={ handleChange } onSubmit={ handleSubmit }>
                {!disable && <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.right}>
                        Enregistrer
                </Button>}
                <Grid container spacing={3} style={{marginTop:'25px'}}>
                <Grid item xs={matches ? 12 : 6}>
                    <Typography variant='h6' color='primary'>INFORMATIONS PERSONNELLES</Typography>
                    {hasRoles() === 'super_admin' && <TextField error={!!error.firstName} helperText={error.firstName} className={classes.width} id="username" name="username" label="Prénom" defaultValue={inputs.current.firstName} disabled={disable} required/>}
                    {hasRoles() === 'super_admin' && <TextField error={!!error.lastName} helperText={error.lastName} className={classes.width} id="lastName" name="lastName" label="Nom" defaultValue={inputs.current.lastName} disabled={disable} required/>}
                    <AutoCompleteAddress error={!!error.address} className={classes.addressWidth} id="addressPro" name="addressPro" label="Adresse professionnelle" defaultValue={inputs.current.address} setNewAddress={setNewAddress} setNewCoordonnate={setNewCoordonnate} disabled={disable} required={true} />
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error.address}</FormHelperText>
                    <TextField InputLabelProps={{ style: {overflow :'hidden', textOverflow : 'ellipsis', whiteSpace :'nowrap', right :'22px', bottom :'0px',} }} error={!!error.mobile} helperText={error.mobile} className={classes.width} id="numPro" name="numPro" label="Numéro de portable professionnel" type="text" defaultValue={inputs.current.mobile} InputProps={{ inputProps: { max: 9999999999 }}} disabled={disable} required />
                    <AutoCompleteAddress error={!!error.officeAddress} className={classes.addressWidth} id="addressFirm" name="addressFirm" label="Adresse du cabinet" defaultValue={inputs.current.officeAddress} setNewAddress={setOfficeNewAddress} setNewCoordonnate={setNewOfficeCoordonnate} disabled={disable}/>
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error.officeAddress}</FormHelperText>
                    <TextField InputLabelProps={{ style: {overflow :'hidden', textOverflow : 'ellipsis', whiteSpace :'nowrap', right :'22px', bottom :'0px',} }} error={!!error.phone} helperText={error.phone} className={classes.width} id="numFixPro" name="numFixPro" label="Numéro de fixe professionnel" type="text" defaultValue={inputs.current.phone} InputProps={{ inputProps: { max: 9999999999 }}} disabled={disable} />
                    {hasRoles() === 'super_admin' && <UploadFile setPhoto={setPhoto} disabled={disable} />}
                    <FormHelperText style={{color: 'red'}}>{error.photo}</FormHelperText>
                    {hasRoles() === 'specialist' && <TextField disabled={disable} error={!!error.adeli} helperText={error.adeli} className={classes.width} defaultValue={inputs.current.adeli} id="adeliNum" name="adeliNum" label="Numéro ADELI" type='number' required/>}
                    <TextField disabled={disable} error={!!error.siret} helperText={error.siret} className={classes.width} id="siret" name="siret" type='number' defaultValue={inputs.current.siret} label="Numéro de siret" required />
                    <TextField error={!!error.urssafAddress} helperText={error.urssafAddress} className={classes.width} id="urssafAddress" name="urssafAddress" label="Adresse URSSAF" defaultValue={inputs.current.urssafAddress} disabled={disable || hasRoles() !== 'super_admin'} required/>
                    {hasRoles() === 'specialist' && <Typography disabled={disable} variant="subtitle1" component="p" style={{color: theme.palette.type === 'light' ? 'grey' : 'color'}}>
                        Assujetti à la TVA :
                        <Checkbox color="primary" checked={checked} disabled={disable}
                        onChange={handleCheckBoxChange} />
                    </Typography>}
                </Grid>
                <Grid item xs={matches ? 12 : 6}>
                    <Typography variant='h6' color='primary'>CONSULTATIONS</Typography>
                    <MultipleSelect disabled={disable} text={'Langue(s) *'} multipleOption={apiLanguages} setter={setLanguages} state={inputs.current.languages} setDisable={setDisable} /> <br />
                    <MultipleSelect disabled={disable} text={'Type(s) de consultation *'} multipleOption={apiConsultationTypes} setter={setConsultationTypes} setDisable={setDisable} state={inputs.current.specialistHasConsultationsType} />
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error['specialistHasConsultationsType[2]']}</FormHelperText>
                    <MultipleSelect disabled={disable} text={'Spécialitée(s) *'} multipleOption={apiSpecialities} setter={setSpecialites} setDisable={setDisable} state={inputs.current.specialities} /> <br />
                    {findConsultationTypes(consultationTypes, '/consultation_types/2') && <TextField disabled={disable} error={!!error.interventionDistance} InputLabelProps={{ style: {overflow :'hidden', textOverflow : 'ellipsis', whiteSpace :'nowrap', right :'22px', bottom :'0px',} }} InputProps={{ endAdornment: <InputAdornment position="end">Km</InputAdornment> }} helperText={error.interventionDistance} className={classes.width} defaultValue={inputs.current.interventionDistance} id="intervention" name="intervention" type='number' label="Rayon possible d'intervention" />}
                    {hasRoles() === 'super_admin' && <TextField disabled={disable} error={!!error.adeli} helperText={error.adeli} className={classes.width} defaultValue={inputs.current.adeli} id="adeliNum" name="adeliNum" label="Numéro ADELI" type='number' required/>}
                    <Typography variant='h6' color='primary'>INFORMATIONS BANCAIRES</Typography>
                    <TextField disabled={disable} error={!!error.iban} helperText={error.iban} className={classes.width} onKeyDown={handleIbanChange} defaultValue={formatIban(inputs.current.iban)} id="iban" name="iban" label="IBAN" type='text' required/>
                    <TextField disabled={disable} error={!!error.bic} helperText={error.bic} className={classes.width} id="bic" name="bic" label="BIC" defaultValue={inputs.current.bic} type='text' required/>
                    {hasRoles() === 'super_admin' && <Typography variant="subtitle1" component="p" style={{color: theme.palette.type === 'light' ? 'grey' : 'color'}}>
                        Assujetti à la TVA :
                        <Checkbox color="primary" checked={checked}
                        onChange={handleCheckBoxChange} />
                    </Typography>}
                <br />
                </Grid>      
                <Grid item xs={12} className={classes.marginRight}>        
                <TextField disabled={disable} rows={2} rowsMax={12} multiline error={!!error.description} helperText={error.description} className={classes.maxWidth} id="description" name="description" type='text' defaultValue={inputs.current.description} label="Description" required /><br />
                </Grid>
                </Grid>
                </form>
                <ConfirmationPopup open={open} handleClose={handleClose} handleDelete={handleDelete} />
                {onDelete && <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{marginBottom: '30px'}}
                    onClick={handleClickOpen}>
                        Supprimer le profil
                </Button>}
                </> : <LinearProgress style={{marginLeft: '-96px'}}/>}
            </Paper>: <LinearProgress />}
        </main>
    )
}

function handleBodyProperties(modifyInputs, profileUser, expectedValue, isSpecialRoute, newOfficeCoordonate) {
    const element = isSpecialRoute ? {user: {'@id': profileUser.user['@id']}} : {user: {'@id': profileUser['@id']}}
    for (const property in modifyInputs) {
        //TODO: create utils function to handle all specialist property
        if (property === 'officeAddress' || property === 'adeli' || property === 'urssafAddress' || property === 'photo' || property === 'interventionDistance' || property === 'siret' || property === 'description' || property === 'iban' || property === 'bic' || property === 'enableTVA' | property === 'languages' || property === 'specialities') {
            if (property === 'officeAddress') {
                if (!newOfficeCoordonate) {
                    element['office'] = null
                } else {
                    element['office'] = {address: formatAddress(modifyInputs[property], newOfficeCoordonate)}
                }
            } else {
                element[property] = modifyInputs[property]
            }
        } else if (property === 'specialistHasConsultationsType') {
            element[property] = formatConsultationTypesBody(modifyInputs[property], expectedValue.price)
        } else {
            if (modifyInputs[property]) {
                element.user[property] = modifyInputs[property]
            }
        }
    }

    const propOwn = Object.getOwnPropertyNames(element.user)
    if (propOwn.length < 1) {
        delete element.user
    }
    return element
}

function formatConsultationTypesNames(consultationTypes) {
    let array = []
    consultationTypes.map((consultationType) => {
        if (consultationType.name === 'Intervention') {
            array.push({'@id': consultationType['@id'], '@type': consultationType['@type'], id: consultationType.id, name: 'À domicile'})
        } else if (consultationType.name === 'Plateforme') {
            array.push({'@id': consultationType['@id'], '@type': consultationType['@type'], id: consultationType.id, name: 'Ligne d\'écoute'})
        } else {
            array.push(consultationType)
        }
        return null
    })
    return array
}
