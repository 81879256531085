import React, { useState, useEffect, useRef } from "react";
import Fab from '@material-ui/core/Fab';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CallEndIcon from '@material-ui/icons/CallEnd';

const Participant = ({setToken, participant, isLocal, blackScreenIsDisplayed, matches, setOpenClosePopup }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [toggleMic, setToggleMic] = useState(false);
  const [toggleCam, setToggleCam] = useState(false);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const muteMic = () => {
    setToggleMic(!toggleMic)
    !toggleMic ? audioTracks.forEach(track => {
      track.disable();
    }) : audioTracks.forEach(track => {
      track.enable();
    })
  
  }

  const muteCam = () => {
    setToggleCam(!toggleCam)
    !toggleCam ? videoTracks.forEach(track => {
      track.disable();
    }) : videoTracks.forEach(track => {
      track.enable();
    })
  
  }

  const beforeLogout = () => {
    setToken(false)
    setOpenClosePopup(true)
  }

  return (
    <div className="participant" style={isLocal ? matches ? {color: 'white'} : {marginTop: 420, color: 'white'} : {color: 'white'}}>
      <h3>{participant.identity}</h3>
      <video style={isLocal ? {maxWidth: 100, maxHeight: 100} : matches ? {maxWidth: 320, maxHeight: 240} : {}} ref={videoRef} autoPlay={true} />
      {isLocal && <div style={blackScreenIsDisplayed ? matches ? {marginTop: '-178px'} : {marginLeft: '-720px', marginTop: 8} : matches ? {marginTop: '-178px'} : {marginTop: '-41px', marginLeft: '-720px'}}>
        <Fab color={toggleMic ? 'red' : 'primary'} aria-label="mic" style={{marginRight: 10}} onClick={muteMic}>
          {!toggleMic ? <MicIcon /> : <MicOffIcon />}
        </Fab>
        <Fab color={toggleCam ? 'red' : "primary"} aria-label="video" style={{marginRight: 10}} onClick={muteCam}>
          {!toggleCam ? <VideocamIcon /> : <VideocamOffIcon />}
        </Fab>
        <Fab onClick={beforeLogout} color="primary" aria-label="callEnd" style={{marginRight: 10}}>
          <CallEndIcon />
        </Fab>
      </div>}
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

export default Participant;
