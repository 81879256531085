import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_QUOTE_OF_WEEK = 'FETCHING_QUOTE_OF_WEEK'
const SET_QUOTE_OF_WEEK = 'SET_QUOTE_OF_WEEK'
const ADD_QUOTE_OF_WEEK = 'ADD_QUOTE_OF_WEEK'
const UPDATE_QUOTE_OF_WEEK = 'UPDATE_QUOTE_OF_WEEK'
const DELETE_QUOTE_OF_WEEK = 'DELETE_QUOTE_OF_WEEK'

function quoteOfWeekReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_QUOTE_OF_WEEK:
            return { ...state, loading: true }
        case SET_QUOTE_OF_WEEK:
            return {...state, quoteOfWeek: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_QUOTE_OF_WEEK:
            return {...state, quoteOfWeek: [action.payload, ...state.quoteOfWeek]}
        case UPDATE_QUOTE_OF_WEEK:
            return {...state, quoteOfWeek: action.payload }
        case DELETE_QUOTE_OF_WEEK:
            return {...state, quoteOfWeek: state.quoteOfWeek.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useQuoteOfWeek() {
    const [state, dispatch] = useReducer(quoteOfWeekReducer, {    
        quoteOfWeek: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        quoteOfWeek: state.quoteOfWeek,
        totalItems: state.totalItems,
        view: state.view,
        fetchQuoteOfWeek: useCallback(async function (route) {
            if ((state.quoteOfWeek || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_QUOTE_OF_WEEK' })
            const quoteOfWeek = await apiFetch(route || '/cms_quotes', {method: 'GET'})
            if (quoteOfWeek) {
                dispatch({type: 'SET_QUOTE_OF_WEEK', payload: quoteOfWeek})
            }
        }, [state]),
        createQuoteOfWeek: useCallback(async function(data, forceUpdate) {
            const newQuoteOfWeek = await apiFetch('/cms_quotes', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            const quoteOfWeek = await apiFetch('/cms_quotes', {method: 'GET'})
            if (!newQuoteOfWeek.violations && quoteOfWeek) {
                dispatch({type: 'SET_QUOTE_OF_WEEK', payload: quoteOfWeek})
            }
            return newQuoteOfWeek
        }, []),
        updateQuoteOfWeek: useCallback(async function(quoteOfWeek, data, forceUpdate = false) {
            const newQuoteOfWeek = await apiFetch('/cms_quotes/' + quoteOfWeek, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceUpdate)
            const newQuoteOfWeekReload = await apiFetch('/cms_quotes', {method: 'GET'})
            if (!newQuoteOfWeek.violations && newQuoteOfWeekReload) {
                dispatch({type: 'SET_QUOTE_OF_WEEK', payload: newQuoteOfWeekReload})
            }
            return newQuoteOfWeek
        }, []),
        deleteQuoteOfWeek: useCallback(async function (deleteId) {
            const quoteOfWeekDelete = await apiFetch('/cms_quotes/' + deleteId, {
                method: 'DELETE'
            }, true)
            if (quoteOfWeekDelete && !quoteOfWeekDelete['hydra:description']) {
                dispatch({type: 'DELETE_QUOTE_OF_WEEK', payload: deleteId})
            }
            return quoteOfWeekDelete
        }, []),
    }
}