import React, { forwardRef } from "react";
import moment from "moment"
import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css";
import '../css/home.css';
const locale = "fr-FR";
moment.locale(locale); 

export function OldDatePickerComponent({setStartDate, startDate, showTime, disabled, error, helperText, text, className, format}) {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    return <TextField className={className} error={error} helperText={helperText} disabled={disabled} onClick={onClick} ref={ref} label={text || 'Date de naissance'} defaultValue={value} />
  });

  return (
    <div style={{marginBottom: 40, marginRight: 30}}>
      <DatePicker 
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        withPortal
        showTimeSelect={showTime}
        showMonthDropdown
        showYearDropdown
        locale={fr}
        disabled={disabled}
        dateFormat={format ? format : "dd MMMM, yyyy"}
        dropdownMode="select"
        timeIntervals={15}
        timeCaption="Heures"
        customInput={<ExampleCustomInput />}
      />
    </div>
  )
}