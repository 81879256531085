import React from 'react'
import CreditCard from './creditCard';
import NotificationCard from './NotificationCard';
import ToolboxCard from './ToolboxCard';
import ConsultCard from './ConsultCard';
import LastConsultCard from './LastConsultCard';
import SpecialistOfMonthCard from './SpecialistOfMonthCard';
import QuoteOfWeekCard from './QuoteOfWeekCard';
import VideoCard from './VideoCard';
import {  Typography, Grid } from '@material-ui/core';
import SocialCard from './SocialCard';

export default function ClientDashboard({ matches, classes, credit, consult, open, lower1825, user}) {

    return (
            <Grid container spacing={matches ? 0 : 6} className={classes.mainGrid}>
                <Grid item xs={matches ? 12 : 6}>
                    <Grid container spacing={matches ? 0 : 3}>
                        <Grid item xs={matches ? 12 : 6}>
                            <CreditCard classes={classes} user={user} credit={credit} />
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            <NotificationCard classes={classes} next={consult.next} />
                        </Grid>
                    </Grid>
                    <ConsultCard classes={classes} />
                    <LastConsultCard classes={classes} last={consult.last} />
                    <div className={classes.mainVideoCard}>
                        <Typography variant="h5" gutterBottom style={{marginBottom: 60}} >Les vidéos Geo-Psy</Typography>
                        <Grid container spacing={matches ? 0 : 3}  style={{marginBottom: 60}}>
                            <Grid item xs={lower1825 ? 12 : 6}>
                                <Typography variant="h5" gutterBottom style={{font: 'normal normal bold 14px/18px Quicksand', height: 50}} >Olivier Delacroix, ambassadeur du nouveau Geo-Psy</Typography><br />
                                <VideoCard matches={matches} classes={classes} url='https://www.youtube.com/embed/nrcJh3aEfMw' open={open} />
                            </Grid>
                            <Grid item xs={lower1825 ? 12 : 6}>
                                <Typography variant="h5" gutterBottom style={{font: 'normal normal bold 14px/18px Quicksand', height: 50}} >Olivier Delacroix raconte Geo-Psy - la démocratisation du soutien psychologique</Typography><br />
                                <VideoCard matches={matches} classes={classes} url='https://www.youtube.com/embed/LF-_6j8L7fo' open={open}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={matches ? 0 : 3}>
                            <Grid item xs={lower1825 ? 12 : 6}>
                                <Typography variant="h5" gutterBottom style={{font: 'normal normal bold 14px/18px Quicksand', height: 50}} >Les stories Geo-Psy - Laurence et Elliott</Typography><br />
                                <VideoCard matches={matches} classes={classes} url='https://www.youtube.com/embed/huEWqC3QHdU' open={open}/>
                            </Grid>
                            <Grid item xs={lower1825 ? 12 : 6}>
                                <Typography variant="h5" gutterBottom style={{font: 'normal normal bold 14px/18px Quicksand', height: 50}} >Les stories Geo-Psy - Florian</Typography><br />
                                <VideoCard matches={matches} classes={classes} url='https://www.youtube.com/embed/qFrIv6R4GOk' open={open}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={matches ? 12 : 6}>
                    <ToolboxCard classes={classes} matches={matches} />
                    <SocialCard classes={classes} matches={matches} />
                    <SpecialistOfMonthCard consult={consult} classes={classes} />
                    <QuoteOfWeekCard consult={consult} classes={classes} matches={matches} />
                </Grid>
            </Grid>
        )
}
