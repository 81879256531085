import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react'

export default function PricePopup({ specialist, setOpen, open }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-address">
            <DialogTitle id="form-dialog-title">Tarif(s) en cabinet :</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {specialist.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet') && specialist.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet').forfaits.map((consultation) => {
                            return (
                              <React.Fragment key={consultation['@id']}><span style={{marginLeft: 10}}> • {consultation.type} - {consultation.price} €</span><br /></React.Fragment>
                            )
                        })}
                </DialogContentText>
            </DialogContent>
      </Dialog>

    )
}