import { formatAvailabilityTitle } from "../helpers/utils"
import { apiFetch } from "./apiFetch"
import moment from 'moment'

function _findConsultationType(consultationType) {
    switch(consultationType) {
        case 'Cabinet':
            return '/consultation_types/1'
        case 'Intervention':
            return '/consultation_types/2'
        case 'Visio':
            return '/consultation_types/3'
        case "Plateforme":
            return '/consultation_types/4'
        default:
            return ''
    }
}

function _formatBody({startDate, endDate, consultationType, specialist, sessionType, onTheFly = false, repetition, repetitionEndDate, editRepetition, id, ficheClient}) {
    if (onTheFly) {
        return {
            startDate: startDate,
            endDate: endDate,
        }
    } else if (repetition) {
        if (ficheClient) {
            return {
                availabilityStartDate: startDate,
                availabilityEndDate: endDate,
                title: 'Cabinet',
                typeSeance: sessionType,
                consultationType: '/consultation_types/1',
                specialist: specialist['@id'],
                repetitionStartDate: startDate,
                repetitionEndDate: moment(startDate).add(repetitionEndDate, 'month'),
                ficheClient: {
                    firstName: ficheClient.firstName,
                    lastName: ficheClient.lastName
                }
            }
        }
        return {
            availabilityStartDate: startDate,
            availabilityEndDate: endDate,
            title: formatAvailabilityTitle(consultationType),
            typeSeance: consultationType === 'Cabinet' ? sessionType : '',
            consultationType: _findConsultationType(consultationType),
            specialist: specialist['@id'],
            repetitionStartDate: startDate,
            repetitionEndDate: moment(startDate).add(repetitionEndDate, 'month')
        }
    } else if (editRepetition) {
        return {
            availabilityStartDate: startDate,
            availabilityEndDate: endDate,
            title: formatAvailabilityTitle(consultationType),
            typeSeance: consultationType === 'Cabinet' ? sessionType : '',
            consultationType: _findConsultationType(consultationType),
            availabilityId: id,
            modifySerie: editRepetition === 'all'
        }
    } else if (ficheClient) {
        return {
            startDate: startDate,
            endDate: endDate,
            title: 'Cabinet',
            typeSeance: sessionType,
            specialist: specialist['@id'],
            consultationType: '/consultation_types/1',
            ficheClient: {
                firstName: ficheClient.firstName,
                lastName: ficheClient.lastName,
            }
        }
    }
    return {
        startDate: startDate,
        endDate: endDate,
        title: formatAvailabilityTitle(consultationType),
        typeSeance: consultationType === 'Cabinet' ? sessionType : '',
        specialist: specialist['@id'],
        consultationType: _findConsultationType(consultationType),
    }
}

export async function fetchAvailability({route, startDate, endDate, consultationType, specialist, sessionType, onTheFly = false, repetition, repetitionEndDate, isUpdate, editRepetition, id, ficheClient}) {
    const body = _formatBody({startDate, endDate, consultationType, specialist, sessionType, onTheFly, repetition, repetitionEndDate, editRepetition, id, ficheClient})

    const newAvailability = await apiFetch(route || '/availabilities', {
        method: isUpdate ? 'PUT' : 'POST',
        body: JSON.stringify(body)
    }, true)
    return newAvailability
}

export async function deleteAvailability(id, route, availabilityId, editRepetition) {
    let body = null
    if (editRepetition) {
        body = {
            availabilityId: availabilityId,
            modifySerie: editRepetition === 'all',
        }
    }

    const deleteAvailability = await apiFetch(route || `/availabilities/${id}`, {
        method: 'DELETE',
        body: JSON.stringify(body)
    }, true)
    return deleteAvailability
}