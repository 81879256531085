import React, {useEffect} from 'react'
import * as mainConstant from '../Constant/mainConstant'
import { apiFetch } from '../service/apiFetch';
import ClientDashboard from './DashboardItem/ClientDashboard';
import SpecialistDashboard from './DashboardItem/SpecialistDashboard';
import { useStylesDashboard } from '../css/Style';
import AdminDashboard from './DashboardItem/AdminDashboard';
import hasRoles from '../service/security/hasRoles';

export default function Dashboard({ user, credit, open, matches, lower1825 }) {
    document.title = mainConstant.DASHBOARD
    const classes = useStylesDashboard();
    const [consult, setConsult] = React.useState(false)
    useEffect(() => {
        if (hasRoles() === "super_admin" || hasRoles() === 'admin') {
            apiFetch(`/admin/dashboard`)
            .then((res) => {
                setConsult(res)
            })
        } else {
            apiFetch(`/users/dashboard`)
            .then((res) => {
                setConsult(res)
            })
        }
    }, [user])

    return (
        <>
            {user && user.client ? <ClientDashboard user={user} matches={matches} credit={credit} open={open} consult={consult} lower1825={lower1825} classes={classes} /> : 
            user.specialist ? <SpecialistDashboard user={user} matches={matches} credit={credit} open={open} consult={consult} lower1825={lower1825} classes={classes} /> : 
            consult && <AdminDashboard classes={classes} matches={matches} lower1825={lower1825} open={open} data={consult} user={user} />}
        </>
    )
}
