import React from 'react'
import Typography from '@material-ui/core/Typography';
import * as mainConstant from '../Constant/mainConstant'

export default function Settings() {
    document.title = mainConstant.SETTINGS
    
    return (
        <main style={{margin:'auto', textAlign:'center'}}>
            <Typography paragraph>
                SECTION PARAMETRES
            </Typography>
        </main>
    )
}