import { Avatar, MenuItem, FormControl, Select, InputLabel, Badge, Box, Button, Card, CardContent, CircularProgress, Divider, Grid, IconButton, LinearProgress, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { NavLink, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { apiFetch } from '../service/apiFetch';
import NavBar from './NavBar'
import clsx from 'clsx';
import Footer from '../components/Footer';
import { isAuth } from '../service/security/isAuth';
import { displayableAddress, formatAddress, TransitionLeft } from '../helpers/utils';
import Alert from '../components/Alert';
import HorizontalStepper from '../components/HorizontalStepper';
import DialogAddress from './VisitItem/DialogAddress';
import mainConstant from '../Constant/mainConstant';
import PhoneIcon from '@material-ui/icons/Phone';
import ChatIcon from '@material-ui/icons/Chat';
import moment from "moment";
import ConsultPopup from '../consultPopup';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import TvIcon from '@material-ui/icons/Tv';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import { useForceUpdate } from '../hooks/forceUpdate';
import { animateScroll as scroll } from 'react-scroll'
import ReactTimeslotCalendar from '../components/TimeSlot/js/react-timeslot-calendar';
import RegisterLoginPopup from "../components/RegisterLoginPopup";
import PlatformPlanning from '../components/PlatformPlanning';
import hasRoles from '../service/security/hasRoles';
import { useStylesConsultSpecialistProfile } from '../css/Style';
import PricePopup from '../components/PricePopup';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

let dateToStart = null
export default function ConsultSpecialistProfile({ chatRoom, setChatRoom, eventSource, menuOpen, setVoiceCall, setHandleConnection }) {
    document.title = mainConstant.RDV
    const classes = useStylesConsultSpecialistProfile();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const lower1000 = useMediaQuery(theme.breakpoints.down('1000'));
    let [specialist, setSpecialist] = useState(false);
    const [appointment, setAppointment] = useState({
        date: null,
        time: null,
        id: null,
    });
    let history = useHistory();
    const [params, setParams] = useState();
    const { id } = useParams()
    const location = useLocation()
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [disable, setDisable] = React.useState(false)
    const [appointments, setAppointments] = React.useState(false)
    const [motifs, setMotifs] = React.useState(false)
    const [intervention, setIntervention] = React.useState(null)
    const [slots, setSlots] = React.useState(null)
    const [transition, setTransition] = React.useState(undefined)
    const [severity, setSeverity] = React.useState(undefined)
    const [textAlert, setTextAlert] = React.useState('')
    const [stepper, setStepper] = React.useState(false)
    const [client, setClient] = React.useState(false)
    const [city, setCity] = React.useState(false)
    const [cityCoordonate, setCityCoordonate] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [option, setOption] = React.useState('')
    const forceUpdate = useForceUpdate();
    const [openPopup, setOpenPopup] = React.useState(false)
    const [openLoginPopup, setOpenLoginPopup] = React.useState(false)
    const [platformAvailability, setPlatformAvailability] = React.useState(false)
    const [consultationtype, setConsultationtype] = React.useState(undefined)
    const [motif, setMotif] = React.useState(undefined)
    const [price, setPrice] = React.useState(undefined)
    const [openPricePopup, setOpenPricePopup] = useState(false)
    const [sessionType, setSessionType] = useState('')
    const [nextSession, setNextSession] = useState(false)
    const [loading, setLoading] = useState('')
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('type');

    useEffect(() => {
        (async () => {
               
                if (isAuth()) {
                    apiFetch('/users/me', {method: 'GET'})
                    .then((response) => {
                        setClient(response)
                    })
                }

                const user = await apiFetch(`/specialists/${id}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/ld+json',
                },
                }, true)

                if (location.state) {
                    scroll.scrollTo(800)
                    const e = {target:{parentNode:  {id: location.state}}}
                    handleClick(e, user)
                } else if (name) {
                    scroll.scrollTo(800)
                    const e = {target:{parentNode:  {id: name}}}
                    handleClick(e, user)
                }

            if (!user['hydra:description']) {
                setSpecialist(user)

                if (!location.state && !name) {
                    const findRightSelectedType = user.readableConsultationType[user.readableConsultationType.length - 1].name
                    if (findRightSelectedType === 'Plateforme') {
                        return handleClick({target:{parentNode:  {id: 'phone'}}}, user)
                    } else if (findRightSelectedType === 'Visio') {
                        return handleClick({target:{parentNode:  {id: 'visio'}}}, user)
                    } else if (findRightSelectedType === 'Intervention') {
                        return handleClick({target:{parentNode:  {id: 'intervention'}}}, user)
                    } else if (findRightSelectedType === 'Cabinet') {
                        return handleClick({target:{parentNode:  {id: 'office'}}}, user)
                    }
                    return null
                }
            } else {
                history.push('/')
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchSlots = async (user, type) => {
        if (type !== 1) {
            const firstSession = type === 3 && user.nextVisioSlot ? user.nextVisioSlot.startDate : user.nextHomeSlot && user.nextHomeSlot.startDate
            const { firstDayOfNextWeek, lastDayOfNextWeek } = formatDate(false, false, false, true, firstSession)
            const slotsItems = isAuth() && user ? await apiFetch(`/slots?pagination=false&availability.specialist=${user.id}&availability.consultationType=${type || findConsultationType((params && params.name) || location.state || user.readableConsultationType[0].name)}&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}`) 
            : user && await apiFetch(`/slots?pagination=false&availability.specialist=${user.id}&availability.consultationType=${type || findConsultationType((params && params.name) || location.state || user.readableConsultationType[0].name)}&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/ld+json',
                },
            }, true)
    
            if (type === 4) {
                const platformAvailability = isAuth() ? await apiFetch(`/availabilities?specialist=${user.id}&consultationType=4&startDate[after]=${moment().format('YYYY-MM-DD')}&endDate[strictly_before]=${moment().add(7, 'days').format('YYYY-MM-DD')}`) : 
                await apiFetch(`/availabilities?specialist=${user.id}&consultationType=4&startDate[after]=${moment().format('YYYY-MM-DD')}&endDate[strictly_before]=${moment().add(7, 'days').format('YYYY-MM-DD')}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/ld+json',
                    },
                }, true)
        
                if (platformAvailability && platformAvailability['hydra:member']) setPlatformAvailability(platformAvailability['hydra:member'])
            }
    
            if (slotsItems && slotsItems['hydra:member']) setSlots(slotsItems['hydra:member'])
        } else {
            const nextSessions = await apiFetch(`/availabilities/${id}/cabinet/type/seance`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/ld+json',
                  },
            }, true)
            setNextSession(nextSessions)
        }
    }

    const StyledBadge = withStyles((theme) => ({
        badge: {
          backgroundColor: (`${specialist.platformStatus === 'online' ? '#6EDEBB' : (specialist.platformStatus === 'busy' || specialist.platformStatus === 'waiting-call') ? '#F19244' : '#C4C8D2' }`),
          color: (`${specialist.platformStatus === 'online' ? '#6EDEBB' : (specialist.platformStatus === 'busy' || specialist.platformStatus === 'waiting-call') ? '#F19244' : '#C4C8D2' }`),
          width: 25,
          height: 25,
          borderRadius: '50%',
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: -1,
            left: -1,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
    }))(Badge);

    const handleClick = (e, user, id) => {
        setSessionType('')
        const findParent = lower1000 ? id : e.target.parentNode.id
        findParent && setSlots(null)
        switch (findParent) {
            case 'phone':
                setParams({color: '#07057C', text: 'white', name: 'Plateforme'})
                return fetchSlots(user && user.id ? user : specialist, 4)
            case 'visio':
                setParams({color: '#79E0DB', text: 'white', name: 'Visio'})
                return fetchSlots(user && user.id ? user : specialist, 3)
            case 'intervention':
                setParams({color: '#D1DCEF', text: 'black', name: 'Intervention'})
                return fetchSlots(user && user.id ? user : specialist, 2)
            case 'office':
                setParams({color: '#D6F5F3', text: 'black', name: 'Cabinet'})
                return fetchSlots(user && user.id ? user : specialist, 1)
            default:
                return null
        }
    }

    const handleSetAppointment = (appointment) => {
        if (params.name === 'Intervention') {
            setOpen(true)
        }
        const slot = findSlots(appointment, slots)
        setAppointment(slot ? {date: slot.startDate, id: slot.id, time: slot.startDate, availability: slot} : {})
    }

    const handleAppointment = async () => {
        if (appointment.date) {
            if (!isAuth()) {
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setTextAlert('Vous devez vous connecter pour prendre un RDV')
                setIsUpdate(true)
                history.push('/login' + window.location.pathname)
            } else if (isCityRequired(params) && !city) {
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setTextAlert('Vous devez selectionner une adresse correct')
                setIsUpdate(true)
            } else if (isMotifRequired(params) && !motif) {
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setTextAlert('Vous devez selectionner un motif')
                setIsUpdate(true)
            } else {
                const address = params.name === 'Intervention' ? formatAddress(city, cityCoordonate) : {}
                const userMotif = params.name === 'Cabinet' ? {libelle : motif} : {}
                const requestBody = Object.assign(address, userMotif, {
                    specialist: specialist['@id'],
                    type: params.name,
                    slots: `/slots/${appointment.id}`,
                })
                const intervention = await apiFetch('/interventions', {
                    method: "POST",
                    body: JSON.stringify(requestBody)
                }, true)
                if (intervention['hydra:description']) {
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setTextAlert(intervention['hydra:description'])
                    setIsUpdate(true)
                    setCity(false)
                    setCityCoordonate(false)
                    setOpen(false)
                } else {
                    setOption(params.name)
                    setMotifs(intervention.availableMotifs)
                    setAppointments(intervention.availableSlots)
                    setIntervention(intervention)
                    setStepper(true)
                    window.scrollTo(0, 0)
                }
            }
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setTextAlert('Vous devez selectionner un créneau')
            setIsUpdate(true)
        }
    }

    const handleVoiceCall = (consult) => {
        setVoiceCall(consult)
        setOpenPopup(false)
    }

    const handleChatPopup = () => {
        if (isAuth()) {
            setOpenPopup(true)
            setConsultationtype('Chat')
            setOpenLoginPopup(false)
        } else {
            setOpenPopup(false)
            setOpenLoginPopup(true)
        }
    }

    const handleVoicePopup = () => {
        if (isAuth()) {
            setOpenPopup(true)
            setConsultationtype("Audio")
            setOpenLoginPopup(false)
        } else {
            setOpenPopup(false)
            setOpenLoginPopup(true)
        }
    }

    const handleClosePopup = () => {
        setOpenPopup(false)
    }

    const handleCloseLoginPopup = () => {
        setOpenLoginPopup(false)
    }

    const handleNext = async () => {
        setDisable(true)
        if (isAuth()) {
            const consult = await apiFetch('/consultations', {
                method: 'POST',
                body: JSON.stringify({
                    type: consultationtype,
                    specialist: specialist['@id'],
                })
            }, true)
            if (consult && consult.type === 'Chat') {
                setOpenPopup(false)
                setChatRoom(consult)
            } else if (consult && consult.type === 'Audio') {
                handleVoiceCall(consult)
            } else {
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setTextAlert(consult['hydra:description'])
                setOpenPopup(false)
            }
            
        } else {
            history.push('/login')
        }
        setDisable(false)
    }

    const listen = () => {
        if (eventSource) {
            eventSource.onmessage = async (event) => {
                if (JSON.parse(event.data).type === 'chat-refuse-unanswer') {
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setTextAlert('Le psychologue n\'a pas accepté votre demande de chat')
                    setIsUpdate(true)
                    setChatRoom(false)
                    return
                }
                if (specialist['@id'] && specialist.id === JSON.parse(event.data).data.id && JSON.parse(event.data).data) {
                    specialist.platformStatus = JSON.parse(event.data).data.platformStatus
                    forceUpdate()
                }
            }
        }
        return true
    }

    const changeWeek = async (week, next) => {
        setSlots(null)
        const { firstDayOfNextWeek, lastDayOfNextWeek } = formatDate(week[0], week[6], next)
        let slotsItems = null
        if (params.name !== 'Cabinet') {
            slotsItems = isAuth() && specialist ? await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=${findConsultationType((params && params.name))}&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}`) 
            : specialist && await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=${findConsultationType((params && params.name))}&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/ld+json',
                },
            }, true)
        } else {
            slotsItems = isAuth() && specialist ? await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=1&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}&availability.typeSeance=${sessionType}`) 
            : specialist && await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=1&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}&availability.typeSeance=${sessionType}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/ld+json',
                },
            }, true)
        }

        if (slotsItems && slotsItems['hydra:member']) setSlots(slotsItems['hydra:member'])
    }

    const formatDate = (first, last, next, onLoad, firstSession) => {
        if (onLoad) {
            let f = null
            let l = null
            switch(moment(firstSession).format('dddd')) {
                case 'lundi':
                    f = 1
                    l = 6
                    break
                case 'mardi':
                    f = 2
                    l = 5
                    break
                case 'mercredi':
                    f = 3
                    l = 4
                    break
                case 'jeudi':
                    f = 4
                    l = 3
                    break
                case 'vendredi':
                    f = 5
                    l = 2
                    break
                case 'samedi':
                    f = 6
                    l = 1
                    break
                case 'dimanche':
                    f = 0
                    l = 7
                    break
                default:
                    break
            }
            if (firstSession) {
                const firstDayOfNextWeek =  f ? moment(moment(new Date(firstSession)).subtract(f, 'days').format('YYYY-MM-DD')) : moment(moment(new Date(firstSession)).format('YYYY-MM-DD'))
                const lastDayOfNextWeek = moment(moment(new Date(firstSession)).add(l, 'days').format('YYYY-MM-DD'))
                dateToStart = moment(firstDayOfNextWeek)._i
                return {firstDayOfNextWeek, lastDayOfNextWeek, dateToStart}
            }
            const firstDayOfNextWeek =  f ? moment(moment(new Date()).subtract(f, 'days').format('YYYY-MM-DD')) : moment(moment(new Date()).format('YYYY-MM-DD'))
            const lastDayOfNextWeek = moment(moment(new Date()).add(l, 'days').format('YYYY-MM-DD'))
            dateToStart = moment(firstDayOfNextWeek)._i

            return {firstDayOfNextWeek, lastDayOfNextWeek, dateToStart}
        }
        const firstDayOfNextWeek = next ? moment(moment(new Date(first.year, first.month - 1 , first.date)).add(7, 'days').format('YYYY-MM-DD')) : moment(moment(new Date(first.year, first.month - 1 , first.date)).subtract(7, 'days').format('YYYY-MM-DD'))
        const lastDayOfNextWeek = next ? moment(moment(new Date(last.year, last.month - 1, last.date)).add(8, 'days').format('YYYY-MM-DD')) : moment(moment(new Date(last.year, last.month - 1, last.date)).subtract(6, 'days').format('YYYY-MM-DD'))
        dateToStart = next ? moment(firstDayOfNextWeek)._i : moment(firstDayOfNextWeek)._i
        return {firstDayOfNextWeek, lastDayOfNextWeek, dateToStart}
    }

    const handleConsult = () => {
        scroll.scrollTo(800)
    }

    const hasConsultationType = (type) => {
        return specialist.readableConsultationType.find((consultationType) => {
            return consultationType.name === type
        })
    }

    const handleSessionType = async (e) => {
        setLoading(true)
        setSessionType('')
        setMotif(e.target.value)
        setPrice(specialist.specialistHasConsultationsType.find(consultationType => consultationType.consultationType.name === 'Cabinet').forfaits.find(forfait => forfait.type === e.target.value).price)
        const findDateToStart = nextSession.find(next => next.type === e.target.value).nextSlot
        setTimeout(async () => {
            const { firstDayOfNextWeek, lastDayOfNextWeek } = formatDate(false, false, false, true, findDateToStart)
            const slotsItems = isAuth() && specialist ? await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=1&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}&availability.typeSeance=${e.target.value}`) 
            : specialist && await apiFetch(`/slots?pagination=false&availability.specialist=${specialist.id}&availability.consultationType=1&startDate[after]=${firstDayOfNextWeek._i}&endDate[strictly_before]=${lastDayOfNextWeek._i}&availability.typeSeance=${e.target.value}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/ld+json',
                },
            }, true)
    
            if (slotsItems && slotsItems['hydra:member']) setSlots(slotsItems['hydra:member'])
            setSessionType(e.target.value)
            setLoading(false)
        }, 300)
    }

    return (
        <>
        {hasRoles() !== "specialist" ? <div style={menuOpen && isAuth() ? {marginLeft: '340px'} : {}}>
            {!stepper ? <>{!isAuth() && <NavBar matches={matches} />}
            {listen() && <div style={matches ? {position: '', marginTop: '10px', width: '100%', height: '240px', background: 'transparent linear-gradient(90deg, #05057C 0%, #79E0DB 100%) 0% 0% no-repeat padding-box'} : {position: 'absolute', marginTop: '100px', width: menuOpen && isAuth() ? '82.17%' : '100%', height: '314px', background: 'transparent linear-gradient(90deg, #05057C 0%, #79E0DB 100%) 0% 0% no-repeat padding-box'}}></div>}
            {specialist && params ? <Box
                mt={2}
                display={matches? 'block' : 'flex'}
                alignItems={'center'}
                style={{justifyContent: 'center'}}
            >
                <Card className={clsx(classes.root, classes.card)}>
                    <CardContent style={matches ? {fontSize: '15px'} : {}}>
                        <Box
                            mt={2}
                            display={matches? 'block' : 'flex'}
                            alignItems={'center'}
                            style={{justifyContent: 'space-evenly'}}
                        >
                            {specialist.readableConsultationType.find((type) => type.name === 'Plateforme') ? <StyledBadge
                                overlap="circle"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                            >
                                <Avatar aria-label="photo" src={`${process.env.REACT_APP_BASE_URL}${specialist.photo.contentUrl}`} className={classes.avatar} style={matches ? {width: 50, height: 50} : {width: 120, height: 120}} />
                            </StyledBadge> : <Avatar aria-label="photo" src={`${process.env.REACT_APP_BASE_URL}${specialist.photo.contentUrl}`} className={classes.avatar} style={matches ? {width: 50, height: 50} : {width: 120, height: 120}} />}
                            <div><Typography variant='h4' style={{color: '#07057C', fontWeight: 'bold'}}>{specialist.user.firstName}</Typography>
                            <Typography variant='h5'style={{color: '#07057C', fontWeight: 'bold'}}>{specialist.city}</Typography></div>
                        </Box>
                        {hasConsultationType('Intervention') && <Typography style={{textAlign: 'center', color: '#07057C', marginTop: 40}} variant='h6'>Intervient à domicile dans un rayon de {specialist.interventionDistance} km</Typography>}
                        <NavLink to={`/psychologue/${specialist.id}`} style={{textDecoration: 'none'}} >
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.button}
                                onClick={handleConsult}
                                style={{background: 'transparent linear-gradient(90deg, #79E0DB 0%, #05057C 100%) 0% 0% no-repeat padding-box', color: 'white'}}
                            >
                                Consulter ce psy
                            </Button>
                        </NavLink> 
                    </CardContent>
                </Card>
                <Typography className={clsx(classes.root, classes.paragraph)} variant='body1'>
                    <span style={{fontWeight: 'bold', fontSize: 19}}>Vous avez choisi ce psychologue ?</span><br/>
                    La ligne d’écoute vous permet de lui parler instantanément par téléphone ou par tchat. Vous préférez la visioconsultation, la consultation à domicile ou en cabinet, référez-vous à son planning pour prendre RDV. Pour cela inscrivez-vous ou connectez-vous à votre espace personnel pour finaliser votre demande.
                </Typography>
            </Box> : <div style={{width: '100%', textAlign: 'center'}}><CircularProgress style={{marginTop: '450px'}} /></div>}
            {specialist && <div className={classes.main}>
                <span style={{fontSize: 19, whiteSpace: 'pre-line',}}>{specialist.description}</span><br/><br/>
                <span style={{fontWeight: 'bold', fontSize: 19}}>Spécialité(s) : </span><span style={{fontSize: 18}}>{specialist.specialities.map((speciality) => `${speciality.name}, `)}</span><br /><br />
                <span style={{fontWeight: 'bold', fontSize: 19}}>Langue(s) : </span><span style={{fontSize: 18}}>{specialist && specialist.languages.map((type) => `${type.name}, `)}</span><br /><br />
                <span style={{fontWeight: 'bold', fontSize: 19}}>Code ADELI : </span><span style={{fontSize: 18}}>{specialist.adeli}</span><br/><br/>
                {specialist.readableConsultationType.map((consultationType, index) => {
                    if (consultationType.name === 'Cabinet') {
                        return <React.Fragment key={index} ><span style={{fontWeight: 'bold', fontSize: 19}}>TARIFS EN CABINET : </span><span style={{fontStyle: 'italic', cursor: 'pointer'}} onClick={() => setOpenPricePopup(true)}>voir les tarifs <IconButton style={{color: 'black'}} aria-label="next" size="small"><NavigateNextIcon fontSize="inherit" /></IconButton></span></React.Fragment>

                    }
                    return null
                })}
                {hasConsultationType('Cabinet') && specialist.office != null ?
                    <div><br/><span style={{fontWeight: 'bold', fontSize: 19}}>ADRESSE DU CABINET : </span><span style={{fontSize: 18}}>{displayableAddress(specialist.office.address)}</span></div>
                    : ''}
            </div>}
        <Divider classes={{root: classes.divider}} />
        <Box
          mt={2}
          display={matches? 'block' : 'flex'}
          alignItems={'center'}
          style={{justifyContent: 'center', marginBottom: 35}}
        >
          {lower1000 ?  
          <div style={{textAlign: 'center', paddingLeft: 15}}>

            {specialist && specialist.readableConsultationType.map((type, index) => {
              return (
                <React.Fragment key={index}>
                    {type.name === 'Plateforme' && <IconButton aria-label="phone" id='phone' className={classes.IconButton} style={{background: '#07057C 0% 0% no-repeat padding-box', color: 'white'}} onClick={(e) => handleClick(e, null, 'phone')}>
                        <PermPhoneMsgIcon />
                    </IconButton>}
                    {type.name === 'Visio' && <IconButton aria-label="tv" id='visio' className={classes.IconButton} style={{background: '#79E0DB 0% 0% no-repeat padding-box', color: 'white'}} onClick={(e) => handleClick(e, null, 'visio')}>
                        <TvIcon />
                    </IconButton>}
                    {type.name === 'Intervention' && <IconButton aria-label="home" id='intervention' className={classes.IconButton} style={{background: '#D1DCEF 0% 0% no-repeat padding-box', color: '#07057C'}} onClick={(e) => handleClick(e, null, 'intervention')}>
                        <HomeIcon />
                    </IconButton>}
                    {type.name === 'Cabinet' && <IconButton aria-label="office" id='office' className={classes.IconButton} style={{background: '#D6F5F3 0% 0% no-repeat padding-box', color: '#07057C'}} onClick={(e) => handleClick(e, null, 'office')}>
                        <BusinessIcon />
                    </IconButton>}
                </React.Fragment>
              )
            })}
          </div>
           : 
          <>
          {specialist && specialist.readableConsultationType.map((type, index) => {
              return (
                <React.Fragment key={index}>
                  {type.name === 'Plateforme' && <Button id='phone' className={classes.topButton} style={{background: '#07057C 0% 0% no-repeat padding-box', color: 'white'}} onClick={handleClick}>
                  par téléphone ou tchat
                  </Button>}
                  {type.name === 'Visio' && <Button id='visio' className={classes.topButton} style={{background: '#79E0DB 0% 0% no-repeat padding-box', color: 'white'}} onClick={handleClick}>
                      Visioconsultation
                  </Button>}
                  {type.name === 'Intervention' && <Button id='intervention' className={classes.topButton} style={{background: '#D1DCEF 0% 0% no-repeat padding-box', color: '#07057C'}} onClick={handleClick}>
                      à domicile
                  </Button>}
                  {type.name === 'Cabinet' && <Button id='office' className={classes.topButton} style={{background: '#D6F5F3 0% 0% no-repeat padding-box', color: '#07057C'}} onClick={handleClick}>
                      en cabinet
                  </Button>}
                </React.Fragment>
              )
            })}
          </>}
        </Box>
        {params && <Card className={clsx(classes.root, classes.card, classes.appointmentCard)}>
            <CardContent style={matches ? {fontSize: '15px'} : {}} className={classes.CardContent}>
                <Typography variant='h6' style={{background: params.color, color: params.text, padding: 20}}>{getTitle(params)}</Typography>
                <Typography variant="body1" color="primary" component="p" style={{marginBottom: '10px', marginTop: 5}}>
                    <span style={{fontWeight: 'bold'}}>{params.name === 'Plateforme' ? specialist.platformStatus === 'online' && 'Choisissez votre mode de consultation' : 'Disponibilité(s) :' }</span>
                </Typography>
                {params.name === 'Plateforme' && specialist.platformStatus === 'online' ? <Grid container alignItems='center' justify='center'>
                    <Grid item xs={matches ? 12 : 6}>
                        Par téléphone<br />
                        <IconButton aria-label="phone" className={classes.margin} onClick={handleVoicePopup} consultationtype='Voice'>
                            <PhoneIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={matches ? 12 : 6}>
                        Par tchat<br />
                        <IconButton aria-label="chat" className={classes.margin} onClick={handleChatPopup} disabled={!!chatRoom.id} consultationtype='Chat'>
                            <ChatIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid> : params.name === 'Plateforme' && <Typography variant="body1" color="primary" component="p" style={{marginBottom: '5px', marginTop: 30}}>{specialist.platformStatus === 'waiting-call' ? `Ce psychologue est en attente d'appel` : specialist.platformStatus === 'busy' ? "Ce psychologue est déjà en consultation." : "Ce psychologue n'est pas disponible actuellement."} </Typography>}
                {specialist && specialist.displayableAvailabilities.find((availability) => {
                    if (availability.consultationType.name === params.name) {
                        return params.name === 'Cabinet'
                    }
                    return false
                })  && 
                nextSession && nextSession.length > 0 ? <><FormControl style={{width: 200, marginBottom: 20}}>
                <InputLabel id="select-label">Type de séance</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      value={sessionType}
                      onChange={handleSessionType}
                    >
                        {nextSession.map((session, index) => {
                            return (
                                <MenuItem key={index} value={session.type}>{session.type} - prochaine séance le : {moment(session.nextSlot).format('DD MMMM YYYY à HH:mm')}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl><br /></>
                : params.name === 'Cabinet' && <span>Pas de disponibilités pour le moment avec ce psychologue</span>}           
                {platformAvailability && params.name === 'Plateforme' &&
                    <PlatformPlanning availability={platformAvailability} />
                }
                { params.name !== 'Plateforme' && slots ?
                params.name === "Cabinet" && !sessionType ? <div></div> :  
                ((params.name === 'Intervention' && specialist.nextHomeSlot) || (params.name === 'Visio' && specialist.nextVisioSlot) || (params.name === 'Cabinet')) ? <ReactTimeslotCalendar initialDate = { dateToStart || moment('2021-09-07').format() } timeslots={formattedSlots(slots)}
                    handleSetAppointment={handleSetAppointment}
                    changeWeek={changeWeek}
                /> : <span>Pas de disponibilités pour le moment avec ce psychologue</span> : params.name !== 'Plateforme' && params.name !== 'Cabinet' && <LinearProgress style={{marginBottom: 86}} />
                }
                {loading && <LinearProgress style={{marginBottom: 86}} />}
                {specialist && !hasParam(specialist.readableConsultationType, params) && <Typography variant="body1" color="primary" component="p" style={{marginBottom: '5px', marginTop: 30}}>Pas de crénaux disponible pour ce type de consultation. </Typography>}
                {specialist && specialist.displayableAvailabilities.find((availability) => {
                    if (availability.consultationType.name === params.name) {
                        if (params.name === 'Cabinet' && !sessionType) {
                            return false
                        }
                        return params.name !== 'Plateforme'
                    }
                    return false
                })  &&
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.appointmentButton}
                        style={{background: 'transparent linear-gradient(90deg, #05057C 0%, #79E0DB 100%) 0% 0% no-repeat padding-box', color: 'white'}}
                        onClick={handleAppointment}
                    >
                        Confirmer la prise de RDV 
                    </Button>}
            </CardContent>
        </Card>}
        {!isAuth() && <Footer />}
        </> :
        <HorizontalStepper sessionType={motif} officePrice={price} setStepper={setStepper} params={params} setAppointment={setAppointment} formatDate={formatDate} appointment={appointment} option={option} client={client} specialist={specialist} city={city} appointments={appointments} motifs={motifs} intervention={intervention} />}
        <Alert isOpen={isUpdate} transition={transition} message={textAlert} setIsUpdate={setIsUpdate} severity={severity} />
        <ConsultPopup open={openPopup} handleClose={handleClosePopup} handleNext={handleNext} disable={disable} type={consultationtype}/>
        <RegisterLoginPopup open={openLoginPopup} handleClose={handleCloseLoginPopup} disable={disable}/>
        {open && !city && <DialogAddress setCity={setCity} open={open} setOpen={setOpen} matches={matches} client={client} setCityCoordonate={setCityCoordonate} />}
        </div> : <Redirect to='/dashboard' />}
        {specialist && <PricePopup specialist={specialist} setOpen={setOpenPricePopup} open={openPricePopup} />}
        </>
    )
}

function hasParam(array, params) {
    var shouldShow = false
    array.find((item) => {
        if(item.name === params.name){
            return shouldShow = true
        }
        return shouldShow = false
    })
    return shouldShow
}

function getTitle(params) {
    let title = null
    switch (params.color) {
        case '#07057C':
            title = 'Consulter via notre ligne d’écoute'
            break 
        case '#79E0DB':
            title = 'Consulter par Visioconsultation'
            break 
        case '#D1DCEF':
            title = 'Consulter à domicile'
            break 
        case '#D6F5F3':
            title = 'Consulter en cabinet'
            break 
        default:
            title = null
    }
    return title.toUpperCase()
}

function isCityRequired(params) {
    if (params.name === 'Intervention') {
        return true
    }
    return false
}

function isMotifRequired(params) {
    if (params.name === 'Cabinet') {
        return true
    }
    return false
}


function findConsultationType(state) {
    switch(state.toLowerCase()) {
      case 'cabinet':
        return '1'
      case 'intervention':
        return '2'
      case 'visio':
        return '3'
      default:
        return '4'
    }
  }

function formattedSlots(slots) {
    let a = []
    slots.map((slot) => {
        a.push(
            slot.map((timeSlot) => {
            return [moment(timeSlot.startDate).format('LT'), moment(timeSlot.endDate).format('LT')]
        }))
        return null
    })
    return a
}

function findSlots(appointment, slots) {
    let slotItem = null
    appointment.length && slots.map((slot) => {
        slot.map((item) => {
            if (moment(item.startDate).format('YYYY-MM-DDTHH:mm') === appointment[0].startDate.format('YYYY-MM-DDTHH:mm')) {
                slotItem = item
            }
            return slotItem
        })
        return null
    })
    return slotItem
}
