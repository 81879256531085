import { Box, Button, Input, InputAdornment, IconButton, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Typography } from '@material-ui/core'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import AutoCompleteAddress from '../../components/AutoCompleteAddress';

const searchOptions = {
    types: ['(cities)'],
    componentRestrictions: {country: "fr"}
}

export default function FoundSpecialists({ availability, setAvailability, handleChangeName, disable, consultationTypes, specialities, classes, matches, setSpecialites, handleChange, handleSearch, value, setConsultationTypes, apiSpecialities, apiConsultationTypes, setNewCoordonnate }) {
    return (
        <Paper className={classes.filter}>
              <Grid container justify="space-between" className={classes.grid}>
                <Grid item xs={matches ? 12 : 8} className={classes.firstGrid} align="center">
                    <FormControl style={matches ? {width: '100%', marginBottom: 20} : {width: '92%', marginBottom: 20, marginLeft: '-42px'}}>
                        <InputLabel htmlFor="name">Prénom du psychologue</InputLabel>
                        <Input
                            id="name"
                            name="name"
                            type={'text'}
                            onChange={handleChangeName}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="name"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                          }
                        />
                  </FormControl>
                  <Grid container justify="space-between" className={classes.grid} style={{marginLeft: -20}}>
                    <Grid item xs={matches ? 12 : 4} className={classes.firstGrid} align="center">
                      <FormControl className={classes.addressWidth}>
                        <InputLabel id="select-label">Spécialité</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          label="SPÉCIALITÉ"
                          value={specialities}
                          onChange={(e) => setSpecialites(e.target.value)}
                        >
                          <MenuItem value="">
                            <em>Supprimer le filtre</em>
                          </MenuItem>
                          {apiSpecialities.map((speciality, index) => {
                              return <MenuItem key={index} value={speciality['@id']}>{speciality.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={matches ? 12 : 4} className={classes.firstGrid} align="center">
                      <AutoCompleteAddress className={classes.addressWidth} id="city" name="city" label="Ville" defaultValue={''} searchOptions={searchOptions} setNewCoordonnate={setNewCoordonnate} />
                    </Grid>
                    <Grid item xs={matches ? 12 : 4} className={classes.firstGrid} align="center">
                      <FormControl className={classes.addressWidth} style={matches ? {paddingBottom: 30} : {}}>
                        <InputLabel id="select-label-consultation">Type de consultation</InputLabel>
                        <Select
                          labelId="select-label-consultation"
                          id="select-consultation"
                          label="Type de consultation"
                          value={consultationTypes}
                          onChange={(e) => setConsultationTypes(e.target.value)}
                        >
                          <MenuItem value="">
                            <em>Supprimer le filtre</em>
                          </MenuItem>
                          {apiConsultationTypes.map((consultationType, index) => {
                              return <MenuItem key={index} value={consultationType['@id']}>{formatConsultationType(consultationType.name)}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={matches ? 12 : 2} style={{paddingTop: 18}}>
                    <Button disabled={disable} style={matches ? {background: '#D1DCEF 0% 0% no-repeat padding-box', borderRadius: '20px', width: '65%'} : {background: '#D1DCEF 0% 0% no-repeat padding-box', borderRadius: '20px', width: 200, height: 60, color: 'black', fontWeight: 'bold'}} onClick={handleSearch}>
                    <SearchIcon className={classes.extendedIcon} />
                      Je trouve mon psy
                    </Button>
                    <FormControl className={classes.addressWidth} style={matches ? {paddingBottom: 30} : {}}>
                      <InputLabel id="select-label-consultation">Disponibilité</InputLabel>
                      <Select
                        labelId="select-label-consultation"
                        id="select-consultation"
                        label="Type de consultation"
                        value={availability}
                        onChange={(e) => setAvailability(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>Supprimer le filtre</em>
                        </MenuItem>
                        <MenuItem value={'today'}>Aujourd'hui</MenuItem>
                        <MenuItem value={'7days'}>7 prochains jours</MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={matches ? 12 : 2} className={classes.firstGrid} style={{paddingLeft: 20}}>
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={matches ? "none" : 'flex-end'}
                  >
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: '#6EDEBB'}} />
                    <Typography variant="body1" align='center' color='primary' style={matches ? {fontSize: 10} : {padding: 10, paddingBottom: 0, fontSize: 15}}>
                      Disponible
                    </Typography>
                  </Box>    
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={matches ? "none" : 'flex-end'}
                  >
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: '#F19244'}} />
                    <Typography variant="body1" align='center' color='primary' style={matches ? {fontSize: 10} : {padding: 10, paddingBottom: 0, fontSize: 15}}>
                      En consultation
                    </Typography>   
                  </Box>             
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={matches ? "none" : 'flex-end'}
                  >
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: '#C4C8D2'}} />
                    <Typography variant="body1" align='center' color='primary' style={matches ? {fontSize: 10} : {padding: 10, paddingBottom: 0, fontSize: 15}}>
                      Non disponible
                    </Typography>
                  </Box>         
                </Grid>
              </Grid>
              {disable && <LinearProgress style={{width: '80%', margin: 'auto'}} />}
            </Paper>
    )
}

function formatConsultationType(consultation) {
  if (consultation === 'Intervention') {
    return 'À domicile'
  }
  if (consultation === 'Plateforme') {
    return 'Ligne d\'écoute'
  }
  return consultation
}
