import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import hasRoles from '../security/hasRoles'


export const SpecialistRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render= {(props) => (
            hasRoles() === 'specialist' ? <Component {...props} matches={rest.matches} user={rest.profileUser} setChatRoom={rest.setChatRoom} setVisioId={rest.setVisioId} setProfileUser={rest.setProfileUser} /> : <Redirect to='/dashboard'/>
          )}/>
    )
}