import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";

export default function SepaPopup({ downloadSepa, open, handleClose, checkedConsolidateData, setCheckedConsolidateData }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = () => {
        setCheckedConsolidateData(!checkedConsolidateData)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog"
                fullScreen={fullScreen}
            >
                <DialogTitle id="sepa-dialog-title">Téléchargement fichier SEPA</DialogTitle>
                <DialogContent>
                    <DialogContentText id="sepa-dialog-description">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedConsolidateData}
                                    onChange={handleChange}
                                    name="checkData"
                                    color="primary"
                                />
                            }
                            label="Consolider les données"
                        />
                    </DialogContentText>
                    <Container style={{textAlign: 'center', marginTop: '20px'}}>
                        <div>
                            <Button onClick={downloadSepa} variant="contained" color="primary">
                                Télécharger
                            </Button>
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}