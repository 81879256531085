import React, { useEffect } from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import * as locales from '@material-ui/core/locale';
import { EnhancedTableToolbar } from '../components/EnhancedTableToolbar';
import { EnhancedTableHead } from '../components/EnhancedTableHead';
import { EnhancedTableBody } from '../components/EnhancedTableBody';
import mainConstant from '../Constant/mainConstant';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { apiFetch } from '../service/apiFetch';
import Alert from '../components/Alert';
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom';
import { useStylesPayments } from '../css/Style';
  
const payments = [
    { id: 'date', numeric: false, disablePadding: true, label: 'Date et heure de paiement' },
    { id: 'price', numeric: true, disablePadding: false, label: 'Montant TTC' },
/*     { id: 'bill', numeric: true, disablePadding: false, label: 'Télécharger le reçu' }, */
]

export default function Payments(props) {
    document.title = mainConstant.PAYMENT
    const classes = useStylesPayments();
    const [order, setOrder] = React.useState('asc');
    const [rows, setRows] = React.useState(null);
    const [totalItems, setTotalItems] = React.useState(null);
    const [view, setView] = React.useState(null);
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [payment, setPayment] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [locale] = React.useState('frFR');
    const [text] = React.useState(false);
    const [transition] = React.useState(false);
    const [severity] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    let history = useHistory()

    useEffect(() => {
        apiFetch('/transactions')
        .then((response) => {
          if (response['hydra:member']) {
            setRows(response['hydra:member'])
            setTotalItems(response['hydra:totalItems'])
            response['hydra:totalItems'] > 30 && setView(response['hydra:view'])
          }
        })
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };
    
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleChangePage = async (event, newPage) => {
      setLoading(true)
      if (newPage > page) {
        await apiFetch(view['hydra:next'])
      } else if (newPage < page) {
        await apiFetch(view['hydra:previous'])
      }
      window.scrollTo(0, 0)
      setPage(newPage);
      setLoading(false)
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleShowPopup = (event, row) => {
        history.push('/creditAccount')
    };

    return (
      <div className={classes.root}>
        <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} >
            {props.user && <><Typography variant="h6" gutterBottom>
                Le solde de votre compte : {props.credit || props.user.client.nbCredits} séance(s)
                <Button className={classes.button} variant="contained" color="primary" onClick={handleShowPopup}>
                    Approvisionner le compte
                </Button>
            </Typography>
            <Typography variant="body1" gutterBottom>
                Voici le récapitulatif de vos paiements. Pour vos factures de consultations, allez dans “Mes consultations”
        </Typography></>}
      {rows ? <Paper className={classes.paper}>
        <EnhancedTableToolbar title={'Mes Paiements'}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={payments}
            />
            <EnhancedTableBody
              order={order}
              orderBy={orderBy}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              isPayment={true}
              emptyMessage={'Pas encore de paiements...'}
            />
          </Table>
        </TableContainer>
        {!!totalItems && <ThemeProvider theme={(outerTheme) => createMuiTheme(outerTheme, locales[locale])}>
            <TablePagination
              labelRowsPerPage=''
              nextIconButtonProps={{ disabled: loading || page > (totalItems / rowsPerPage) - 1}}
              backIconButtonProps={{ disabled: loading || page === 0 }} 
              rowsPerPageOptions={[]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </ThemeProvider>}
      </Paper> : <LinearProgress />}
   <Alert isOpen={payment} transition={transition} message={text} setIsUpdate={setPayment} severity={severity} />
    </motion.div>
    </div>
    )
}
