import React, { useEffect, useState } from 'react'
import { LinearProgress, Checkbox, FormControlLabel, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TransitionLeft } from '../../../helpers/utils'

export default function AddNews({ handleCloseMenu, updateNews, selected, open, handleClose, createNews, setIsUpdate, setText, setSeverity, setTransition }) {
    const [url, setUrl] = useState(false)
    const [urlError, setUrlError] = useState(false)
    const [titleError, setTitleError] = useState(false)
    const [title, setTitle] = useState(false)
    const [isCheckboxUpdated, setIsCheckboxUpdated] = useState(false)
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(false)

    const handleChangeTitle = (event) => {
        setDisable(false)
        setTitle(event.target.value);
    };

    const handleChange = (event) => {
        setIsCheckboxUpdated(true)
        setDisable(false)
        setActive(event.target.checked);
    };

    useEffect(() => {
        (async () => {
            if (selected) {
                setUrl(selected.url)
                setActive(selected.star)
                setTitle(selected.title)
            }
        })()
    }, [selected])

    const handleSubmit = async () => {
        if (!title) return setTitleError('Ce champ ne doit pas être vide')
        if (!url) return setUrlError('Ce champ ne doit pas être vide')
        setDisable(true)
        setLoading(true)
        handleCloseMenu()
        const createNewNews = !selected ? await createNews({
            url: url,
            title: title,
            active: active
        }) : await updateNews(selected.id, {
            url: url,
            title: title,
            active: active
        })
        if (createNewNews) {
            setText('Le psychologue a été ajouté')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText('Erreur lors de l\'ajout du psychologue')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
        setTitleError(false)
        setUrlError(false)
        setDisable(false)
        setLoading(false)
        handleClose()
    }

    const handleChangeUrl = (e) => {
        setUrl(e.target.value)
        setDisable(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="title">
            <DialogTitle id="title">Ajouter un article</DialogTitle>
            <DialogContent>
                <TextField  
                    required
                    label="Titre"
                    style={{width: '100%'}}
                    onChange={handleChangeTitle}
                    defaultValue={selected ? selected.title : ''}
                    helperText={titleError}
                    error={!!titleError}
                />
                <TextField  
                    required
                    label="Url"
                    style={{width: '100%'}}
                    onChange={handleChangeUrl}
                    defaultValue={selected ? selected.url : ''}
                    helperText={urlError}
                    error={!!urlError}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={selected && !isCheckboxUpdated ? selected.active : active}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'checkbox' }}
                        />
                    }
                    label="Rendre cet article actif"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={disable}>
                    Enregistrer
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </Dialog>
    )
}