import { SPECIALIST, SUPER_ADMIN, USER, CLIENT } from "../../Constant/mainConstant";
import { isAuth } from "./isAuth";

export default function hasRoles() {
    if(isAuth() && setRole()) {
        if (setRole()[0] === USER) {
            return 'user'
        } else if (setRole()[0] === SPECIALIST) {
            return 'specialist'
        } else if (setRole()[0] === SUPER_ADMIN) {
            return 'super_admin'
        } else if (setRole()[0] === CLIENT) {
            return 'client'
        }
        return 'super_admin'
    }
}

function setRole() {
    return JSON.parse(localStorage.getItem('REACT_ROLE'))
}