import React from "react";
import {
    Dialog,
    Button,
    FormControl,
    InputLabel,
    Select, 
    MenuItem,
    FormHelperText,
    FormLabel,
    FormGroup,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { OldDatePickerComponent } from "../../components/DateTimePicker";
import { formatAvailabilityTitle } from "../../helpers/utils";
import { Link } from 'react-router-dom';
import hasRoles from "../../service/security/hasRoles";

export default function CustomTuiModal({
    isOpen = false,
    toggle,
    onSubmit,
    submitText = "Save",
    selectedSchedule,
    setStartDate,
    setEndDate,
    user,
    consultationType,
    setConsultationType,
    sessionType,
    setSessionType,
    error,
    repetition,
    setRepetition,
    repetitionEndDate,
    setRepetitionEndDate,
    editRepetition,
    setEditRepetition,
    startDate,
    endDate,
    ownBookedSchedule,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setNoForfait,
    noForfait,
}) {

    const handleChangeConultationType = (e) => {
        if (user.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet') && !user.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet').forfaits.length && e.target.value === 'Cabinet') {
            setNoForfait(true)
        } else {
            setConsultationType(e.target.value)
        }
    }

    const handleBack = () => {
        setConsultationType('')
        setNoForfait(false)
    }

    return (
        <Dialog open={isOpen} onClose={toggle}>
            {!noForfait ? <div className="tui-full-calendar-popup-container" style={{padding: '17px 55px'}}>
                { ownBookedSchedule && <div style={{display: "flex"}}>
                    <TextField error={!!error.firstName} style={{width: 195, marginRight: 30}} helperText={error.firstName && 'Ce champ ne doit pas être vide'} defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} id="username" name="username" label="Prénom" required/>
                    <TextField error={!!error.lastName} style={{width: 195, marginRight: 30}} helperText={error.lastName && 'Ce champ ne doit pas être vide'} defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} id="lastName" name="lastName" label="Nom" required/>
                </div>}
                <div style={{ display: "flex" }}>
                    {!ownBookedSchedule && <FormControl style={{width: 195, marginTop: 20}} error={error.consultationType}>
                        <InputLabel id="select-availability">Disponibilité *</InputLabel>
                        <Select
                            labelId="select-availability"
                            id="selectAvailability"
                            value={consultationType}
                            onChange={handleChangeConultationType}
                        >
                            {user && user.readableConsultationType.map((speciality) => {
                                return <MenuItem key={speciality.name} value={speciality.name}>{formatAvailabilityTitle(speciality.name)}</MenuItem>
                            })}
                        </Select>
                        {error.consultationType && <FormHelperText style={{color: 'red'}}>Veuillez sélectionner une Disponibilité</FormHelperText> }
                    </FormControl>}
                    {(consultationType === 'Cabinet' || ownBookedSchedule) && <FormControl style={ownBookedSchedule ? {width: 195, marginTop: 20} : {width: 195, marginLeft: 30, marginTop: 20}} error={error.sessionType}>
                        <InputLabel id="select-availability">Type de séance *</InputLabel>
                        <Select
                            labelId="select-availability"
                            id="selectAvailability"
                            value={sessionType}
                            onChange={(e) => setSessionType(e.target.value)}
                        >
                            {user && user.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet').forfaits.map((forfait, index) => {
                                    return <MenuItem key={index} value={forfait.type}>{forfait.type} - {forfait.duration} minutes</MenuItem>
                            })}
                        </Select>
                        {error.sessionType && <FormHelperText style={{color: 'red'}}>Veuillez sélectionner une Disponibilité</FormHelperText> }
                    </FormControl>}
                </div>
                <div style={{display: 'inline-flex', marginTop: 20}}>
                    <OldDatePickerComponent format={'dd MMMM, yyyy à HH:mm'} showTime={true} text="Date de début" setStartDate={setStartDate} startDate={startDate}/>
                    {!ownBookedSchedule && <OldDatePickerComponent format={'dd MMMM, yyyy à HH:mm'} showTime={true} text="Date de fin" setStartDate={setEndDate} startDate={endDate}/>}
                </div>
                <div style={{display: 'flex', marginTop: -20}}>
                    {!selectedSchedule && <FormControl style={{width: 195}}>
                        <InputLabel htmlFor="repetition">Répétition</InputLabel>
                        <Select
                            labelId="select-repetition"
                            id="select"
                            value={repetition}
                            onChange={(e) => setRepetition(e.target.value)}
                        >
                            <MenuItem aria-label="None" value="" ><em>Sélectionnez une répétition</em></MenuItem>
                            <MenuItem aria-label="day" value="day" >Par jour</MenuItem>
                            <MenuItem aria-label="week" value="week" >Par semaine</MenuItem>
                        </Select>
                    </FormControl>}
                    {repetition && <FormControl style={{width: 195, marginLeft: 30}} error={error.repetition}>
                        <InputLabel htmlFor="repetition">Date de fin de répétition *</InputLabel>
                        <Select
                            value={repetitionEndDate}
                            onChange={(e) => setRepetitionEndDate(e.target.value)}
                        >
                            <MenuItem aria-label="None" value="" ><em>Sélectionnez une fin de répétition</em></MenuItem>
                            <MenuItem aria-label="day" value="1" >Dans 1 mois</MenuItem>
                            <MenuItem aria-label="week" value="3" >Dans 3 mois</MenuItem>
                        </Select>
                        {error.repetition && <FormHelperText style={{color: 'red'}}>Veuillez sélectionner une fin de répétition</FormHelperText> }
                        </FormControl>}
                    {selectedSchedule && selectedSchedule.body.groupHash && <FormControl>
                        <FormLabel component="legend">Modification</FormLabel>
                        <FormGroup>
                            <RadioGroup aria-label="availability" name="availability" value={editRepetition} onChange={(e) => setEditRepetition(e.target.value)}>
                                <FormControlLabel value="only" control={<Radio />} label="Cet évènement seulement" />
                                <FormControlLabel value="after" control={<Radio />} label="Cet évènement et tous les suivants" />
                                <FormControlLabel value="all" control={<Radio />} label="Tous les évènements" />
                            </RadioGroup>
                        </FormGroup>
                    </FormControl>}
                </div>
                <button
                    onClick={toggle}
                    className="tui-full-calendar-button tui-full-calendar-popup-close"
                >
                    <span className="tui-full-calendar-icon tui-full-calendar-ic-close" />
                </button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onSubmit}
                    className="tui-full-calendar-popup-save"
                    style={{marginTop: 20}}
                >
                    <span>{submitText}</span>
                </Button>
            </div> : 
            <div>
                <DialogContent>
                    <DialogContentText id="description">
                        Vous devez ajouter des tarifs pour ce type de disponibilité
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBack} color="primary">
                        Retour
                    </Button>
                    <Link style={{textDecoration: 'none', color: '#05057c', padding: '10px', borderRadius: 4}} to={hasRoles() === 'super_admin' ? {pathname: '/specialists'} : {pathname: '/profil', state: 1}}>
                        AJOUTER UN TARIF
                    </Link>
                </DialogActions>
            </div>
            }
        </Dialog>
    );
}
