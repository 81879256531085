import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_PRICES = 'FETCHING_PRICES'
const SET_PRICES = 'SET_PRICES'
const ADD_PRICES = 'ADD_PRICES'
const UPDATE_PRICES = 'UPDATE_PRICES'
const DELETE_PRICES = 'DELETE_PRICES'

function pricesReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_PRICES:
            return { ...state, loading: true }
        case SET_PRICES:
            return {...state, prices: action.payload, loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_PRICES:
            return {...state, prices: [action.payload, ...state.prices]}
        case UPDATE_PRICES:
            return {...state, prices: action.payload }
        case DELETE_PRICES:
            return {...state, prices: state.prices.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function usePrices() {
    const [state, dispatch] = useReducer(pricesReducer, {    
        prices: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        prices: state.prices,
        totalItems: state.totalItems,
        view: state.view,
        fetchPrices: useCallback(async function (route) {
            if ((state.prices || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_PRICES' })
            const prices = await apiFetch(route || '/forfaits', {method: 'GET'})
            if (prices) {
                dispatch({type: 'SET_PRICES', payload: prices})
            }
            return prices
        }, [state]),
        createPrices: useCallback(async function(data, route, forceUpdate) {
            const newPrices = await apiFetch('/forfaits', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            const prices = await apiFetch(route, {method: 'GET'})
            if (!newPrices.violations && prices) {
                dispatch({type: 'SET_PRICES', payload: prices})
            }
            return newPrices
        }, []),
        updatePrices: useCallback(async function(prices, data, forceUpdate = false) {
            const newPrices = await apiFetch('/forfaits/' + prices, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceUpdate)
            const pricesReload = await apiFetch('/forfaits', {method: 'GET'})
            if (!newPrices.violations && pricesReload) {
                dispatch({type: 'SET_PRICES', payload: pricesReload})
            }
            return newPrices
        }, []),
        deletePrices: useCallback(async function (priceId) {
            const pricesDelete = await apiFetch('/forfaits/' + priceId, {
                method: 'DELETE'
            }, true)
            if (pricesDelete && !pricesDelete['hydra:description']) {
                dispatch({type: 'DELETE_PRICES', payload: priceId})
            }
            return pricesDelete
        }, []),
    }
}