import React from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function ContentMenuItem({ selected, handleClose, anchorEl, active, handleDelete, handleActive, handleClickOpen }) {

    return (
        <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleClickOpen}>Modifier</MenuItem>
            <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
            {selected && !selected.star && <MenuItem onClick={handleActive}>{active ? 'Activer' : selected && selected.active ? 'Désactiver' : 'Sélectionner'}</MenuItem>}
        </Menu>
    )
}