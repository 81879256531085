import React from 'react'
import {  Typography, Button, Divider, Grid } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export default function NewsCard({ classes, matches, consult }) {
    return (
        <div className={classes.newsCard}>
            <Typography variant="h5" gutterBottom style={matches ? {marginBottom: 65} : {marginBottom: 40}}>
                actualités
            </Typography>
            <Typography variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, font: 'normal normal bold 25px/29px Quicksand', textAlign: 'left', marginLeft: 30} : {marginBottom: 40, font: 'normal normal bold 25px/29px Quicksand', textAlign: 'left', marginLeft: 30}}>
                Les dernières infos geo-psy
            </Typography>
            <Typography
                variant={'body1'} 
                gutterBottom
                className={classes.newsCardButton}
            >
                Bienvenue dans l’aventure Geo-Psy !
                L’équipe Geo-Psy est heureuse de démarrer ce nouveau chapitre avec vous. Une aventure humaine, innovante et pleine d’énergie !
                Pour toutes informations, vous pouvez contacter Laurent Chauvel, responsable du SAV Geo-Psy :
                Par mail : <a href="mailto:lc@prosconsultegroup.com ">lc@prosconsultegroup.com </a> ou par téléphone (du lundi au vendredi, de 9h à 12h et de 13h à 17h) : <a href={`tel:0297843253`}>02 97 84 32 53</a>
            </Typography>
            <Typography
                variant={'body1'} 
                gutterBottom
                className={classes.newsCardButton}
            >
                Besoin d'aide ? Vous pouvez consulter notre page <a target='_blank' rel="noopener noreferrer" href='https://www.geo-psy.fr/faq-psychologue/'>FAQ</a>                
            </Typography>
            <Divider variant="middle" className={classes.divider} />
            <Typography variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, font: 'normal normal bold 25px/29px Quicksand'} : {marginBottom: 40, font: 'normal normal bold 25px/29px Quicksand', textAlign: 'left', marginLeft: 30}}>
                le blog geo-psy
            </Typography>
            <Grid container spacing={3}>
                {consult && consult.blogPosts.length && consult.blogPosts.map((blogPost) => (
                    <React.Fragment key={blogPost.id}>
                        <Grid item xs={matches ? 12 : 6}>
                            <img src={`${process.env.REACT_APP_BASE_URL}${blogPost.photo.contentUrl}`} alt={'blog'} width='155px' height='100px' />
                        </Grid>
                        <Grid item xs={matches ? 12 : 6} style={matches ? {textAlign: 'center'} : {textAlign: 'left'}}>
                            <Typography variant={'body1'} gutterBottom style={{marginBottom: 10}}>
                                {blogPost.content}
                            </Typography>

                            <a rel="noopener noreferrer" target='_blank' href={blogPost.url} style={{ textDecoration: 'none' }}>
                            <Button
                                variant="outlined"
                                className={classes.readButton}
                                endIcon={<ArrowRightIcon style={{width: 40, height: 40}} />}
                            >
                                    lire l'article
                            </Button>
                            </a>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
            <Typography variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, marginTop: 20, font: 'normal normal bold 18px/21px Quicksand'} : {marginBottom: 40, font: 'normal normal bold 18px/21px Quicksand', marginLeft: 30, marginTop: 20}}>
                Vous souhaitez écrire un article sur un sujet en particulier ?
            </Typography>
            <Button
                variant="outlined"
                className={classes.contactButton}
            >
                contactez-nous
            </Button>
            <Divider variant="middle" className={classes.divider} />
            <Typography variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, font: 'normal normal bold 25px/29px Quicksand'} : {marginBottom: 40, font: 'normal normal bold 25px/29px Quicksand', textAlign: 'left', marginLeft: 30}}>
                actualités presse
            </Typography>
            {consult && consult.news.length && consult.news.map((newArticle) => (
                <Typography key={newArticle.id} variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, font: 'normal normal bold 14px/18px Quicksand'} : {font: 'normal normal bold 14px/18px Quicksand', marginLeft: 30, textAlign: 'left', marginBottom: 40}}>
                    {newArticle.title}<br /><br />
                    <a rel="noopener noreferrer" target='_blank' href={newArticle.url}>
                        <Button
                            variant="outlined"
                            className={classes.readButton}
                            endIcon={<ArrowRightIcon style={{width: 40, height: 40}} />}
                        >
                            lire l'article
                        </Button>
                    </a>
                </Typography>
            ))}
            <Divider variant="middle" className={classes.divider} />
            <Typography variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, font: 'normal normal bold 25px/29px Quicksand'} : {marginBottom: 40, font: 'normal normal bold 25px/29px Quicksand'}}>
                rejoignez la communauté geo-psy
            </Typography>
            <Typography variant={'body1'} gutterBottom style={matches ? {marginBottom: 65, font: 'normal normal bold 18px/21px Quicksand'} : {marginBottom: 40, font: 'normal normal bold 18px/21px Quicksand', marginLeft: 30}}>
                Rejoignez le groupe pour échanger avec les psychologues et les équipes du siège
            </Typography>
            <a href="https://www.linkedin.com/groups/9028747/" rel="noopener noreferrer" target='_blank' style={{ color:"white", textDecoration: 'none' }}>
                <LinkedInIcon style={{width: 70, height: 70}} />
            </a>
        </div>
    )
}
