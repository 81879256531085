import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_INTERVENTION = 'FETCHING_INTERVENTION'
const SET_INTERVENTION = 'SET_INTERVENTION'
const ADD_intervention = 'ADD_intervention'
const UPDATE_intervention = 'UPDATE_intervention'
const DELETE_intervention = 'DELETE_intervention'

function interventionsReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_INTERVENTION:
            return { ...state, loading: true }
        case SET_INTERVENTION:
            return {...state, interventions: action.payload['hydra:member'], loading: false, totalInterventionItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_intervention:
            return {...state, interventions: [action.payload, ...state.interventions]}
        case UPDATE_intervention:
            return {...state, interventions: action.payload }
        case DELETE_intervention:
            return {...state, interventions: state.interventions.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useIntervention() {
    const [state, dispatch] = useReducer(interventionsReducer, {    
        view: null,
        totalInterventionItems: 0,
        interventions: null,
        loading: false,
    })

    return {
        interventions: state.interventions,
        interventionView: state.view,
        totalInterventionItems: state.totalInterventionItems,
        fetchInterventions: useCallback(async function (route = null) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_INTERVENTION' })
            const interventions = await apiFetch(route || '/interventions?type=Intervention&order[startDate]=desc', {method: 'GET'})
            if (interventions['hydra:member']) {
                dispatch({type: 'SET_INTERVENTION', payload: interventions})
            }
        }, [state]),
        createInterventions: useCallback(async function(data, forceUpdate) {
            const newInterventions = await apiFetch('/interventions', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            if (!newInterventions.violations) {
                dispatch({type: 'SET_INTERVENTION', payload: newInterventions})
            }
            return newInterventions
        }, []),
        updateInterventions: useCallback(async function(interventions, data, forceUpdate = false) {
            const newInterventions = await apiFetch('/interventions/' + interventions, {
                method: 'PUT',
                body: data,
            }, forceUpdate)
            dispatch({type: 'UPDATE_intervention', payload: newInterventions, target: interventions })
            return newInterventions
        }, []),
        deleteInterventions: useCallback(async function (consultationId) {
            const discussionDelete = await apiFetch('/interventions/' + consultationId, {
                method: 'DELETE'
            })
            if (discussionDelete) {
                dispatch({type: 'DELETE_intervention', payload: consultationId})
            }
            return discussionDelete
        }, []),
    }
}