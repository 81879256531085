import React, { useState, useEffect } from 'react'
import { Backdrop } from '@material-ui/core';
import Room from './Room'
import { apiFetch } from '../service/apiFetch';
import Alert from '../components/Alert';
import { TransitionLeft } from '../helpers/utils';
import ClosePopup from "../components/Twilio/ClosePopup";

let tempToken = null
export default function Visio({ visioId, setVisioId, matches }) {
    const [roomName, setRoomName] = useState('');
    const [token, setToken] = useState(null);
    const [transition, setTransition] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [severity, setSeverity] = useState(false);
    const [text, setText] = useState(false);
    const [openClosePopup, setOpenClosePopup] = useState(false);

    useEffect(() => {
        apiFetch(`/twilio/video/${visioId}/join`)
            .then(res => {
                if (res && res.token) {
                    setToken(res.token);
                    tempToken = res.token
                    setRoomName(res.roomName)
                } else {
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setText('Désolé, une erreur s\'est produite, veuillez réessayer')
                    setIsOpen(true)
                }
            })
        return () => (setVisioId(null))
    }, [setToken, setRoomName, visioId, setVisioId]);

    const handleLogout = async () => {
        const endVisio = await apiFetch(`/twilio/video/${visioId}/close`)
        if (endVisio.success) {
            setText(endVisio.message)
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setIsOpen(true)
        }
        setToken(null);
        tempToken = null
        setTimeout(() => {
            setVisioId(null)
        },2000)
    };
    const handleClose = (e, close) => {
      if (close) {
        handleLogout()
        setTimeout(() => {
            window.location.reload()
        }, 2000)
      } else {
        setToken(tempToken)
      }
      setOpenClosePopup(false)
    }

    return (
        <main>
                {token && <Backdrop open={!!token} style={{zIndex: 999999}}>
                    <Room setToken={setToken} setOpenClosePopup={setOpenClosePopup} matches={matches} setTransition={setTransition} setIsOpen={setIsOpen} roomName={roomName} token={token} handleLogout={handleLogout} />
                </Backdrop>}
                <ClosePopup setOpenClosePopup={setOpenClosePopup} open={openClosePopup} handleClose={handleClose} />
                <Alert isOpen={isOpen} transition={transition} message={text} setIsUpdate={setIsOpen} severity={severity} />
        </main>
    )
}
