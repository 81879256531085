import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { SpecialistProfile } from './EditSpecialistProfile';
import Prices from './Prices';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function SpecialistProfileTabs({userData, setText, setSeverity, classes, setTransition, setIsUpdate, setNewSideBarInfo}) {
    let location = useLocation()
    const [value, setValue] = React.useState(location.state ? 1 : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab style={!value ? {backgroundColor: '#05057C', color: 'white', fontSize: 20, textTransform: 'capitalize'} : {color: '#05057C', fontSize: 20, textTransform: 'capitalize'}} label="Informations générales" {...a11yProps(0)} />
                    <Tab style={value ? {backgroundColor: '#05057C', color: 'white', fontSize: 20, textTransform: 'capitalize'} : {color: '#05057C', fontSize: 20, textTransform: 'capitalize'}} label="Tarifs" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <SpecialistProfile classes={classes} userData={userData} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} setNewSideBarInfo={setNewSideBarInfo}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Prices userData={userData} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} setNewSideBarInfo={setNewSideBarInfo} />
            </TabPanel>
        </>
    );
}