import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { green  } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { translateStatus } from '../../helpers/utils';
import "moment/locale/fr";


const ITEM_HEIGHT = 48;

export default function DisplayNotification({ profileUser, displayableNotification = [], matches, menuOpen }) {
    const [open, setOpen] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    let history = useHistory()

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        setVisible(true)
        setIsOpen(true)
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        !isOpen && setVisible(!displayableNotification.length)
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open, displayableNotification, isOpen]);

    return (
        <div>
            <IconButton 
                style={ matches ? menuOpen ? {display: 'none'} : {position: 'absolute', right: 35, top: 5} : {position: 'absolute', right: 200, top: 10}} 
                aria-label="more"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge color="secondary" variant="dot" invisible={visible}>
                    <NotificationsNoneIcon style={{ color: green[50] }} />
                </Badge>
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style= {{maxHeight: ITEM_HEIGHT * 4.5, width: '40ch'}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', maxHeight: ITEM_HEIGHT * 4.5, width: '40ch', overflowY: 'scroll' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {displayableNotification.length ? displayableNotification.map((notification) => (
                                        <MenuItem key={notification.id} style={{whiteSpace: 'normal'}} onClick={() => history.push(`/incoming-consultation/${profileUser.id}`)}>
                                            {notification.intervention ? `Vous avez une nouvelle intervention de type : ${notification.intervention.type}, le ${moment(notification.intervention.startDate).format('LL')} avec le status : ${translateStatus(notification.intervention.status)}`
                                            : `Vous avez une nouvelle consultation de type : ${notification.consultation.type}, le ${moment(notification.consultation.startDate).format('LL')}`
                                            }
                                        </MenuItem>
                                    )) : 'Pas de nouvelle notification...'}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
  );
}