import React, { useEffect, useRef } from 'react'
import * as mainConstant from '../Constant/mainConstant'
import hasRoles from '../service/security/hasRoles';
import { EditUserProfile } from './ProfileItem/User/EditUserProfile';
import Alert from '../components/Alert';
import { SuperAdminProfile } from './ProfileItem/SuperadminProfile';
import { useClients } from '../hooks/clients';
import { useSpecialists } from '../hooks/specialist';
import { motion } from "framer-motion";
import { useStylesProfile } from '../css/Style';
import SpecialistProfileTabs from './ProfileItem/Specialist/SpecialistProfileTabs';
import { SpecialistProfile } from './ProfileItem/Specialist/EditSpecialistProfile';

export default function Profile(userData) {
    const classes = useStylesProfile();
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [transition, setTransition] = React.useState(undefined)
    const [severity, setSeverity] = React.useState(undefined)
    const [foundByName, setFoundByName] = React.useState(false);
    const [text, setText] = React.useState('')
    const mounted = useRef(false);

    const {
        clients,
        totalClientItems,
        clientView,
        fetchClients
    } = useClients()
    const {
        specialists,
        totalItems,
        view,
        fetchSpecialists,
        createSpecialist,
    } = useSpecialists()

    useEffect(() => {
        mounted.current = true
        if (userData.hasToFetch) {
            if (userData.isSpecialist) {
                fetchSpecialists(null, () => mounted)
            } else {
                !foundByName && fetchClients(null, () => mounted)
            }
        }
        return () => mounted.current = false
    }, [fetchClients, fetchSpecialists, userData.isSpecialist, userData.hasToFetch, foundByName])

    document.title = mainConstant.PROFILE

    return (
        <>
        {hasRoles() === 'specialist' && userData.props.userData && userData.props.userData.specialist.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet') ? <SpecialistProfileTabs classes={classes} userData={userData.props.userData} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} setNewSideBarInfo={userData.props.setProfileUser}/> : hasRoles() === 'specialist' && userData.props.userData && <SpecialistProfile classes={classes} userData={userData.props.userData} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} setNewSideBarInfo={userData.props.setProfileUser}/>}
        {(hasRoles() === 'user' || hasRoles() === 'client') && <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} ><EditUserProfile setText={setText} setSeverity={setSeverity} classes={classes} userData={userData.props.userData} setTransition={setTransition} setIsUpdate={setIsUpdate} setNewSideBarInfo={userData.props.setProfileUser} /></motion.div> }
        {hasRoles() === 'super_admin' && <SuperAdminProfile foundByName ={foundByName} setFoundByName={setFoundByName} fetchClients={fetchClients} currentProfile={userData.toggleProfile} view={userData.toggleProfile === 'specialists' ? view : clientView} fetchSpecialists={fetchSpecialists} totalItems={userData.toggleProfile === 'specialists' ? totalItems : totalClientItems} setSeverity={setSeverity} createSpecialist={createSpecialist} isSpecialist={userData.isSpecialist} users={userData.isSpecialist ? specialists : clients} defaultClasses={classes} setTransition={setTransition} setIsUpdate={setIsUpdate} setText={setText} breadcrumb={userData.breadcrumb} setBreadcrumb={userData.setBreadcrumb} />}
        {isUpdate && <Alert isOpen={isUpdate} transition={transition} message={text || 'Votre profile a bien été modifié'} setIsUpdate={setIsUpdate} severity={severity} />}
        </>
    )
}
