import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send';
import React, { useState } from 'react'
import { useStylesNewMessage } from '../../css/Style';
import { TransitionLeft } from '../../helpers/utils';
import { handleFormError } from '../../service/security/error';

export function NewMessage({ userData, setIsUpdate, setTransition, setText, setSeverity, createDiscussion, createMessages, handleReceiveMessage}) {
    const classes = useStylesNewMessage()
    const [disable, setDisable] = useState(true)
    const [error, setError] = useState(false)
    const [type, setType] = React.useState('');

    const handleType = (event) => {
        setType(event.target.value);
    };

    const handleChange = () => {
        setDisable(false)
    }
    
    const handleSendMessage = async (e) => {
        e.preventDefault()
        setDisable(true)
        setError(false)
        let data = new FormData(e.target)
        let dataObject = {
            subject : data.get('subject'),
            type : type,
            messages: [
                {
                    message: data.get('message')
                }
            ]
        }

        const discussion = await createDiscussion(dataObject, null, true)
        if (!discussion.violations) {
                setSeverity('success')
                setText('Message envoyé !')
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                document.getElementById("messageForm").reset();
                setTimeout(() => {
                    handleReceiveMessage()
                }, 2000)

        } else {
            setSeverity('error')
            setText('Problème(s) lors de l\'envoie du message !')
            const errorField = handleFormError(discussion, ['subject', 'type', 'messages'])
            setError(errorField)
            setIsUpdate(true)
            setTransition(() => TransitionLeft)
        }
        setDisable(false)
    }
    
    return(
        <Paper className={classes.paperPosition}>
            <Typography variant="h5" gutterBottom color="primary" className={classes.marginBottom}>
                Nouveau message
            </Typography>
            <form id='messageForm' autoComplete="off" onChange={ handleChange } onSubmit={ handleSendMessage }>
                <TextField error={!!error.subject} helperText={error.subject} className={classes.fieldWidth} id="subject" name="subject" label="Sujet" required />
                <FormControl className={classes.fieldWidth} error={!!error.type} required>
                    <InputLabel id="type">Type de message</InputLabel>
                    <Select
                        labelId="type"
                        id="type"
                        value={type}
                        onChange={handleType}
                    >
                        <MenuItem value={'Information'}>Information</MenuItem>
                        <MenuItem value={'Problème'}>Problème</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error.type}</FormHelperText>}
                </FormControl>
                <TextField
                    id="message"
                    name="message"
                    label="Votre message"
                    multiline
                    rows={5}
                    className={classes.fieldWidth}
                    required
                    error={!!error.messages}
                    helperText={error.messages}
                />
                <Button
                    disabled={disable}
                    type='submit'
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                >
                    Envoyer
                </Button>
            </form>
        </Paper>
    )
}
