import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

export const FETCHING_SPECIALISTS = 'FETCHING_SPECIALISTS'
export const SET_SPECIALISTS = 'SET_SPECIALISTS'
export const ADD_SPECIALISTS = 'ADD_SPECIALISTS'
export const UPDATE_SPECIALISTS = 'UPDATE_SPECIALISTS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_SPECIALISTS:
            return { ...state, loading: true }
        case SET_SPECIALISTS:
            return {...state, specialists: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_SPECIALISTS:
            return {...state, specialists: [action.payload, ...state.specialists]}
        case UPDATE_SPECIALISTS:
            return {...state, specialists: action.payload }
        default:
            return state
    }
}

export function useSpecialists() {
    const [state, dispatch] = useReducer(reducer, {    
        specialists: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        specialists: state.specialists,
        totalItems: state.totalItems,
        view: state.view,
        fetchSpecialists: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_SPECIALISTS' })
            const specialists = await apiFetch(route || '/specialists?order[user.firstName]=asc', {method: 'GET'})
            if (isSubscribed().current) {
                dispatch({type: 'SET_SPECIALISTS', payload: specialists})
            }
        },
        createSpecialist: async function(data, forceResponse) {
            const newUser = await apiFetch('/specialists', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceResponse)
            if (newUser.id) {
                dispatch({type: 'ADD_SPECIALISTS', payload: newUser })
            }
            return newUser
        },
        updateSpecialist: async function(specialistId, data, forceResponse) {
            const updatedSpecialist = await apiFetch('/specialists/' + specialistId, {
                method: 'PUT',
                body: data,
            }, forceResponse)
            let newUser = {}
            if (!updatedSpecialist.violations) {
                newUser = await apiFetch(`${updatedSpecialist.user['@id']}`, { method: 'GET'})
            }
            dispatch({type: 'UPDATE_SPECIALISTS', payload: newUser, target: specialistId })
            return {updatedSpecialist: updatedSpecialist, updatedUser: newUser}
        },
        fetchPublicSpecialists: async function (forceResponse, isSubscribed) {
            if (state.specialists || state.loading || state.totalItems) {
                return
            }
            dispatch({ type: 'FETCHING_SPECIALISTS' })
            const specialists = await apiFetch('/specialists/public', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/ld+json',
                }
            }, forceResponse)
            if (isSubscribed().current) {
                dispatch({type: 'SET_SPECIALISTS', payload: specialists})
            }
        },
    }
}