import React from 'react'
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import GetAppIcon from '@material-ui/icons/GetApp';
import '../index.css';
import { NavLink } from 'react-router-dom';
import { translateStatus } from '../helpers/utils';
import clsx from 'clsx';
import { useStylesEnhancedTableBody } from '../css/Style';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export function EnhancedTableBody(props) {
    const classes = useStylesEnhancedTableBody()
    const { stableSortNeeded, rows, onLoginAs, order, orderBy, isVisio, onEdit, isIntervention, modifyOption, emptyMessage, onShow, handleShowPopup, isSpecialist, isClient, isConsult, isPlatforme, specialistTable, isPayment, isAdmin, isDiscussion } = props;
    function descendingComparator(a, b, orderBy) {
      if (a && b) {
        if (b.user) {
          if (b.user[orderBy] < a.user[orderBy]) {
            return -1;
          }
          if (b.user[orderBy] > a.user[orderBy]) {
            return 1;
          }
          return 0;
        } else if (b.client && b.client.user) {
          if (b.client.user[orderBy] < a.client.user[orderBy]) {
            return -1;
          }
          if (b.client.user[orderBy] > a.client.user[orderBy]) {
            return 1;
          }
          return 0;
        } else if (b.specialist && b.specialist.user) {
          if (b.specialist.user[orderBy] < a.specialist.user[orderBy]) {
            return -1;
          }
          if (b.specialist.user[orderBy] > a.specialist.user[orderBy]) {
            return 1;
          }
          return 0;
        } else {
          if (b[orderBy] < a[orderBy]) {
            return -1;
          }
          if (b[orderBy] > a[orderBy]) {
            return 1;
          }
          return 0;
        }
      }
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
      
    function stableSort(array, comparator) {
      if (stableSortNeeded) {
        return array
      } else {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }
    }
    
/*     const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
 */
    return (
    <TableBody>
    {rows && stableSort(rows, getComparator(order, orderBy))
      /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
      .map((row, index) => {
        const isItemSelected = props.isSelected && props.isSelected(row.name || row.id);
        const labelId = `enhanced-table-${index}`;

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
            role="checkbox"
            aria-checked={isItemSelected}
          >
            {props.handleClick && <TableCell padding="checkbox" onClick={(event) => (props.handleClick && props.handleClick(event, row.name || row.id)) || (handleShowPopup && handleShowPopup(event, row))}>
              <Checkbox
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>}
            {!isAdmin && <TableCell className="pointer" onClick={(event) => (props.handleShowMessage && props.handleShowMessage(event, row.id)) || (handleShowPopup && handleShowPopup(event, row))} id={labelId} scope="row" padding="none" style={{paddingLeft: '50px', marginTop: '10px'}}>
              {(isSpecialist ? `${row.client.user.firstName} ${row.client.user.lastName}` : isClient && row.specialist ? `${row.specialist.user.firstName} ${row.specialist.user.lastName}` : null) || (row.lastMessage && moment(row.lastMessage).format("lll")) || (row.user && row.user.firstName)} {row.user && row.user.lastName} {specialistTable ? <div className={clsx(classes.shape, classes.shapeCircle)} style={row.active ? {backgroundColor: '#6EDEBB'} : {backgroundColor: '#C4C8D2'}} /> : '' } {row.paidAt && moment(row.paidAt).format('LL')}
            </TableCell>}
            {isAdmin && <><TableCell className="pointer" onClick={(event) => handleShowPopup && handleShowPopup(event, row)} component="th" id={labelId} scope="row" padding="none" style={{paddingLeft: '50px', marginTop: '10px'}}>
              {`${row.client.user.firstName} ${row.client.user.lastName}`}
            </TableCell>
            <TableCell className="pointer" onClick={(event) => handleShowPopup && handleShowPopup(event, row)} component="th" id={labelId} scope="row" padding="none" style={{paddingLeft: '50px', marginTop: '10px'}}>
              {`${row.specialist && row.specialist.user && row.specialist.user.firstName} ${row.specialist && row.specialist.user && row.specialist.user.lastName}`}
            </TableCell></>}
            <TableCell className="pointer" onClick={(event) => (props.handleShowMessage && props.handleShowMessage(event, row.id)) || (handleShowPopup && handleShowPopup(event, row))} align="right" style={props.objectStyle}>
              {(row.amount && `${row.amount} ${row.currency}`) || (row.startDate && moment(row.startDate).format("lll")) || (row.messages && formatMessage(row, row.messages[row.messages.length - 1].message.substr(0, 40))) || (row.user && row.user.mobile)}
            </TableCell>
            {!isPayment && !isVisio && !isDiscussion && <TableCell className="pointer" align="right" onClick={(event) => (props.handleShowMessage && props.handleShowMessage(event, row.id)) || (handleShowPopup && handleShowPopup(event, row))} style={props.messageStyle}>
              {row.city || (row.room && row.room.duration) || (row.user && row.user.address && row.user.address.address)}
            </TableCell>}
            {(row.consultType || (row.user && row.user.email)) && <TableCell align="right" style={{marginTop: '10px'}}>
              {row.consultType || row.user.email}
            </TableCell>}
            {modifyOption && <TableCell align="right">
              <ButtonGroup color="primary" aria-label="button group" variant="text">
                <Button>
                  <NavLink to={specialistTable ? `/specialist/profil/edit/${row.id}` : `/client/profil/edit/${row.id}`} ><EditIcon style={{cursor: 'pointer', color: 'grey', marginLeft: 5, marginRight: 5, marginTop: 5}} onClick={(event) => onEdit && onEdit(event, row)}/></NavLink>
                </Button>
                <Button>
                  <NavLink to={specialistTable ? `/specialist/profil/show/${row.id}` : `/client/profil/show/${row.id}`} ><VisibilityIcon style={{cursor: 'pointer', color: 'grey', marginLeft: 5, marginRight: 5, marginTop: 5}} onClick={(event) => onShow && onShow(event, row)}/></NavLink>
                </Button>
                <Button>
                  <ExitToAppIcon style={{cursor: 'pointer', color: 'grey', marginLeft: 5, marginRight: 5}} onClick={(e) => onLoginAs && onLoginAs(e, row)}/>
                </Button>
              </ButtonGroup>
            </TableCell>}
            {isConsult && <TableCell className="pointer" onClick={(event) => handleShowPopup && handleShowPopup(event, row)} align="right" style={props.objectStyle}>{row.motif || row.type}</TableCell>}
            {isConsult && !isPlatforme && <TableCell className="pointer" onClick={(event) => handleShowPopup && handleShowPopup(event, row)} align="right" style={props.objectStyle}>{translateStatus(row.status)}</TableCell>}
            {isConsult && !isAdmin && (isIntervention || isPlatforme || isVisio) && <TableCell className="pointer" align="right" style={props.objectStyle}>{ isClient && (row.status === 'Approved' || (row.status === 'completed' && isPlatforme)) && new Date() > new Date(row.endDate) && <GetAppIcon style={{cursor: 'pointer', color: 'grey'}} onClick={(event) => onShow && onShow(event, row)}/>}</TableCell>}
          </TableRow>
        );
      })}
    {rows.length === 0 && (
      <TableRow style={{ height: 53 }}>
        <TableCell colSpan={6} style={{textAlign: 'center'}}>{emptyMessage || 'Pas encore de consultations...'}</TableCell>
      </TableRow>
    )}
  </TableBody>
    )
}

function formatMessage(row, message) {
  return message && <span>{row.deletedAt && <span style={{color: 'red'}}>Supprimé - </span>}{message}</span>
}
