import React, { useEffect, useState } from 'react'
import { LinearProgress, Checkbox, FormControlLabel, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TransitionLeft } from '../../../helpers/utils'

export default function AddQuote({ handleCloseMenu, updateQuoteOfWeek, selected, open, handleClose, createQuoteOfWeek, setIsUpdate, setText, setSeverity, setTransition }) {
    const [quote, setQuote] = useState(false)
    const [quoteError, setQuoteError] = useState(false)
    const [authorError, setAuthorError] = useState(false)
    const [author, setAuthor] = useState(false)
    const [isCheckboxUpdated, setIsCheckboxUpdated] = useState(false)
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(false)

    const handleChangeAuthor = (event) => {
        setDisable(false)
        setAuthor(event.target.value);
    };

    const handleChange = (event) => {
        setIsCheckboxUpdated(true)
        setDisable(false)
        setActive(event.target.checked);
    };

    useEffect(() => {
        (async () => {
            if (selected) {
                setQuote(selected.quote)
                setActive(selected.star)
                setAuthor(selected.writer)
            }
        })()
    }, [selected])

    const handleSubmit = async () => {
        if (!author) return setAuthorError('Ce champ ne doit pas être vide')
        if (!quote) return setQuoteError('Ce champ ne doit pas être vide')
        setDisable(true)
        setLoading(true)
        handleCloseMenu()
        const createQuote = !selected ? await createQuoteOfWeek({
            quote: quote,
            writer: author,
            star: active
        }) : await updateQuoteOfWeek(selected.id, {
            quote: quote,
            writer: author,
            star: active
        })
        if (createQuote) {
            setText('Le psychologue a été ajouté')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText('Erreur lors de l\'ajout du psychologue')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
        setAuthorError(false)
        setQuoteError(false)
        setDisable(false)
        setLoading(false)
        handleClose()
    }

    const handleChangeQuote = (e) => {
        setQuote(e.target.value)
        setDisable(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="title">
            <DialogTitle id="title">Ajouter une citation</DialogTitle>
            <DialogContent>
                <TextField  
                    required
                    label="Auteur"
                    style={{width: '100%'}}
                    onChange={handleChangeAuthor}
                    defaultValue={selected ? selected.writer : ''}
                    helperText={authorError}
                    error={!!authorError}
                />
                <TextField  
                    required
                    label="Citation"
                    multiline
                    rows={4}
                    style={{width: '100%'}}
                    onChange={handleChangeQuote}
                    defaultValue={selected ? selected.quote : ''}
                    helperText={quoteError}
                    error={!!quoteError}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={selected && !isCheckboxUpdated ? selected.star : active}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'checkbox' }}
                        />
                    }
                    label="Choisir cette citation pour le mois"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={disable}>
                    Enregistrer
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </Dialog>
    )
}