import React, { useState } from 'react'
import { Paper, Button, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { useStylesVoice } from '../../css/Style';

export default function Voice({ menuOpen, setVoiceCall, voiceCall }) {
    const classes = useStylesVoice();
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setVoiceCall(false)
    }

    const handleOpenPopup = () => {
        setOpen(true)
    }

    return (
        <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="popup-title"
            aria-describedby="popup-description"
        >
            <DialogTitle id="popup-title">{'Voulez-vous fermer la popup ?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="popup-description">
                    Attention, si vous fermez cette popup vous n'aurez plus accès au numéro !
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                    Non
                </Button>
                <Button onClick={handleClose} color="primary" >
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
        <Paper className={classes.voicePaper} style={menuOpen ? {left: '57%'} : {}}>
            <IconButton aria-label="close" className={classes.close} onClick={handleOpenPopup}>
                <CloseIcon fontSize="small" />
            </IconButton>
            <Typography variant="h6" gutterBottom style={{marginTop: 45, marginRight: 20}}>
                Veuillez composer le <a href={`tel:${voiceCall.room.callNumber}`}>{voiceCall.room.callNumber}</a> suivi de votre code consultation <span style={{color: '#05057C'}}>{voiceCall.room.callSecretCode}</span> pour entrer en contact avec votre psychologue !
            </Typography>
            <Typography variant="body1" style={{fontStyle: 'italic'}} gutterBottom>
                Assurez-vous d'avoir un bon réseau.
            </Typography>
            <Typography variant="body1" style={{fontStyle: 'italic'}} gutterBottom>
                Le code est valable 3 minutes.
            </Typography>
            <Typography variant="body1" style={{fontStyle: 'italic'}} gutterBottom>
                Appel non surtaxé
            </Typography>
        </Paper>
        </>
    )
}

/* function fancyTimeFormat(duration) {   
    let hrs = ~~(duration / 3600);
    let mins = ~~((duration % 3600) / 60);
    let secs = ~~duration % 60;

    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
} */