import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import { FormHelperText, Button, InputAdornment, TextField, FormControl, InputLabel, Select, MenuItem , Slide} from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPricesPopup({ error, open, handleClose, addPrices, duration, setDuration, setType, setPrice }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = e => {
        setDuration(e.target.value)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog"
                fullScreen={fullScreen}
                TransitionComponent={Transition}
            >
                <DialogTitle id="prices-dialog-title">Ajouter un tarif</DialogTitle>
                <DialogContent>
                    <TextField error={!!error.type} helperText={error.type} style={{width: 215, marginBottom: 20}} onChange={(e) => setType(e.target.value)} id='label' label="Type de séance" type='text'></TextField><br />
                    <TextField error={!!error.price} helperText={error.price} style={{width: 215, marginBottom: 20}} onChange={(e) => setPrice(e.target.value)} label="Prix" type='number' id='price' InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}></TextField><br />
                    <FormControl style={{width: 215}} error={!!error.duration}>
                        <InputLabel id="select-label">Durée</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={duration}
                            onChange={handleChange}
                        >
                            <MenuItem value={'30'}>30 min</MenuItem>
                            <MenuItem value={'45'}>45 min</MenuItem>
                            <MenuItem value={'60'}>60 min</MenuItem>
                        </Select>
                        <FormHelperText>{error.duration}</FormHelperText>
                    </FormControl><br />
                    <Container style={{textAlign: 'center', marginTop: '20px'}}>
                        <div>
                            <Button onClick={addPrices} variant="contained" color="primary">
                                Ajouter
                            </Button>
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}