import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import Container from "@material-ui/core/Container";

export default function RegisterLoginPopup({ open, handleClose, text }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{width: '100%'}}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullScreen={fullScreen}
            >
                <DialogContent style={{margin: '30px'}}>
                    <DialogContentText>
                        {text || `Vous souhaitez consulter ce psychologue ? Créez votre espace personnel ou connectez-vous en cliquant ici.`}
                    </DialogContentText>
                    <NavLink to='/login' style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
                        <Button style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#fff'}} variant="contained" color="secondary">
                            S'inscrire / Se connecter
                        </Button>
                    </NavLink>
                    <Container style={{textAlign: 'center', marginTop: '20px'}}>
                        <div>
                            <Button onClick={handleClose} style={{margin: '30px auto'}} >
                                Annuler
                            </Button>
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}
