import React from 'react'
import {  Grid } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export default function SocialCard({ classes }) {

    return (
        <div className={classes.consultCard}>
            <Grid container spacing={1} style={{justifyContent: 'center', paddingLeft: 30}}>
                <Grid item xs={2} style={{marginRight: 30}}>
                    <a href="https://www.instagram.com/geopsyfrance/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                        <InstagramIcon style={{width: 40, height: 40}} />
                    </a>
                </Grid>
                <Grid item xs={2} style={{marginRight: 30}}>
                    <a href="https://www.facebook.com/geopsyfrance/" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                        <FacebookIcon style={{width: 40, height: 40}} />
                    </a>
                </Grid>
                <Grid item xs={2} style={{marginRight: 30}}>
                    <a href="https://twitter.com/Geo_Psy_France" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                        <TwitterIcon style={{width: 40, height: 40}} />
                    </a>
                </Grid>
                <Grid item xs={2} style={{marginRight: 30}}>
                    <a href="https://fr.linkedin.com/company/geo-psy" rel="noopener noreferrer" target='_blank' style={{ color:"white" ,textDecoration: 'none' }}>
                        <LinkedInIcon style={{width: 40, height: 40}} />
                    </a>
                </Grid>
            </Grid>
        </div>
    )
}
