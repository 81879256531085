import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Paper, useTheme } from "@material-ui/core";
import React, {useState, useEffect} from "react";
import ChatMessages from "./Components/ChatMessages";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionLeft } from "../../helpers/utils";
import { handleFormError } from "../../service/security/error";
import SendIcon from '@material-ui/icons/Send';
import { useStylesShowMessage } from "../../css/Style";

export default function TestShowMessage(props) {
    const classes = useStylesShowMessage()
    const theme = useTheme()
    let discussion = findRightDiscussion(props)
    const [response, setResponse] = useState(false)
    const [error, setError] = useState({})
    const [disable, setDisable] = useState(true)
    const [value, setValue] = useState('')

    const handleResponse = () => {
        setResponse(!response)
    }

    const handleChange = (e) => {
        setValue(e.currentTarget.children[0].children[1].children[0].value)
        setDisable(false)
    }

    const close = () => {
        props.setShowMessage(null)
    }

    useEffect(() => {
        const paper = document.getElementById('box')
        paper.scrollTop = paper.scrollHeight
    }, [])

    const sendResponse = async (e) => {
        e.preventDefault()
        setDisable(true)
        setError(false)

        const data = value
        
        const messageBody = {
            message: data,
            discussion: `/discussions/${discussion.rightRow.id}`
        }
        const message = await props.createMessages(messageBody, `/discussions`, true)

        if (!message.violations) {
            setValue('')
            const paper = document.getElementById('box')
            paper.scrollTop = paper.scrollHeight
            props.setSeverity('success')
            props.setText('Message envoyé !')
            props.setIsUpdate(true)
            props.setTransition(() => TransitionLeft)
            setResponse(false)
        } else {
            props.setSeverity('error')
            props.setText('Problème(s) lors de l\'envoie du message !')
            const errorField = handleFormError(message, ['message'])
            setError(errorField)
            props.setIsUpdate(true)
            props.setTransition(() => TransitionLeft)
        }
    }

    return (
          <div>
              <div className={classes.topBar}>
                    <CloseIcon style={{right: 0, color: theme.palette.type === "light" ? 'white' : '#424242' , paddingBottom: 3, cursor: 'pointer'}} onClick={close} />
              </div>
              <Paper id={'box'} className={classes.messagePaper}>
              {discussion.rightRow.messages.map((message, index) => (
                <ChatMessages
                key={index}
                date={message.sentAt}
                author={message.author}
                avatar={props.userData.id === message.author.id && props.userData.specialist && `${process.env.REACT_APP_BASE_URL}${props.userData.specialist && props.userData.specialist.photo.contentUrl}`}
                messages={[message.message]}
                side={message.author.id === discussion.rightRow.author.id ? 'right' : 'left'}
                isLastItem={discussion.rightRow.messages[discussion.rightRow.messages.length -1]}
                />
              ))}
            </Paper>
            <form id='messageForm' autoComplete="off" onChange={ handleChange } onSubmit={ sendResponse } className={classes.sendMessage}>
                <FormControl style={{margin: 10, width: '97%', backgroundColor: theme.palette.type === "light" ? 'white' : '#424242'}}>
                    <InputLabel htmlFor="message">Réponse</InputLabel>
                    <Input
                      error={!!error.message}
                      id="message"
                      type={'text'}
                      value={value}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton type="submit" disabled={disable} onClick={handleResponse}>
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText style={{color: 'red'}}>{error.message}</FormHelperText>
                </FormControl>
            </form>
          </div>
      );
}

function findRightDiscussion(props) {
    let rightRow = null
    props.rows.forEach((row) => {
        if (row.id === props.selectedMessage) {
            rightRow = row
        }
        return null
    })
    
    //let discussions immutable --> /!\
    const sorted = [...rightRow.messages].sort(function(a, b){return b.id-a.id})
    return { sorted, rightRow};
}
