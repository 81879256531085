import React from 'react'
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, IconButton, Tooltip, useMediaQuery, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Menu } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useToolbarStyles } from '../css/Style';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, actif, handleChangeActif, handleSearch, handleChangeName, handleChange, setBreadcrumb, onDeleteClick, isSpecialist, disable, isClient, sepaPopup, isAdmin, exportFiles } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    let history = useHistory()

    const handleClick = () => {
      setBreadcrumb(['Profil', 'Ajouter'])
    }

    const handleMapClick = () => {
      setBreadcrumb(['Profil', '' , '', '', 'Map'])
    }

    const handleCreditAccount = () => {
      history.push('/creditAccount')
    }
    
    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    return (
      <Toolbar
        className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
        {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} message(s) selectionné(s)
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h5" id="tableTitle" component="div" color="primary" >
            {props.title}
          </Typography>    
        )}
        {isAdmin && handleSearch && 
        <div style={matches ? {marginRight: 5} : {textAlign: 'center', marginRight: 20}}>
          <div><TextField style={matches ? {marginLeft: 20, width: 200} : {width: 315}} id="name" label={`Rechercher un ${isSpecialist ? 'spécialiste' : 'client'}`} onChange={handleChangeName} />
          </div>
          {isSpecialist && <FormControl style={matches ? {marginLeft: 20, width: 200, textAlign: 'initial'} : {width: 315, textAlign: 'initial'}}>
            <InputLabel id="select-label">Actif/Inactif</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={actif}
              onChange={handleChangeActif}
            >
              <MenuItem value={'all'}>Tous</MenuItem>
              <MenuItem value={'actif'}>Actif</MenuItem>
              <MenuItem value={'inactif'}>Inactif</MenuItem>
            </Select>
          </FormControl>}<br />
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SearchIcon />}
            onClick={(e) => handleSearch(e, isSpecialist)}
            disabled={disable}
            style={{margin: 12}}
          >
            Rechercher 
          </Button>
        </div>}
        {numSelected > 0 && (
        <Tooltip title="Supprimer">
          <IconButton aria-label="delete" onClick={onDeleteClick} disabled={disable}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
        {handleChange && isSpecialist && !matches && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleClick}
            style={matches ? {paddingLeft: 20, paddingRight: 20} : {}}
          >
            Créer un spécialiste
          </Button>
        )}
        {handleChange && isSpecialist && !matches && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleMapClick}
          >
            Voir la map
          </Button>
        )}
        {isClient && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleCreditAccount}
          >
            Je recharge mon compte
          </Button>
        )}
        {sepaPopup && isAdmin && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={sepaPopup}
          >
            Export SEPA
          </Button>
        )}        
        {handleChange && isAdmin && !matches && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={exportFiles}
          >
            Export {!isSpecialist ? 'Clients' : 'Spécialistes'}
          </Button>
        )}
        {handleChange && isAdmin && isSpecialist && !matches && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={(e) => exportFiles(e, false, true)}
          >
            Export Honoraires
          </Button>
        )}
        {matches && 
          <>
          <IconButton onClick={handleClickMenu}>
              <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {handleChange && isSpecialist && <MenuItem onClick={handleClick}>Créer un spécialiste</MenuItem>}
            {handleChange && isSpecialist && <MenuItem onClick={handleMapClick}>Voir la map</MenuItem>}
            {handleChange && isAdmin && <MenuItem onClick={exportFiles}>Export {!isSpecialist ? 'Clients' : 'Spécialistes'}</MenuItem>}
            {handleChange && isAdmin && isSpecialist && <MenuItem onClick={(e) => exportFiles(e, false, true)}>Export Honoraires</MenuItem>}
          </Menu>
          </>
        }
      </Toolbar>
    );
  };