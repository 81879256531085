import React from 'react';
import clsx from 'clsx';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import SimpleBottomNavigation from './MenuItem/ToggleAvailabilityButton';
import ListTab from './MenuItem/ListTab';
import AuthService from '../service/security/handleTokenProvider'
import { useHistory } from 'react-router-dom'
import Messages from './Messages'
import Consult from './Consult'
import Settings from './Settings'
import interdit from './Interdit'
import {ClientRoute} from '../service/routes/ClientRoute'
import {UserRoute} from '../service/routes/UserRoute'
import {Route, Switch, Redirect } from 'react-router-dom'
import { isAuth } from '../service/security/isAuth';
import { isLoginAsUser } from '../service/security/isAuth';
import { apiFetch } from '../service/apiFetch';
import Profile from './Profile';
import hasRoles from '../service/security/hasRoles';
import { Visit } from './Visit';
import { routes } from '../Constant/routes';
import { SpecialistAndAdminRoute } from '../service/routes/SpecialistAndAdminRoute';
import { SpecialistRoute } from '../service/routes/SpecialistRoute';
import logo from '../geopsy.png'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, useMediaQuery } from '@material-ui/core';
import { TransitionLeft } from '../helpers/utils';
import Payments from './Payments';
import { ShowSpecialistProfile } from './ProfileItem/Specialist/ShowSpecialistProfile';
import { SpecialistProfile } from './ProfileItem/Specialist/EditSpecialistProfile';
import { ShowUserProfile } from './ProfileItem/User/ShowUserProfile';
import { AdminRoute } from '../service/routes/AdminRoute';
import { EditUserProfile } from './ProfileItem/User/EditUserProfile';
import { sharedUseStyles } from '../sharedStyle/sharedProfileStyle'
import Alert from '../components/Alert';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import DetailsIcon from '@material-ui/icons/Details';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Planning from './Planning';
import Chat from '../components/Twilio/Chat'
import CrediteAccount from './CrediteAccount';
import Voice from '../components/Twilio/Voice';
import Visio from './Visio';
import LinearProgress from '@material-ui/core/LinearProgress';
import DisplayNotification from './MenuItem/DisplayNotification';
import Dashboard from './Dashboard';
import ChangePassword from './ProfileItem/ChangePassword';
import { motion } from "framer-motion";
import IncomingConsultation from './IncomingConsultation';
import { useStylesMenu } from '../css/Style';
import { red } from '@material-ui/core/colors'
import Fees from './Fees';
import AdminPayments from './AdminPayments';
import ContentManagement from './ContentManagement';

const color = ['#44b700', '#ff9800', '#d32f2f'];

let eventSource = null

export default function MainMenu({ setEventSource, onToggleDark, switchTheme, setChatRoom, chatRoom, open, setOpen, voiceCall, setVoiceCall, handleConnection, setHandleConnection }) {
  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: color[available] || '#ff9800',
      color: color[available] || '#ff9800',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
      right: 230,
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

  const classes = useStylesMenu()
  const theme = useTheme();
  const [available, setAvailable]= React.useState(false);
  let history = useHistory();
  const [currentUser, setCurrentUser] = React.useState(undefined);
  const [profileUser, setProfileUser] = React.useState(null)
  const [success, setSuccess] = React.useState(false)
  const [openPopup, setOpenPopup] = React.useState(false)
  const [breadcrumb, setBreadcrumb] = React.useState(['Profile'])
  const [toggleProfile, setToggleProfile] = React.useState('specialists')
  const sharedClasses = sharedUseStyles()
  const [isUpdate, setIsUpdate] = React.useState(false)
  const [transition, setTransition] = React.useState(undefined)
  const [visioId, setVisioId] = React.useState(undefined)
  const [severity, setSeverity] = React.useState(undefined)
  const [notification, setNotification] = React.useState(undefined)
  const [textAlert, setTextAlert] = React.useState('')
  const [notificationText, setNotificationText] = React.useState('')
  const [credit, setCredit] = React.useState(false)
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const lower1825 = useMediaQuery(theme.breakpoints.down(1825))

  React.useEffect(() => {
      //TODO: remove /me and get directly client or specialist
      apiFetch('/users/me', {method: 'GET'})
        .then((response) => {
          const url = new URL(process.env.REACT_APP_MERCURE_SUBSCRIBE_URL)
          localStorage.setItem('REACT_ROLE', JSON.stringify(response.roles))

          if (response.specialist) {
            url.searchParams.append('topic', 'https://geo-psy.fr/specialists/' + response.specialist.id)
            eventSource = new EventSource(url, { withCredentials: true })
            setEventSource(eventSource)
            eventSource.onmessage = event => {
              if (JSON.parse(event.data).type === 'platformStatus') {
                if (JSON.parse(event.data).data.platformStatus === 'waiting-call') {
                  setAvailable('waiting-call')
                } else {
                  setAvailable(getIndex(JSON.parse(event.data).data.platformStatus))
                }
              } 
              setNotification(JSON.parse(event.data))
              setNotificationText(JSON.parse(event.data).type === 'new-audio' ? 'Vous avez une nouvelle demande d\'appel' : 'Vous avez une nouvelle demande de chat')
              if (JSON.parse(event.data).type === 'new-chat') setSuccess(true)
            }
            setAvailable(getIndex(response.specialist.platformStatus))
          } else if (response.client) {
            url.searchParams.append('topic', 'https://geo-psy.fr/public')
            url.searchParams.append('topic', 'https://geo-psy.fr/clients/' + response.client.id)
            eventSource = new EventSource(url, { withCredentials: true })
            setEventSource(eventSource)
            eventSource.onmessage = event => {
              if (JSON.parse(event.data).status === 'refused') {
                setVoiceCall(false)
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                setTextAlert('Désolé, le spécialist ne répond pas à votre appel')
                setSeverity('error')
              } else if (JSON.parse(event.data).type === 'new-credit-balance') {
                setCredit(JSON.parse(event.data).data.nbCredits)
              }
            }
          }
          return setProfileUser(response)
        })
        .catch(() => setProfileUser(false))
    }, [setVoiceCall, setHandleConnection, setEventSource])

  React.useEffect(() => {
    if (isAuth()) {
      setCurrentUser(true)
    }
  }, [])
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickDisconnect = () => {
    eventSource = null
    AuthService.logout()
    history.push('/')
    window.location.reload()
    setCurrentUser(null)
  }

  const handleAccept = () => {
    apiFetch(notification.data.acceptUrl)
    .then((res) => {
      if (res && res.success) {
        if (notification.type === 'new-chat') {
          setChatRoom(notification.data.consultation)
        } else if (notification.type === 'new-audio') {
          handleConnection.accept()
          setVoiceCall(notification.data.consultation)
        }
      } else {
        setTransition(() => TransitionLeft)
        setTextAlert(res ? res.message : 'Vous ne pouvez pas accepter cette consultation')
        setSeverity('error')
        setIsUpdate(true)
      }
      setSuccess(false)
    })
  };

  const handleRefuse = () => {
    handleConnection && handleConnection.reject()
    apiFetch(notification.data.refuseUrl)
    .then((res) => {
      setSuccess(false)
      setOpenPopup(false)
    })
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
};
  
  const getIndex = (status) => {
    if (status === 'online') {
      return 0
    } else if (status === 'offline') {
      return 2
    }
    return 1
  }

  const divStyle = {
    display: 'inline',
    position: 'absolute',
    right: 0,
    margin: 'auto',
    top: '8px',
  }

  const divStyleHidden = {
    display: 'none',
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeUser = () => {
    localStorage.setItem('REACT_TOKEN_AUTH', localStorage.getItem('REACT_TOKEN_AUTH_ORIGIN'))
    localStorage.removeItem('REACT_TOKEN_AUTH_ORIGIN')
    history.push('/dashboard')
    window.location.reload()
  }

  return (
    <>
    {currentUser && hasRoles() ? 
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={isLoginAsUser() ? {backgroundColor: '#ffca7c'} : {}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            style={{zIndex: '99999'}}
          >
          <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{minWidth: '100px'}}>
            <img src={logo} alt={'logo'} width='83px' height='27px' style={{marginTop: '13px', cursor: 'pointer'}} onClick={() => history.push('/dashboard')} />
          </Typography>
          {/* {!matches && <SwitchDark onToggleDark={onToggleDark} switchTheme={switchTheme} />} */}
          {hasRoles() === 'specialist' && !matches && available !== false && profileUser && profileUser.specialist.readableConsultationType.find((type) => type.name === "Plateforme") && <SimpleBottomNavigation setTransition={setTransition} message={setTextAlert} setIsUpdate={setIsUpdate} setSeverity={setSeverity} profileUser={profileUser} available={available} handleClickAvailable={setAvailable} /> }
            {(hasRoles() === 'specialist' || hasRoles() === 'client') && profileUser && <DisplayNotification profileUser={profileUser} menuOpen={open} displayableNotification={profileUser && profileUser.specialist ? profileUser.specialist.displayableNotification : profileUser.client && profileUser.client.displayableNotification} matches={matches} />}
          {!matches && isLoginAsUser() && <ColorButton style={{position: 'absolute', right: 256}} variant="contained" color="secondary" onClick={handleChangeUser}>
                Reconnection en tant qu'Admin
          </ColorButton>}
          {!matches && <Button style={{position: 'absolute', right: 35}} variant="contained" color="secondary" onClick={handleClickDisconnect}>
                Se déconnecter
          </Button>}
        </Toolbar>
         {matches && <div style={!open ? divStyle : divStyleHidden}>
         <IconButton aria-label="delete" className={classes.margin} onClick={handleClickMenu} color={"secondary"}>
          <DetailsIcon fontSize="small" />
        </IconButton>
        <Menu
        id="phoneMenu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
      >
        {hasRoles() === 'specialist' && profileUser && available !== false && <MenuItem><SimpleBottomNavigation setIsUpdate={setIsUpdate} setTransition={setTransition} message={setTextAlert} setSeverity={setSeverity} profileUser={profileUser} available={available} handleClickAvailable={setAvailable} matches={matches}/></MenuItem>}
        {/* <MenuItem onClick={handleCloseMenu}><SwitchDark onToggleDark={onToggleDark} switchTheme={switchTheme} /><Typography>Thème sombre</Typography></MenuItem> */}
        {isLoginAsUser() && <MenuItem onClick={handleCloseMenu}>
          <ColorButton style={{marginLeft: 13, marginTop: 10}} variant="contained" color="secondary" onClick={handleChangeUser}>
            Reconnection en tant qu'Admin
          </ColorButton>
        </MenuItem>}
        <MenuItem onClick={handleCloseMenu}>
          <IconButton onClick={handleClickDisconnect} >
            <PowerOffIcon  color="secondary" /><Typography style={{marginLeft: 13}}>Se déconnecter</Typography>
          </IconButton>
        </MenuItem>
      </Menu>
      </div>}
      </AppBar>
      {(!matches || open) && <Drawer
        onClick={matches ? handleDrawerClose : null}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {profileUser && profileUser.specialist ? <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          > 
            {profileUser && <Avatar alt="Photo" className={classes.innerImg} src={`${process.env.REACT_APP_BASE_URL}${profileUser.specialist.photo.contentUrl}`} style={{position: 'fixed', left: '22px'}}/> }
            <div className={classes.info}>
            {profileUser && `${profileUser.firstName} ${profileUser.lastName}`}<br />
            {profileUser && `${profileUser.email}`}
            </div>
          </StyledBadge> :
          <div className={classes.info}>
          {profileUser && `${profileUser.firstName} ${profileUser.lastName}`}<br />
          {profileUser && `${profileUser.email}`}
          </div>
          }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        {profileUser && <ListTab checkConnect={handleClickDisconnect} profile={profileUser} setBreadcrumb={setBreadcrumb} setToggleProfile={setToggleProfile} />}
      </Drawer>}
      <main className={classes.content}>
          {success && notificationText && <Dialog open={success} aria-labelledby="form-dialog-address" classes={{paper: classes.chatPaper}}>
            <DialogTitle id="form-dialog-title">Nouvelle consultation par Tchat</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  {notificationText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAccept} color="primary">
                    accepter
                </Button>
                <Button onClick={() => setOpenPopup(true)} color="primary">
                    refuser
                </Button>
            </DialogActions>
      </Dialog>}
            <Dialog open={openPopup} onClose={handleClosePopup} aria-labelledby="form-dialog-address">
            <DialogTitle id="form-dialog-title">Attention</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  Voulez-vous vraiment refuser la consultation ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClosePopup} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleRefuse} color="primary">
                    Confirmer
                </Button>
            </DialogActions>
      </Dialog>
        <div className={classes.toolbar} />
        {isAuth() ? <Switch>
            {profileUser && <Route path={[routes.home, routes.dashboard]} exact ><motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} ><Dashboard lower1825={lower1825} user={profileUser} matches={matches} credit={credit} open={open} /></motion.div></Route>}
            <UserRoute path={routes.profile} exact component={Profile} userData={profileUser} setProfileUser={setProfileUser} />
            <UserRoute path={routes.changePassword} exact component={ChangePassword} userData={profileUser} />
            <AdminRoute path={routes.specialist} component={Profile} toggleProfile={toggleProfile} userData={profileUser} setProfileUser={setProfileUser} isSpecialist={true} hasToFetch={true} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} exact />
            <AdminRoute path={routes.client} component={Profile} toggleProfile={toggleProfile} userData={profileUser} setProfileUser={setProfileUser} isSpecialist={false} hasToFetch={true} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} exact />
            <Route path={routes.message} exact>
              <Messages userData={profileUser} open={open} />
            </Route>
            <Route path={routes.consult} exact ><Consult userData={profileUser} setVisioId={setVisioId} /></Route>
            <SpecialistAndAdminRoute path={routes.planning} exact component={Planning} setVisioId={setVisioId} menuOpen={open} matches={matches} profileUser={profileUser} setTransition={setTransition} setIsUpdate={setIsUpdate} setTextAlert={setTextAlert} setSeverity={setSeverity} />
            <UserRoute path={routes.incomingConsultation} exact component={IncomingConsultation} profileUser={profileUser} matches={matches} setChatRoom={setChatRoom} setVisioId={setVisioId}/>
            <AdminRoute path={routes.settings} exact component={Settings}/>
            <ClientRoute path={routes.interdit} component={interdit} />
            <SpecialistRoute path={routes.fees} component={Fees} profileUser={profileUser} setProfileUser={setProfileUser}/>
            <ClientRoute path={routes.creditAccount} component={CrediteAccount} credit={credit} user={profileUser} open={open} />
            {eventSource && <ClientRoute path={routes.visit} component={Visit} user={profileUser} eventSource={eventSource} />}
            <ClientRoute path={routes.payment} component={Payments} credit={credit} user={profileUser} />
            <AdminRoute path={routes.ShowSpecialistProfile} component={ShowSpecialistProfile} exact breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} />
            <AdminRoute path={routes.EditSpecialistProfile} component={SpecialistProfile} exact setNewSideBarInfo={setProfileUser} classes={sharedClasses} setText={setTextAlert} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} />
            <AdminRoute path={routes.ShowClientProfile} component={ShowUserProfile} exact breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} />
            <AdminRoute path={routes.EditClientProfile} component={EditUserProfile} exact setNewSideBarInfo={setProfileUser} classes={sharedClasses} setText={setTextAlert} setSeverity={setSeverity} setTransition={setTransition} setIsUpdate={setIsUpdate} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} />
            <AdminRoute path={routes.transactions} component={AdminPayments} exact />
            <AdminRoute path={routes.contentManagement} component={ContentManagement} exact />
        </Switch> : <Redirect exact to={routes.login}/>}
        <Alert isOpen={isUpdate} transition={transition} message={textAlert} setIsUpdate={setIsUpdate} severity={severity} />
        {chatRoom && <Chat chatRoom={chatRoom} setOpen={setOpen} setChatRoom={setChatRoom} matches={matches} user={profileUser} />}
        {voiceCall && <Voice menuOpen={open} voiceCall={voiceCall} setVoiceCall={setVoiceCall} matches={matches} user={profileUser} />}
        {visioId && <Visio visioId={visioId} setVisioId={setVisioId} matches={matches} />}
      </main>
    </div> : <LinearProgress />}
    </>
  );
}
