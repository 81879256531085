import { Badge, Container, Grid, IconButton, LinearProgress, List, Paper, TextField, Typography } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import React, { Component } from 'react'
import { apiFetch } from '../../service/apiFetch'
import ChatItem from './ChatItem'
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import moment from "moment";
import ConsultPopup from '../../consultPopup'
import hasRoles from '../../service/security/hasRoles'
const Chat = require("twilio-chat")

class ChatApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      chatReady: false,
      messages: [],
      newMessage: '',
      text: "",
      loading: false,
      channel: null,
      close: false,
      isMinimize: false,
      isFullScreen: false,
      openPopup: false,
      email: null,
      title: '',
      notification: false,
      member: false,
      isTyping: false
    }

    this.matches = this.props.matches
    this.setOpen = this.props.setOpen
    this.scrollDiv = React.createRef()
    this.hide = this.hide.bind(this)
    this.toggleExpend = this.toggleExpend.bind(this)
    this.channelName = 'general'
  }

  componentDidMount = async () => {
    this.setState({ 
      loading: true 
    })

    if (this.props.chatRoom['hydra:description']) {
      this.setState({openPopup: true, text: `${this.props.chatRoom['hydra:description']} En cliquant sur accepter vous serez redirigé vers une page pour recharger vos crédits`, title: '⚠ - Une erreur c\' est produite'})
    } else {
      const token = this.props.user.specialist ? this.props.chatRoom.room.specialistToken : this.props.chatRoom.room.clientToken

      this.setState({ token: token })

      const client = await Chat.Client.create(token)
      this.client = client

      client.on("tokenAboutToExpire", async () => {
      const token = await this.getToken()
      client.updateToken(token)
      })
  
      client.on("tokenExpired", async () => {
        const token = await this.getToken()
        client.updateToken(token)
      })
      
      client.on("channelJoined", async (channel) => {
        // getting list of all messages since this is an existing channel
        const messages = await channel.getMessages()
        this.setState({ messages: messages.items || [] })
        this.scrollToBottom()
      })
    
      try {
        const channel = await client.getChannelByUniqueName(this.props.chatRoom.room.roomName)
        this.joinChannel(channel)
      } catch(err) {
        try {
          alert("Désolé, une erreur s'est produite, veuillez réessayer plus tard")
        } catch (e) {
          throw new Error("Unable to create channel, please reload this page : ", e)
        }
      } 
    }

  }

  joinChannel = async (channel) => {
    this.channelName = channel
    if (channel.channelState.status !== "joined") {
     await channel.join()
   }
   this.setOpen(false)
   this.setState({ 
       channel:channel, 
       loading: false 
   })
   if (this.props.chatRoom.isFromUpdate) {
     this.setState({member: true})
   }
   channel.on("messageAdded", this.handleMessageAdded)
   
   // Listen for members joining a channel
   channel.on('memberJoined', this.activeChannel);
   // Listen for members user info changing
   channel.on('memberInfoUpdated', function(member) {
     console.log(member.identity + 'updated their info.');
   });
   // Listen for members leaving a channel
   channel.on('memberLeft', this.closeChannel);
   // Listen for members typing
   channel.on('typingStarted', this.setIsTyping);
   // Listen for members typing
   channel.on('typingEnded', this.removeIsTyping);
   this.scrollToBottom()
 }

  setIsTyping = (member) => {
    this.setState({ isTyping: member.identity})
  }

  removeIsTyping = () => {
    this.setState({ isTyping: false})
  }

 activeChannel = (member) =>  {
    this.setState({member: true})
    this.setState({memberEntered: member.identity})
    this.setState({memberOut: false})
    console.log(member.identity + 'has joined the channel.');
  }

  closeChannel = (member) => {
    this.setState({memberOut: member.identity})
    console.log(member.identity + 'has left the channel.');
  }

  handleMessageAdded = (message) => {
    const { messages } = this.state
    this.state.isMinimize ? this.setState({
        notification: true
      })
    : this.setState({
      notification: false
    })

    this.setState({
        messages: [...messages, message],
      },
      this.scrollToBottom
    )
  }

  scrollToBottom = () => {
    const scrollHeight = this.scrollDiv.current && this.scrollDiv.current.scrollHeight
    const height = this.scrollDiv.current && this.scrollDiv.current.clientHeight
    const maxScrollTop = scrollHeight - height
    if (this.scrollDiv.current) this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
  }

  sendMessage = () => {
    const { text, channel } = this.state
    if (text) {
      this.setState({ loading: true })
      channel.sendMessage(String(text).trim())
      this.setState({ text: "", loading: false })
    }
  }

  logOut = event => {
    event && event.preventDefault() 
    if (this.channelName.leave) {
      this.channelName.removeListener('messageAdded', this.handleMessageAdded)
      this.channelName.removeListener('memberJoined', this.activeChannel)
      this.channelName.removeListener('memberLeft', this.closeChannel)
      this.channelName.removeListener('typingStarted', this.setIsTyping)
      this.channelName.removeListener('typingEnded', this.removeIsTyping)
      this.channelName.leave()
    }
    this.setState({
      close: true,
      token: '',
      chatReady: false,
      messages: [],
      newMessage: '',
      memberEntered: ''
    })
    this.props.setChatRoom(false)
  }

  getToken = async () => {
    const response = await apiFetch(`/twilio/refresh-token`)
    return response.token
  }

  hide = () => {
    this.setState({
      isMinimize : !this.state.isMinimize,
    })
  }

  toggleExpend = () => {
    this.setState({
      isFullScreen : !this.state.isFullScreen,
    })
  }

  handleClose = () => {
    if (this.state.title) {
      this.logOut()
      this.props.setChatRoom(false)
    }
    this.setState({openPopup: false})
  }

  handleNext = async (e) => {
    if (this.state.title) {
      window.location = "/payment"
    } else {
      if (this.props.chatRoom && this.props.chatRoom.id) {
        await apiFetch(`/twilio/chat/${this.props.chatRoom.id}/close`)
      }
      this.logOut(e)
      this.setState({openPopup: false})
    }
  }

  handleKeyDown = (e) => {
    this.channelName.typing()
  }

  render() {
    const { text, messages, channel, close, isFullScreen, isMinimize, member } = this.state
    const firstName = this.props.chatRoom.isFromUpdate ? this.props.chatRoom && this.props.chatRoom.roomIdentity : this.props.chatRoom && this.props.chatRoom.type ? this.props.chatRoom && this.props.chatRoom.client.roomIdentity : this.props.chatRoom && this.props.chatRoom.specialist.roomIdentity
    const specialistName = this.props.user.firstName
    
    return (
      <>
      {!close && <><Badge color="secondary">
        <Paper style={isFullScreen && !isMinimize ? styles.fullScreenTopPaper : isMinimize ? styles.minimizeTopPaper : this.props.matches ? mobileStyles.topPaper : styles.topPaper}>
        <CloseIcon style={{right: 0, color: 'white' , paddingBottom: 3, cursor: 'pointer'}} onClick={() => this.setState({openPopup: true})} />
        {isFullScreen && !this.props.matches ?  <FullscreenExitIcon style={{right: 0, color: 'white' , paddingBottom: 3, cursor: 'pointer'}} onClick={this.toggleExpend} /> : !this.props.matches && <FullscreenIcon style={{right: 0, color: 'white' , paddingBottom: 3, cursor: 'pointer'}} onClick={this.toggleExpend} />}
        {isMinimize && !this.props.matches ? <MaximizeIcon style={{right: 0, color: 'white' , paddingBottom: 3, cursor: 'pointer'}} onClick={this.hide} /> : !this.props.matches && <MinimizeIcon style={{right: 0, color: 'white' , paddingBottom: 3, cursor: 'pointer'}} onClick={this.hide} />}
      </Paper></Badge>
      <Paper style={this.props.matches ? mobileStyles.paper : isFullScreen && !isMinimize ? styles.fullScreenPaper : isMinimize ? styles.minimizePaper : styles.paper}>
      <Container style={{padding: 0}}>
  
        <Grid style={styles.mainGrid}>
          {this.props.user.client && <Typography variant="caption" display="block" gutterBottom style={{textAlign: 'center'}}>
          Votre conversation ne sera pas conservée par notre système. Vos échanges sont protégés et confidentiels.<br />
                    {this.state.memberEntered ? `${this.state.memberEntered.split('_')[0]} a rejoint le salon à ${moment().format('LT')}` : !this.props.chatRoom.isFromUpdate && 'Veuillez patienter le spécialiste va rejoindre le salon - ' + moment().format('LT')}
          </Typography>}
          <Grid item style={isFullScreen ? hasRoles() === 'client' ? styles.gridFullScreenClientItemChatList : styles.gridFullScreenItemChatList : hasRoles() === 'client' ? styles.gridItemClientChatList : styles.gridItemChatList} ref={this.scrollDiv}>
            <List dense={true}>
                {messages &&
                  messages.map((message) => 
                    <ChatItem
                      key={message.index}
                      message={message}
                      firstName={firstName}
                      specialistName={specialistName}/>
                  )}
            </List>
          </Grid>
          {this.state.memberOut && <Typography variant="caption" display="block" gutterBottom style={{textAlign: 'center', color: 'red'}}>
                    {`${this.state.memberOut.split('_')[0]} est parti à ${moment().format('LT')}`}
          </Typography>}
          <Grid item style={this.props.matches ? mobileStyles.gridItemMessage : isFullScreen && !isMinimize ? styles.fullScreenGridItemMessage : isMinimize ? styles.minimizeGridItemMessage : styles.gridItemMessage}>
            {member || this.props.user.specialist ? <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item style={styles.textFieldContainer}>
                <Typography variant="caption" display="block" gutterBottom>
                    {this.state.isTyping && `${this.state.isTyping.split('_')[0]} est en train d'écrire...`}
                </Typography>
                <TextField
                  required
                  style={ styles.textField}
                  placeholder="Entrer un message"
                  variant="outlined"
                  multiline
                  rows={2}
                  value={text}
                  disabled={!channel || !!this.state.memberOut}
                  onKeyDown={this.handleKeyDown}
                  onChange={(event) =>
                    this.setState({ text: event.target.value })
                  }/>
              </Grid> 
              
              <Grid item>
                <IconButton
                  style={this.matches ? mobileStyles.sendButton : styles.sendButton}
                  onClick={this.sendMessage}
                  disabled={!channel}>
                  <Send style={styles.sendIcon} />
                </IconButton>
              </Grid>
            </Grid> : <LinearProgress />}
          </Grid>
        </Grid>
      </Container>
      </Paper></>}
      <ConsultPopup open={this.state.openPopup} handleClose={this.handleClose} handleNext={this.handleNext} text={text || 'Attention, si vous fermez la discussion vous ne pourrez plus avoir accès à celle-ci !'} title={this.state.title || 'Voulez vous vraiment fermer la discussion ?'} />
      </>
    )
  }
}

const styles = {
  topPaper: {position: "fixed", maxWidth: 550, minWidth: 550, minHeight: 25, maxHeight: 30, bottom: 545, right: 30, background: '#05057c'},
  fullScreenTopPaper: {position: "fixed", maxWidth: '100%', minWidth: '100%', minHeight: 25, maxHeight: 30, top: 64, left: 72, background: '#05057c'},
  minimizeTopPaper: {position: "fixed", maxWidth: 200, minWidth: 200, minHeight: 25, maxHeight: 30, bottom: 0, right: 50, background: '#05057c', marginTop: 5, paddingTop: 5},
  paper: {position: "fixed", right: 30, bottom: 0, maxWidth: 550, minWidth: 550, minHeight: 550, maxHeight: 550, overflowY: 'hidden', 
  overflowX: 'hidden',},
  fullScreenPaper: {top: 89, position: "fixed", right: 0, bottom: 0, maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%', overflowY: 'hidden', 
  overflowX: 'hidden',},
  minimizePaper: {display: 'none'},
  textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
  textFieldContainer: { flex: 1, marginRight: 12 },
  gridItem: { paddingTop: 12, paddingBottom: 12 },
  gridItemClientChatList: { overflow: "auto", height: "390px", textAlign: 'center', verticalAlign: 'middle' },
  gridItemChatList: { overflow: "auto", height: "415px", textAlign: 'center', verticalAlign: 'middle' },
  gridFullScreenItemChatList: { overflow: "auto", height: "790px", textAlign: 'center', verticalAlign: 'middle' },
  gridFullScreenClientItemChatList: { overflow: "auto", height: "740px", textAlign: 'center', verticalAlign: 'middle' },
  gridItemMessage: { marginTop: 12, marginBottom: 12, position: "fixed", bottom: 0, right: 30, width: 535 },
  fullScreenGridItemMessage: { marginTop: 12, marginBottom: 12, position: "fixed", bottom: 0, right: 30, width: '90%' },
  minimizeGridItemMessage: { display: 'none' },
  sendButton: { backgroundColor: "#3f51b5", marginRight: 12 },
  sendIcon: { color: "white" },
  mainGrid: { borderWidth: 1 },
}

const mobileStyles = {
  topPaper: {position: "fixed", minHeight: 25, maxHeight: 25, top: 64, left: 7, background: '#05057c', zIndex: 9999},
  paper: {position: "fixed", right: 0, bottom: 0, maxWidth: '100%', minWidth: '100%', height: '94%', overflowY: 'hidden', 
  overflowX: 'hidden',},
  gridItemMessage: { marginTop: 12, marginBottom: 12, position: "fixed", bottom: 0, right: 30, width: 300 },
  sendButton: { backgroundColor: "#3f51b5" },
}

export default ChatApp
