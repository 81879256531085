import React from 'react'
import NotificationCard from './NotificationCard';
import LastConsultCard from './LastConsultCard';
import NewsCard from './NewsCard';
import VideoCard from './VideoCard';
import {  Typography, Grid } from '@material-ui/core';
import SocialCard from './SocialCard';

export default function SpecialistDashboard({ matches, classes, consult, open, lower1825, user }) {

    return (
            <Grid container spacing={matches ? 0 : 6} className={classes.mainGrid}>
                <Grid item xs={matches ? 12 : 6}>
                    <Grid container spacing={matches ? 0 : 3}>
                        <Grid item xs={12}>
                            <NotificationCard isSpecialist={true} classes={classes} next={consult.futureInterventions} />
                        </Grid>
                    </Grid>
                    <LastConsultCard isSpecialist={true} user={user} classes={classes} last={consult.requests} />
                    <div className={classes.mainVideoCard}>
                        <Typography variant="h5" gutterBottom style={{marginBottom: 60}} >Les vidéos Geo-Psy</Typography>
                        <Grid container spacing={matches ? 0 : 3}  style={{marginBottom: 60}}>
                            <Grid item xs={lower1825 ? 12 : 6}>
                                <Typography variant="h5" gutterBottom style={{font: 'normal normal bold 16px/18px Arial', textTransform: 'uppercase'}} >Olivier Delacroix, ambassadeur du nouveau Geo-Psy</Typography><br />
                                <VideoCard matches={matches} classes={classes} url='https://www.youtube.com/embed/nrcJh3aEfMw' open={open} />
                            </Grid>
                            <Grid item xs={lower1825 ? 12 : 6}>
                                <Typography variant="h5" gutterBottom style={{font: 'normal normal bold 16px/18px Arial', textTransform: 'uppercase'}} >Olivier Delacroix raconte - La démocratisation du soutien psychologique</Typography><br />
                                <VideoCard matches={matches} classes={classes} url='https://www.youtube.com/embed/LF-_6j8L7fo' open={open}/>
                            </Grid>
                        </Grid>
                    </div>
                    <SocialCard classes={classes} matches={matches} />
                </Grid>
                <Grid item xs={matches ? 12 : 6} >
                    {consult && <NewsCard consult={consult} matches={matches} classes={classes} />}
                </Grid>
            </Grid>
        )
}
