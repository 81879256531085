import React, { useState, useRef, useEffect } from "react"
import CustomTuiCalendar from "./PlanningItem/CustomTuiCalendar"
import CustomTuiModal from "./PlanningItem/CustomTuiModal"
import { Typography, CircularProgress } from '@material-ui/core'
import { apiFetch } from "../service/apiFetch"
import { useHistory, useParams } from "react-router-dom"
import { TransitionLeft } from "../helpers/utils"
import { deleteAvailability, fetchAvailability } from "../service/apiPlanningFetch"
import hasRoles from "../service/security/hasRoles"
import moment from 'moment'

const attendees = [
  {
    id: "1",
    name: "Chin"
  },
  { id: "2", name: "Khanh" },
  { id: "3", name: "Linh" }
]

const allDifferentCalendars = {
  Cabinet: {
    id: "1",
    name: "Cabinet",
    color: "#ffffff",
    bgColor: "#4991cc",
    dragBgColor: "#4991cc",
    borderColor: "#79B0E0"
  },
  Plateforme: {
    id: "2",
    name: "Ligne d'écoute",
    color: "#ffffff",
    bgColor: "#05057C",
    dragBgColor: "#05057C",
    borderColor: "#000054"
  },
  Intervention: {
    id: "3",
    name: "À domicile",
    color: "#ffffff",
    bgColor: "#D1DCEE",
    dragBgColor: "#D1DCEE",
    borderColor: "#aec4e8"
  },
  Visio: {
    id: "4",
    name: "Visio",
    color: "#ffffff",
    bgColor: "#79E0DB",
    dragBgColor: "#79E0DB",
    borderColor: "#31c4bc"
  },
}

export default function Planning({ setTransition, setIsUpdate, setTextAlert, setSeverity, matches, menuOpen, setVisioId }) {
  const [modal, setModal] = useState(false)
  const [event, setEvent] = useState(null)
  const [startDate, setStartDate] = useState(false)
  const [endDate, setEndDate] = useState(false)
  const [consultationType, setConsultationType] = useState('')
  const [allShedules, setAllSchedules] = useState(false)
  const [error, setError] = useState({})
  const [specialist, setSpecialist] = useState(false)
  const [specialists, setSpecialists] = useState(false)
  const [defaultProps, setDefaultProps] = useState(false)
  const [sessionType, setSessionType] = useState('')
  const [repetition, setRepetition] = useState('')
  const [repetitionEndDate, setRepetitionEndDate] = useState('')
  const [selectedSpecialist, setSelectedSpecialist] = useState(null)
  const [ownBookedSchedule, setOwnBookedSchedule] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [editRepetition, setEditRepetition] = useState('only')
  const [filterSchedules, setFilterSchedules] = useState(false);
  const [noForfait, setNoForfait] = useState(false);
  const [calendars, setCalendars] = useState([{
    id: "5",
    name: "Créneaux réservés",
    color: "#ffffff",
    bgColor: "#ffca7f",
    dragBgColor: "#ffca7f",
    borderColor: "#ed961c"
  },]);
  const childRef = useRef()
  const { id } = useParams()
  let history = useHistory()

  const toggle = () => {
    setConsultationType('')
    setEditRepetition('only')
    setOwnBookedSchedule(false)
    setModal(!modal)
  }

  function _findConsultationTypeName(consultationType) {
    switch(consultationType) {
        case '/consultation_types/1':
            return 'Cabinet'
        case '/consultation_types/2':
            return 'Intervention'
        case '/consultation_types/3':
            return 'Visio'
        case "/consultation_types/4":
            return 'Plateforme'
        default:
            return ''
    }
  }

  function onBeforeCreateSchedule(event) {
    if (specialist) {
      setStartDate(new Date(event.start))
      setEndDate(new Date(event.end))
      setModal(true)
      setEvent(event)
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert('Vous devez d\'abord sélectionner un spécialiste pour créer une nouvelle disponibilité !')
      setIsUpdate(true)
    }
    event.guide.clearGuideElement()
  }

  async function handleCreateSchedule() {
    setError({})
    if ((!consultationType && !ownBookedSchedule) || !startDate || (!endDate && !ownBookedSchedule) || (consultationType === 'Cabinet' && !sessionType) || (ownBookedSchedule && !firstName) || (ownBookedSchedule && !lastName))
        return setError({consultationType: !consultationType, startDate: !startDate, endDate: !endDate, sessionType: consultationType === 'Cabinet' && !sessionType, firstName: ownBookedSchedule && !firstName, lastName: ownBookedSchedule && !lastName})
    
    let newAvailability = null
    if (!repetition) {
      if (ownBookedSchedule) {
        newAvailability = await fetchAvailability({ route: '/availabilities/fiche/clients', startDate, endDate, consultationType: '/consultation_types/1', sessionType, specialist, ficheClient: {firstName, lastName} })
      } else {
        newAvailability = await fetchAvailability({ startDate, endDate, consultationType, sessionType, specialist })
      }
    } else {
      if (!repetitionEndDate) return setError({repetition: true})
      if (ownBookedSchedule) {
        newAvailability = await fetchAvailability({ route: `/availabilities/create/repetition/${repetition}`, startDate, endDate, consultationType: '/consultation_types/1', sessionType, specialist, repetition, repetitionEndDate, ficheClient: {firstName, lastName} })
      } else {
        newAvailability = await fetchAvailability({ route: `/availabilities/create/repetition/${repetition}`, startDate, endDate, consultationType, sessionType, specialist, repetition, repetitionEndDate })
      }
    }
    
    if (newAvailability && newAvailability['@id']) {
      const schedule = formatSchedule(newAvailability)
      childRef.current.createSchedule(schedule)
      setModal(false)
      setOwnBookedSchedule(false)
    } else if (Array.isArray(newAvailability) && !newAvailability[0].message) {
      newAvailability.map(availability => childRef.current.createSchedule(formatSchedule(availability)))
      setModal(false)
      setOwnBookedSchedule(false)
      setRepetition('')
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert(newAvailability ? newAvailability[0] ? newAvailability[0].message : newAvailability['hydra:description'] : 'Erreur lors de la création, veuillez reessayer !')
      setIsUpdate(true)
    }
  }

  async function onBeforeUpdateSchedule(event) {
    if (specialist && event.schedule.bgColor !== "#d32626" && event.schedule.bgColor !== "#ffca7f") {
      if (event.target) {
        setConsultationType(event.schedule.body.consultationType.name || _findConsultationTypeName(event.schedule.body.consultationType))
        setSessionType(event.schedule.body.typeSeance)
        setStartDate(new Date(event.schedule.start))
        setEndDate(new Date(event.schedule.end))
        setModal(true)
        setEvent(event)
      } else {
        childRef.current.updateSchedule(event.schedule, event.changes)
        const updateAvailability = await fetchAvailability({ route: `/availabilities/${event.schedule.id}`, startDate: event.start._date, endDate: event.end._date, onTheFly: true, isUpdate: true })
        
        if (updateAvailability && updateAvailability['@id']) {
          const schedule = formatSchedule(updateAvailability)
          childRef.current.updateSchedule(event.schedule, schedule)
        } else {
          setTransition(() => TransitionLeft)
          setSeverity('error')
          setTextAlert(updateAvailability ? updateAvailability['hydra:description'] : 'Erreur lors de la modification, veuillez reessayer !')
          setIsUpdate(true)
        }
      }
    } else {
      if (event.schedule.bgColor !== "#d32626" && event.schedule.bgColor !== "#ffca7f") {
        setTransition(() => TransitionLeft)
        setSeverity('error')
        setTextAlert('Vous devez d\'abord sélectionner un spécialiste pour modifier une disponibilité !')
        setIsUpdate(true)
      } else {
        setTransition(() => TransitionLeft)
        setSeverity('error')
        setTextAlert('Vous ne pouvez pas modifier ce type de RDV')
        setIsUpdate(true)
      }
    }
  }

  async function handleUpdateSchedule() {
    let updateAvailability = null
    if (editRepetition === 'only') {
      updateAvailability = await fetchAvailability({ route: `/availabilities/${event.schedule.id}`, startDate, endDate, consultationType, specialist, sessionType, isUpdate: true })
    } else {
      updateAvailability = await fetchAvailability({ route: `/availabilities/update/repetition`, startDate, endDate, consultationType, specialist, sessionType, isUpdate: true, editRepetition, id: event.schedule.id })
    }

    if (updateAvailability && updateAvailability['@id']) {
      const schedule = formatSchedule(updateAvailability)
      childRef.current.updateSchedule(event.schedule, schedule)
      setModal(false)
    } else if (Array.isArray(updateAvailability)) {
      updateAvailability.map((availability) => {
        availability.calendarId = _findRightData(availability.title).calendarId
        return childRef.current.updateSchedule(filterSchedules.find((schedule) => schedule.id === availability.id), formatSchedule(availability))
      })
      setModal(false)
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert(updateAvailability ? updateAvailability.message || updateAvailability['hydra:description'] : 'Erreur lors de la modification, veuillez reessayer !')
      setIsUpdate(true)
    }
    setEditRepetition('only')
  }

  async function onBeforeDeleteSchedule(event) {
    if (event.schedule.calendarId === '5') {
      return setVisioId(event.schedule.body.slots[0].intervention.id)
    }
    if (specialist) {
      if (event.schedule.body.groupHash) {
        setEvent(event)
        setConsultationType(event.schedule.body.consultationType.name || _findConsultationTypeName(event.schedule.body.consultationType))
        setSessionType(event.schedule.body.typeSeance)
        setStartDate(new Date(event.schedule.start))
        setEndDate(new Date(event.schedule.end))
        setModal(true)
      } else {
        const deletedAvailability = await deleteAvailability(event.schedule.id)
        if (deletedAvailability && !deletedAvailability['hydra:description']) {
          const { schedule } = event
          childRef.current.deleteSchedule(schedule)
        } else {
          setTransition(() => TransitionLeft)
          setSeverity('error')
          setTextAlert(deletedAvailability ? deletedAvailability['hydra:description'] : 'Erreur lors de la suppression, veuillez reessayer !')
          setIsUpdate(true)
        }
      }
    }
    return true
  }

  async function handleDeleteSchedule() {
    let deletedAvailability = null
    if (editRepetition === 'only') {
      deletedAvailability = await deleteAvailability(event.schedule.id)
    } else {
      deletedAvailability = await deleteAvailability(null, '/availabilities/delete/repetition', event.schedule.id, editRepetition)
    }

    if (deletedAvailability.status === 'success') {
      deletedAvailability.id.map((deletedId) => {
        const filterSchedule = filterSchedules.find((schedule) => schedule.id === deletedId)
        return childRef.current.deleteSchedule(filterSchedule)
      })
      setModal(false)
    } else if (deletedAvailability && !deletedAvailability['hydra:description']) {
      const { schedule } = event
      childRef.current.deleteSchedule(schedule)
      setModal(false)
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert(deletedAvailability ? deletedAvailability['hydra:description'] : 'Erreur lors de la suppression, veuillez reessayer !')
      setIsUpdate(true)
    }
  }

  async function initializeAdminView() {
    setCalendars(oldArray => [...oldArray, allDifferentCalendars.Cabinet, allDifferentCalendars.Visio, allDifferentCalendars.Plateforme, allDifferentCalendars.Intervention])
    const specialists = await apiFetch('/specialists/light?pagination=false')
    const allAvailabilites = await apiFetch(`/admin/availabilities?startDate[strictly_after]=${moment().format('YYYY-MM-DD')}&pagination=false`)
    if (specialists && specialists['hydra:member'] && allAvailabilites && allAvailabilites['hydra:member']) {
      const tab = []
      setSpecialists(specialists['hydra:member'])
      setDefaultProps({options: specialists['hydra:member'], getOptionLabel: (option) => `${option.user.firstName} ${option.user.lastName}`})
      allAvailabilites['hydra:member'].map((availability) => {return tab.push(formatSchedule(availability, true))})
      setAllSchedules(tab)
      setFilterSchedules(tab)
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert('Erreur lors de récupération des données, veuillez reessayer')
      setIsUpdate(true)
      history.goBack()
    }
  }

  async function initializeSpecialistView() {
    const allShedulesFetch = await apiFetch(`/availabilities?pagination=false`)
    if (allShedulesFetch && allShedulesFetch['hydra:member']) {
      const tab = []
      allShedulesFetch['hydra:member'].map((availability) => {return tab.push(formatSchedule(availability))})
      setAllSchedules(tab)
      setFilterSchedules(tab)
    }
    const specialistFetch = await apiFetch(`/specialists/${id}`)
    if (specialistFetch && specialistFetch.id) {
      specialistFetch.readableConsultationType.map((consultationType) => {
        return setCalendars(oldArray => [...oldArray, allDifferentCalendars[consultationType.name]])
      })
      setSpecialist(specialistFetch)
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert('Erreur lors de récupération des données, veuillez reessayer')
      setIsUpdate(true)
      history.goBack()
    }
  }

  useEffect(() => {
    (async () => {
      if (hasRoles() === 'super_admin') {
        await initializeAdminView()
      } else {
        await initializeSpecialistView()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function formatSchedule(data, isAdmin, hasSelectedSpecialist) {
    const hasOwnBookedSlots = isAdmin || hasSelectedSpecialist ? data.bookedSlots && data.bookedSlots.statut === 'Booked' && data.bookedSlots.ficheClient
    : data.slots && data.slots[0] && data.slots[0].statut === 'Booked' && data.slots[0].ficheClient
    const hasBookedSlots = isAdmin || hasSelectedSpecialist ? data.bookedSlots && data.bookedSlots.statut === 'Booked' && data.bookedSlots.client
    : data.slots && data.slots[0] && data.slots[0].statut === 'Booked' && data.slots[0].client
    const isIntervention = data.slots && data.slots[0] && data.slots[0].statut === 'Booked' && data.slots[0].intervention

    return {
        id: data.id ? data.id : parseInt(data['@id'].replace( /^\D+/g, '')),
        title: hasBookedSlots ? `Réservé avec ${hasBookedSlots.user.firstName} en ${data.title}` : hasOwnBookedSlots ? `Réservé avec ${hasOwnBookedSlots.firstName} en cabinet` : isAdmin ? `${data.title} / ${data.specialist.user.firstName}` : data.title,
        calendarId: hasOwnBookedSlots ? '6' : hasBookedSlots ? '5' :_findRightData(data.title).calendarId,
        category: "time",
        attendees: _formatAttendees(hasBookedSlots, hasOwnBookedSlots, isAdmin, data),
        start: data.startDate,
        end: data.endDate,
        body: data,
        color: "#ffffff",
        bgColor: hasBookedSlots ? '#ffca7f' : hasOwnBookedSlots ? '#d32626' : _findRightData(data.title).color,
        dragBgColor: hasBookedSlots ? '#ffca7f' : hasOwnBookedSlots ? '#d32626' : _findRightData(data.title).color,
        borderColor: hasOwnBookedSlots && "#aa0808",
        recurrenceRule: !!data.groupHash && 'Événement répété',
        location: isIntervention && isIntervention.address && `${isIntervention.address}, ${isIntervention.postalCode} ${isIntervention.city}`,
        state: data.title === 'Cabinet' && data.typeSeance,
    }
  }

  function _formatAttendees(hasBookedSlots, hasOwnBookedSlots, isAdmin, data) {
    const hasAttendees = 
    isAdmin && hasBookedSlots ? [`${data.specialist.user.firstName} ${data.specialist.user.lastName}`, `${hasBookedSlots.user.firstName} ${hasBookedSlots.user.lastName}`] : 
    isAdmin && hasOwnBookedSlots ? [`${data.specialist.user.firstName} ${data.specialist.user.lastName}`, `${hasOwnBookedSlots.firstName} ${hasOwnBookedSlots.lastName}`] : 
    hasBookedSlots ? [`${hasBookedSlots.user.firstName} ${hasBookedSlots.user.lastName}`] : 
    hasOwnBookedSlots ? [`${hasOwnBookedSlots.firstName} ${hasOwnBookedSlots.lastName}`] : 
    isAdmin ? [`${data.specialist.user.firstName} ${data.specialist.user.lastName}`] : 
    []
    
    return hasAttendees
  }
  
  function _findRightData(title) {
    if (title === 'Cabinet') {
      return {calendarId: '1', color: '#4991cc'}
    } else if (title === "Ligne d'écoute") {
      return {calendarId: '2', color: '#05057C'}
    } else if (title === 'Visio') {
      return {calendarId: '4', color: '#79E0DB'}
    }
    return {calendarId: '3', color: '#D1DCEE'}
  }

  function handleOpenModal() {
    if (!specialist.specialistHasConsultationsType.find((consultationType) => consultationType.consultationType.name === 'Cabinet').forfaits.length) {
      setNoForfait(true)
    }
    setOwnBookedSchedule(true)
    setModal(true)
  }

  async function handleChangeSpecialist(e, value) {
    setAllSchedules(false)
    setFilterSchedules(false)
    setCalendars([{
      id: "5",
      name: "Créneaux réservés",
      color: "#ffffff",
      bgColor: "#ffca7f",
      dragBgColor: "#ffca7f",
      borderColor: "#ed961c"
    }])

    if (!value) {
      setSpecialist(false)
      setSelectedSpecialist(null)
      return await initializeAdminView()
    }

    const specialistFetch = await apiFetch(`/specialists/${value.id}`)
    const tab = []
    if (specialistFetch && specialistFetch.id) {
      specialistFetch.readableConsultationType.map((consultationType) => {
        return setCalendars(oldArray => [...oldArray, allDifferentCalendars[consultationType.name]])
      })
      setSpecialist(specialistFetch)
      specialistFetch.displayableAvailabilities.map((availability) => {return tab.push(formatSchedule(availability, false, true))})
      setAllSchedules(tab)
      setFilterSchedules(tab)
      setSelectedSpecialist(value)
    } else {
      setTransition(() => TransitionLeft)
      setSeverity('error')
      setTextAlert('Erreur lors de récupération des données, veuillez reessayer')
      setIsUpdate(true)
      history.goBack()
    }
  }

  return (
    <div>
      <Typography color='primary' variant='body1' style={{textAlign: 'center', margin: 10}}>Pour ajouter vos disponibilités, cliquez sur le planning et remplissez la plage horaires de votre choix.</Typography>
      {filterSchedules && allShedules && (specialist || specialists) ? <CustomTuiCalendar
        ref={childRef}
        {...{
          isReadOnly: false,
          showSlidebar: true,
          showMenu: true,
          useCreationPopup: false,
          calendars,
          schedules: allShedules,
          filterSchedules,
          setFilterSchedules,
          onBeforeCreateSchedule,
          onBeforeUpdateSchedule,
          onBeforeDeleteSchedule,
          specialist,
          toggle,
          handleOpenModal,
          specialists,
          defaultProps,
          handleChangeSpecialist,
          selectedSpecialist,
          matches,
          menuOpen,
        }}
      /> : <div style={{textAlign: 'center', marginTop: 50}}><CircularProgress /></div>}
      <CustomTuiModal
        {...{
          isOpen: modal,
          toggle,
          onSubmit:
            event?.triggerEventName === "mouseup"
              ? handleCreateSchedule
              : event?.deleteGroup ? 
              handleDeleteSchedule
              : event?.triggerEventName === "click" ? 
              handleUpdateSchedule
              : handleCreateSchedule,
          submitText: event?.triggerEventName === "mouseup" ? "Enregistrer" : event?.deleteGroup ? 'Supprimer' : event?.triggerEventName === "click" ? "Modifier" : 'Enregistrer',
          calendars,
          attendees,
          selectedSchedule: event && event.schedule,
          setStartDate,
          setEndDate,
          consultationType,
          setConsultationType,
          user: specialist,
          sessionType,
          setSessionType,
          error,
          repetition,
          setRepetition,
          repetitionEndDate,
          setRepetitionEndDate,
          editRepetition,
          setEditRepetition,
          startDate,
          endDate,
          ownBookedSchedule,
          firstName,
          setFirstName,
          lastName,
          setLastName,
          setNoForfait,
          noForfait,
        }}
      />
    </div>
  )
}
