import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"


export const FETCHING_CLIENTS = 'FETCHING_CLIENTS'
export const SET_CLIENTS = 'SET_CLIENTS'
export const ADD_CLIENTS = 'ADD_CLIENTS'
export const UPDATE_CLIENTS = 'UPDATE_CLIENTS'

export function reducer(state = [], action) {
    switch (action.type) {
        case FETCHING_CLIENTS:
            return { ...state, loading: true }
        case SET_CLIENTS:
            return {...state, clients: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_CLIENTS:
            return {...state, clients: action.payload}
        case UPDATE_CLIENTS:
            return {...state, client: action.payload }
        default:
            return state
    }
}

export function useClients() {
    const [state, dispatch] = useReducer(reducer, {    
        clients: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        clients: state.clients,
        totalClientItems: state.totalItems,
        clientView: state.view,
        fetchClients: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_CLIENTS' })
            const clients = await apiFetch(route || '/clients?order[user.lastName]=asc', {method: 'GET'})
            if (isSubscribed().current) {
                dispatch({type: 'SET_CLIENTS', payload: clients})
            }
        },
        createClients: async function(data, headers, forceResponse = false) {
            const newUser = await apiFetch('/clients', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            }, forceResponse)
            dispatch({type: 'ADD_CLIENTS', payload: newUser })
            return newUser
        },
        updateClient: async function(clientId, data, forceResponse = false) {
            const updatedClients = await apiFetch('/clients/' + clientId, {
                method: 'PUT',
                body: data,
            }, forceResponse)
            let newUser = {}

            if (!updatedClients.violations && !updatedClients['hydra:description']) {
                newUser = await apiFetch(`${updatedClients.user['@id']}`, { method: 'GET'})
            }
            dispatch({type: 'UPDATE_CLIENTS', payload: newUser, target: clientId })
            return updatedClients
        },
    }
}