import { LinearProgress, TextField, useTheme } from '@material-ui/core'
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { removeLastComma } from '../helpers/utils'

export default function AutoCompleteAddress({ disabled, setDisable, matches, className = {}, id, name, label, defaultValue = '', required, error, setNewAddress, setNewCoordonnate, searchOptions = {}, text = '', customStyle = false}) {
    const [address, setAddress] = React.useState(defaultValue)
    const theme = useTheme();

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value)
        if (searchOptions.types) {
            setAddress(removeLastComma(value))
            setNewAddress && setNewAddress(results[0].address_components[0].long_name)
        } else {
            setAddress(value)
            setNewAddress && setNewAddress(results)
        }
        setNewCoordonnate && setNewCoordonnate({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
    }

    const handleChange = (value) => {
        setDisable && setDisable(false)
        if (searchOptions.types) {
            setAddress(removeLastComma(value))
        } else {
            setAddress(value)
        }
        if (!value) {
            setNewCoordonnate && setNewCoordonnate(false)
            setNewAddress && setNewAddress(false)
        }
    }

    return (
        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} searchOptions={searchOptions}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <TextField {...getInputProps({disabled: disabled, label: label, error: error, className: className, id: id, name: name, helperText: text, required: required, autoComplete: 'off'})}/>
                    <div>
                        {loading ? <LinearProgress style={{maxWidth: customStyle ? '400px' : '450px', marginLeft: !matches && customStyle && '35.5%', textAlign: customStyle && 'left',}} /> : null}
                        {suggestions.map((suggestion, index) => {
                            const style = {
                                background: theme.palette.type === 'light' ? suggestion.active ? 'linear-gradient(45deg, #05057c 30%, #43aea9 90%)' : '#fff' : suggestion.active ? 'linear-gradient(45deg, #05057c 30%, #43aea9 90%)' : '#424242',
                                padding: '5px',
                                maxWidth: '400px',
                                marginLeft: !matches && customStyle && '35.5%',
                                textAlign: customStyle && 'left',
                                color: theme.palette.type === 'light' ? suggestion.active && 'white' : 'white',
                                borderRadius: '3px'
                            }
                            return <div {...getSuggestionItemProps(suggestion, { style })} key={index} >{removeLastComma(suggestion.description)}</div>
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}