import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import hasRoles from '../../service/security/hasRoles';

export default function Filter({specialists, defaultProps, handleSpecialistSearch}) {

    return (
        <div style={{marginLeft: 25, maxWidth: 400}}>
            {(hasRoles() === 'super_admin' || hasRoles() === 'admin') && specialists && defaultProps &&
                <Autocomplete
                    {...defaultProps}
                    id="specialist"
                    debug
                    onChange={handleSpecialistSearch}
                    renderInput={(params) => <TextField {...params} label="Filtrer par spécialiste" margin="normal" />}
                />
            }
        </div>
    )
}