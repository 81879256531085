import React from 'react'
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';
import { useStylesUploadItem } from '../../css/Style'

export default function UploadFile({ setPhoto, title }) {
    const classes = useStylesUploadItem();
    const [files, setFiles] = React.useState([]);
    const [fileUrl, setFileUrl] = React.useState(null);


    const handleFileChanged = e => {
      const inputFiles = document.querySelector("#inputFiles");
      const objectFiles = inputFiles.files;
      const files = Object.keys(objectFiles).map(index => {
          return objectFiles[index]
      });
      setFiles(files)
      setPhoto(files)
      const reader = new FileReader()
      reader.onload = e => {
          setFileUrl(e.target.result)
      }
      reader.readAsDataURL(e.target.files[0])
    };
    
    const handleDeleteFile = lastModified => _ => {
      const updatedFiles = files.filter(f => f.lastModified !== lastModified)
      setFiles(updatedFiles)
      setPhoto(updatedFiles)
    };

    return (
        <Grid container spacing={2} style={{marginLeft: '-7px'}}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => document.querySelector("#inputFiles").click()}
            disabled={files.length > 0}
            size="small"
          >
            <AddIcon/>
            {title || 'Changer d\'avatar'}
          </Button>
          <input
            type="file"
            id="inputFiles"
            style={{ visibility: "hidden" }}
            multiple
            onChange={handleFileChanged}
            accept="image/*"
          />
        </Grid>
        <Grid item xs={3} md={5}>
          <div>
            {files.length > 0 && <List>
              {files.map(f => (
                <ListItem key={f.lastModified}>
                  <ListItemAvatar>
                    <Avatar alt="Avatar" src={fileUrl} className={classes.large}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={f.name}
                    secondary={f.type}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleDeleteFile(f.lastModified)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>}
          </div>
        </Grid>
      </Grid>
    )
}