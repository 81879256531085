import { Button, Checkbox, Divider, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Paper, TextField, Tooltip, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import AutoCompleteAddress from '../../../components/AutoCompleteAddress'
import { apiFetch } from '../../../service/apiFetch'
import { formatAddress, TransitionLeft, findConsultationTypes, formatConsultationTypesBody } from '../../../helpers/utils'
import MultipleSelect from '../../../components/MultipleSelect'
import { handleFormError } from '../../../service/security/error'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Alert from '../../../components/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from '../../Error'
import { useStylesValidSpecialistProfile } from '../../../css/Style'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}))(Tooltip);

export function ValidSpecialistProfile() {
    let { id } = useParams();
    const classes = useStylesValidSpecialistProfile();
    let history = useHistory()
    const [disable, setDisable] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const [specialistFetch, setSpecialistFetch] = React.useState(false)
    const [newOfficeAddress, setOfficeNewAddress] = React.useState('')
    const [error, setError] = React.useState(false)
    const [checked, setChecked] = React.useState(false)
    const [languages, setLanguages] = React.useState('')
    const [consultationTypes, setConsultationTypes] = React.useState('')
    const [specialities, setSpecialites] = React.useState('')
    const [apiLanguages, setApiLanguages] = React.useState(null)
    const [apiConsultationTypes, setApiConsultationTypes] = React.useState(null)
    const [apiSpecialities, setApiSpecialities] = React.useState(null)
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [transition, setTransition] = React.useState(undefined)
    const [severity, setSeverity] = React.useState(undefined)
    const [text, setText] = React.useState('')
    const [matchPass, setMatchPass] = React.useState(false)
    const [newValues, setNewValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [newOfficeCoordonate, setNewOfficeCoordonnate] = React.useState({
        lat: null,
        lng: null
    })
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    React.useEffect(() => {
        apiFetch(`/validation/specialist/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/ld+json'
            }
        }, true)
        .then((res) => {
            if (res && res.id) {
                setSpecialistFetch(res)
                apiFetch("/specialities", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/ld+json'
                    }
                  }, true)
                  .then(res => {
                    return setApiSpecialities((res && res['hydra:member']) || []);
                  })
        
                apiFetch("/languages", {
                  method: "GET",
                  headers: {
                    'Content-Type': 'application/ld+json'
                }
                }, true)
                .then(res => {
                  return setApiLanguages((res && res['hydra:member']) || []);
                })
        
                apiFetch("/consultation_types", {
                  method: "GET",
                  headers: {
                    'Content-Type': 'application/ld+json'
                }
                }, true)
                .then(res => {
                  return setApiConsultationTypes(formatConsultationTypesNames(res && res['hydra:member']) || []);
                })
            } else {
                setSpecialistFetch(res)
            }
        })
    }, [id]);
    
    const handleSubmit = async (e) => {
        setDisable(true)
        setLoading(true)
        e.preventDefault()
        let data = new FormData(e.target)
        const newPass = data.get('password')
        const confirmPass = data.get('confirmPass')

        if (newPass === confirmPass) {
            setMatchPass(false)
        } else {
            return setMatchPass('Les deux mots de passe ne correspondent pas')
        }

        const dataObject =  {
            officeAddress : newOfficeAddress,
            password : data.get('confirmPass'),
            mobile : data.get('numPro'),
            phone : data.get('numFixPro'),
            languages : languages,
            specialistHasConsultationsType : consultationTypes,
            specialities : specialities,
            siret : data.get('siret'),
            description : data.get('description'),
            iban : data.get('iban').replace(/ /g,''),
            bic : data.get('bic'),
            enableTVA : checked,
            interventionDistance : findConsultationTypes(consultationTypes, '/consultation_types/2') && parseInt(data.get('intervention')),
            officeCoordonate : newOfficeCoordonate
        }
        
        const requestBody = JSON.stringify(handleBodyProperties(dataObject, specialistFetch))
        const specialist = await apiFetch(`/specialists/${specialistFetch.id}/validation/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/ld+json'
            },
            body: requestBody
        }, true)


        if (specialist.violations) {
            setText('Désolé, la modification n\' pas pu être pris en compte !')
            setSeverity('error')
            const errorField = handleFormError(specialist, ['user.plainPassword', 'user.mobile', 'description', 'siret', 'languages', 'specialistHasConsultationsType[0]', 'specialistHasConsultationsType', 'specialities', 'interventionDistance', 'adeli', 'iban', 'bic', 'enableTVA', 'user.phone'])
            setError(errorField)
        } else if (specialist['hydra:description']) {
            setText('Désolé, la modification n\' pas pu être pris en compte !')
            setSeverity('error')
            setIsUpdate(true)
            setTransition(() => TransitionLeft)
            setDisable(false)
        } else {
            setError({})
            setSeverity('success')
            setIsUpdate(true)
            setTransition(() => TransitionLeft)
            setText('Votre compte a été mis à jour, vous allez être redirigé !')
            setTimeout(() => {
                history.push('/')
                window.location.reload()
            }, 3000)
        }
        setLoading(false)
    }

    const handleChange = () => {
        setDisable(false)
    }

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleIbanChange = (e) => {
        if (e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 32 || e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 18 || (e.target && !e.target.value)) {
            return e.target.value
        }
        const cleanIban = (e.target && e.target.value)
        .replace(/\s\s+/g, ' ')
        .replace(/[^0-9a-zA-Z]/gi, '')
        .toUpperCase()
        const parts = []
        
        if (cleanIban.length > 0) {
          parts.push(cleanIban.substring(0, 4))
        }
      
        if (cleanIban.length > 4) {
          parts.push(cleanIban.substring(4, 8))
        }
      
        if (cleanIban.length > 8) {
          parts.push(cleanIban.substring(8, 12))
        }
      
        if (cleanIban.length > 12) {
          parts.push(cleanIban.substring(12, 16))
        }
      
        if (cleanIban.length > 16) {
          parts.push(cleanIban.substring(16, 20))
        }
      
        if (cleanIban.length > 20) {
          parts.push(cleanIban.substring(20, 24))
        }
    
        if (cleanIban.length > 24) {
          parts.push(cleanIban.substring(24, 26))
        }
      
        e.target.value = parts.join(' ')
    }

    const [ConfirmValues, setConfirmValues] = React.useState({
        password: '',
        showPassword: false,
    });
    
    const handleChangePass = (prop) => (event) => {
        setNewValues({ ...newValues, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setNewValues({ ...newValues, showPassword: !newValues.showPassword });
    };

    const handleConfirmPass = (prop) => (event) => {
        setConfirmValues({ ...ConfirmValues, [prop]: event.target.value });
    };

    const handleClickShowConfirmPassword = () => {
        setConfirmValues({ ...ConfirmValues, showPassword: !ConfirmValues.showPassword });
    };
  
    return (
        <main>
            {!specialistFetch && <div style={{marginTop: '50px', textAlign: 'center'}} ><CircularProgress /></div>}
            {apiLanguages && apiSpecialities && apiConsultationTypes && <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color='primary'>
                    Mon profil
                </Typography>
                <Divider style={{maxWidth: '1220px', marginBottom:'10px'}}/>
                <>
                <form autoComplete="off" onChange={ handleChange } onSubmit={ handleSubmit }>
                <Grid container spacing={3} style={{marginTop:'25px'}}>
                <Grid item xs={matches ? 12 : 6}>
                <HtmlTooltip
                        title={
                            <>
                                <Typography color="inherit">Votre mot de passe doit contenir au moins: </Typography>
                                <ul>
                                    <li><b>{"Une majuscule"}</b></li>
                                    <li><b>{"Une minuscule"}</b></li>
                                    <li><b>{"Un chiffre"}</b></li>
                                    <li><b>{"8 caractères minimum"}</b></li>
                                </ul>
                            </>
                        }
                    >
                        <FormControl className={classes.addressWidth}>
                            <InputLabel htmlFor="password" required error={!!error && !!error.plainPassword}>Nouveau mot de passe</InputLabel>
                            <Input
                                required
                                id="password"
                                name="password"
                                error={!!error && !!error.field}
                                type={newValues.showPassword ? 'text' : 'password'}
                                value={newValues.password}
                                onChange={handleChangePass('password')}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                    >
                                      {newValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                            />
                        </FormControl>
                    </HtmlTooltip>
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error && error.plainPassword}</FormHelperText>
                    <FormControl className={classes.addressWidth}>
                        <InputLabel style={{ overflow :'hidden', textOverflow : 'ellipsis', whiteSpace :'nowrap', right :'22px', bottom :'0px' }} htmlFor="password" required error={!!matchPass}>Confirmer votre mot de passe</InputLabel>
                        <Input
                            required
                            id="confirmPass"
                            name="confirmPass"
                            error={!!matchPass}
                            type={ConfirmValues.showPassword ? 'text' : 'password'}
                            value={ConfirmValues.password}
                            onChange={handleConfirmPass('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                >
                                  {ConfirmValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                          }
                        />
                    </FormControl>
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{matchPass}</FormHelperText>
                    <TextField error={!!error.mobile} InputLabelProps={{ style: {overflow :'hidden', textOverflow : 'ellipsis', whiteSpace :'nowrap', right :'22px', bottom :'0px',} }} helperText={error.mobile} className={classes.width} id="numPro" name="numPro" label="Numéro de portable professionnel" type="text" InputProps={{ inputProps: { max: 9999999999 }}} required />
                    <AutoCompleteAddress error={!!error.officeAddress} className={classes.addressWidth} id="addressFirm" name="addressFirm" label="Adresse du cabinet" setNewAddress={setOfficeNewAddress} setNewCoordonnate={setNewOfficeCoordonnate}/>
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error['specialistHasConsultationsType[0]']}</FormHelperText>
                    <TextField error={!!error.phone} helperText={error.phone} className={classes.width} id="numFixPro" name="numFixPro" label="Numéro de fixe professionnel" type="text" InputProps={{ inputProps: { max: 9999999999 }}} />
                    {findConsultationTypes(consultationTypes, '/consultation_types/2') && <TextField error={!!error.interventionDistance} InputLabelProps={{ style: {overflow :'hidden', textOverflow : 'ellipsis', whiteSpace :'nowrap', right :'22px', bottom :'0px',} }} InputProps={{ endAdornment: <InputAdornment position="end">Km</InputAdornment> }} helperText={error.interventionDistance} className={classes.width} id="intervention" name="intervention" type='number' label="Rayon possible d'intervention" />}
                    <Typography variant="subtitle1" component="p" style={{color: theme.palette.type === 'light' ? 'grey' : 'color'}}><br />
                        Assujetti à la TVA :
                        <Checkbox color="primary" checked={checked}
                        onChange={handleCheckBoxChange} />
                    </Typography><br />
                </Grid>
                <Grid item xs={matches ? 12 : 6}>
                    <TextField error={!!error.siret} helperText={error.siret} className={classes.width} id="siret" name="siret" type='number' label="Numéro de siret" required />
                    <MultipleSelect text={'Langue(s) *'} multipleOption={apiLanguages} setter={setLanguages} />
                    <FormHelperText style={{color: 'red'}}>{error.languages}</FormHelperText><br />
                    <MultipleSelect text={'Type(s) de consultation *'} multipleOption={apiConsultationTypes} setter={setConsultationTypes} />
                    <FormHelperText style={{color: 'red'}}>{error.specialistHasConsultationsType}</FormHelperText><br />
                    <MultipleSelect text={'Spécialitée(s) *'} multipleOption={apiSpecialities} setter={setSpecialites} /> <br />
                    <TextField error={!!error.iban} helperText={error.iban} className={classes.width} onKeyDown={handleIbanChange} id="iban" name="iban" label="IBAN" type='text' required/>
                    <TextField error={!!error.bic} helperText={error.bic} className={classes.width} id="bic" name="bic" label="BIC" type='text' required/><br />
                </Grid>     
                <Grid item xs={12} className={classes.marginRight}>        
                    <TextField rowsMax={12} multiline error={!!error.description} helperText={error.description} className={classes.maxWidth} id="description" name="description" type='text' label="Description" required /><br />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={disable}>
                            Enregistrer
                    </Button>
                </Grid>           
                </Grid>
                </form>
                {loading && <LinearProgress />}
                </>
            </Paper>}
            {specialistFetch && !specialistFetch.id && <Error />}
            <Alert isOpen={isUpdate} transition={transition} message={text || 'Votre profile a bien été modifié'} setIsUpdate={setIsUpdate} severity={severity} />
        </main>
    )
}

function handleBodyProperties(modifyInputs, profileUser) {
    const element = {user: {'@id': profileUser.user['@id']}, office: {}}
    for (const property in modifyInputs) {
        //TODO: create utils function to handle all specialist property
        if (property === 'adeli' || property === 'interventionDistance' || property === 'siret' || property === 'description' || property === 'iban' || property === 'bic' || property === 'enableTVA' | property === 'languages' || property === 'specialities') {
            element[property] = modifyInputs[property]
        } else if (property === 'officeAddress') {
            if (formatAddress(modifyInputs[property], modifyInputs.officeCoordonate)) {
                element.office.address = formatAddress(modifyInputs[property], modifyInputs.officeCoordonate)
            }
        } else if (property === 'specialistHasConsultationsType') {
            element[property] = formatConsultationTypesBody(modifyInputs[property])
        } else {
            if (modifyInputs[property] && !modifyInputs[property].lat) {
                element.user[property] = modifyInputs[property]
            }
        }
    }

    const propOwn = Object.getOwnPropertyNames(element.user)
    if (propOwn.length < 1) {
        delete element.user
    }
    const propOwnOffice = Object.getOwnPropertyNames(element.office)
    if (propOwnOffice.length < 1) {
        delete element.office
    }
    return element
}

function formatConsultationTypesNames(consultationTypes) {
    let array = []
    consultationTypes.map((consultationType) => {
        if (consultationType.name === 'Intervention') {
            array.push({'@id': consultationType['@id'], '@type': consultationType['@type'], id: consultationType.id, name: 'À domicile'})
        } else if (consultationType.name === 'Plateforme') {
            array.push({'@id': consultationType['@id'], '@type': consultationType['@type'], id: consultationType.id, name: 'Ligne d\'écoute'})
        } else {
            array.push(consultationType)
        }
        return null
    })
    return array
}
