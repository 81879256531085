import {Map, Marker, GoogleApiWrapper, InfoWindow, Circle} from 'google-maps-react';
import React from 'react'
import { Component } from 'react';
import * as mainConstant from '../../../Constant/mainConstant'
import { apiFetch } from '../../../service/apiFetch';

let temporaryUser = null
export class MapContainer extends Component {
    constructor(props) {
      super(props)
      this.state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        users: false
      };
      this.onMarkerClick = this.onMarkerClick.bind(this)
      this.onMapClicked = this.onMapClicked.bind(this)
    }

    componentDidMount = async () => {
      document.title = mainConstant.MAP
      const specialists = await apiFetch('/specialists?pagination=false')
      if (specialists['hydra:member']) {
        this.setState({users: specialists['hydra:member']})
        temporaryUser = specialists['hydra:member']
      }
    }

    onMarkerClick(props, marker, e) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
        users: [this.state.users.find((user) => user.user.firstName === props.name)]
      });
    }

    onMapClicked() {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null,
          users: temporaryUser,
        })
      }
    };

    render() {
      return (
        <Map 
            google={this.props.google} 
            zoom={6}
            style={this.props.matches ? {width: "90%"} : {width: '1600px', height: '700px'}}
            initialCenter={{
                lat: 48.8534,
                lng: 2.3488
            }}
            onClick={this.onMapClicked}
        >
            {this.state.users && this.state.users.map((user, index) => (
                <Marker onClick={this.onMarkerClick} name={user.user.firstName} title={`${user.user.firstName} ${user.user.lastName}`} key={index} position={{lat: user.user.address.latitude, lng: user.user.address.longitude}} />
            ))}
            {this.state.users && this.state.users.map((user, index) => (
                <Circle
                  key={index}
                  radius={user.interventionDistance * 1000}
                  center={{lat: parseFloat(user.user.address.latitude), lng: parseFloat(user.user.address.longitude)}}
                  strokeColor='transparent'
                  strokeOpacity={0}
                  strokeWeight={5}
                  fillColor='#FF0000'
                  fillOpacity={0.2}
                />
            ))}
            <InfoWindow
              onClose={this.onMapClicked}
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
            >
                <div style={{maxHeight: 40, maxWidth: 50, fontSize: 12}}>
                  <p>{this.state.selectedPlace.title}</p>
                </div>
            </InfoWindow>
        </Map>
      );
    }
  }
   
  export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  })(MapContainer)