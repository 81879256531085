import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ConsultTable from './ConsultTable'
import { useConsult } from '../../hooks/consult';
import { useIntervention } from '../../hooks/intervention';
import hasRoles from '../../service/security/hasRoles';
import { useCabinet } from '../../hooks/cabinet';
import { useVisio } from '../../hooks/visio';
import { useStylesConsultTab } from '../../css/Style';
import Filter from './Filter';
import { apiFetch } from '../../service/apiFetch';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function ConsultTab({ userData, setVisioId }) {
    const classes = useStylesConsultTab();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true)
    const [specialists, setSpecialists] = React.useState(false)
    const [defaultProps, setDefaultProps] = React.useState(false)
    const [headCells, setHeadCells] = React.useState(hasRoles() === 'super_admin' ? [
      { id: 'firstName', numeric: false, disablePadding: true, label: 'Client' },
      { id: 'lastName', numeric: false, disablePadding: true, label: 'Spécialiste' },
      { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
      { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
      { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
  ]: hasRoles() === 'specialist' ? [
        { id: 'name', numeric: false, disablePadding: true, label: 'Patient' },
        { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
        { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
        { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
        { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
    ] : [
      { id: 'name', numeric: false, disablePadding: true, label: 'Spécialiste' },
      { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
      { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
      { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
      { id: 'action', numeric: true, disablePadding: false, label: 'Facture' },
  ]
    );

    const {
        consults,
        consultView,
        totalConsultItems,
        fetchConsults,
    } = useConsult()

    const {
        cabinet,
        officeView,
        totalOfficeItems,
        fetchCabinets,
    } = useCabinet()

    const {
      visio,
      visioView,
      totalVisioItems,
      fetchVisios,
    } = useVisio()

    const {
      interventions,
      interventionView,
      totalInterventionItems,
      fetchInterventions,
    } = useIntervention()

    useEffect(() => {
      (async () => {
        if (userData) {
          if (userData.client) {
              await fetchInterventions(`/interventions?type=Intervention&client=/clients/${userData.client.id}&order[startDate]=desc`)
              await fetchCabinets(`/interventions?type=Cabinet&client=/clients/${userData.client.id}&order[startDate]=desc`)
              await fetchVisios(`/interventions?type=Visio&client=/clients/${userData.client.id}&order[startDate]=desc`)
              await fetchConsults(`/consultations?order[startDate]=desc`)
              setLoading(false)
          } else if (userData.specialist) {
              await fetchCabinets(`/interventions?type=Cabinet&specialist=/specialists/${userData.specialist.id}&order[startDate]=desc`)
              await fetchInterventions(`/interventions?type=Intervention&specialist=/specialists/${userData.specialist.id}&order[startDate]=desc`)
              await fetchVisios(`/interventions?type=Visio&specialist=/specialists/${userData.specialist.id}&order[startDate]=desc`)
              await fetchConsults(`/consultations?order[startDate]=desc`)
              setLoading(false)
          } else {
              await fetchInterventions()
              await fetchCabinets()
              await fetchConsults()
              await fetchVisios()
              const specialists = await apiFetch('/specialists/light?pagination=false')
              if (specialists && specialists['hydra:member']) {
                setSpecialists(specialists['hydra:member'])
                setDefaultProps({options: specialists['hydra:member'], getOptionLabel: (option) => `${option.user.firstName} ${option.user.lastName}`})
              }
              setLoading(false)
          }
        }
      })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    const handleChange = (event, newValue) => {
      if (hasRoles() === 'super_admin') {
        if (newValue === 2) {
          setHeadCells([
              { id: 'firstName', numeric: false, disablePadding: true, label: 'Client' },
              { id: 'lastName', numeric: false, disablePadding: true, label: 'Spécialiste' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'duration', numeric: true, disablePadding: false, label: 'Durée' },
              { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
          ])
        } else if (newValue === 0) {
            setHeadCells([
                { id: 'firstName', numeric: false, disablePadding: true, label: 'Client' },
                { id: 'lastName', numeric: false, disablePadding: true, label: 'Spécialiste' },
                { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
                { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
                { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
                { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
            ])
        } else if (newValue === 3) {
          setHeadCells([
            { id: 'firstName', numeric: false, disablePadding: true, label: 'Client' },
            { id: 'lastName', numeric: false, disablePadding: true, label: 'Spécialiste' },
            { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
            { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
            { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
          ])
        } else {
            setHeadCells([
                { id: 'firstName', numeric: false, disablePadding: true, label: 'Client' },
                { id: 'lastName', numeric: false, disablePadding: true, label: 'Spécialiste' },
                { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
                { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
                { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
                { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
            ])
        }
      } else if (hasRoles() === 'specialist') {
        if (newValue === 2) {
          setHeadCells([
              { id: 'name', numeric: false, disablePadding: true, label: 'Patient' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'duration', numeric: true, disablePadding: false, label: 'Durée' },
              { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
          ])
      } else if (newValue === 0) {
          setHeadCells([
              { id: 'name', numeric: false, disablePadding: true, label: 'Patient' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
              { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
              { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
          ])
      } else if (newValue === 1) {
        setHeadCells([
            { id: 'name', numeric: false, disablePadding: true, label: 'Patient' },
            { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
            { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
            { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
            { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
        ])
    } else if (newValue === 3) {
        setHeadCells([
          { id: 'name', numeric: false, disablePadding: true, label: 'Patient' },
          { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
          { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
          { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
        ])
      } else {
          setHeadCells([
              { id: 'name', numeric: false, disablePadding: true, label: 'Spécialiste' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
              { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
              { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
          ])
      }
      } else {
        if (newValue === 2) {
          setHeadCells([
              { id: 'name', numeric: false, disablePadding: true, label: 'Spécialiste' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'duration', numeric: true, disablePadding: false, label: 'Durée' },
              { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
              { id: 'action', numeric: true, disablePadding: false, label: 'Facture' },
          ])
      } else if (newValue === 0) {
          setHeadCells([
              { id: 'name', numeric: false, disablePadding: true, label: 'Spécialiste' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
              { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
              { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
              { id: 'action', numeric: true, disablePadding: false, label: 'Facture' },
          ])
      } else if (newValue === 3) {
        setHeadCells([
          { id: 'name', numeric: false, disablePadding: true, label: 'Spécialiste' },
          { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
          { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
          { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
          { id: 'action', numeric: true, disablePadding: false, label: 'Facture' },
        ])
      } else {
          setHeadCells([
              { id: 'name', numeric: false, disablePadding: true, label: 'Spécialiste' },
              { id: 'startDate', numeric: true, disablePadding: false, label: 'Date et heure de début' },
              { id: 'city', numeric: true, disablePadding: false, label: 'Lieu' },
              { id: 'motif', numeric: true, disablePadding: false, label: 'Motif' },
              { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
          ])
      }
      }
      setValue(newValue);
    };

    const handleSpecialistSearch = async (e, value) => {
      if (value) {
        await fetchConsults(`/consultations?order[startDate]=desc&specialist=${value.id}`)
        await fetchInterventions(`/interventions?type=Intervention&order[startDate]=desc&specialist=${value.id}`)
        await fetchCabinets(`/interventions?type=Cabinet&order[startDate]=desc&specialist=${value.id}`)
        await fetchVisios(`/interventions?type=Visio&order[startDate]=desc&specialist=${value.id}`)
      } else {
        await fetchConsults(`/consultations?order[startDate]=desc`)
        await fetchInterventions(`/interventions?type=Intervention&order[startDate]=desc`)
        await fetchCabinets(`/interventions?type=Cabinet&order[startDate]=desc`)
        await fetchVisios(`/interventions?type=Visio&order[startDate]=desc`)
      }
    }
    
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="tabs">
                <Tab label="A domicile" {...a11yProps(0)} />
                <Tab label="En Cabinet" {...a11yProps(1)} />
                <Tab label="Ligne d'écoute" {...a11yProps(2)} />
                <Tab label="Visioconsultation" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <Filter specialists={specialists} defaultProps={defaultProps} handleSpecialistSearch={handleSpecialistSearch} />
            <TabPanel value={value} index={0}>
                <ConsultTable consults={interventions || []} view={interventionView} totalItems={totalInterventionItems} fetchConsults={fetchInterventions} loading={loading} headCells={headCells} isSpecialist={userData && userData.specialist} isClient={userData && userData.client} isAdmin={hasRoles() === 'super_admin'} isIntervention={true} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ConsultTable consults={cabinet || []} view={officeView} totalItems={totalOfficeItems} fetchConsults={fetchCabinets} loading={loading} headCells={headCells} isSpecialist={userData && userData.specialist} isClient={userData && userData.client} isAdmin={hasRoles() === 'super_admin'} isCabinet={true}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ConsultTable consults={consults || []} view={consultView} totalItems={totalConsultItems} fetchConsults={fetchConsults} loading={loading} headCells={headCells} isSpecialist={userData && userData.specialist} isClient={userData && userData.client} isAdmin={hasRoles() === 'super_admin'}  isPlatforme={true} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ConsultTable consults={visio || []} view={visioView} totalItems={totalVisioItems} fetchConsults={fetchVisios} loading={loading} setVisioId={setVisioId} headCells={headCells} isSpecialist={userData && userData.specialist} isClient={userData && userData.client} isAdmin={hasRoles() === 'super_admin'} isVisio={true} />
            </TabPanel>
        </div>
    );
}
