import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { apiFetch } from '../../service/apiFetch';
import { Backdrop, Card, CardContent } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { TransitionLeft } from '../../helpers/utils';

const options = ['Disponible', 'Absent', 'Indisponible'];

export default function SplitButton({ setIsUpdate, setTransition, message, setSeverity, handleClickAvailable, profileUser, available, matches }) {
  const [open, setOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(available);
  const [color, setColor] = React.useState('');

  const StyledTooltip = withStyles({
    tooltip: {
      background: '#05057c',
      borderRadius: 3,
      border: 0,
      color: 'white',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      fontSize: 13,
      padding: '10px 30px'
    },
    popper: {
      marginTop: 12
    }
  })(Tooltip);

  const getAvailability = (index) => {
    if (options[index] === 'Disponible') {
      return 'online'
    } else if (options[index] === 'Indisponible') {
      return 'offline'
    }
    return 'busy'
  }

  const getColor = (index) => {
    if (options[index] === 'Disponible') {
      return '#44b700'
    } else if (options[index] === 'Indisponible') {
      return '#d32f2f'
    }
    return '#ff9800'
  }

  const handleMenuItemClick = async (event, index) => {
    const availability = getAvailability(index)
    const changePlatformStatus = await apiFetch(`/specialists/${profileUser && profileUser.specialist.id}`, {
      method: 'PUT',
      body: JSON.stringify({platformStatus: availability})
    }, true)

    if (changePlatformStatus && changePlatformStatus['hydra:description']) {
      setSeverity('error')
      message(changePlatformStatus['hydra:description'])
      setTransition(() => TransitionLeft)
      setIsUpdate(true)
    } else {
      handleClickAvailable(index)
      setColor(getColor(index))
    }

    setOpen(false);
    setPopup(false)
  };

  const handleToggle = () => {
    if (!matches) {
      setOpen((prevOpen) => !prevOpen);
    } else {
      setPopup(!popup)
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    setColor(getColor(available))
    setSelectedIndex && setSelectedIndex(available)
  }, [setSelectedIndex, available, setColor])

  return (
    <>
    <Grid container direction="column" alignItems="center" style={{top: 0, left: 0, position: 'absolute', height: 64, transform: 'translateY(20%)'}}>
      {profileUser.specialist.platformStatus !== 'waiting-call' && available !== 'waiting-call' ? <Grid item xs={12}>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          <Button style={{backgroundColor: color}}>{options[selectedIndex]}</Button>
          <Button
            style={{backgroundColor: color}}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="Votre disponibilité"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid> :
      <Box
          mt={1}
          display={'flex'}
          alignItems={'center'}
      >
      <Typography style={matches ? {fontSize: 12} : {marginRight: 10}} variant='body1'>Vous êtes en attente d'un appel </Typography>
      {!matches && <StyledTooltip arrow={true} title='Un client vous a  choisi pour une consultation audio. Si il ne vous contacte pas durant les 3 prochaines minutes, vous serez de nouveau disponible' style={{zIndex: '99999'}}><HelpIcon /></StyledTooltip>}
      </Box>
      }
    </Grid>
    {popup && <Backdrop open={popup} onClick={handleClose} >
      <Card style={{maxWidth: 300, marginTop: 10}}>
          <CardContent>
            {options.map((option, index) => (
              <>
              <Button
                style={{marginBottom: 5, width: 110}}
                color={'primary'}
                variant="contained"
                key={option}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </Button><br /></>
            ))}
          </CardContent>
      </Card>
    </Backdrop>}
    </>
  );
}
