import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_NEWS = 'FETCHING_NEWS'
const SET_NEWS = 'SET_NEWS'
const ADD_NEWS = 'ADD_NEWS'
const UPDATE_NEWS = 'UPDATE_NEWS'
const DELETE_NEWS = 'DELETE_NEWS'

function newsReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_NEWS:
            return { ...state, loading: true }
        case SET_NEWS:
            return {...state, news: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_NEWS:
            return {...state, news: [action.payload, ...state.news]}
        case UPDATE_NEWS:
            return {...state, news: action.payload }
        case DELETE_NEWS:
            return {...state, news: state.news.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useNews() {
    const [state, dispatch] = useReducer(newsReducer, {    
        news: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        news: state.news,
        totalItems: state.totalItems,
        view: state.view,
        fetchNews: useCallback(async function (route) {
            if ((state.news || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_NEWS' })
            const news = await apiFetch(route || '/cms_news', {method: 'GET'})
            if (news) {
                dispatch({type: 'SET_NEWS', payload: news})
            }
        }, [state]),
        createNews: useCallback(async function(data, forceUpdate) {
            const newNews = await apiFetch('/cms_news', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            const news = await apiFetch('/cms_news', {method: 'GET'})
            if (!newNews.violations && news) {
                dispatch({type: 'SET_NEWS', payload: news})
            }
            return newNews
        }, []),
        updateNews: useCallback(async function(news, data, forceUpdate = false) {
            const newNews = await apiFetch('/cms_news/' + news, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceUpdate)
            const newNewsReload = await apiFetch('/cms_news', {method: 'GET'})
            if (!newNews.violations && newNewsReload) {
                dispatch({type: 'SET_NEWS', payload: newNewsReload})
            }

            return newNews
        }, []),
        deleteNews: useCallback(async function (newsId) {
            const newsDelete = await apiFetch('/cms_news/' + newsId, {
                method: 'DELETE'
            })
            if (newsDelete) {
                dispatch({type: 'DELETE_NEWS', payload: newsId})
            }
            return newsDelete
        }, []),
    }
}