import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_ADDRESSES = 'FETCHING_ADDRESSES'
const SET_ADDRESSES = 'SET_ADDRESSES'
const ADD_ADDRESSES = 'ADD_ADDRESSES'
const UPDATE_ADDRESSES = 'UPDATE_ADDRESSES'

function addressesReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_ADDRESSES:
            return { ...state, loading: true }
        case SET_ADDRESSES:
            return {...state, addresses: action.payload, loading: false}
        case ADD_ADDRESSES:
            return {...state, addresses: [action.payload, ...state.addresses]}
        case UPDATE_ADDRESSES:
            return {...state, addresses: action.payload }
        default:
            return state
    }
}

export function useAddresses() {
    const [state, dispatch] = useReducer(addressesReducer, {    
        addresses: [],
        loading: false,
    })

    return {
        addresses: state.addresses,
        fetchAddresses: async function () {
            if (state.addresses || state.loading) {
                return
            }
            dispatch({ type: 'FETCHING_ADDRESSES' })
            const addresses = await apiFetch('/addresses', {method: 'GET'})
            dispatch({type: 'SET_ADDRESSES', payload: addresses['hydra:member']})
        },
        createAddresses: async function(data) {
            const newAddresses = await apiFetch('/offices', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            dispatch({type: 'ADD_ADDRESSES', payload: newAddresses })
        },
        updateAddresses: async function(addresses, data, forceUpdate = false) {
            const newAddresses = await apiFetch('/addresses/' + addresses, {
                method: 'PUT',
                body: data,
            }, forceUpdate)
            dispatch({type: 'UPDATE_ADDRESSES', payload: newAddresses, target: addresses })
            return newAddresses
        },
    }
}