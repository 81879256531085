import { Button, Divider, FormHelperText, Grid, LinearProgress, Paper, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import AutoCompleteAddress from '../../../components/AutoCompleteAddress'
import { ActiveBreadcrumb } from '../../../components/Breadcrumb';
import ConfirmationPopup from '../../../components/ConfirmationPopup';
import { OldDatePickerComponent } from '../../../components/DateTimePicker';
import mainConstant from '../../../Constant/mainConstant';
import { displayableAddress, formatAddress, isEquivalent, TransitionLeft } from '../../../helpers/utils';
import { useClients } from '../../../hooks/clients';
import { apiFetch } from '../../../service/apiFetch';
import { handleFormError } from '../../../service/security/error';
import hasRoles from '../../../service/security/hasRoles';

export function EditUserProfile({ classes, userData, setIsUpdate, setTransition, onDelete, setText, setNewSideBarInfo, setSeverity, isSpecialRoute, breadcrumb, setBreadcrumb }) {
    document.title = mainConstant.PROFILE
    const { id } = useParams()
    const history = useHistory()
    const [disable, setDisable] = React.useState(true)
    const [profileUser, setProfileUser] = React.useState(null)
    const [newAddress, setNewAddress] = React.useState('')
    const [birthdate, setBirthdate] = React.useState(null)
    const [error, setError] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [newCoordonate, setNewCoordonnate] = React.useState({
        lat: null,
        lng: null
    })

    const {
        updateClient,
    } = useClients()

    const inputs = React.useRef({})
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    React.useEffect(() => {
        (async () => {
            if (userData) {
                inputs.current.firstName = userData.firstName
                inputs.current.lastName = userData.lastName
                inputs.current.address = userData.address ? `${displayableAddress(userData.address)}` : ''
                inputs.current.mobile = userData.mobile
                inputs.current.email = userData.email
                inputs.current.birthdate = userData.birthdate
                setBirthdate(userData.birthdate)
                setProfileUser(userData)
            } else if (id) {
                const user = await apiFetch(`/clients/${id}`, {method: 'GET'})
                inputs.current.firstName = user.user.firstName
                inputs.current.lastName = user.user.lastName
                inputs.current.address = user.user.address ? `${displayableAddress(user.user.address)}` : ''
                inputs.current.mobile = user.user.mobile
                inputs.current.email = user.user.email
                inputs.current.birthdate = user.user.birthdate
                setBirthdate(user.user.birthdate)
                setProfileUser(user)
            }
        })()
    }, [userData, id])
    
    const handleSubmit = async (e) => {
        setLoading(true)
        setDisable(true)
        setError({})
        e.preventDefault()
        let data = new FormData(e.target)
        const dataObject =  {
            firstName : data.get('firstName'),
            lastName : data.get('lastName'),
            address : data.get('address'),
            mobile : data.get('numPro'),
            birthdate : birthdate,
            email : data.get('mailPro'),
        }

        const modifyInputs = isEquivalent(inputs.current, dataObject)
        if (modifyInputs) {
            const requestBody = JSON.stringify(handleBodyProperties(modifyInputs, profileUser, isSpecialRoute, newAddress, newCoordonate))
            const client = await updateClient(profileUser.client ? profileUser.client.id : profileUser.id, requestBody, true)
            if (!client.violations && !client['hydra:description']) {
                setText('Compte modifié')
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                if (hasRoles() === 'super_admin') {
                    setTimeout(() => {
                        history.push('/clients')
                        document.location.reload();
                    }, 1000)
                } else {
                    setNewSideBarInfo(handleUpdateClient(client))
                }
            } else {
                setText('Désolé, votre compte n\' a pas pu être modifié !')
                setSeverity('error')
                if (client.violations) {
                    const errorField = handleFormError(client, ['user.firstName', 'user.lastName', 'user.address', 'user.mobile', 'user.birthdate', 'user.email'])
                    setError(errorField)
                }
                setIsUpdate(true)
                setTransition(() => TransitionLeft);
            }
        }
        setLoading(false)
    }

    const handleDelete = () => {
        apiFetch(`/clients/${profileUser.client.id}`, {method: 'DELETE'})
        .then((res) => {
            if (res) {
                setSeverity('success')
                setText('Le profil a bien été supprimé')
                setIsUpdate(true)
                setTransition(() => TransitionLeft);
                if (hasRoles() === 'super_admin') {
                    setTimeout(() => {
                        document.location.reload();
                    }, 1000)
                }
            } else {
                setSeverity('error')
                setText('Echec lors de la suppression du profil')
                setIsUpdate(true)
                setTransition(() => TransitionLeft);
            }
            setOpen(false)
        })
    }

    const breadcrumbTab = [
        'Profil',
        'Ajouter',
        'Modifier',
        'Voir',
        'Map',
      ];

    const handleBack = () => {
        history.push('/clients')
    }

    const handleModify = () => {
        setDisable(false)
    }

    const handleChangeMdp = () => {
        history.push('/change-password')
    }
    
    return (
    <main>
        {isSpecialRoute && <ActiveBreadcrumb breadcrumb={breadcrumb} tab={breadcrumbTab} setBreadcrumb={setBreadcrumb} handleBack={handleBack} />}
        {profileUser ? <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom color='primary' className={classes.avatar}>
                Mon profil
            </Typography>
            <Typography variant="body1" gutterBottom color='primary' className={classes.typography}>
            Bienvenue chez vous ! Voici votre espace ‘’profil’’. Vous pouvez compléter les informations afin de faciliter votre navigation.
Les données enregistrées dans votre espace profil sont sécurisées. Vous pouvez les modifier à tout moment.
            </Typography>
            <Divider className={classes.divider}/>
            {profileUser ? <>
            {disable && !loading && <Button
                variant="contained"
                color="primary"
                className={classes.right}
                onClick={handleModify}
                >
                    Modifier mes informations
            </Button>}
            <form autoComplete="off" onSubmit={ handleSubmit }>
            {!disable && <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.right}>
                    Enregistrer
            </Button>}
            <Grid container spacing={3} style={{marginTop:'25px'}}>
                <Grid item xs={matches ? 12 : 6}>
                    <TextField error={!!error.firstName} helperText={error.firstName} className={classes.width} id="firstName" name="firstName" label="Prénom" defaultValue={inputs.current.firstName} required disabled={disable} />
                    <TextField error={!!error.lastName} helperText={error.lastName} className={classes.width} id="lastName" name="lastName" label="Nom" defaultValue={inputs.current.lastName} required disabled={disable} />
                    <TextField error={!!error.mobile} helperText={error.mobile} className={classes.width} id="numPro" name="numPro" label="Numéro de portable" defaultValue={inputs.current.mobile} type="text" InputProps={{ inputProps: { max: 9999999999 }}} disabled={disable} />
                </Grid>
                <Grid item xs={matches ? 12 : 6}>
                    <TextField error={!!error.mail} helperText={error.mail} className={classes.width} id="mailPro" name="mailPro" label="Adresse mail" defaultValue={inputs.current.email} type='email' disabled={disable} />
                    <OldDatePickerComponent className={classes.width} setStartDate={setBirthdate} startDate={Date.parse(birthdate)} error={!!error.birthdate} helperText={error.birthdate} disabled={disable} />
                    <AutoCompleteAddress error={!!error.address} className={classes.addressWidth} id="address" name="address" label="Adresse" defaultValue={inputs.current.address || ''} setNewAddress={setNewAddress} setNewCoordonnate={setNewCoordonnate} disabled={disable} setDisable={setDisable} />      
                    <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error.address}</FormHelperText>
                </Grid>         
            </Grid>
            <ConfirmationPopup open={open} handleClose={handleClose} handleDelete={handleDelete} />
            </form>
            {onDelete && <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{marginBottom: '30px'}}
                    onClick={handleClickOpen}>
                        Supprimer le profil
                </Button>}
            {(hasRoles() === 'client' || hasRoles() === 'specialist') && <Button
                    variant="contained"
                    color="primary"
                    style={{marginBottom: '30px'}}
                    onClick={handleChangeMdp}>
                        Modifier mon mot de passe
                </Button>}
            </> : <LinearProgress style={{marginLeft: '-96px'}}/>}
            {loading && <LinearProgress style={{marginLeft: '-96px'}}/>}
        </Paper> : <LinearProgress />}
    </main>
    )
}

function handleBodyProperties(modifyInputs, profileUser, isSpecialRoute = false, newAddress, newCoordonate) {
    const element = isSpecialRoute ? {user: {'@id': profileUser.user['@id']}} : {user: {'@id': profileUser['@id']}}
    for (const property in modifyInputs) {
        if (property === 'address') {
            element.user[property] = formatAddress(newAddress, newCoordonate)
        } else {
            element.user[property] = modifyInputs[property]
        }
    }
    return element
}

function handleUpdateClient(client) {
    let user = {}
    let newClient = null
    user = client.user
    delete client.user
    newClient = user
    newClient.client = client
    return newClient
}