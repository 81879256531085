import React, { useState, useEffect } from 'react'
import { apiFetch } from '../service/apiFetch'
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core/';
import moment from 'moment'
import { useStylesFees } from '../css/Style';
import { download, TransitionLeft } from '../helpers/utils';
import Alert from '../components/Alert'

const yearTab = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
const monthTab = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
export default function Fees({ user, setProfileUser }) {
    const classes = useStylesFees()
    const [fees, setFees] = useState(false)
    const [year, setYear] = useState('')
    const [feesYear, setFeesYear] = useState('')
    const [feesMonth, setFeesMonth] = useState('')
    const [alert, setAlert] = useState('')
    const [transition, setTransition] = useState('')
    const [text, setText] = useState('')
    const [severity, setSeverity] = useState('')
    const [open, setOpen] = useState(false)
    
    useEffect(() => {
        (async () => {
            if (user && user.specialist && user.specialist.feesAlert) {
                const toggleFeesAlert = await apiFetch(`/specialists/${user.specialist.id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        feesAlert: false
                    })
                })
                if (toggleFeesAlert['@id']) {
                    const userFetch = await apiFetch('/users/me')
                    if (userFetch.id) {
                        setProfileUser(userFetch)
                    }
                }
            }
            const fetchFees = await apiFetch('/specialists/monthly-fees')

            if (Array.isArray(fetchFees)) {
                setFees(fetchFees)
            } else {
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setText(fetchFees.message)
                setAlert(true)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearch = async () => {
        const search = await apiFetch(`/specialists/monthly-fees?${year ? `year=${year}` : ''}`, {
            method: 'GET',
        }, true)

        if (Array.isArray(search)) {
            setFees(search)
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText(search.message)
            setAlert(true)
        }
    }

    const handleExport = async () => {
        const fetchFees = await apiFetch(`/specialists/monthly-fees/pdf?${feesYear ? `year=${feesYear}` : ''}${feesYear && feesMonth ? `&month=${feesMonth}` : ''}${feesMonth && !feesYear ? `month=${feesMonth}` : ''}`, {
            method: 'GET',
        }, false, false, true)

        if (fetchFees.ok) {
            const fees = await fetchFees.blob()
            download(fees, `Honoraires-${feesMonth ? monthTab[feesMonth - 1] : moment().format('MMMM')}_${feesYear || moment().format('YYYY')}`)
        } else {
            const response = await fetchFees.json()
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText(response.message)
            setAlert(true)
        }
        setOpen(!open)
    }

    return (
        <>
        <div>
            <FormControl className={classes.select}>
                <InputLabel id="select-year">Année</InputLabel>
                <Select
                    labelId="select-year"
                    id="selectMonth"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                >
                    {yearTab.map((year) => {
                        return <MenuItem key={year} value={year}>{year}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Button className={classes.button} variant="contained" color="secondary" onClick={handleSearch}>
                Rechercher
            </Button>
            <Button className={classes.button} variant="contained" color="secondary" onClick={() => setOpen(!open)}>
                Export honoraires
            </Button>
        </div>
        <span style={{fontStyle: 'italic', color: 'rgb(5, 5, 124)', fontSize: 15}}>Par défaut le tableau affiche les dates d'honoraires du mois en cours.</span>
        <TableContainer style={{marginTop: 15}} component={Paper}>
            <Table aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} style={{color: 'white'}}>Type</TableCell>
                        <TableCell className={classes.tableHead} style={{color: 'white'}} align="right">Client</TableCell>
                        <TableCell className={classes.tableHead} style={{color: 'white'}} align="right">Date</TableCell>
                        <TableCell className={classes.tableHead} style={{color: 'white'}} align="right">Reversement</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!fees.length ? fees.map((row) => (
                        <TableRow key={row['@id']}>
                            <TableCell component="th" scope="row">
                              {row.type}
                            </TableCell>
                            <TableCell align="right">{row.client.user.fullName}</TableCell>
                            <TableCell align="right">{moment(row.startDate).format('LLL')}</TableCell>
                            <TableCell align="right">{row.feesHt} €</TableCell>
                        </TableRow>
                    )) : 
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="center">Pas encore d'honoraires ...</TableCell>
                        </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        <Alert isOpen={alert} transition={transition} message={text} setIsUpdate={setAlert} severity={severity} />
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="title"
            aria-describedby="description"
        >
            <DialogContent>
                <DialogContentText id="description">
                    Par défaut l'export se fera sur le mois en cours.
                </DialogContentText>
                <FormControl className={classes.select}>
                <InputLabel id="select-year">Année</InputLabel>
                <Select
                    labelId="select-year"
                    id="selectMonth"
                    value={feesYear}
                    onChange={(e) => setFeesYear(e.target.value)}
                >
                    {yearTab.map((year) => {
                        return <MenuItem key={year} value={year}>{year}</MenuItem>
                    })}
                </Select>
                </FormControl>
                <FormControl className={classes.select}>
                    <InputLabel id="select-month">Mois</InputLabel>
                    <Select
                        labelId="select-month"
                        id="selectYear"
                        value={feesMonth}
                        onChange={(e) => setFeesMonth(e.target.value)}
                    >
                        {monthTab.map((month, index) => {
                            return <MenuItem key={index} value={index + 1}>{month}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions style={{margin: 'auto'}}>
                <Button variant="contained" color="secondary" onClick={handleExport}>
                    Export honoraires
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}