import { createMuiTheme, Paper, Table, TableContainer, TablePagination, ThemeProvider, useMediaQuery } from '@material-ui/core';
import React from 'react'
import { EnhancedTableBody } from '../../components/EnhancedTableBody';
import { EnhancedTableHead } from '../../components/EnhancedTableHead';
import { EnhancedTableToolbar } from '../../components/EnhancedTableToolbar';
import * as locales from '@material-ui/core/locale';
import { TransitionLeft } from '../../helpers/utils';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import { useStylesMessages } from '../../css/Style';

const headCells = [
    { id: 'lastMessageAt', numeric: false, disablePadding: false, label: 'Le' },
    { id: 'message', numeric: true, disablePadding: false, label: 'Message' },
];



export function MessageTable(props) {
    const { showMessage, rows, deleteMessages, discussions, setText, setSeverity, setTransition, setIsUpdate, setShowMessage, menuOpen, totalItems, fetchMessages, view } = props
    const classes = useStylesMessages(menuOpen);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('sentAt');
    const [selected, setSelected] = React.useState([]);
    const [disable, setDisable] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [locale] = React.useState('frFR');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChangePage = async (event, newPage) => {
      setShowMessage(false)
      setLoading(true)
      if (newPage > page) {
        await fetchMessages(view['hydra:next'])
      } else if (newPage < page) {
        await fetchMessages(view['hydra:previous'])
      }
      window.scrollTo(0, 0)
      setLoading(false)
      setPage(newPage);
    };

    const matches = useMediaQuery('(min-width:1024px)');

    const ellipsisMessage = { cursor: 'pointer', marginLeft: '20px', marginTop: '10px', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: matches ? menuOpen ? '740px' : '1000px' : '300px', display: 'block', borderBottom: 'none', paddingRight: '0px'}

    const ellipsisObject = {marginTop: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px'}

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };
    
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };

    const handleShowMessage = (event, row) => {
      props.setShowMessage(row)
    }

    const onDeleteClick = (event) => {
      setDisable(true)
      selected.map((row) => {
          const items = findRightDiscussion(discussions, row)
          return items.map(async (item) => {
            setOpen(false);
            setShowMessage(false)
            const messageDelete = await deleteMessages(item.id)
            if (messageDelete) {
              setSelected([])
              setDisable(false)
              setSeverity('success')
              setText('La discussion a bien été supprimé')
              setIsUpdate(true)
              setTransition(() => TransitionLeft)
            } else {
              setDisable(false)
              setSeverity('error')
              setText('Désolé, la discussion n\'a pas pu être supprimé !')
              setIsUpdate(true)
              setTransition(() => TransitionLeft)
            }
          })
      })
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ConfirmationPopup open={open} handleClose={handleClose} handleDelete={onDeleteClick} text={'Voulez vous vraiment supprimer ce(s) message(s) ?'} />
        <EnhancedTableToolbar title={'Discussion(s)'} numSelected={selected.length} onDeleteClick={handleClickOpen} disable={disable} />
        <TableContainer style={showMessage > 0 ? {overflowX: 'hidden'} : {}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              deletedOption={true}
            />
            <EnhancedTableBody
              order={order}
              orderBy={orderBy}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              messageStyle={ellipsisMessage}
              objectStyle={ellipsisObject}
              isSelected={isSelected}
              handleClick={handleClick}
              handleShowMessage={handleShowMessage}
              emptyMessage={'Vous n\'avez pas encore de discussions...'}
              isDiscussion={true}
            />
          </Table>
        </TableContainer>
        <ThemeProvider theme={(outerTheme) => createMuiTheme(outerTheme, locales[locale])}>
            <TablePagination
              labelRowsPerPage=''
              nextIconButtonProps={{ disabled: loading || page > (totalItems / rowsPerPage) - 1}}
              backIconButtonProps={{ disabled: loading || page === 0 }} 
              rowsPerPageOptions={[]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </ThemeProvider>
      </Paper>
    </div>
    )
}

function findRightDiscussion(rows, selected) {
  let rightRow = []
  rows.forEach((row) => {
    if (row.id === selected) {
        rightRow.push(row)
    }
    return null
  })
  
  return rightRow;
}
