import { isAuth } from "./security/isAuth"
import AuthService from './security/handleTokenProvider'

export async function apiFetch(endpoint, options = {}, forceResponse, isMedia = false, isSpecialRoute = false) {
    const user = isAuth()
    const token = user && user.token
    const headers = !isMedia ? options.headers || {
        'Content-Type': 'application/ld+json',
        'Authorization' : 'Bearer ' + token,
    } : {
        'Authorization' : 'Bearer ' + token,
    }
    const ClassicOptions = {
        headers,
        ...options
    }

    const response = await fetch(process.env.REACT_APP_BASE_URL + endpoint, ClassicOptions)
    let responseAfterRefresh = null

    if (response.status === 401) {
        responseAfterRefresh = await handleRefreshToken(user, endpoint, options)
    }

    let responseData = null
    if (response.ok && specialCase(response, isSpecialRoute)) {
        responseData = await (!options.headers && response.status !== 204 && options.method !== 'DELETE' && options.method !== 'POST' && response.json()) || response.ok
        if (forceResponse && responseData === response.ok && response.status !== 204) {
            responseData = await response.json()
        }
    } else if (forceResponse && !responseAfterRefresh) {
        responseData = await response.json() 
    } else if (responseAfterRefresh && responseAfterRefresh.ok) {
        responseData = await responseAfterRefresh.json()
    } else if (responseAfterRefresh && !responseAfterRefresh.ok) {
        return AuthService.logout()
    }

    return !specialCase(response, isSpecialRoute) ? response : responseData
}

async function handleRefreshToken(user, endpoint, options = {}) {
    const data = { refresh_token: `${user.refresh_token}` }
    const refreshOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data)
    }
    const refreshNeeded = await fetch (process.env.REACT_APP_BASE_URL + '/authentication_token/refresh', refreshOptions)

    if (refreshNeeded.ok) {
        const refreshNeededData = await refreshNeeded.json()
        localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(refreshNeededData))
        const OptionsAfterRefresh = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + refreshNeededData.token,
            },
            ...options
        }
        return await fetch(process.env.REACT_APP_BASE_URL + endpoint, OptionsAfterRefresh)
    } else {
        return {ok: false}
    }
}

function specialCase(response, isSpecialRoute) {
    return !response.url.endsWith('/invoice') &&
    !response.url.endsWith('/accounting/sepa?consolidate=0') &&
    !response.url.endsWith('/accounting/sepa?consolidate=1') &&
    !response.url.endsWith('/specialists/resume') && 
    !response.url.endsWith('/clients/resume') && !isSpecialRoute
}
