import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  Typography } from '@material-ui/core';

export default function ClientsCharts({ data, matches }) {

    return (
        <div style={matches ? {border: '5px solid #D1DCEF', borderRadius: 43, padding: 10, height: 250} : {border: '5px solid #D1DCEF', borderRadius: 43, padding: 10}}>
            <Typography variant="h5" gutterBottom style={{textAlign: 'center', fontSize: 28, textTransform: 'uppercase', color: '#05057c'}}>
                Clients inscrits
            </Typography><br />
            <ResponsiveContainer height={matches ? data.length * 50 + 100 : data.length * 50 + 200} width="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="inscriptions" stroke="#05057C" activeDot={{ r: 8 }} />
                    <Legend height={5} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
