import { Button, Collapse, IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import clsx from 'clsx';
import logo from '../img/GEOPSY-2021_250px.png'
import { useStylesNavBar } from '../css/Style';

export default function NavBar({matches}) {
    const classes = useStylesNavBar();
    const [expanded, setExpanded] = useState(false)
    let history = useHistory()

    const goHome = () => {
        history.push('/')
    }
    return (
        <header className='navbar'>
            {matches ?
            <>
            <IconButton aria-label="delete" className={clsx(classes.expand, {[classes.expandOpen]: expanded})} onClick={() => {setExpanded(!expanded)}} style={{float: 'left'}}>
                {expanded ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
            <img src={logo} alt={'logo'} onClick={goHome} className={classes.logo}/>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Button target="_blank" href="https://www.geo-psy.fr/la-plateforme/" id='platforme' style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Consulter maintenant
                </Button>
                <Button target="_blank" href="https://app.geo-psy.fr/" id='consult' style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Trouver mon psy
                </Button>
                <Button target="_blank" href="https://www.geo-psy.fr/le-concept/" id='concept' style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Le concept
                </Button>
                <Button target="_blank" href="https://www.geo-psy.fr/la-consultation/" id='specialist' style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    La consultation
                </Button>
                <Button target="_blank" href="https://www.geo-psy.fr/blog/" id='blog' style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Blog
                </Button>
                <NavLink to='/login' style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
                    <Button style={{width: '100%', textTransform: 'none', fontSize: 20, color: '#fff'}} variant="contained" color="secondary">
                        S'inscrire / Se connecter
                    </Button>
                </NavLink>
            </Collapse>
            </>
            :
            <div className='navbar__item'>
                <img src={logo} alt={'logo'} onClick={goHome} className={classes.logo}/>
                <Button target="_blank" href="https://www.geo-psy.fr/la-plateforme/" id='platforme' style={{marginRight: 20, textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Consulter maintenant
                </Button>
                <Button target="_blank" href="https://app.geo-psy.fr/" id='consult' style={{marginRight: 20, textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Trouver mon psy
                </Button>
                <Button target="_blank" href="https://www.geo-psy.fr/le-concept/" id='concept' style={{marginRight: 20, textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Le concept
                </Button>
                <Button target="_blank" href="https://app.geo-psy.fr/la-consultation/" id='specialist' style={{marginRight: 20, textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    La consultation
                </Button>
                <Button target="_blank" href="https://www.geo-psy.fr/blog/" id='blog' style={{marginRight: 30, textTransform: 'none', fontSize: 20, color: '#989898'}}>
                    Blog
                </Button>
                <NavLink to='/login' style={{textDecoration: 'none', color: 'inherit'}}>
                    <Button style={{marginRight: 40, textTransform: 'none', fontSize: 20, color: '#fff', width: 255}} variant="contained" color="secondary">
                        S'inscrire / Se connecter
                    </Button>
                </NavLink>
            </div>}
        </header>
    )
}
