import React, { useState } from 'react'
import { Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Toolbar,
    Typography,
    Button,
    LinearProgress,
    IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import ContentMenuItem from './ContentMenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddSpecialist from './SpecialistOfMonthItem/AddSpecialist';
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';

export default function SpecialistOfMonth({ classes, updateSpecialistOfMonth, fetchSpecialistOfMonth, deleteSpecialistOfMonth, createSpecialistOfMonth, specialistOfMonth, setIsUpdate, setText, setSeverity, setTransition }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClosePopup = () => {
        setSelected(false)
        setOpen(false);
        setAnchorEl(null)
    };

    const handleClick = (event, row) => {
        setSelected(row)
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        handleClose()
        const deleted = await deleteSpecialistOfMonth(selected.id)
        if (deleted && !deleted['hydra:description']) {
            setText('Le psychologue a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText((deleted && deleted['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
    }

    const handleActive = async () => {
        handleClose()
        const actived = await apiFetch(`/cms_specialist_stars/${selected.id}`, {
            method: 'PUT',
            body: JSON.stringify({star: true})
        })
        await fetchSpecialistOfMonth('/cms_specialist_stars/')
        if (actived) {
            setText('Le psychologue a été activé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText('Erreur lors de l\'activation')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
    }

    return (
        <Paper>
            <Toolbar>
                <Typography component='div' color="primary" variant='h6' className={classes.title}>
                    Gérer le spécialiste du mois
                </Typography>
                <Button variant="contained" color="primary" size='small' onClick={handleClickOpen}>
                    Ajouter un spécialiste
                </Button>
            </Toolbar>
            {specialistOfMonth ? <TableContainer>
                <Table aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Prénom Nom</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Active</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {specialistOfMonth.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.specialist.fullName}
                                </TableCell>
                                <TableCell align="right">{row.description}</TableCell>
                                <TableCell align="right">{<div className={clsx(classes.shape, classes.shapeCircle)} style={row.star ? {backgroundColor: '#6EDEBB'} : {backgroundColor: '#C4C8D2'}} />}</TableCell>
                                <TableCell align="right"><IconButton onClick={(event) => handleClick(event, row)} ><MoreVertIcon /></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> :
            <LinearProgress />}
            <ContentMenuItem selected={selected} handleClickOpen={handleClickOpen} handleActive={handleActive} handleDelete={handleDelete} active={true} handleClose={handleClose} anchorEl={anchorEl} />
            <AddSpecialist handleCloseMenu={handleClose} updateSpecialistOfMonth={updateSpecialistOfMonth} selected={selected} setIsUpdate={setIsUpdate} setText={setText} setTransition={setTransition} setSeverity={setSeverity} createSpecialistOfMonth={createSpecialistOfMonth} handleClose={handleClosePopup} open={open} />
        </Paper>
    )
}