import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LoginForm } from './LoginFormItem/Login';
import {Paper, Typography} from '@material-ui/core';
import logo from "../img/GEOPSY-2021_250px.png";
import Grid from "@material-ui/core/Grid";
import { useStylesTabsLogin } from '../css/Style';

export function TabsLogin() {
  const classes = useStylesTabsLogin();

/*   const handleChangeIndex = (index) => {
    setValue(index);
  }; */

  return (
    <div className={classes.root}>
      <Grid style={{marginBottom: '40px'}}>
        <div className={classes.title}>
          <img src={logo} alt={'logo'} className={classes.logo} style={{display: 'block', margin: 'auto'}}/>
        </div>
        <Typography variant="body1" className={classes.title} gutterBottom color='primary' style={{textAlign: 'center'}}>
          Bienvenue sur Geo-Psy !<br/>
          Pour commencer à consulter, il ne vous reste plus qu’à vous connecter.
        </Typography>
      </Grid>
        <Paper>
            {/* this comoponent trigger animation beetwen the two tabs but trigger a depracated warning in console, pending fix */}
{/*             <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            > */}
                <LoginForm />
{/*             </SwipeableViews> */}
      </Paper>
    </div>
  );
}
