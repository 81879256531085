import { Card, CardContent, Typography } from '@material-ui/core'
import React, {useState} from 'react'
import { useHistory } from 'react-router-dom';
import Alert from '../../components/Alert';
import moment from "moment";
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import { useStylesAppointmentOverview } from '../../css/Style';

const appointmentOptions = { weekday: 'long', month: 'long', day: 'numeric' };
export function AppointmentOverview({ officePrice, setStepper, idClient, id, intervention, setTransition, cityCoordonate, client, specialist, appointment, option, city, price, payment, transition, setPaymentValidate, paymentValidate, motif, matches }) {
    let history = useHistory()
    const classes = useStylesAppointmentOverview();
    const [severity, setSeverity] = useState(null)
    const [text, setText] = useState('')

    React.useEffect(() => {
        (async () => {
            if (client) {
                    if (option === 'Cabinet' || option === 'Visio') {
                        apiFetch(`/interventions/${id}/payment`, {
                            method: "GET",
                        }, true)
                        .then((res) => {
                            if (res['hydra:description'] || res.status === 'error') {
                                setSeverity('error')
                                setTransition(() => TransitionLeft)
                                setText(res['hydra:description'] || res.message)
                                setTimeout(() => {
                                  setStepper(false)
                                }, 5000)
                            } else {
                                setSeverity('success')
                                option === 'Visio' ? setText('Une séance sera débité de votre compte, vous allez être redirigé vers la page des consultations dans 5 sec') : setText('Le paiement se fera directement chez le spécialiste, vous allez être redirigé vers la page d\'accueil dans 5 sec')
                                setTransition(() => TransitionLeft)
                                payment && setTimeout(() => {
                                    history.push(`/incoming-consultation/${idClient}`)
                                }, 6000)
                            }
                        })
                        return
                    }
                    setSeverity('success')
                    setText('Votre paiement a bien été pris en compte, vous allez être redirigé vers la page des consultations dans 5 sec')
                    setTransition(() => TransitionLeft)
                    payment && setTimeout(() => {
                        history.push(`/incoming-consultation/${idClient}`)
                    }, 6000)
                }
        })()
    }, [payment, history, client, setTransition, id, option, idClient, setStepper])
    
    return (
        <>
        <Alert isOpen={!!payment} transition={transition} severity={severity} message={text} setIsUpdate={setText} />
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Votre rendez-vous :
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {option === 'Intervention' ? `Le ${appointment.date ? new Date(appointment.date).toLocaleDateString('fr-FR', appointmentOptions) : moment(new Date(appointment.availability.availability.startDate)).format('LL')} • à ${moment(appointment.time).format('LT')} • à ${city[0].address_components[2].long_name}` : `Le ${appointment.date ? new Date(appointment.date).toLocaleDateString('fr-FR', appointmentOptions) : moment(new Date(appointment.availability.availability.startDate)).format('LL')} • à ${moment(appointment.time).format('LT')}`}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    <span style={{fontWeight: 'bold'}}>Avec : {specialist.user.firstName} {specialist.user.lastName}</span><br />
                    <span style={{fontWeight: 'bold'}}>Motif : {motif} </span> <br />
                    {option === 'Cabinet' && <span style={{fontWeight: 'bold'}}>Tarif : {officePrice} €</span>} 
                </Typography>
                { !payment ? <Typography variant="body2" component="p">
                    {option === 'Cabinet' ? `pour un rendez-vous en ${option}` : option === 'Visio' ? `Si vous acceptez, la consultation vous coûtera une séance` : `pour un rendez-vous à ${option} d'un montant de ${intervention.billingTtc} €`}
                </Typography> :
                <Typography variant="body2" component="p">
                    {option === 'Cabinet' ? 'Le paiement se fera directement chez le spécialiste' : 'Statut du paiement : validé'}
                </Typography>}
            </CardContent>
        </Card>
        <div style={matches ? {paddingBottom: '10px'} : {paddingBottom: '150px'}}></div>
        </>
    )
}
