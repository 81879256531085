import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, Slide, DialogActions, Button } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationVisioPopup({ showPopup, setShowPopup, setVisioId, selected }) {

    const handleStartVisio = () => {
        setShowPopup(false)
        setVisioId(selected)
    }

    return (
        <Dialog
            open={!!showPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setShowPopup(null)}
            aria-labelledby="title"
            aria-describedby="description"
        >
            <DialogTitle id="title">{"Visioconsultation"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="description">
                    Vous êtes sur le point de rejoindre la visioconsultation.
                    Veuillez ne pas fermer cette fenêtre, ni raccrocher tant que votre interlocuteur n'a pas rejoint la visioconsultation.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => setShowPopup(null)}>
                    Annuler
                </Button>
                <Button variant="contained" color="primary" onClick={handleStartVisio}>
                    Continuer
                </Button>
            </DialogActions>
        </Dialog>
    )
}