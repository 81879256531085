import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_CABINET = 'FETCHING_CABINET'
const SET_CABINET = 'SET_CABINET'
const ADD_CABINET = 'ADD_CABINET'
const UPDATE_CABINET = 'UPDATE_CABINET'
const DELETE_CABINET = 'DELETE_CABINET'

function cabinetReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_CABINET:
            return { ...state, loading: true }
        case SET_CABINET:
            return {...state, cabinet: action.payload['hydra:member'], loading: false, totalOfficeItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_CABINET:
            return {...state, cabinet: [action.payload, ...state.cabinet]}
        case UPDATE_CABINET:
            return {...state, cabinet: action.payload }
        case DELETE_CABINET:
            return {...state, cabinet: state.cabinet.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useCabinet() {
    const [state, dispatch] = useReducer(cabinetReducer, {   
        view: null,
        totalOfficeItems: 0, 
        cabinet: null,
        loading: false,
    })

    return {
        cabinet: state.cabinet,
        officeView: state.view,
        totalOfficeItems: state.totalOfficeItems,
        fetchCabinets: useCallback(async function (route = null) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_CABINET' })
            const cabinet = await apiFetch(route || '/interventions?type=Cabinet&order[startDate]=desc', {method: 'GET'})
            if (cabinet['hydra:member']) {
                dispatch({type: 'SET_CABINET', payload: cabinet})
            }
        }, [state]),
        createCabinets: useCallback(async function(data, forceUpdate) {
            const newCabinets = await apiFetch('/cabinet', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            if (!newCabinets.violations) {
                dispatch({type: 'SET_CABINET', payload: newCabinets})
            }
            return newCabinets
        }, []),
        updateCabinets: useCallback(async function(cabinet, data, forceUpdate = false) {
            const newCabinets = await apiFetch('/cabinet/' + cabinet, {
                method: 'PUT',
                body: data,
            }, forceUpdate)
            dispatch({type: 'UPDATE_intervention', payload: newCabinets, target: cabinet })
            return newCabinets
        }, []),
        deleteCabinets: useCallback(async function (consultationId) {
            const discussionDelete = await apiFetch('/cabinet/' + consultationId, {
                method: 'DELETE'
            })
            if (discussionDelete) {
                dispatch({type: 'DELETE_intervention', payload: consultationId})
            }
            return discussionDelete
        }, []),
    }
}