import { makeStyles, lighten } from "@material-ui/core";
import { red } from '@material-ui/core/colors';
import hasRoles from "../service/security/hasRoles";
import { green } from '@material-ui/core/colors';

const drawerWidth = 340;

export const useStylesMenu = makeStyles((theme) => ({
    root: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        width: '93% !important',
      },
    },
    info: {
      minWidth: '250px',
      maxWidth: '250px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '60px'
    },
    chatPaper: {
      border: '2px solid #05057C',
      width: '650px',
      background: '#D1DCEF 0% 0% no-repeat padding-box',
      borderRadius: '62px',
      textAlign: 'center',
      color: '#05057c',
      padding: 15,
      [theme.breakpoints.down('xs')]: {
        width: 300,
        padding: 5,
      },
    },
}));

export const useStylesVisit = makeStyles((theme) => ({    
  paper: {
    [theme.breakpoints.down('xs')]: {
        margin:'5px 8px 5px 0px',
    },
},
title: {
    padding: '20px 30px'
},
filter: {
    position: 'absolute',
    width: '77%',
    marginBottom: 20,
    marginTop: '-250px',
    boxShadow: '2px 22px 28px #00000029',
    border: '8px solid #D1DCEF',
    borderRadius: '75px',
    opacity: 1,
    margin: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
      borderRadius: '70px',
      width: '90%',
    },
  },
  grid: {
    maxWidth: 1200,
    padding: '0px',
    margin: 'auto',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  formControl: {
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      width: 210,
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  top: {
    marginTop: 170,
    paddingTop: 250,
    position: 'relative',
    background: 'transparent linear-gradient(180deg, #05057C 0%, #79E0DB 100%) 0% 0% no-repeat padding-box',
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 300,
    },
  },
  mainTitle: {
    textAlign: 'center',
    font: 'normal normal bold 50px/57px Quicksand',
    letterSpacing: '0px',
    color: '#05057C',
    marginBottom: 0,
    marginTop: 200
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 25,
    height: 25,
    [theme.breakpoints.down('sm')]: {
      width: 12,
      height: 12,
      marginRight: 10,
      marginLeft: 5
    },
  },
  shapeCircle: {
    borderRadius: '50%',
  },
  addressWidth: {
    width: 200,
    marginTop: 18,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down(1350)]: {
      width: 190,
      textAlign: 'left'
    },
  },
  divider: {
    borderLeft: '10px dotted #79E0DB',
    color: theme.palette.type === "light" ? '#fff' : '#424242', 
    backgroundColor: theme.palette.type === "light" ? '#fff !important' : '#424242 !important',
    height : 352,
    width: 60,
    [theme.breakpoints.down('xs')]: {
      height : '10px',
      width: '100%',
      borderLeft: 'none',
      borderTop: '10px dotted #79E0DB',
    },
  },
  firstGrid: {
    padding: '5px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  typoTitle: {
    marginLeft: '20px',
    marginBottom: 10
  }
}));

export const useStylesTabsLogin = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 1200,
    margin: 'auto',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '20px',
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
    tabPanel: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    }
  },
}));

export const useStylesProfile = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      flexGrow: 1,
    },
  },
  paper: {
      paddingLeft: theme.spacing(12),
      paddingTop: theme.spacing(3),
      margin: 'auto',
      maxWidth: 1500,
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
          paddingLeft: theme.spacing(2),
      },
  },
  width: {
      width: 450,
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
          width: 300
      },
      [theme.breakpoints.down('sm')]: {
          width: 160
      },
      [theme.breakpoints.down('xs')]: {
          width: '95%'
      },
  },
  maxWidth: {
      width: '100%',
      marginBottom: 40,
  },
  marginRight: {
      marginTop: '-20px',
      marginRight: 200,
      [theme.breakpoints.down('md')]: {
          marginRight: 100
      },
      [theme.breakpoints.down('sm')]: {
          marginRight: 50
      },
      [theme.breakpoints.down('xs')]: {
          marginRight: 20
      },
  },
  large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
  },
  right: {
      float: 'right',
      top: -60,
      right: 190,
      [theme.breakpoints.down('md')]: {
          right: 0,
          top: 10,
          float: 'none',
      },
      [theme.breakpoints.down('xs')]: {
          float: 'none',
          right: 0,
          top: 10,
      },
      [theme.breakpoints.down('sm')]: {
          right: 0,
          top: 10,
          float: 'none',
      },
  },
  formControl: {
      minWidth: 120,
  },
  addressWidth: {
      width: 450,
      [theme.breakpoints.down('md')]: {
          width: 300
      },
      [theme.breakpoints.down('sm')]: {
          width: 160
      },
      [theme.breakpoints.down('xs')]: {
          width: '95%'
      },
  },
  avatar: {
      [theme.breakpoints.down('xs')]: {
          margin: 'auto',
          textAlign: 'center',
      },
  },
  divider: {
      marginBottom:'10px',
      marginRight: '13%',
      [theme.breakpoints.down('xs')]: {
          marginRight: '18px',
          marginTop: '5px'
      },
  },
  typography: {
      maxWidth: 925,
      [theme.breakpoints.down('md')]: {
          maxWidth: 375,
      },
      [theme.breakpoints.down(1825)]: {
          maxWidth: 575,
      },
      [theme.breakpoints.down(1525)]: {
          maxWidth: 375,
      },
  }
}));

export const useStylesPayments = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  button: {
      float: 'right',
      [theme.breakpoints.down('xs')]: {
        float: 'none',
        marginBottom: '15px',
        display: 'block'
      },
  },
  textField: {
      width: 300,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        width: '90%'
      },
  },
  card: {
    [theme.breakpoints.down('xs')]: {
      margin: 10
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    [theme.breakpoints.down('xs')]: {
      minWidth: 280,
    },
  },
}));

export const useStylesNavBar = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      }),
  },
  expandOpen: {
      transform: 'rotate(180deg)',
  },
  logo: {
      width: '150px', 
      marginRight: 420,
      marginLeft: 100,
      maxHeight: 50,
      [theme.breakpoints.down('1841')]: {
          marginRight: 390,
      },
      [theme.breakpoints.down('1771')]: {
          marginRight: 290,
      }, 
      [theme.breakpoints.down('1671')]: {
          marginRight: 90,
      },
      [theme.breakpoints.down('1571')]: {
          marginRight: 100,
      }, 
      [theme.breakpoints.down('1371')]: {
          marginRight: 50,
      },  
      [theme.breakpoints.down('1316')]: {
          marginLeft: 10,
          marginRight: 50,
      },  
      [theme.breakpoints.down('1230')]: {
          marginLeft: 10,
          marginRight: 10,
      },
  }
}));

export const useStylesMessages = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  grid: {
      [theme.breakpoints.down('sm')]: {
          width: '100%',
      },
  }
}));

export const useStylesIncomingConsultation = makeStyles({
    title: {
        textAlign: 'left',
        margin: '30px 10px'
    },
});

export const useStylesHome = makeStyles((theme) => ({
  paper: {
      margin:'5px 150px',
      [theme.breakpoints.down('xs')]: {
          margin:'5px 8px 5px 0px',
      },
  },
  title: {
      padding: '20px 30px'
  },
  filter: {
      position: 'absolute',
      width: '70%',
      marginBottom: 20,
      marginTop: '-250px',
      padding: 20,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '2px 22px 28px #00000029',
      border: '8px solid #D1DCEF',
      borderRadius: '75px',
      opacity: 1,
      margin: 'auto',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      [theme.breakpoints.down('xs')]: {
        marginTop: '-190px',
        borderRadius: '70px',
      },
    },
    grid: {
      maxWidth: 1200,
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    formControl: {
      minWidth: 120,
      [theme.breakpoints.down('sm')]: {
        width: 210,
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    top: {
      marginTop: 170,
      paddingTop: 250,
      position: 'relative',
      background: 'transparent linear-gradient(180deg, #05057C 0%, #79E0DB 100%) 0% 0% no-repeat padding-box',
      paddingBottom: 50,
      [theme.breakpoints.down('xs')]: {
        marginTop: 250,
      },
    },
    mainTitle: {
      textAlign: 'center',
      font: 'normal normal bold 50px/57px Quicksand',
      letterSpacing: '0px',
      color: '#05057C',
      marginBottom: 0,
      marginTop: 200,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        font: 'normal normal bold 40px/47px Quicksand',
      },
    },
    shape: {
      backgroundColor: theme.palette.primary.main,
      width: 25,
      height: 25,
      [theme.breakpoints.down('xs')]: {
        width: 12,
        height: 12,
        marginRight: 10
      },
    },
    shapeCircle: {
      borderRadius: '50%',
    },
    addressWidth:{
      width: 200,
      marginTop: 18,
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    divider: {
      borderLeft: '10px dotted #79E0DB',
      color: theme.palette.type === "light" ? '#fff' : '#424242', 
      backgroundColor: theme.palette.type === "light" ? '#fff !important' : '#424242 !important',
      height : 330,
      width: 120,
      [theme.breakpoints.down('xs')]: {
        height : '10px',
        width: '100%',
        borderLeft: 'none',
        borderTop: '10px dotted #79E0DB',
      },
    },
    firstGrid: {
      padding: '5px 0px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    typoTitle: {
      marginLeft: '20px',
      marginBottom: 10
    }
}));

export const useStylesDashboard = makeStyles((theme) => ({
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginBottom: 100
  },
  backgroundBar: {
    backgroundColor: '#05057C'
  },
  mainGrid:{
      marginLeft: 35,
      width: '95%',
      [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginLeft: 0,
      },
  },
  creditCard: {
      background: 'transparent linear-gradient(180deg, #79E0DB 0%, #05057C 100%) 0% 0% no-repeat padding-box',
      borderRadius: 41,
      marginBottom: 30,
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      paddingTop: 30,
      paddingBottom: 30,
  },
  notificationCard: {
      background: '#79B0E0 0% 0% no-repeat padding-box;',
      borderRadius: 41,
      marginBottom: 30,
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      paddingBottom: 30,
      paddingTop: 30,
      [theme.breakpoints.down('xs')]: {
          marginRight: 0,
      },
  },
  toolboxCard: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '5px solid #79E0DB',
      borderRadius: 41,
      marginBottom: 30,
      textAlign: 'center',
      color: "#05057c",
      textTransform: 'uppercase',
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 30,
  },
  consultCard: {
      background: '#05057C 0% 0% no-repeat padding-box',
      borderRadius: 43,
      marginBottom: 30,
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      paddingBottom: 30,
      paddingTop: 30,
  },
  lastConsultCard: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '5px solid #D1DCEF',
      borderRadius: 43,
      marginBottom: 30,
      textAlign: 'center',
      color: "#05057c",
      textTransform: 'uppercase',
      paddingBottom: 30,
      paddingTop: 30,
  },
  specialistOfMonthCard: {
      background: '#D1DCEF 0% 0% no-repeat padding-box',
      borderRadius: 43,
      marginBottom: 30,
      padding: 35,
      color: "#05057c",
      textAlign: 'center',
  },
  quoteOfWeekCard: {
      background: '#D6F5F3 0% 0% no-repeat padding-box',
      borderRadius: 43,
      marginBottom: 30,
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: 35,
      color: "#05057c",
  },
  mainVideoCard:{
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '5px solid #D1DCEF',
      borderRadius: 43,
      marginBottom: 30,
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: 35,
      color: "#05057c",
  },
  videoCard: {
      height: 200,
      marginBottom: 30,
      [theme.breakpoints.down('xs')]: {
          width: '230px',
      },
  },
  creditButton: {
      color: 'black',
      backgroundColor: 'white'
  },
  consultButton: {
      width: 300,
      [theme.breakpoints.down('xs')]: {
          width: '200px',
      },
  },
  lastConsultButton: {
      width: 300,
      border: '5px solid #D1DCEF',
      borderRadius: 7,
      [theme.breakpoints.down('xs')]: {
          width: '200px',
      },
  },
  specialistOfMonthButton: {
      width: 300,
      borderRadius: 7,
      backgroundColor: 'white',
      font: 'normal normal bold 16px/18px Arial',
      color: '#05057c',
      [theme.breakpoints.down('xs')]: {
          width: '200px',
      },
  },
  toolIcon: {
      width: 60,
      height: 60,
      color: 'black',
      [theme.breakpoints.down('xs')]: {
          width: 50,
          height: 50,
      },
  },
  toolShadowIcon: {
      width: 100,
      height: 100,
      marginRight: 40,
      [theme.breakpoints.down('xs')]: {
          width: 70,
          height: 70,
      },
  },
  newsCard: {
      background: 'transparent linear-gradient(180deg, #79E0DB 0%, #05057C 100%) 0% 0% no-repeat padding-box;',
      borderRadius: 46,
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: '10px 35px',
      color: 'white',
  },
  newsCardButton: {
      width: '100%',
      backgroundColor: 'white',
      color: '#05057c',
      font: 'normal normal bold 16px/25px Quicksand',
      padding: '14px 60px',
      borderRadius: 11,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
      padding: '14px 12px',
      width: '100%',
      },
  },
  divider: {
      margin: 'auto',
      marginTop: '50px',
      marginBottom: '50px',
      color: 'white',
      backgroundColor: 'white',
      height: '4px',
      width: '260px',
      borderRadius: '10px',
  },
  readButton: {
      width: 203,
      color: 'white',
      font: 'normal normal bold 16px/18px Arial',
      border: '2px solid #FFFFFF',
      borderRadius: '11px',
  },
  contactButton: {
      width: 420,
      border: '2px solid #FFFFFF',
      borderRadius: '11px',
      color: 'white',
      padding: 11,
      [theme.breakpoints.down('xs')]: {
          width: '100%',
      },
  },
}))

export const useStylesCrediteAccount = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 400,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
  },
  },
  creditBox: {
      padding: 40,
      textAlign: 'center',
      font: 'normal normal bold 28px/38px Quicksand',
      color: '#05057c',
      textTransform: 'uppercase',
      maxWidth: '763px',
      margin: 'auto',
      borderRadius: 31,
      marginTop: 40
  },
  textBox: {
      textAlign: 'left',
      maxWidth: 1200,
      margin: 'auto',
      paddingTop: 40
  },
  sessionBox: {
      background: '#D1DCEF 0% 0% no-repeat padding-box',
      width: '100%',
      padding: '40px 100px',
  },
  roundedGrid: {
      maxWidth: 254
  },
  button: {
      background: 'transparent linear-gradient(180deg, #79E0DB 0%, #05057C 100%) 0% 0% no-repeat padding-box',
      borderRadius: '41px',
      font: 'normal normal bold 40px/30px Quicksand',
      color: '#FFFFFF',
      padding: '50px 100px',
      marginTop: '80px',
      lineHeight: '40px',
      [theme.breakpoints.down('xs')]: {
          width: '250px',
          font: 'normal normal bold 30px/20px Quicksand',
          lineHeight: '40px',
      },
  },
  divider: {
      border: '10px dashed #79E0DB',
      borderStyle: 'none none dotted', 
      color: theme.palette.type === "light" ? '#fafafa' : '#303030', 
      backgroundColor: theme.palette.type === "light" ? '#fafafa !important' : '#303030 !important',
      margin: '50px 180px',
      [theme.breakpoints.down('xs')]: {
          margin: '50px 20px',
      },
  },
  stripeBox: {
      maxWidth: 1000,
      textAlign: 'left',
      margin: 'auto',
      fontSize: 19,
  },
  closeButton: {
      float: 'right',
      margin: -10
  }
}));

export const useStylesConsultSpecialistProfile = makeStyles((theme) => ({
  root: {
      zIndex: '0',
  },
  card: {
      boxShadow: '13px 30px 34px #00000029',
      borderRadius: '75px',
      marginLeft: 20,
      marginTop: 250,
      padding: '0px 20px',
      maxWidth: 1200,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
          marginTop: '-200px',
          marginRight: 20,
      },
  },
  avatar: {
      width: '120px',
      height: '120px',
      [theme.breakpoints.down('xs')]: {
        width: '50px',
        height: '50px',
      },
  },
  paragraph: {
      marginTop: 390,
      marginLeft: 50,
      maxWidth: 1000,
      color: '#07057C',
      [theme.breakpoints.down('xs')]: {
          marginTop: 50,
          height: '50px',
          marginLeft: 20,
          marginRight: 20,
      },
  },
  button: {
      height: 60,
      borderRadius: 21,
      fontSize: 20,
      fontWeight: 'bold',
      width: 423, 
      marginTop: 50,
      [theme.breakpoints.down('1918')]: {
          width: '100%',
      },
  },
  main : {
      margin: 'auto',
      color: '#07057c',
      marginTop: 100,
      maxWidth: 1200,
      fontFamily: 'Quicksand',
      [theme.breakpoints.down('xs')]: {
          marginTop: 245,
          marginLeft: 20,
          marginRight: 20,
      },
  },
  divider: {
      border: '10px dashed #79E0DB',
      borderStyle: 'none none dotted', 
      color: theme.palette.type === "light" ? '#fafafa' : '#303030', 
      backgroundColor: theme.palette.type === "light" ? '#fafafa !important' : '#303030 !important',
      margin: '100px 200px',
      [theme.breakpoints.down('xs')]: {
          margin: '50px 20px',
      },
  },
  topButton: {
      marginRight: '75px',
      marginBottom: 25,
      fontSize: 15,
      padding: '10px 30px',
      fontWeight: 'bold',
      borderRadius: '20px',
  },
  IconButton: {
      marginRight: '28px',
  },
  appointmentCard: {
      margin: 'auto',
      padding: 0,
      marginBottom: 200,
      [theme.breakpoints.down('xs')]: {
          margin: '10px 10px',
      },
  },
  CardContent: {
      padding: 0
  },
  appointmentButton: {
      borderRadius: '33px',
      marginTop: 50,
  }
}));

export const useStylesAppointmentCity = makeStyles((theme) => ({
  center: {
      paddingTop: '100px',
      paddingBottom: '400px',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
          paddingTop: '5px',
          paddingBottom: '20px',
          textAlign: 'center',
      },
  },
  field: {
      width: '400px',
      marginBottom: '30px',
      [theme.breakpoints.down('xs')]: {
          width: '95%',
          marginBottom: '15px',
      },
  },
  option : {
      width: '400px',
      [theme.breakpoints.down('xs')]: {
          width: '95%',
      },
  }
}));

export const useStylesAppointmentOverview = makeStyles({
  root: {
    maxWidth: '600px',  
    justifyContent: 'center',
    margin:'auto',
  },
  bullet: {
    display: 'inline-block',
    margin: '2px 4px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  modalStyle: {
      top: '300px',
      left: '700px',
      position: 'absolute',
      width: 600,
      height: 300,
      backgroundColor: 'White',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      padding: '2px 3px',
  }
});

export const useStylesDialogAddress = makeStyles((theme) => ({
  field: {
      width: '500px',
      marginBottom: '30px',
      [theme.breakpoints.down('xs')]: {
          width: '95%',
          marginBottom: '15px',
      },
  },
}));

export const useStylesMakeAppointment = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: 'auto',
    justifyContent: 'center',
    padding: '25px 50px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5px',
      marginLeft: '5px',
    },
  },
  center: {
      paddingTop: '20px',
      paddingBottom: '100px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
  },
  avatar: {
      width: '100px',
      height: '100px',
      [theme.breakpoints.down('xs')]: {
        width: '50px',
        height: '50px',
      },
  },
  cardHeader: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      fontSize: 8,
      justifyContent: 'center',
    },
  }
}));

export const useStylesSpecialistCard = makeStyles((theme) => ({
  root: {
    maxWidth: 1140,
    padding: '40px',
    marginBottom: '100px',
    borderRadius: '139px',
    boxShadow: '0px 14px 40px rgba(34, 35, 58, 0.2)',
    '&:hover': {
      boxShadow: '0px 1px 10px rgba(34, 35, 58, 0.2)',
    },
    paddingBottom: 0, 
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5px',
      marginBottom: '25px',
      display: 'block',
      padding: '0px',
      marginTop: 140,
      marginLeft: 20,
      marginRight: 20,
      borderRadius: '70px',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
    width: '150px',
    height: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      height: '150px',
    },
  },
  cardHeader: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      fontSize: 8,
      justifyContent: 'center',
    },
  },
  leftTypography: {
    fontSize: 'inherit',
    color : '#07057C',
    width: '100%',
    textAlign: 'left',
    marginBottom: 15
  },
  rightTypography: {
    marginBottom: '20px', 
    color: '#07057C',
    fontSize: 16,
    whiteSpace: 'pre-line',
  },
  button: {
    width: 250,
    height: 60,
    borderRadius: 21,
    fontSize: 15,
    justifyContent: 'start',
    marginLeft: -10,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 10,
      marginBottom: 10
    },
  },
  topButton: {
    marginRight: '20px',
    marginBottom: 25,
    fontSize: 15,
    padding: '10px 30px',
    fontWeight: 'bold',
    borderRadius: '20px',
    width: 250,
  },
  IconButton: {
    marginRight: '10px',
  },
  name: {
    font: 'normal normal bold 25px/30px Quicksand;'
  },
  city: {
    font: 'normal normal bold 15px/20px Quicksand;'
  },
  interventionDistance: {
    font: 'normal normal normal 15px/20px Quicksand;'
  },
  divider: {
    borderLeft: '10px dotted #79E0DB',
    color: theme.palette.type === "light" ? '#fff' : '#424242', 
    backgroundColor: theme.palette.type === "light" ? '#fff !important' : '#424242 !important',
    height : '100%',
    width: 120,
    [theme.breakpoints.down('xs')]: {
      height : '10px',
      width: '100%',
      borderLeft: 'none',
      borderTop: '10px dotted #79E0DB',
    },
  },
  title: {
    width: '100%',
    font: 'normal normal bold 17px/22px Quicksand;',
    marginBottom: 25
  },
  greenLine: {
    backgroundColor: '#79E0DB',
    height: 3,
    marginTop: 10,
    marginBottom: 25
  }
}));

export const useStylesUploadItem = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2)
  },
}));

export const useStylesSuperAdminProfile = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const useStylesChangePassword = makeStyles((theme) => ({
  paper: {
      paddingTop: theme.spacing(3),
      margin: 'auto',
      maxWidth: 1500,
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
          paddingLeft: theme.spacing(2),
      },
  },
  width: {
      width: 450,
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
          width: 300
      },
      [theme.breakpoints.down('sm')]: {
          width: 160
      },
      [theme.breakpoints.down('xs')]: {
          width: '95%'
      },
  },
  avatar: {
      [theme.breakpoints.down('xs')]: {
          margin: 'auto',
          textAlign: 'center',
      },
  },
  divider: {
      marginRight: '13%',
      marginBottom: 50,
      [theme.breakpoints.down('xs')]: {
          marginRight: '18px',
          marginTop: '5px'
      },
  },
  padding: {
      paddingLeft: 150,
      [theme.breakpoints.down('md')]: {
          paddingLeft: 100,
      },
      [theme.breakpoints.down('sm')]: {
          paddingLeft: 50,
      },
      [theme.breakpoints.down('xs')]: {
          paddingLeft: 10,
      },
  }
}));

export const useStylesShowUserProfile = makeStyles((theme) => ({
  root: {
      maxWidth: 1000,
      margin: 'auto',
  },
  expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
      }),
  },
  expandOpen: {
      transform: 'rotate(180deg)',
  },
  avatar: {
      backgroundColor: red[500],
  },
  grid: {
      '& svg': {
        margin: theme.spacing(1.5),
      },
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
  },
  typography: {
      marginBottom: '10px'
  } 
}));

export const useStylesValidSpecialistProfile = makeStyles((theme) => ({
  paper: {
      marginTop: '50px',
      paddingLeft: theme.spacing(12),
      paddingTop: theme.spacing(3),
      margin: 'auto',
      maxWidth: 1500,
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
          paddingLeft: theme.spacing(2),
      },
  },
  width: {
      width: 450,
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
          width: 300
      },
      [theme.breakpoints.down('sm')]: {
          width: 160
      },
      [theme.breakpoints.down('xs')]: {
          width: '90%'
      },
  },
  marginRight: {
      marginTop: '-20px',
      marginRight: 200,
      [theme.breakpoints.down('md')]: {
          marginRight: 100
      },
      [theme.breakpoints.down('sm')]: {
          marginRight: 50
      },
      [theme.breakpoints.down('xs')]: {
          marginRight: 20
      },
  },
  right: {
      [theme.breakpoints.down('xs')]: {
          float: 'none',
          right: 0,
          top: 10,
      },
  },
  addressWidth: {
      width: 450,
      [theme.breakpoints.down('md')]: {
          width: 300
      },
      [theme.breakpoints.down('sm')]: {
          width: 160
      },
      [theme.breakpoints.down('xs')]: {
          width: '90%'
      },
  },
  avatar: {
      [theme.breakpoints.down('xs')]: {
          margin: 'auto',
          textAlign: 'center',
      },
  },
  divider: {
      marginBottom:'10px',
      marginRight: '13%',
      [theme.breakpoints.down('xs')]: {
          marginRight: '18px',
          marginTop: '5px'
      },
  },
  maxWidth: {
      width: '94%',
      marginBottom: 40,
  },
}));

export const useStylesShowSpecialistProfile = makeStyles((theme) => ({
  root: {
    maxWidth: 843,
    borderRadius: 20,
    margin: 'auto',
  },
  content: {
    padding: 24,
  },
  grid: {
    marginTop: 30,
    width: hasRoles() === 'super_admin' ? 'fit-content' : '',
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export const useStylesMessageTable = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
/*       width: (props) => 
      props ? 1190 : 1404,
    marginBottom: theme.spacing(2),
    maxWidth: (props) => 
    props ? 1190 : 1404, */
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
      overflowX: 'scroll',
      marginRight: 'auto',
      marginLeft: 'auto'
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
/*     "@media (max-width: 1660px)": {
    paper: {
      width: 1250,
      maxWidth: 1250,
    }
  },
  "@media (max-width: 1024px)": {
    paper: {
      width: 700,
      maxWidth: 700,
    }
  } */
}));

export const useStylesNewMessage = makeStyles((theme) => ({
  paperPosition: {
      maxWidth: '600px',
      padding: '20px 40px',
  },
  fieldWidth: {
      width: '100%',
      maxWidth: '500px',
      marginBottom: '40px'
  },
  marginBottom: {
      marginBottom: '20px'
  }
}));

export const useStylesShowMessage = makeStyles((theme) => ({
  topBar: {
      position: "fixed",
      bottom: 540, 
      right: 50, 
      width: 550, 
      maxHeight: 20, 
      backgroundColor: theme.palette.type === "light" ? '#424242' : '#eee', 
      marginBottom: '10px', 
      paddingBottom: 5, 
      borderTopLeftRadius: 5, 
      borderTopRightRadius: 5,
      [theme.breakpoints.down('xs')]: {
          width: 275,
          bottom: 265, 
          right: 25,
      },
  },
  messagePaper: {
      position: "fixed", 
      bottom: 3, 
      right: 50, 
      maxWidth: 550, 
      minWidth: 550, 
      minHeight: 547, 
      maxHeight: 547, 
      overflowY: 'scroll', 
      overflowX: 'hidden',
      [theme.breakpoints.down('xs')]: {
          maxWidth: 275, 
          minWidth: 275, 
          minHeight: 275, 
          maxHeight: 275,  
          right: 25,
      },
  },
  sendMessage: {
      position: "fixed", 
      bottom: 0, 
      right: 65, 
      width: 535, 
      backgroundColor: theme.palette.type === "light" ? 'white' : '#424242',
      [theme.breakpoints.down('xs')]: {
          width: 267, 
          right: 32,
      },
  },
}));

export const useStylesChatMessages = makeStyles(({ palette, spacing }) => {
  const radius = spacing(2.5);
  const size = spacing(4);
  const rightBgColor = palette.primary.main;
  // if you want the same as facebook messenger, use this color '#09f'
  return {
    avatar: {
      width: size,
      height: size,
      marginTop: 20,
    },
    msg: {
      padding: spacing(1, 2),
      borderRadius: 4,
      marginBottom: 4,
      display: "inline-block",
      wordBreak: "break-all",
      maxWidth: 370,
      fontFamily:
        // eslint-disable-next-line max-len
        'Quicksand, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    leftRow: {
      textAlign: "left"
    },
    left: {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor: palette.type === "light" ? palette.grey[100] : '#222'
    },
    leftFirst: {
      borderTopLeftRadius: radius
    },
    leftLast: {
      borderBottomLeftRadius: radius
    },
    rightRow: {
      textAlign: "right"
    },
    right: {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor: rightBgColor,
      color: palette.common.white
    },
    rightFirst: {
      borderTopRightRadius: radius
    },
    rightLast: {
      borderBottomRightRadius: radius
    }
  };
});

export const useStylesSignIn = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
      paddingTop: theme.spacing(6),
      marginTop: '100px',
      margin: 'auto',
      maxWidth: 1025,
      flexGrow: 1,
  },
  width: {
      minWidth: 400,
      marginBottom: 40,
      [theme.breakpoints.down('xs')]: {
          minWidth: 200,
      },
  },
  large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
  },
  right: {
      float: 'right',
      top: -60,
      right: 190
  },
  formControl: {
      width: 400,
      [theme.breakpoints.down('xs')]: {
          width: 200,
      },
  },
  left: {
      marginLeft: 100,
      [theme.breakpoints.down('xs')]: {
          marginLeft: 10,
      },
  },
  margin: {
      margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
      fontStyle: 'italic',
      textAlign: 'center',
      marginLeft: -100,
  }
}));

export const useStylesLogin = makeStyles((theme,) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  paperLogin: {
      padding: theme.spacing(4),
      margin: 'auto',
      textAlign: 'center',
      justifyContent: 'center'
  },
  center: {
      paddingLeft: '100px',
      padding: theme.spacing(4),
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
          padding: 0,
          marginTop: '10px',
          marginLeft: 0
      },
  },
  fullSize: {
      width: '50ch',
      color: "red"
  },
  divider: {
      width: 505,
      [theme.breakpoints.down('xs')]: {
          width: 215,
      },
  },
  fieldWidth: {
      width: '250px',
      [theme.breakpoints.down('xs')]: {
          width: 225,
      },
  },
  icon: {
      width: '60px',
      position: 'relative',
      left: '460px',
      [theme.breakpoints.down('xs')]: {
          left: 190,
      },
  },
  link: {
      marginLeft: '190px',
      [theme.breakpoints.down('xs')]: {
          marginLeft: 10,
      },
  },
  title: {
      fontStyle: 'italic',
      textAlign: 'center',
  }
}));

export const useStylesChangePasswordLogin = makeStyles((theme) => ({
  paperReset: {
      padding: theme.spacing(4),
      margin: 'auto',
      textAlign: 'center',
      justifyContent: 'center',
      marginTop: '250px',
      maxWidth: '440px'
  },
}));

export const useStylesFutureInterventionsTable = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    backgroundColor: '#07057C',
    color: 'white'
  }
});

export const useStylesHomeCard = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    minWidth: 345,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 280,
      minWidth: 280,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export const useStylesConsultTable = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  bold: {
      fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  backDropTypo: {
    textAlign: 'left',
    marginBottom: 8,
  },
  marginTop: {
    marginTop: 15,
  }
}));

export const useStylesConsultTab = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const useStylesSkeletonCard = makeStyles((theme) => ({
  root: {
    maxWidth: 1140,
    padding: '40px',
    marginBottom: '100px',
    borderRadius: '139px',
    boxShadow: '0px 14px 40px rgba(34, 35, 58, 0.2)',
    '&:hover': {
      boxShadow: '0px 1px 10px rgba(34, 35, 58, 0.2)',
    },
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5px',
      marginBottom: '25px',
      display: 'block',
      padding: '0px',
      marginTop: 70,
      marginLeft: 20,
      marginRight: 20,
      borderRadius: '70px',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
    width: '150px',
    height: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      height: '150px',
    },
  },
  cardHeader: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      fontSize: 8,
      justifyContent: 'center',
    },
  },
  leftTypography: {
    fontSize: 'inherit',
    color : '#07057C',
    width: '100%',
    textAlign: 'left',
    marginBottom: 15
  },
  rightTypography: {
    marginBottom: '20px', 
    color: '#07057C',
    fontSize: 16
  },
  button: {
    width: 350,
    height: 60,
    borderRadius: 21,
    fontSize: 20,
    justifyContent: 'start',
    marginLeft: -10,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 10,
      marginBottom: 10
    },
  },
  topButton: {
    marginRight: '35px',
    marginBottom: 25,
    fontSize: 15,
    padding: '10px 30px',
    fontWeight: 'bold',
    borderRadius: '20px',
    width: 250,
  },
  IconButton: {
    marginRight: '10px',
  },
  name: {
    font: 'normal normal bold 25px/30px Quicksand;'
  },
  city: {
    font: 'normal normal bold 15px/20px Quicksand;'
  },
  interventionDistance: {
    font: 'normal normal normal 15px/20px Quicksand;'
  },
  divider: {
    borderLeft: '10px dotted #79E0DB',
    color: theme.palette.type === "light" ? '#fff' : '#424242', 
    backgroundColor: theme.palette.type === "light" ? '#fff !important' : '#424242 !important',
    height : '100%',
    width: 120,
    [theme.breakpoints.down('xs')]: {
      height : '10px',
      width: '100%',
      borderLeft: 'none',
      borderTop: '10px dotted #79E0DB',
    },
  },
  title: {
    width: '100%',
    font: 'normal normal bold 17px/22px Quicksand;',
    marginBottom: 25
  },
  greenLine: {
    backgroundColor: '#79E0DB',
    height: 3,
    marginTop: 10,
    marginBottom: 25
  }
}));

export const useStylesHorizontalStepper = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 150,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const useStylesFooter = makeStyles((theme) => ({
  Typography: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: '#07057c',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: 20,
      [theme.breakpoints.down('xs')]: {
          fontSize: 13,
          height: 70,
      },
  },
  footer: {
      width: '100%',
      height: 550,
      background: '#D1DCEF 0% 0% no-repeat padding-box',
      paddingTop: 50,
      overflowX: 'hidden'
  },
  field: {
      width: '700px',
      marginRight: 60
  },
  button: {
      height: 56,
      width: 200,
      borderRadius: 37,
      fontSize: 'large'
  },
  gridIcon: {
      marginTop: 50,
      [theme.breakpoints.down('xs')]: {
          marginLeft: 15
      },
  },
  icon: {
      marginRight: 30,
      color: 'white',
      width: 70,
      height: 70,
      [theme.breakpoints.down('xs')]: {
          width: 50,
          height: 50,
      },
  },
  grid: {
      maxWidth: 1300,
      margin: 'auto'
  }
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 40%',
  },
  button: {
    margin: theme.spacing(1),
  },  
}));

export const useStylesEnhancedTableBody = makeStyles(() => ({
  shape: {
    width: 10,
    height: 10,
    display: 'inline-block',
    marginLeft: 5
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));

export const useStylesAlert = makeStyles(() => ({
  icon: {
      fontSize: 28
  },
  padding: {
      padding: 50
  }
}));

export const useStylesClosePopup = makeStyles(() => ({
  paper: {
      width: '650px',
      background: '#D1DCEF 0% 0% no-repeat padding-box',
      boxShadow: '13px 30px 34px #00000029',
      borderRadius: '75px',
      margin: 'auto',
      textAlign: 'center',
      fontWeight: 'blod',
      color: '#05057c',
      paddingBottom: 30
  }
}));

export const useStylesVoice = makeStyles(() => ({
  voicePaper: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bottom: '-95px',
    border: '2px solid #05057C',
    width: '650px',
    background: '#D1DCEF 0% 0% no-repeat padding-box',
    boxShadow: '13px 30px 34px #00000029',
    borderRadius: '75px',
    margin: 'auto',
    textAlign: 'center',
    fontWeight: 'blod',
    color: '#05057c',
    padding: 20
  },
  close: {
      float: 'right'
  },
}));

export const useStylesConsultPopup = makeStyles((theme) => ({
  voicePaper: {
    position: 'fixed !important',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bottom: '-95px',
    border: '2px solid #05057C',
    width: '650px',
    background: '#D1DCEF 0% 0% no-repeat padding-box',
    boxShadow: '13px 30px 34px #00000029',
    borderRadius: '75px',
    margin: 'auto',
    textAlign: 'center',
    fontWeight: 'blod',
    color: '#05057c',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      padding: 5,
    },
  },
  close: {
      float: 'right'
  },
}));

export const useStylesCguPopup = makeStyles((theme) => ({
  cguPaper: {
    maxWidth: '900px !important',
  },
}));

export const useStylesDatePickerPopup = makeStyles(() => ({
  datePicker: {
    marginRight: 20
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  select: {
    marginRight: 40,
    width: 200,
  },
}));

export const useStylesFees = makeStyles(() => ({
  select: {
    marginRight: 40,
    marginBottom: 20,
    width: 200,
  },
  button: {
    marginTop: 12,
    marginRight: 40
  },
  tableHead: {
    padding: 20,
    backgroundColor: '#05057C',
    fontWeight: 'bold',
    fontSize: 16
  }
}));

export const useStylesContentManagement = makeStyles(() => ({
  title: {
    flex: '1 1 100%',
  },
  shape: {
    width: 10,
    height: 10,
    display: 'inline-block',
    marginLeft: 5
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));