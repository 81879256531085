import { Button, Link, Slide, Snackbar, TextField, Typography } from '@material-ui/core'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { apiFetch } from '../../service/apiFetch';

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export function ResetPassword({ onReset, onLoading }) {
    const [disable, setDisable] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [transition, setTransition] = React.useState(undefined);
    const handleClose = () => {
      setOpen(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        setDisable(true)
        onLoading(true)
        const data = new FormData(e.target)
        const email = { email : `${data.get('username')}` }
        const reset = await apiFetch(
            '/reset-password/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(email)
        }, true)
        if (reset[0]) {
            setError(reset[0])
        } else {
            setTransition(() => TransitionLeft)
            setOpen(true)
            setTimeout(() => {
                onReset(false)
            }, 3000)
        }
        setDisable(false)
        onLoading(false)
    }

    const handleBack = (e) => {
        e.preventDefault()
        onReset(false)
    }


    return (
        <form noValidate onSubmit={ handleFormSubmit }>
            <TextField error={!!error.field} helperText={error.message} id="username" name="username" label="Email" style={{marginRight: '50px'}}/>
            <Button
                disabled={disable}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<VpnKeyIcon />}
                style={{marginTop: '15px'}}>
                    Reset
            </Button>
            <Typography style={{paddingTop: '20px'}}>
                <Link href="#" onClick={handleBack} variant="body2">
                    Retourner à l'écran de connexion
                </Link>
            </Typography>
            {open && <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={open}
                onClose={handleClose}
                TransitionComponent={transition}
                key={transition ? transition.name : ''}
            >
                <MuiAlert elevation={6} variant="filled" severity="success" >Un email de confirmation vient d'être envoyé sur votre boîte mail</MuiAlert>
            </Snackbar>}
        </form>
    )
}