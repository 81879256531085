import React from 'react';
import { Button, Dialog, DialogContent, CircularProgress, DialogContentText, useMediaQuery, Container, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStylesDatePickerPopup } from '../css/Style';

const yearTab = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
const monthTab = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

export default function DatePickerPopup({ setYear, setMonth, year, month, downloadLoading, fees, exportFiles, openDatePickerPopup, setOpenDatePickerPopup }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStylesDatePickerPopup()

    return (
        <div>
            <Dialog
                open={openDatePickerPopup}
                onClose={() => setOpenDatePickerPopup(!openDatePickerPopup)}
                aria-labelledby="dialog"
                fullScreen={fullScreen}
            >
                <DialogContent style={{textAlign: 'center'}}>
                    <DialogContentText>Si vous ne touchez pas aux dates, l'ensemble des {fees ? 'Honoraires' : 'clients'} sera téléchargé</DialogContentText>
                    <div style={{display: 'inline-flex', paddingLeft: 30}}>
                        <FormControl className={classes.select}>
                            <InputLabel id="select-year">Année</InputLabel>
                            <Select
                                labelId="select-year"
                                id="selectMonth"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            >
                                {yearTab.map((year) => {
                                    return <MenuItem key={year} value={year}>{year}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.select}>
                            <InputLabel id="select-month">Mois</InputLabel>
                            <Select
                                labelId="select-month"
                                id="selectYear"
                                value={month}
                                onChange={(e) => setMonth(e.target.value)}
                            >
                                {monthTab.map((month, index) => {
                                    return <MenuItem key={index} value={index + 1}>{month}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <Container style={{textAlign: 'center', marginTop: '20px'}}>
                        <div>
                            <Button onClick={(e) => exportFiles(e, true, fees)} variant="contained" color="primary" disabled={downloadLoading}>
                                Télécharger
                            </Button>
                            {downloadLoading && <CircularProgress size={24} className={classes.buttonProgress} style={{marginTop: 84}} />}
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}