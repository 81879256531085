import { CircularProgress, Typography, useMediaQuery, useTheme, Grid } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import "../css/home.css"
import { apiFetch } from '../service/apiFetch'
import FoundSpecialists from './DashboardItem/foundSpecialists'
import SpecialistCard from './VisitItem/SpecialistCard'
import SkeletonCard from '../components/SkeletonCard'
import {Waypoint} from "react-waypoint";
import SkeletonFilterPaper from '../components/SkeletonFilterPaper'
import NavBar from './NavBar'
import Footer from '../components/Footer'
import { useForceUpdate } from '../hooks/forceUpdate'
import { removeLastAmpersand, TransitionLeft } from '../helpers/utils'
import Alert from '../components/Alert'
import { useStylesHome } from '../css/Style'
import moment from 'moment'

let globalSpecialists = null
export function Home({ eventSource }) {
    document.title = 'Geo Psy'
    const classes = useStylesHome();
    const mounted = useRef(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [spinner, setSpinner] = useState(false)
    const [disable, setDisable] = useState(false)
    const [allSpecialists, setAllSpecialist] = React.useState(null)
    const [view, setView] = React.useState(null)
    const [apiConsultationTypes, setApiConsultationTypes] = React.useState(null)
    const [apiSpecialities, setApiSpecialities] = React.useState(null)
    const [consultationTypes, setConsultationTypes] = React.useState('')
    const [availability, setAvailability] = React.useState('')
    const [specialities, setSpecialites] = React.useState('')
    const forceUpdate = useForceUpdate();
    const [value, setValue] = React.useState('');
    const [newCoordonate, setNewCoordonnate] = React.useState({
      lat: null,
      lng: null
    })
    const [transition, setTransition] = React.useState(false);
    const [severity, setSeveriry] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [foundByName, setFoundByName] = React.useState(false)

    const updateAllSpecialists = (data) => {
      globalSpecialists.map((specialist) => {
        if (specialist.id === data.id) {
          return specialist.platformStatus = data.platformStatus
        }
        return null
      })
      setAllSpecialist(globalSpecialists)
      forceUpdate()
    }

/*     const {
        specialists,
        fetchPublicSpecialists,
    } = useSpecialists() */
    
    useEffect(() => {
        //mounted check is component is still mounted to prevent memory leak if user did not wait until promise resolve
        mounted.current = true;
        (async () => {
            eventSource.onmessage = event => {
                // Will be called every time an update is published by the server
                updateAllSpecialists(JSON.parse(event.data).data)
            }
            const specialists = await apiFetch('/specialists', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/ld+json',
                }
            }, true)
            if (mounted.current) {
              setAllSpecialist(specialists['hydra:member'])
              globalSpecialists = specialists['hydra:member']
              setView(specialists['hydra:view'])
            }
            apiFetch("/specialities", {
              method: "GET",
              headers: {
                'Content-Type': 'application/ld+json',
                },
            }, true)
            .then(res => {
              if (mounted.current) {
                return setApiSpecialities(res['hydra:member']);
              }
            })
  
            apiFetch("/consultation_types", {
              method: "GET",
              headers: {
                'Content-Type': 'application/ld+json',
              },
            }, true)
            .then(res => {
              if (mounted.current) {
                return setApiConsultationTypes(res['hydra:member']);
              }
            })
          })()
        /* fetchPublicSpecialists(true, () => mounted) */
        return () => (mounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
/*     const [cleanInterval, setCleanInterval] = useState(false) */

    const enterHandler = async() => {
        setSpinner(true)
        const specialists = view && view['hydra:next'] && await apiFetch(view['hydra:next'], {headers: {
            'Content-Type': 'application/ld+json',
        }}, true)
        if (specialists) {
          setView(specialists['hydra:view'])
          setAllSpecialist((specialists['hydra:member'] && allSpecialists.concat(specialists['hydra:member'])) || allSpecialists)
          globalSpecialists = (specialists['hydra:member'] && allSpecialists.concat(specialists['hydra:member'])) || allSpecialists
        }
        setSpinner(false)
    };

    const handleSearch = async () => {
      setDisable(true)
      const latLng = newCoordonate.lat ? `currentLatLng=${newCoordonate.lat},${newCoordonate.lng}&` : ''
      const chosenSpecialities = specialities ? `specialities=${specialities}&` : ''
      const chosenAvailability = availability ? `availabilities.startDate[strictly_after]=${moment().format('YYYY-MM-DD')}&availabilities.startDate[strictly_before]=${availability === 'today' ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().add(7, 'days').format('YYYY-MM-DD')}&` : ''
      const firstName = foundByName ? `user.firstName=${foundByName}&` : ''
      const chosenConsultationTypes = consultationTypes ? `specialistHasConsultationsType.consultationType=${consultationTypes}&` : ''
      const url = removeLastAmpersand(`/specialists?${latLng}${chosenSpecialities}${firstName}${chosenConsultationTypes}${chosenAvailability}`)
      const filterSpecialist = await apiFetch(url, {
        headers: {
        'Content-Type': 'application/ld+json',
        }
      }, true)

      if (filterSpecialist['hydra:member']) {
          setView(filterSpecialist['hydra:view'])
          setAllSpecialist(filterSpecialist['hydra:member'])
          globalSpecialists = filterSpecialist['hydra:member']
      } else {
          setSeveriry('error')
          setTransition(() => TransitionLeft)
          setIsOpen(true)
      }
      setDisable(false)
    }

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const handleChangeName = (e) => {
      setFoundByName(e.target.value)
    }

    return (
        <div>
          <NavBar matches={matches} />
{/*             <div>
                <div className={styles.background}>
                    <div className={styles.container}>
                        <Carousel cardDist={25} navOnTop navTitle={'NOTRE PLATEFORME DE SOUTIEN PSYCHOLOGIQUE'} cleanInterval={cleanInterval} >
                            {Array((specialists && specialists.length) || 5)
                                .fill(0)
                                .map((test, index) => (
                                  <HomeCard key={index} specialist={specialists && specialists[index]} />
                                ))}
                        </Carousel>
                    </div>
                </div>
            </div> */}
            <Typography variant="h3" align='center' className={classes.mainTitle}>
                NOS PSYCHOLOGUES
            </Typography>
            <Grid container spacing={0} style={{marginTop: 20}}>
                <Grid item xs={matches ? 10 : 8} container justify="space-between" className={classes.grid}>
                </Grid>
            </Grid>
            <div className={classes.top}>
            {apiSpecialities && apiConsultationTypes ? <FoundSpecialists availability={availability} setAvailability={setAvailability} handleChangeName={handleChangeName} disable={disable} setNewCoordonnate={setNewCoordonnate} consultationTypes={consultationTypes} specialities={specialities} apiConsultationTypes={apiConsultationTypes} apiSpecialities={apiSpecialities} value={value} setConsultationTypes={setConsultationTypes} setSpecialites={setSpecialites} classes={classes} matches={matches} handleChange={handleChange} handleSearch={handleSearch} /> : <SkeletonFilterPaper classes={classes} matches={matches} />}
            {apiSpecialities && apiConsultationTypes && allSpecialists ? allSpecialists.map((specialist, index) => (
              <React.Fragment key={index}>
                  {index === allSpecialists.length - 1 && <Waypoint onEnter={() => enterHandler(index)} />}
                  <SpecialistCard matches={matches} specialist={specialist} isFromDashboard={true} />
              </React.Fragment>
            )) : <SkeletonCard />}
            {/* <HorizontalStepper client={props.user} /> */}
            <Alert isOpen={isOpen} transition={transition} message={'Désolé, votre recherche n\' a pas pu aboutir'} setIsUpdate={setIsOpen} severity={severity} />
            {spinner && <div style={{marginTop: '50px', textAlign: 'center'}} ><CircularProgress /></div>}
            </div>
            <div style={{marginTop: 200}}>
              <Footer />
            </div>
        </div>
    )
}
