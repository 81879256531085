import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react'
import AutoCompleteAddress from '../../components/AutoCompleteAddress';
import { useStylesDialogAddress } from '../../css/Style';

export default function DialogAddress({ setCity, matches, client, setCityCoordonate, open, setOpen }) {
    const classes = useStylesDialogAddress()
    const [disable, setDisable] = React.useState(true)
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-address">
            <DialogTitle id="form-dialog-title">Adresse de l'intervention</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Afin de s'assurer que le spécialiste puisse intervenir sur le lieu de l'intervention, veuillez préciser l'adresse de celle-ci.
                </DialogContentText>
                <AutoCompleteAddress setDisable={setDisable} matches={matches} className={classes.field} id="searchCity" name="searchCity" label="Saisissez votre adresse" customStyle={false} required={true} setNewAddress={setCity} defaultValue={(client && client.address && client.address.address) ||  ''} setNewCoordonnate={setCityCoordonate} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={disable}>
                    Confirmer
                </Button>
            </DialogActions>
      </Dialog>

    )
}