import { Slide } from "@material-ui/core";
import React from 'react'
import { routes } from "../Constant/routes";

export function isEquivalent(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    const element = {}
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (a[propName] !== b[propName]) {
            element[propName] = b[propName]
        }
    }

    return element.length === 0 || element;
}

export function formatAddress(newAddress, newCoordonate) {
    if (!newAddress || !newCoordonate|| !Array.isArray(newAddress)) return null

    return {
        address: `${newAddress[0].address_components.find((address) => address.types.find((type) => type === 'street_number')) && newAddress[0].address_components.find((address) => address.types.find((type) => type === 'street_number')).long_name} ${newAddress[0].address_components.find((address) => address.types.find((type) => type === 'route')) && newAddress[0].address_components.find((address) => address.types.find((type) => type === 'route')).long_name}`,
        city: newAddress[0].address_components.find((address) => address.types.find((type) => type === 'locality')) && newAddress[0].address_components.find((address) => address.types.find((type) => type === 'locality')).long_name,
        country: newAddress[0].address_components.find((address) => address.types.find((type) => type === 'country')) && newAddress[0].address_components.find((address) => address.types.find((type) => type === 'country')).long_name,
        postalCode: newAddress[0].address_components.find((address) => address.types.find((type) => type === 'postal_code')) ? newAddress[0].address_components.find((address) => address.types.find((type) => type === 'postal_code')).long_name : null,
        latitude: `${newCoordonate.lat}`,
        longitude: `${newCoordonate.lng}`,
    }
}

export function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export function removeLastComma(strng){  
    const n=strng.lastIndexOf(",");
    if (n > 0) {
        const a=strng.substring(0,n)
        return a;
    }
    return strng
}

export function isKnownRoutes(route, get = false) {
    const r = route.lastIndexOf('/')
    let formattedRoute = route
    if (r > 0) {
        formattedRoute = route.substring(0,r) + '/:id'
    }
    if (get) {
        return formattedRoute
    }
    return Object.keys(routes).find((key) => !!(routes[key] === formattedRoute)); 
}

export function getHours(date) {
    let minutes = date.getMinutes()
    if (date.getMinutes() < 10) {
        minutes = `0${minutes}`
    }
    return `${date.getHours()}:${minutes}`
}

export function formatIban(e) {
    if (!e) return null
    if (e.keyCode === 8 || e.keyCode === 37 || (e.target && !e.target.value)) {
        return e.target.value
    }
    const cleanIban = (e.target && e.target.value) || e
    .replace(/\s\s+/g, ' ')
    .replace(/[^0-9a-zA-Z]/gi, '')
    .toUpperCase()
    const parts = []

    if (cleanIban.length > 0) {
      parts.push(cleanIban.substring(0, 4))
    }
  
    if (cleanIban.length > 4) {
      parts.push(cleanIban.substring(4, 8))
    }
  
    if (cleanIban.length > 8) {
      parts.push(cleanIban.substring(8, 12))
    }
  
    if (cleanIban.length > 12) {
      parts.push(cleanIban.substring(12, 16))
    }
  
    if (cleanIban.length > 16) {
      parts.push(cleanIban.substring(16, 20))
    }
  
    if (cleanIban.length > 20) {
      parts.push(cleanIban.substring(20, 24))
    }

    if (cleanIban.length > 24) {
      parts.push(cleanIban.substring(24, 27))
    }

    return parts.join(' ')
}

export function findConsultationTypes(consultationTypes, type) {
    let hasConsultationTypesOffice = null
    consultationTypes && consultationTypes.map((item) => {
        if (item === type) {
            return hasConsultationTypesOffice = true
        }
        return false
    })
    return hasConsultationTypesOffice
}

export function formatConsultationTypesBody(array) {
    let tab = []
    array.map((el) => {
        return tab.push({consultationType: el})
    })
    return tab
}

export function removeLastAmpersand(strng){  
    const n=strng.charAt(strng.length-1);
    if (n === '&') {
        const a=strng.substring(0, strng.length - 1)
        return a;
    }
    return strng
}

export function isRoutesAuthorizedBeforeConnection(location) {
    return location.pathname === '/' || location.pathname.startsWith('/validation/') || location.pathname.startsWith('/login') || location.pathname.startsWith('/email-validation/') || location.pathname.startsWith('/reset-password/')
}

export function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function translateStatus(status) {
    switch(status) {
        case 'Approved':
            return 'Validée'
        case 'Pending':
            return "En attente"
        case 'pending':
            return "En attente"
        case 'Refused':
            return 'Refusée'
        case 'Cancelled':
            return 'Annulée'
        case 'canceled':
            return 'Annulée'
        case 'completed':
            return 'Terminée'
        case 'Payment':
            return 'Paiement'
        case 'Booked':
            return 'Réservé'
        default:
            return status
    }
}

export function translateType(type) {
    switch(type) {
        case 'Intervention':
            return 'À domicile'
        case 'Chat':
            return "Tchat"
        case 'Plateforme':
            return 'Ligne d\'écoute'
        default:
            return type
    }
}

export function displayableAddress(address) {
    let formatDisplayableAddress = ''
    if (address.address !== 'undefined undefined') {
        formatDisplayableAddress += address.address + ', '
    }
    if (address.postalCode) {
        formatDisplayableAddress += address.postalCode + ', '
    }
    if (address.city) {
        formatDisplayableAddress += address.city
    }
    return formatDisplayableAddress
}

export function formatAvailabilityTitle(title) {
    if (title === 'Plateforme') return "Ligne d'écoute"
    if (title === 'Intervention') return "À domicile"
    return title
}