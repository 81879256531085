import { Button, LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useParams } from 'react-router-dom';
import { apiFetch } from '../../../service/apiFetch';
import mainConstant from '../../../Constant/mainConstant';
import { ActiveBreadcrumb } from '../../../components/Breadcrumb';
import cx from 'clsx';
import BrandCardHeader from '@mui-treasury/components/cardHeader/brand';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useN03TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n03';
import { useLightTopShadowStyles } from '@mui-treasury/styles/shadow/lightTop';
import hasRoles from '../../../service/security/hasRoles';
import AdminGrid from './DynamicGrid.jsx/AdminGrid';
import ClientGrid from './DynamicGrid.jsx/ClientGrid';
import { isAuth } from '../../../service/security/isAuth';
import { useStylesShowSpecialistProfile } from '../../../css/Style';

const breadcrumbTab = [
    'Profil',
    'Ajouter',
    'Modifier',
    'Voir',
    'Map',
  ];

export function ShowSpecialistProfile(props) {
    document.title = mainConstant.PROFILE
    const { id } = useParams()
    const [userData, setUserData] = useState(null)
    const history = useHistory()
    const styles = useN03TextInfoContentStyles();
    const shadowStyles = useLightTopShadowStyles();
    const cardStyles = useStylesShowSpecialistProfile();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        (async () => {
          let user = null
            if (isAuth()) {
              user = await apiFetch(`/specialists/${id}`, {
                method: 'GET',
                })
            } else {
              user = await apiFetch(`/specialists/${id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/ld+json',
              },
            }, true)
          }
            setUserData(user)
        })()
    }, [id])

    const handleBack = () => {
        history.push('/specialists')
        window.location.reload();  
    }

    const handleClick = () => {
        isAuth() ? history.push('/visit') : history.push('/')
    }

    return (
        <>
        {(hasRoles() === 'client' || !isAuth()) && <Button variant='contained' color='primary' onClick={handleClick} >Retour</Button>}
        {hasRoles() === 'super_admin' && <ActiveBreadcrumb breadcrumb={props.props.breadcrumb} tab={breadcrumbTab} setBreadcrumb={props.props.setBreadcrumb} handleBack={handleBack} />}
        {userData ? 
        <Card className={cx(cardStyles.root, shadowStyles.root)}>
        <BrandCardHeader
          image={`${process.env.REACT_APP_BASE_URL}${userData.photo.contentUrl}`}
          extra={userData.user.email}
        />
        <CardContent className={cardStyles.content}>
          <TextInfoContent
            classes={styles}
            overline={`${userData.user.firstName} ${userData.user.lastName}`}
            body={userData.description}
          />
          {hasRoles() === 'super_admin' ? <AdminGrid cardStyles={cardStyles} matches={matches} userData={userData} /> : <ClientGrid cardStyles={cardStyles} matches={matches} userData={userData} />}
        </CardContent>
      </Card> : <LinearProgress />}
        </>
    )
}