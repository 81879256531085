import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import { Payment } from '../../components/Payment'
import Alert from '../../components/Alert';

export function AppointmentPayment({ setStepper, setTransition, setPaymentValidate, option, price, matches, id, transition }) {
    const [text, setText] = React.useState(null)
    const [severity, setSeverity] = React.useState(null)
    const [isOpen, setIsOpen] = React.useState(null)

    return (
        <div style={matches ? {margin: 'auto', maxWidth: '590px', paddingBottom: '5px', marginTop: '5px'} : {margin: 'auto', maxWidth: '590px', paddingBottom: '200px', marginTop: '30px'}}>
            {option === 'Cabinet' ? <Typography component='p'>
                    Le paiement ce fera directement chez le specialiste<br />
                    Prix de la consultation : {price} €
            </Typography> : option === 'Visio' ? <Typography component='p'>
            Cette consultation vous coûtera 1 séance.
Pour accéder à votre visio, allez dans Mes demandes en cours, elle sera accessible 5 min avant le créneau réservé cliquez sur le pictogramme pour ouvrir la conversation.
            </Typography> :
            <>
            <Typography variant="h5" component="h2" color='primary'>
                Paiement
            </Typography>
            <Divider />
            <Payment setStepper={setStepper} setSeveriry={setSeverity} setText={setText} setPayment={setIsOpen} id={id} setTransition={setTransition} setPaymentValidate={setPaymentValidate} type={'intervention'} quantity={1} />
            <Alert isOpen={isOpen} transition={transition} message={text} setIsUpdate={setIsOpen} severity={severity} />
            </>
            }
        </div>
    )
}
