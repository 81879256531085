import React from 'react'
import {  Typography } from '@material-ui/core';

export default function QuoteOfWeekCard({ classes, matches, consult }) {
    return (
        <>
            {consult && consult.quote && <div className={classes.quoteOfWeekCard}>
                <Typography variant="h5" gutterBottom style={matches ? {marginBottom: 30} : {marginBottom: 30}}>
                    LA CITATION DE LA SEMAINE
                </Typography>
                <Typography variant={matches ? 'h4' : "h3"} gutterBottom style={matches ? {marginBottom: 65, fontStyle: 'italic', textTransform: 'none', fontSize: '30px'} : {marginBottom: 50, fontStyle: 'italic', textTransform: 'none', fontSize: '30px'}}>
                    « {consult.quote.quote} » 
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {consult.quote.writer}
                </Typography>
            </div>}
        </>
    )
}
