import React from 'react'
import SpecialistCard from './SpecialistCard'
import SkeletonCard from '../../components/SkeletonCard';

export function AppointmentSpecialist({ setSpecialist, matches, specialist }) {
    const [selectedValue, setSelectedValue] = React.useState('a')

    const handleChange = (event) => {
        setSelectedValue(parseInt(event.target.value));
    }

    return (
        <div style={{paddingBottom: 10}}>
            {specialist ? 
                    <SpecialistCard isFromStepper={true} matches={matches} specialist={specialist} specialistChoose={setSpecialist} selectedValue={selectedValue} handleChange={handleChange} />
             : <SkeletonCard />}
        </div>
    )
}
