import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import { TransitionLeft } from '../helpers/utils';
import { LinearProgress } from '@material-ui/core'

const Room = ({ setToken, roomName, token, handleLogout, setTransition, setIsOpen, matches, setOpenClosePopup }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = participant => {
      if (participant.state === 'connected') {
        setParticipants(() => {
          return [participant]
        });
      }
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    }).catch((e) => {
        setTransition(() => TransitionLeft)
        setIsOpen(true)
        console.warn(e)
        setRoom(null)
        handleLogout()
    });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="room" style={matches ? {width: '100%', textAlign: 'center'} : {width: '100%', textAlign: 'center', display: 'contents'}}>
      {!!participants.length ? participants.map(participant => {
          return <Participant setToken={setToken} setOpenClosePopup={setOpenClosePopup} key={participant.sid} participant={participant} matches={matches} />
        }) : <div style={matches ? {width: '100%', height: 240, backgroundColor: 'black', color: 'white', display: 'grid'} : {width: 640, height: 480, backgroundColor: 'black', color: 'white', display: 'grid'}}><LinearProgress />Veuillez patienter votre interlocuteur va rejoindre la visioconsultation</div>}
      {room && (
        <Participant
          key={room.localParticipant.sid}
          participant={room.localParticipant}
          isLocal={true}
          setToken={setToken}
          setOpenClosePopup={setOpenClosePopup}
          blackScreenIsDisplayed={participants.length === 0}
          matches={matches}
        />
      )}
{/*       <div>
        <Fab color="primary" aria-label="mic">
          <MicIcon />
        </Fab>
        <Fab color="primary" aria-label="video">
          <VideocamIcon />
        </Fab>
        <Fab onClick={handleLogout} color="primary" aria-label="callEnd">
          <CallEndIcon />
        </Fab>
      </div> */}
    </div>
  );
};

export default Room;
