import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import * as locales from '@material-ui/core/locale';
import { EnhancedTableToolbar } from '../../components/EnhancedTableToolbar';
import { EnhancedTableHead } from '../../components/EnhancedTableHead';
import { EnhancedTableBody } from '../../components/EnhancedTableBody';
import { Backdrop, Card, CardContent, CardHeader, LinearProgress, Typography } from '@material-ui/core';
import moment from "moment";
import { apiFetch } from '../../service/apiFetch';
import { download, TransitionLeft, translateStatus } from '../../helpers/utils';
import { useStylesConsultTable } from '../../css/Style';
import SepaPopup from '../../components/SepaPopup';
import Alert from '../../components/Alert';
import CloseIcon from '@material-ui/icons/Close';
import hasRoles from '../../service/security/hasRoles';

export default function Consults({ isCabinet, setVisioId, isVisio, consults, headCells, isSpecialist, isClient, isPlatforme, isAdmin, loading, view, totalItems, fetchConsults, isIntervention }) {
    const classes = useStylesConsultTable();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [disable, setDisable] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [rowSelected, setRowSelected] = React.useState(false);
    const [openSepaPopup, setOpenSepaPopup] = React.useState(false);
    const [checkedConsolidateData, setCheckedConsolidateData] = React.useState(false);
    const [locale] = React.useState('frFR');
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState(false);
    const [text, setText] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [severity, setSeverity] = React.useState(false);
    const [transition, setTransition] = React.useState(false);
    const [loadingComment, setLoadingComment] = React.useState(true);
    
    const handleClose = () => {
      setOpen(false);
    };
    const handleShowPopup = (event, row) => {
        setRowSelected(row)
        setOpen(!open);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        isPlatforme && property === 'name' && fetchConsults(`/consultations?order[specialist.user.lastName]=${isAsc ? 'desc' : 'asc'}`)
        isPlatforme && property === 'lastName' && fetchConsults(`/consultations?order[specialist.user.firstName]=${isAsc ? 'desc' : 'asc'}`)
        isPlatforme && property === 'startDate' && fetchConsults(`/consultations?order[startDate]=${isAsc ? 'desc' : 'asc'}`)
        isPlatforme && property === 'duration' && fetchConsults(`/consultations?order[room.duration]=${isAsc ? 'desc' : 'asc'}`)
        isPlatforme && property === 'firstName' && fetchConsults(`/consultations?order[client.user.lastName]=${isAsc ? 'desc' : 'asc'}`)
        isIntervention && property === 'name' && fetchConsults(`/interventions?type=Intervention&order[specialist.user.lastName]=${isAsc ? 'desc' : 'asc'}`)
        isIntervention && property === 'startDate' && fetchConsults(`/interventions?type=Intervention&order[startDate]=${isAsc ? 'desc' : 'asc'}`)
        isVisio && property === 'name' && fetchConsults(`/interventions?type=Visio&order[specialist.user.lastName]=${isAsc ? 'desc' : 'asc'}`)
        isVisio && property === 'startDate' && fetchConsults(`/interventions?type=Visio&order[startDate]=${isAsc ? 'desc' : 'asc'}`)
        isCabinet && property === 'name' && fetchConsults(`/interventions?type=Cabinet&order[specialist.user.lastName]=${isAsc ? 'desc' : 'asc'}`)
        isCabinet && property === 'startDate' && fetchConsults(`/interventions?type=Cabinet&order[startDate]=${isAsc ? 'desc' : 'asc'}`)
    };
    
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = consults.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleChangePage = async (event, newPage) => {
      setDisable(true)
      if (newPage > page) {
        await fetchConsults(view['hydra:next'])
      } else if (newPage < page) {
        await fetchConsults(view['hydra:previous'])
      }
      window.scrollTo(0, 0)
      setPage(newPage);
      setDisable(false)
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const downloadAction = async (el, row) => {
      let fetchBills = null
      if (isClient) {
        fetchBills = isIntervention || isVisio ? await apiFetch(`/interventions/${row.id}/invoice`) : isPlatforme && await apiFetch (`/consultations/${row.id}/invoice`)
      } else if (isSpecialist) {
        fetchBills = isIntervention && await apiFetch (`/interventions/${row.id}/fees`)
      }
      if (fetchBills) {
        const bills = await fetchBills.blob()
        download(bills, `Facture-${row.startDate}`)
      }
    }

    const sepaPopup = () => {
      setOpenSepaPopup(!openSepaPopup)
    }

    const downloadSepa = async () => {
      const fetchBlob = await apiFetch(`/accounting/sepa?consolidate=${checkedConsolidateData ? 1 : 0}`)
      if (fetchBlob) {
        const blob = await fetchBlob.blob()
        download(blob, `Interventions-SEPA${moment().format()}.xml`)
        setOpenSepaPopup(!openSepaPopup)
      }
    }

    const handleSubmitFailure = async () => {
      const failure = await apiFetch(`/failures`, {
        method: 'POST',
        body: JSON.stringify({
          room: rowSelected.room['@id'],
          comment: comment,
        })
      }, true)

      if (failure['hydra:description']) {
        setSeverity('error')
        setText(failure['hydra:description'])
        setTransition(() => TransitionLeft)
        setIsUpdate(true)
      } else {
        setSeverity('success')
        setText('Merci pour votre commentaire !')
        setTransition(() => TransitionLeft)
        setIsUpdate(true)
        setTimeout(() => {
          window.location.reload()
        }, 4000)
      }

      handleClose()
    }

    const handleChangeComment = (e) => {
      setLoadingComment(false)
      setComment(e.target.value)
    }

    return (
      <div className={classes.root}>
      {!loading ? <Paper className={classes.paper}>
        <EnhancedTableToolbar title={'Mes consultations'} sepaPopup={sepaPopup} isAdmin={isAdmin} isClient={isClient}/>
        <span style={{fontStyle: 'italic', color: '#05057c', paddingLeft: 16}}>Dans cet espace, retrouvez tout l’historique de vos consultations{!isCabinet && isClient && ' et téléchargez vos factures pour obtenir un remboursement par votre mutuelle'}.</span><br/>
          {(isVisio || isPlatforme) && !isSpecialist ? <span style={{fontStyle: 'italic', color: '#05057c', paddingLeft: 16}}>Les consultations de moins de 5 minutes ne sont pas affichées</span> : ''}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={consults.length}
              headCells={headCells}
            />
            <EnhancedTableBody
              order={order}
              orderBy={orderBy}
              rows={consults}
              stableSortNeeded={true}
              page={page}
              rowsPerPage={rowsPerPage}
              handleShowPopup={handleShowPopup}
              isSpecialist={isSpecialist}
              isClient={isClient}
              isAdmin={isAdmin}
              isConsult={true}
              isIntervention={isIntervention}
              isPlatforme={isPlatforme}
              onShow={downloadAction}
              isVisio={isVisio}
            />
          </Table>
        </TableContainer>
        <ThemeProvider theme={(outerTheme) => createMuiTheme(outerTheme, locales[locale])}>
            <TablePagination
              labelRowsPerPage=''
              nextIconButtonProps={{ disabled: disable || page >= (totalItems / rowsPerPage) - 1}}
              backIconButtonProps={{ disabled: disable || page === 0 }} 
              rowsPerPageOptions={[]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </ThemeProvider>
      </Paper> : <LinearProgress />}
      <Backdrop className={classes.backdrop} style={{zIndex: 9999999}} open={open}>
            <Card className={classes.card}>
                <IconButton variant="contained" size='small' onClick={() => handleClose()} style={{float: 'right'}}>
                  <CloseIcon/>
                </IconButton>
                <CardHeader
                  title={(isSpecialist && rowSelected ? `${rowSelected.client.user.firstName} ${rowSelected.client.user.lastName}` : isClient ? `${rowSelected.specialist && rowSelected.specialist.user.firstName} ${rowSelected.specialist && rowSelected.specialist.user.lastName}` : rowSelected && isAdmin && `Spécialiste : ${rowSelected.specialist.user.firstName} ${rowSelected.specialist.user.lastName}`)}
                  subheader={isAdmin && rowSelected && `Client : ${rowSelected.client.user.firstName} ${rowSelected.client.user.lastName}`}
                />
                <CardContent className={classes.center}>
                  {(!isPlatforme && !isVisio) && <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>Adresse : </span> {rowSelected.address}
                  </Typography>}
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>Date : </span> {moment(rowSelected.startDate).format("LLL")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>{rowSelected.duration ? 'Duration : ' : 'Statut : ' }</span> {translateStatus(rowSelected.status) || rowSelected.duration}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>{rowSelected.type ? 'Type : ' : 'Adresse : ' }</span> {rowSelected.motif || rowSelected.type}
                  </Typography>
                  {rowSelected && rowSelected.room && <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>Durée : </span> {rowSelected.room.duration}
                  </Typography>}
                  {rowSelected && rowSelected.canceled_reason && hasRoles() === 'super_admin' && <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>Raison de l'échec : </span> {rowSelected.canceled_reason}
                  </Typography>}
                  {rowSelected && rowSelected.room && isSpecialist && rowSelected.room.failure ? <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>Problème rencontré : </span> {rowSelected.room.failure.comment}
                  </Typography> : 
                  rowSelected && rowSelected.room && isSpecialist && <div style={{textAlign: 'left'}}>
                    <TextField
                      id="multiline"
                      label="Vous avez rencontré un problème technique lors de la consultation ?"
                      multiline
                      rows={4}
                      placeholder="Merci de décrire le problème rencontré"
                      style={{width: 550}}
                      onChange={handleChangeComment}
                    /><br /><br />
                    <Button disabled={loadingComment} variant='contained' color='primary' onClick={handleSubmitFailure}>
                      Envoyer      
                    </Button>
                  </div>}
                  {isCabinet && isClient && <Typography variant="body2" color="textSecondary" component="p" className={classes.backDropTypo}>
                    <span className={classes.bold}>Prix : </span>{rowSelected.billingTtc} €
                  </Typography>}
                </CardContent>
            </Card>
      </Backdrop>
      <SepaPopup downloadSepa={downloadSepa} open={openSepaPopup} handleClose={sepaPopup} checkedConsolidateData={checkedConsolidateData} setCheckedConsolidateData={setCheckedConsolidateData} />
      <Alert isOpen={isUpdate} setIsUpdate={setIsUpdate} transition={transition} message={text} severity={severity} />
    </div>
    )
}

/* function is24(rowSelected) {
    let days = moment().diff(moment(rowSelected.startDate), 'days');
    return days === 0;
} */
