import React, { useState } from 'react'
import { Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Toolbar,
    Typography,
    Button,
    LinearProgress,
    IconButton
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContentMenuItem from './ContentMenuItem';
import clsx from 'clsx';
import AddQuote from './QuoteOfWeekItem/AddQuote';
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';

export default function QuoteOfWeek({ classes, fetchQuoteOfWeek, createQuoteOfWeek, updateQuoteOfWeek, deleteQuoteOfWeek, setIsUpdate, setText, setTransition, setSeverity, quoteOfWeek }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClosePopup = () => {
        setSelected(false)
        setOpen(false);
        setAnchorEl(null)
    };

    const handleClick = (event, row) => {
        setSelected(row)
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        handleClose()
        const deleted = await deleteQuoteOfWeek(selected.id)
        if (deleted && !deleted['hydra:description']) {
            setText('La citation a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText((deleted && deleted['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
    }

    const handleActive = async () => {
        handleClose()
        const actived = await apiFetch(`/cms_quotes/${selected.id}`, {
            method: 'PUT',
            body: JSON.stringify({star: true})
        })
        await fetchQuoteOfWeek('/cms_quotes/')
        if (actived) {
            setText('La citation a été activé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setIsUpdate(true)
        } else {
            setText('Erreur lors de l\'activation')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setIsUpdate(true)
        }
    }

    return (
        <Paper>
            <Toolbar>
                <Typography component='div' color="primary" variant='h6' className={classes.title}>
                    Gérer la citation de la semaine
                </Typography>
                <Button variant="contained" color="primary" size='small' onClick={handleClickOpen}>
                    Ajouter une citation
                </Button>
            </Toolbar>
            {quoteOfWeek ? <TableContainer>
                <Table aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Auteur</TableCell>
                            <TableCell align="right">Citation</TableCell>
                            <TableCell align="right">Active</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quoteOfWeek.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.writer}
                                </TableCell>
                                <TableCell align="right">{row.quote}</TableCell>
                                <TableCell align="right">{<div className={clsx(classes.shape, classes.shapeCircle)} style={row.star ? {backgroundColor: '#6EDEBB'} : {backgroundColor: '#C4C8D2'}} />}</TableCell>
                                <TableCell align="right"><IconButton onClick={(event) => handleClick(event, row)} ><MoreVertIcon /></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> :
            <LinearProgress />}
            <ContentMenuItem selected={selected} handleClickOpen={handleClickOpen} handleActive={handleActive} handleDelete={handleDelete} active={true} handleClose={handleClose} anchorEl={anchorEl} />
            <AddQuote handleCloseMenu={handleClose} updateQuoteOfWeek={updateQuoteOfWeek} selected={selected} setIsUpdate={setIsUpdate} setText={setText} setTransition={setTransition} setSeverity={setSeverity} createQuoteOfWeek={createQuoteOfWeek} handleClose={handleClosePopup} open={open} />
        </Paper>
    )
}