import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export default function RefusePopup({open, setOpen, handleRefuse, setComment, comment, error}) {

    return (
        <Dialog
            open={!!open}
            onClose={() => setOpen(false)}
            aria-labelledby="title"
            aria-describedby="description"
        >
            <DialogTitle id="title">{"Voulez-vous refuser ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="description">
                    Vous êtes sur le point de refuser la consultation, cette action est irréversible ! {open && open.intervention && open.intervention.type === 'Intervention' &&  <br />} {open && open.intervention && open.intervention.type === 'Intervention' && 'Ce commentaire sera envoyé au patient.'}
                </DialogContentText>
                {open && open.intervention && open.intervention.type === 'Intervention' && <TextField
                    id="standard-multiline-static"
                    label="Commentaire"
                    multiline
                    rows={4}
                    placeholder="Expliquer en quelques mots pourquoi vous refusez."
                    autoFocus
                    required
                    style={{width: '100%'}}
                    error={!!error} helperText={error}
                    onChange={(e) => setComment(e.target.value)}
                />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleRefuse} disabled={open && open.intervention && open.intervention.type === 'Intervention' ? !comment : false} color="primary">
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}