import { FormControl, Input, InputLabel, useTheme, Select, Typography, Grid, Button, Divider, IconButton, Backdrop, Paper, useMediaQuery } from '@material-ui/core';
import React, {useEffect} from 'react'
import { Payment } from '../components/Payment';
import mainConstant from '../Constant/mainConstant';
import { apiFetch } from '../service/apiFetch'
import Alert from '../components/Alert';
import { motion } from 'framer-motion'
import stripeLogo from '../img/Stripe-Logo.png'
import CloseIcon from '@material-ui/icons/Close';
import { useStylesCrediteAccount } from '../css/Style';
import circles from '../img/cercles-pointillés.png'

export default function CrediteAccount(props) {
    document.title = mainConstant.RECHARGE
    const classes = useStylesCrediteAccount();
    const [nbConsultation, setNbConsultation] = React.useState(false);
    const [consultations, setConsultations] = React.useState(false);
    const [body, setBody] = React.useState(false);
    const [text, setText] = React.useState(false);
    const [transition, setTransition] = React.useState(false);
    const [severity, setSeveriry] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [payment, setPayment] = React.useState(false);
    const theme = useTheme();
    const lower1025 = useMediaQuery(theme.breakpoints.down(1025))
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(() => {
        apiFetch('/credit_packages', {method: 'GET'}).then((response) => {
          if(response && response['hydra:member']) setConsultations(response['hydra:member'])
        })
    }, [])

    const handleChange = (event) => {
        setBody({type: 'consultation', package: event.target.value})
        setNbConsultation(event.target.value || '');
        setTimeout(() => {
            setOpen(true)
        }, 200)
    }

    const handleValidation = () => {
        setOpen(false)
        setTimeout(() => {
            setBody(false)
            //TODO: remove this workaround for mercure
            window.location.reload()
        },3000)
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <div style={{textAlign: 'center', marginTop: 60}}>
            <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay: 0.2, duration: 0.5}} >
                {props.user && <><Typography variant="h3" color='primary' gutterBottom>
                    LE SOLDE DE VOTRE COMPTE
                </Typography>
                <div className={classes.creditBox}>
                    SOLDE ACTUEL: {props.credit || props.user.client.nbCredits} séance(s) restante(s)
                </div></>}
                <div className={classes.textBox}>
                    <Typography variant="h6" color='primary' gutterBottom>Sur la ligne d’écoute Geo-Psy, vous pouvez choisir d’effectuer un suivi ponctuel d’une seule séance ou sélectionner l’une de nos formules de 1, 3 ou 5 séances à prix dégressifs, que vous pourrez utiliser avec le psychologue de votre choix, selon vos disponibilités et vos préférences.</Typography><br />
                    <Typography variant="h6" color='primary' gutterBottom> Vous pourrez alors consulter par appel téléphonique ou par tchat, sans attente, à tout moment !</Typography><br />
                    <Typography variant="h6" color='primary' gutterBottom style={{textTransform: 'uppercase'}}> POUR CONSULTER PAR VISIO, EN CABINET OU À DOMICILE, VOUS DEVREZ PRENDRE RENDEZ-VOUS.</Typography><br />
                </div>
                <Grid container spacing={5} className={classes.sessionBox} style={{padding: '70px 60px', margin: 'auto', marginTop: 40, textAlign: 'center', justifyContent: 'space-around'}}>
                    <Grid item xs={4} className={classes.roundedGrid}>
                        <img src={circles} alt={'circles'} width='215' height='215' style={{marginTop: -25}} />
                        <Typography style={{textTransform: 'uppercase', marginTop: -180, maxWidth: 200, marginLeft: 6}} variant="h6" color='primary' gutterBottom>la formule liberté</Typography><br />
                        <Typography style={lower1025 ? {textTransform: 'uppercase', fontSize: 13} : {textTransform: 'uppercase', fontSize: 13}} variant="body1" color='primary' gutterBottom>EXEMPLE: 5 consultations</Typography>
                        <Typography style={lower1025 ? {textTransform: 'uppercase', fontSize: 13, marginBottom: 100} : {textTransform: 'uppercase', fontSize: 13}} variant="body1" color='primary' gutterBottom>225 euros TTC</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.roundedGrid}>
                        <img src={circles} alt={'circles'} width='215' height='215' style={{marginTop: -25}} />
                        <Typography style={{textTransform: 'uppercase', marginTop: -180, maxWidth: 200, marginLeft: 6}} variant="h6" color='primary' gutterBottom>la formule découverte</Typography><br />
                        <Typography style={lower1025 ? {textTransform: 'uppercase', fontSize: 13} : {textTransform: 'uppercase', fontSize: 13}} variant="body1" color='primary' gutterBottom>EXEMPLE: 2 APPEL + 1 visio</Typography>
                        <Typography style={lower1025 ? {textTransform: 'uppercase', fontSize: 13, marginBottom: 100} : {textTransform: 'uppercase', fontSize: 13}} variant="body1" color='primary' gutterBottom>139,5 euros TTC</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.roundedGrid}>
                        <img src={circles} alt={'circles'} width='215' height='215' style={{marginTop: -25}} />
                        <Typography style={{textTransform: 'uppercase', marginTop: -180, maxWidth: 200, marginLeft: 6}} variant="h6" color='primary' gutterBottom>la formule simplicité</Typography><br />
                        <Typography style={lower1025 ? {textTransform: 'uppercase', fontSize: 13} : {textTransform: 'uppercase', fontSize: 13}} variant="body1" color='primary' gutterBottom>EXEMPLE: 1 APPEL</Typography>
                        <Typography style={lower1025 ? {textTransform: 'uppercase', fontSize: 13, marginBottom: 100} : {textTransform: 'uppercase', fontSize: 13}} variant="body1" color='primary' gutterBottom>48 euros TTC</Typography>
                    </Grid>
                </Grid>
                <Button variant='contained' className={classes.button} onClick={handleOpen}>
                    RECHARGER MON COMPTE
                </Button>
                <Divider classes={{root: classes.divider}} />
                <img src={stripeLogo} alt={'stripeLogo'} width='276' height='131' />
                <Typography variant="body1" color='primary' className={classes.stripeBox}>
                    Vos paiements et vos données personnelles sont entièrement sécurisées. Nous utilisons la solution de paiement en ligne <span style={{fontWeight: 'bold'}}>Stripe</span> pour vos transactions ! Visitez le site <b>stripe.com</b> si vous souhaitez en savoir plus sur ce service !
                </Typography>
                {consultations && open && <Backdrop open={open} style={matches ? {display: 'block', zIndex: 999999} : {zIndex: 999999}}>
                    <Paper style={lower1025 ? {minWidth: '100%', marginTop: 300} : {padding: '10px'}}>
                        <IconButton aria-label="close" onClick={handleOpen} className={classes.closeButton}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="consultation">Choisissez votre formule</InputLabel>
                            <Select
                                native
                                value={nbConsultation}
                                onChange={handleChange}
                                input={<Input id="consultation" />}
                            >
                                <option aria-label="None" value="" />
                                {consultations.map((consultation) => {
                                    return (
                                      <option key={consultation['@id']} value={consultation['@id']}>{` ${consultation.name} - ${consultation.nbCredits} ${consultation.nbCredits === 1 ? 'séance' : 'séances'} : ${consultation.amount}€`}</option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {nbConsultation && body && <Payment customStyle={{marginLeft: 20, marginTop: -50}} setNbConsultation={setBody} handleValidation={handleValidation} body={body} restocking={true} setSeveriry={setSeveriry} setTransition={setTransition} setText={setText} setPayment={setPayment} severity={severity} type={'consultation'} quantity={nbConsultation} />}
                    </Paper>
                </Backdrop>}
                <Alert isOpen={payment} transition={transition} message={text} setIsUpdate={setPayment} severity={severity} />
            </motion.div>
        </div>
    )
}
