import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { NavLink } from 'react-router-dom'
import { isAuth } from '../../service/security/isAuth';
import SearchIcon from '@material-ui/icons/Search';
import hasRoles from '../../service/security/hasRoles';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PeopleIcon from '@material-ui/icons/People';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ReceiptIcon from '@material-ui/icons/Receipt';
import UpdateIcon from '@material-ui/icons/Update';

export default function ListTab({ checkConnect, profile, onToggleDarkMode, setBreadcrumb, setToggleProfile }) {
    const [currentSpecialistId, setCurrentSpecialistId] = React.useState(null)
    const adminPrimary = [
      {path: '/dashboard', item: 'Tableau de bord'},
      {path: {pathname: `/specialists`}, item: 'Specialistes', breadcrumb: true},
      {path: {pathname: `/clients`}, item: 'Utilisateurs', breadcrumb: true},
      {path: '/message', item: 'Mes messages'}, {path: '/consult', item: 'Mes consultations'},
      {path: {pathname: `/planning/${currentSpecialistId}`, props: profile}, item: 'Mon planning'},
    ]
    const adminSecondary = [
      {path: '/transactions', item: 'Liste des transactions'},
      {path: '/content-management', item: 'Gestion de contenu'},
      {path: '/settings', item: 'Paramètres'}
    ]
    const SpecialistSecondary = [
      {path: `/fees`, item: 'Mes notes d\'honoraires'},
      {path: `/incoming-consultation/${currentSpecialistId}`, item: 'Mes demandes en cours'},
    ]
    const adminIcon = [<DashboardIcon />, <SupervisedUserCircleIcon />, <PeopleIcon />, <MailIcon />, <SupervisorAccountIcon />, <TodayIcon />]
    const userIcon = [<DashboardIcon />, <PersonIcon />, <SupervisorAccountIcon />]
    const specialistIcon = [<DashboardIcon />, <PersonIcon />, <MailIcon />, <SupervisorAccountIcon />, <TodayIcon />]
    const adminSecondaryIcon = [<PaymentIcon />, <UpdateIcon />, <SettingsIcon />]
    const specialistSecondaryIcon = [<ReceiptIcon />, <PlaylistAddCheckIcon />]
    const userSecondaryIcon = [<SearchIcon />, <AccountBalanceWalletIcon />, <PlaylistAddCheckIcon />, <LiveHelpIcon />, <PaymentIcon />]
    const userPrimary = [
      {path: '/dashboard', item: 'Tableau de bord'},
      {path: {pathname: `/profil`}, item: 'Profil'},
      {path: '/consult', item: 'Mes consultations'},
    ]
    const specialistPrimary = [
      {path: '/dashboard', item: 'Tableau de bord'},
      {path: {pathname: `/profil`}, item: 'Profil'},
      {path: '/message', item: 'Mes messages'}, {path: '/consult', item: 'Mes consultations'},
      {path: {pathname: `/planning/${currentSpecialistId}`, props: profile}, item: 'Mon planning'},
    ]
    const userSecondary = [
      {path: {pathname: '/visit', props: profile }, item: 'Prendre un RDV'},
      {path: {pathname: '/creditAccount', props: profile }, item: 'Créditer mon compte'},
      {path: `/incoming-consultation/${currentSpecialistId}`, item: 'Mes demandes en cours'},
      {path: '/message', item: 'SAV'},
      {path: {pathname: '/payment', props: profile }, item: 'Mes paiements'},
    ]
    const handleConnection = (e) => {
      if (!isAuth()) {
        checkConnect(null)
      }
      setBreadcrumb(['Profil'])
    }

    const handleClick = (e) => {
      if (e.currentTarget.children[1].children[0].innerHTML === 'Specialistes') {
        setToggleProfile('specialists')
      } else if (e.currentTarget.children[1].children[0].innerHTML === 'Utilisateurs') {
        setToggleProfile('utilisateurs')
      }
    }

    React.useEffect(() => {
      if (window.location.pathname.startsWith('/speci')) {
        setToggleProfile('specialists')
      } else if (window.location.pathname.startsWith('/cli')) {
        setToggleProfile('utilisateurs')
      }
      if (isAuth() && profile) {
        setCurrentSpecialistId((profile.specialist && profile.specialist.id) || (profile.client && profile.client.id) || profile.id)
      }
    }, [profile, setToggleProfile, SpecialistSecondary])

    return (
        <>
        <List>
        {findRightMenu(adminPrimary, userPrimary, specialistPrimary).map((text, index) => (
        <NavLink to={text.path} style={{textDecoration: 'none', color: '#05057c'}} key={text.item} onClick={handleConnection}>
          <ListItem onClick={handleClick} button>
            <ListItemIcon style={{color: '#05057c'}}>
                {findRightMenu(adminIcon, userIcon, specialistIcon)[index]}
            </ListItemIcon>
            <ListItemText primary={text.item} />
          </ListItem>
        </NavLink>
        ))}
      </List>
      <Divider />
      <List>
        {findRightMenu(adminSecondary, userSecondary, SpecialistSecondary).map((text, index) => (
          <div key={index}>
          {<NavLink to={text.path} style={{textDecoration: 'none', color: '#05057c'}} key={text.item} onClick={handleConnection}>
            <ListItem button>
              {index === 0 && profile.specialist && profile.specialist.feesAlert ? <Badge color="error" variant="dot" invisible={false}>
                <ListItemIcon style={{color: '#05057c'}} >
                    {findRightMenu(adminSecondaryIcon, userSecondaryIcon, specialistSecondaryIcon)[index]}
                </ListItemIcon>
                <ListItemText primary={text.item} />
                <span style={{width: 10}}></span>
              </Badge> : <><ListItemIcon style={{color: '#05057c'}} >
                    {findRightMenu(adminSecondaryIcon, userSecondaryIcon, specialistSecondaryIcon)[index]}
                </ListItemIcon>
                <ListItemText primary={text.item} /></>}
            </ListItem>
          </NavLink>}
          </div>
          ))}
      </List>
      </>
    )
}

function findRightMenu(adminTab, userTab, specialistTab) {
  if (hasRoles() === 'admin' || hasRoles() === 'super_admin') {
    return adminTab
  }
  if (hasRoles() === 'specialist') {
    return specialistTab
  }
  return userTab
}
