import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import hasRoles from '../security/hasRoles'


export const SpecialistAndAdminRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render= {(props) => (
            hasRoles() === 'specialist' || hasRoles() === 'super_admin' || hasRoles() === 'admin' ? <Component {...props} matches={rest.matches} setTransition={rest.setTransition} setIsUpdate={rest.setIsUpdate} setTextAlert={rest.setTextAlert} setSeverity={rest.setSeverity} setVisioId={rest.setVisioId} user={rest.profileUser} menuOpen={rest.menuOpen} /> : <Redirect to='/dashboard'/>
          )}/>
    )
}