import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Cell, ResponsiveContainer } from 'recharts'
import { Typography } from '@material-ui/core/';

const CustomizedLabel = (props) => {    
    const {x, y, value, index, width} = props;

    return <text 
                x={x + Math.round(width) + 5}
                y={y + 25}
                fontSize='16' 
                fontFamily='sans-serif'
                fill={colors[index % 20]}
            >
                    {time_convert(value)}
            </text>
};
const colors = ['#05057C', '#05057C', '#79E0DB'];

export default function AveragePerType({ data, matches }) {
  	return (
        <div style={matches ? {marginTop: 30} : {marginTop: 230}}>
            <Typography variant="h5" gutterBottom style={{textTransform: 'uppercase', color: "#05057c", fontSize: "23px", textAlign: "center"}}>
                Durée moyenne des consultations
            </Typography>
            <ResponsiveContainer height={data.length * 50 + 10} width="100%">
                <BarChart
                    width={600}
                    height={200}
                    data={data}
                    layout="vertical" 
                    margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                >
                    <XAxis type="number" hide />
                    <YAxis type="category" width={50} padding={{ left: 20 }} dataKey="type"/>
            
                    <Bar 
                       dataKey="duration" 
                       textBreakAll='label'
                       barSize={40}
                       label={<CustomizedLabel />}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

function time_convert(num) { 
    return new Date(num * 1000).toISOString().substr(11, 8)
}