import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  color: #777F8F;
  font-size: 16px;
`;

const InputWrapper = styled('div')`
  width: 450px;
  border: 1px solid #d9d9d9;
  background-color: #D1DCEE;
  color: #424242;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  &:hover {
    border-color: #05057c;
  }

  &.focused {
    border-color: #05057c;
    box-shadow: 0 0 0 2px rgba(5, 5, 124, 0.2);
  }

  &.filter {
    width: 300px;
    padding: 4px;
  }

  &.disable {
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
  }

  &.focusedFilter {
    width: 300px;
    padding: 4px;
    border-color: #05057c;
    box-shadow: 0 0 0 2px rgba(5, 5, 124, 0.2);
  }

  & input {
    background-color: #D1DCEE;
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }

  @media screen and (max-width: 1321px) {
      width: 300px;
  }

  @media screen and (max-width: 960px) {
    width: 160px;
  }

  @media screen and (max-width: 600px) {
    width: 210px;
  }
`;

const Tag = styled(({ label, setDisable, onDelete, ...props }) => (
  <div onClick={() => setDisable && setDisable(false)} {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #424242;
  color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  &.centerItem {
    margin: 2px 20px 0;
    text-align: start;
  }

  &.centerPhoneItem {
    width: 210px;
    margin: 2px 20px 0;
    text-align: start;
  }

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #424242;
    font-weight: 600;

    & svg {
      color: #79e0db;
    }
  }

  & li[data-focus='true'] {
    background-color: #79e0db;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

export default function MultipleSelect({ text, multipleOption, setter, state, setDisable, isFilter, matches, isCenter, disabled }) {
    
    const getDefaultValue = (state) => {
      let tab = []
      state && state.forEach((el) => {
        if (el.consultationType && el.consultationType.name === 'Plateforme') {
          el.consultationType.name = 'Ligne d\'écoute'
        } else if (el.consultationType && el.consultationType.name === 'Intervention') {
          el.consultationType.name = 'À domicile'
        }
        tab.push(el.consultationType || el)
      })
      return tab
    }
    
    const {
      getRootProps,
      getInputLabelProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: `customized-select-${text}`,
      defaultValue: getDefaultValue(state),
      multiple: true,
      options: multipleOption,
      required: true,
      getOptionLabel: (option) => option && option.name,
      getOptionSelected: (option, value) => option.name === value.name
    });

    React.useEffect(() => {
      let tab = []
      value.forEach((el) => {
        tab.push(el['@id'])
      })
      setter(tab)
    }, [value, setter])

    const handleClick = () => {
      setDisable && setDisable(false)
    }

    return (
      <NoSsr>
        <div>
          <div {...getRootProps()}>
            <Label {...getInputLabelProps()}>{text}</Label>
            <InputWrapper ref={setAnchorEl} className={disabled ? 'disable' : focused && isFilter && !matches ? 'focusedFilter' : isFilter && !matches ? 'filter' : focused ? 'focused' : ''}>
              {value.map((option, index) => {
                return (
                  <Tag label={option && option.name} {...getTagProps({ index })} setDisable={setDisable} />
                )
              })}

              <input {...getInputProps()} />
            </InputWrapper>
          </div>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()} className={matches ? 'centerPhoneItem' : isCenter ? 'centerItem' : ''} onClick={handleClick} >
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.name}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
        </div>
      </NoSsr>
    );
}
