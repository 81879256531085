import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react'
import { useStylesSkeletonCard } from '../css/Style';

export default function SkeletonCard({ matches }) {
    const classes = useStylesSkeletonCard();

    return (
        <Card className={classes.root}>
          <CardContent style={matches ? {fontSize: '15px'} : {}}>
            <Grid container justify="space-between" className={classes.grid}>
              <Grid item xs={matches ? 12 : 7}>
                <Grid container className={classes.grid}>
                  <Grid style={matches ? {textAlign: 'center', margin: 'auto', justifyContent: 'center', marginBottom: 20, display: 'grid'} : {}} item xs={matches ? 12 : 3}>
                    <Skeleton animation="wave" variant="circle" width={120} height={120} />
                  </Grid>
                  <Grid item xs={matches ? 12 : 8}>
                    <div style={matches ? {marginLeft: 40} : {paddingTop: 0, marginLeft: 20}}>
                      <div style={{display: 'flex'}}>
                        <Skeleton animation="wave" height={40} width='20%' style={{marginRight: 20}} />
                        <Skeleton animation="wave" height={40} width='30%' />
                      </div>
                      <div style={{display: 'flex'}}>
                        <Skeleton animation="wave" height={40} width='30%' style={{marginRight: 20}} />
                        <Skeleton animation="wave" height={40} width='40%' />
                      </div>
                      <div style={{display: 'flex'}}>
                        <Skeleton animation="wave" height={40} width='50%' style={{marginRight: 20}} />
                        <Skeleton animation="wave" height={40} width='50%' />
                      </div>
                      <Skeleton animation="wave" height={60} width='100%' />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={matches ? 12 : 4}>
                <Skeleton animation="wave" height={60} width='97%' />
              </Grid>
            </Grid>
            <Grid container className={classes.grid} style={{marginTop: 30}}>
              <Grid item xs={matches ? 12 : 5}>
                <Box
                  mt={2}
                  display={'block'}
                  alignItems={'center'}
                  style={{justifyContent: 'center', textAlign: 'center'}}
                >
                  <>
                    <Skeleton animation="wave" height={40} width='30%' style={{marginLeft: 40}} />
                    <React.Fragment>
                      <Skeleton animation="wave" height={60} width='97%' />
                      <Skeleton animation="wave" height={60} width='97%' />
                    </React.Fragment>
                  </>
                </Box>
              </Grid>
              <Grid item xs={matches ? 12 : 7}>
                <Box
                  mt={2}
                  display={'block'}
                  alignItems={'center'}
                  style={{justifyContent: 'center', textAlign: 'center'}}
                >
                  <>
                    <Skeleton animation="wave" height={40} width='30%' style={{marginLeft: 40}} />
                    <React.Fragment>
                      <Skeleton animation="wave" height={60} width='97%' />
                      <Skeleton animation="wave" height={60} width='97%' />
                    </React.Fragment>
                  </>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
}