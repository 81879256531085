import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from "@material-ui/core/Grid"
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import AuthService from '../../service/security/handleTokenProvider'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress';
import * as mainConstant from '../../Constant/mainConstant'
import { Divider, IconButton, useMediaQuery } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';
import { ResetPassword } from './ResetPassword'
import { isKnownRoutes } from '../../helpers/utils'
import { useStylesLogin } from '../../css/Style'

export function LoginForm() {
    const classes = useStylesLogin();
    const { id } = useParams();
    let location = useLocation()
    const [error, setError] = useState(null)
    const [toggleIdField, setToggleIdField] = useState(true)
    const [togglePassField, setTogglePassField] = useState(true)
    const [checked, setChecked] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)
    const [top, setTop] = useState('-50px')
    const [loading, setLoading] = useState(false)
    let history = useHistory()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    document.title = mainConstant.LOGIN

    const handleSubmit = async function (e) {
        setError(null)
        setLoading(true)
        e.preventDefault()
        let data = new FormData(e.target)
        const email = data.get('username')
        const pass = data.get('password')

        try {
            data = { email: `${email}`, password: `${pass}` } 
            AuthService.login(data).then((response) => {
                response.json().then((responseData) => {
                    localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(responseData))
                    if(response.ok) {
                        if (window.location.pathname.startsWith('/login/psychologue')) {
                            history.push('/psychologue/' + location.pathname.substr(location.pathname.lastIndexOf("/") + 1))
                        } else if (localStorage.getItem('LAST_URL') && isKnownRoutes(localStorage.getItem('LAST_URL')) && !localStorage.getItem('LAST_URL').startsWith('/validation/specialist')  && !localStorage.getItem('LAST_URL').startsWith('/reset-password/')) {
                            history.push(localStorage.getItem('LAST_URL'))
                            window.location.reload();
                        } else {
                            history.push('/dashboard')
                            window.location.reload();
                        }
                    } else {
                        setLoading(false)
                        if (responseData.code === 401) {
                            setError('Nom d\'utilisateur ou mot de passe incorrect')
                        } else {
                            setError(responseData.detail)
                        }
                    }
                })
            }).catch((e) => {
                setError(e)
            })
        } catch (e) {
            setError(e)
            setLoading(false)
        }        
    }

    const handleChangeUsername = (e) => {
        e.preventDefault()
        setError(false)
        if (!e.target.value) {
            setToggleIdField(false)
        } else {
            setToggleIdField(true)
        }
    }

    const handleChangePassword = (e) => {
        e.preventDefault()
        setError(false)
        if (!e.target.value) {
            setTogglePassField(false)
            setTop('-72px')
        } else {
            setTogglePassField(true)
            setTop('-50px')
        }
    }

    const handleChange = () => {
        setChecked(!checked)
    }

    const handleResetPassword = (e) => {
        e.preventDefault()
        setResetPassword(true)
    }

    const handleBackToHome = (e) => {
        history.push('/')
        window.location.reload();
    }

    return (
        <div>
            { (!id || isKnownRoutes(window.location.pathname) === 'emailValidationId' || window.location.pathname.startsWith('/login/show')) && <Grid container direction="column" alignItems="center" className={classes.center}>
                <Grid item xs={12}>
                        {!resetPassword ? 
                        <>
                        <div>
                            {error && <Alert severity="error" className="fullSize">{error}</Alert>}
                        </div>
                        <form className={classes.root} noValidate onSubmit={ handleSubmit }>
                            <Typography variant="h4" color="primary" gutterBottom>
                                Se connecter
                            </Typography>
                            <Divider className={classes.divider} />
                            { toggleIdField ? <TextField id="username" name="username" label="Adresse mail" onChange={handleChangeUsername} style={{marginRight:'20px'}} required/> :  
                            <TextField required error id="erreur" name="erreur" label="Adresse mail" helperText="Entrer un identifiant" onChange={handleChangeUsername}/> }
                            { togglePassField ? <TextField required id="password" type={checked ? "text" : "password"} name="password" label="Mot de passe" onChange={handleChangePassword} className={classes.fieldWidth} /> : 
                            <TextField required error id="erreurPass" type="password" name="erreurPass" label="Mot de passe" helperText="Entrer un mot de passe" onChange={handleChangePassword}/> }
                            <div className={classes.icon} style={{top: top}}>
                            { checked ? 
                            <IconButton aria-label="password" onClick={handleChange}>
                                <VisibilityIcon fontSize="small" />
                            </IconButton> : 
                            <IconButton aria-label="password" onClick={handleChange}>
                                <VisibilityOffIcon fontSize="small" />
                            </IconButton>}
                            </div>
                            <Button
                                disabled={loading}
                                type="submit"
                                variant="contained"
                                color="primary"
                                endIcon={<LockOpenIcon />}
                                style={{marginTop: '-20px', marginBottom: '30px'}}>
                                    Se connecter
                            </Button>
                        </form>
                        <Typography>
                            <Link href="#" onClick={handleResetPassword} variant="body2" style={{marginLeft: '10px'}}>
                                Mot de passe oublié
                            </Link>
                            {matches && <br />}
                            <Link href="" onClick={handleBackToHome} variant="body2" className={classes.link}>
                                Revenir sur la page d'accueil
                            </Link>
                        </Typography>
                        </>
                        : <ResetPassword onReset={setResetPassword} onLoading={setLoading}/>}
                    {loading && <LinearProgress />}
                </Grid>
            </Grid>}
        </div>
      );
}
