export const routes = {
    login: '/login',
    loginRedirect: '/login/psychologue/:id',
    loginFocus: '/login?tab=login',
    dashboard: '/dashboard',
    resetPasswordId: '/reset-password/:id',
    initPasswordId: '/validation/specialist/:id',
    emailValidationId: '/email-validation/:id',
    resetPassword: '/reset-password',
    initPassword: '/validation/specialist/',
    emailValidation: '/email-validation',
    home: '/',
    profile: '/profil',
    fees: '/fees',
    ShowSpecialistProfile: '/specialist/profil/show/:id',
    ShowSpecialistCard: '/psychologue/:id',
    EditSpecialistProfile: '/specialist/profil/edit/:id',
    EditClientProfile: '/client/profil/edit/:id',
    ShowClientProfile: '/client/profil/show/:id',
    EditProfile: '/profil/edit/:id',
    specialist: '/specialists',
    client: '/clients',
    message: '/message',
    payment: '/payment',
    transactions: '/transactions',
    consult: '/consult',
    planning: '/planning/:id',
    settings: '/settings',
    interdit: '/interdit',
    changePassword: '/change-password',
    incomingConsultation: '/incoming-consultation/:id',
    creditAccount: '/creditAccount',
    visit: '/visit',
    appointmentStepper: '/appointment-stepper',
    contentManagement: '/content-management',
}