import React from 'react'
import Typography from '@material-ui/core/Typography';
import * as mainConstant from '../Constant/mainConstant'
import { Button, Grid } from '@material-ui/core';
import TextLoop from "react-text-loop";
import { NavLink } from 'react-router-dom';
import { Home } from './Home';

export default function Error({ setLoginPage, shouldShowHome}) {
    document.title = mainConstant.ERROR

    return (
        <>
        {shouldShowHome && <Home setLoginPage={setLoginPage} />}
        <main style={{margin:'auto', textAlign:'center', marginTop: '100px'}}>
                <Grid
                    container
                    className="fullSize"
                    direction="column"
                    justify="center"
                    alignItems="center"
                    id="ERR404"
                >
                    <Typography variant="h3">Désolé !</Typography>
                    <Typography variant="h5" align="center" style={{color: "red"}}>
                      Cette page n'héxiste pas (plus)
                    </Typography>
                    <Grid container justify="center" alignItems="center">
                      <div id="beforeTooLate">
                        <p>Retourner d'où vous venez ! </p>
                        <NavLink to={'/'}>

                        <TextLoop
                          springConfig={{ stiffness: 100, damping: 12 }}
                          interval={1000}
                        >
                          <Button className="textLink" to="/">
                            Téléportation
                          </Button>
                          <Button className="textLink" to="/">
                            Téléportation
                          </Button>
                        </TextLoop>
                        </NavLink>

                      </div>
                    </Grid>
                </Grid>
        </main>
      </>
    )
}