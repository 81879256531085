import React, { useEffect } from 'react'
import CreditCard from './creditCard';
import NotificationCard from './NotificationCard';
import { Grid } from '@material-ui/core';
import ClientsCharts from './ClientsCharts';
import SpecialistsCharts from './SpecialistsCharts';
import OngoingConsultation from './OngoingConsultation';
import AveragePerType from './AveragePerType';
import ConsultationPerTypes from './ConsultationPerTypes';

export default function AdminDashboard({ matches, classes, data, open, lower1825, user}) {
    const [specialistData, setSpecialistData] = React.useState(false)

    useEffect(() => {
        setSpecialistData([
            {'name': 'En ligne', value: data.nbOnlineSpecialist.online},
            {'name': 'Total', value: data.nbOnlineSpecialist.total},
        ])
    }, [data])


    return (
            <Grid container spacing={matches ? 0 : 6} className={classes.mainGrid}>
                <Grid item xs={matches ? 12 : 6}>
                    <CreditCard classes={classes} user={user} credit={data} />
                    <ClientsCharts matches={matches} data={data.clientRegistrations} />
                    {specialistData && <SpecialistsCharts matches={matches} data={specialistData} />}
                </Grid>
                <Grid item xs={matches ? 12 : 6}>
                    <NotificationCard matches={matches} classes={classes} next={data} />
                    <OngoingConsultation rows={data.runningConsultations} />
                    <AveragePerType matches={matches} classes={classes} data={data.consultationsDuration} />
                </Grid>
                <ConsultationPerTypes classes={classes} data={data.consultationsTyped} />
            </Grid>
        )
}
