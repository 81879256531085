import { makeStyles } from "@material-ui/core";

export const sharedUseStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
        flexGrow: 1,
      },
    },
    paper: {
        paddingLeft: theme.spacing(12),
        paddingTop: theme.spacing(3),
        margin: 'auto',
        maxWidth: 1500,
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
        },
    },
    width: {
        width: 450,
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
            width: 300
        },
        [theme.breakpoints.down('sm')]: {
            width: 160
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        },
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    right: {
        float: 'right',
        top: -60,
        right: 190,
        [theme.breakpoints.down('xs')]: {
            float: 'none',
            right: 0,
            top: 10,
        },
    },
    formControl: {
        minWidth: 120,
    },
    addressWidth: {
        width: 450,
        [theme.breakpoints.down('md')]: {
            width: 300
        },
        [theme.breakpoints.down('sm')]: {
            width: 160
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        },
    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            margin: 'auto',
            textAlign: 'center',
        },
    },
    divider: {
        marginBottom:'10px',
        marginRight: '13%',
        [theme.breakpoints.down('xs')]: {
            marginRight: '18px',
            marginTop: '5px'
        },
    }
  }));