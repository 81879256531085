import { Button, ButtonGroup, Divider, FormHelperText, Grid, Paper, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { apiFetch } from '../../../service/apiFetch'
import { formatAddress, TransitionLeft } from '../../../helpers/utils'
import UploadFile from '.././UploadItem'
import { handleFormError } from '../../../service/security/error'
import AutoCompleteAddress from '../../../components/AutoCompleteAddress'

export function AddSpecialist({ classes, setBreadcrumb, createSpecialist, setTransition, setIsUpdate, setText, setSeverity, matches }) {
    const [disable, setDisable] = React.useState(true)
    const [photo, setPhoto] = React.useState(null)
    const [apiLanguages, setApiLanguages] = React.useState(null)
    const [apiConsultationTypes, setApiConsultationTypes] = React.useState(null)
    const [apiSpecialities, setApiSpecialities] = React.useState(null)
    const [newAddress, setNewAddress] = React.useState('')
    const [newCoordonate, setNewCoordonnate] = React.useState({
        lat: null,
        lng: null
    })

    const [error, setError] = React.useState({})
    React.useEffect(() => {
        apiFetch("/specialities", {
            method: "GET",
          })
          .then(res => {
            return setApiSpecialities(res['hydra:member']);
          })

        apiFetch("/languages", {
          method: "GET",
        })
        .then(res => {
          return setApiLanguages(res['hydra:member']);
        })

        apiFetch("/consultation_types", {
          method: "GET",
        })
        .then(res => {
          return setApiConsultationTypes(res['hydra:member']);
        })

    }, []);

    const handleChange = () => {
        setDisable(false)
        setError({mobile: null})
        setError({phone: null})
    }

    const handleClick = () => {
        setBreadcrumb(['Profil'])
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let data = new FormData(e.target)

        let dataPhoto = new FormData() 
        dataPhoto.append('file', photo && photo[0])

        const createPhoto = await apiFetch('/media_objects', {
            method: 'POST',
            body: dataPhoto
        }, true, true)

        if (createPhoto['@id']) {
            const dataObject =  {
                firstName : data.get('username'),
                lastName : data.get('lastName'),
                urssafAddress: data.get('urssafAddress'),
                email : data.get('mailPro'),
                adeli : data.get('adeliNum'),
                photo: createPhoto['@id'],
                address : newAddress,
                coordonate: newCoordonate,
            }
            const body = formatBody(dataObject)
            const specialist = await createSpecialist(body, true)

            if (!specialist.violations) {
                setSeverity('success')
                setText('Spécialiste créé ! vous allez être redirigé vers la section profil des spécialistes')
                setIsUpdate(true)
                setTransition(() => TransitionLeft)
                setTimeout(() => {
                    setBreadcrumb(['Profil'])
                }, 3000)
            } else {
                setSeverity('error')
                setText('Echec lors de la création de compte, les champs ne sont pas rempli correctement')
                const errorField = handleFormError(specialist, ['user.firstName', 'user.address', 'user.lastName', 'urssafAddress', 'user.email', 'adeli', 'photo', 'user.address.postalCode'])
                setError(errorField)
                setIsUpdate(true)
                setTransition(() => TransitionLeft);
            }
        } else {
            setSeverity('error')
            setText('Veuillez ajouter un photo !')
            setError({photo: 'Problème lors de l\'envoie de la photo'})
            setIsUpdate(true)
            setTransition(() => TransitionLeft);
        }
    }

    return (
        <Paper className={classes.paper}>
            {apiLanguages && apiSpecialities && apiConsultationTypes && <><Typography variant="h5" gutterBottom color='primary' style={{padding: '10px'}} >
                Ajouter un utilisateur
            </Typography>
            <Divider style={{maxWidth: '1220px', marginBottom:'10px'}}/>
            <form autoComplete="off" onChange={ handleChange } onSubmit={ handleSubmit }>
            <Grid container spacing={3} style={{marginTop:'25px'}}>
            <Grid item xs={matches ? 12 : 6}>
                <TextField error={!!error.firstName} helperText={error.firstName} className={classes.width} id="username" name="username" label="Prénom" required/>
                <TextField error={!!error.lastName} helperText={error.lastName} className={classes.width} id="lastName" name="lastName" label="Nom" required/>
                <UploadFile setPhoto={setPhoto} />
                <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error.photo}</FormHelperText>
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
                <TextField error={!!error.adeli} helperText={error.adeli} className={classes.width} id="adeliNum" name="adeliNum" label="Numéro ADELI" type='number' required/>
                <TextField error={!!error.email} helperText={error.email} className={classes.width} id="mailPro" name="mailPro" label="Adresse mail professionnelle" type='email' required/>
                <TextField error={!!error.urssafAddress} helperText={error.urssafAddress} className={classes.width} id="urssafAddress" name="urssafAddress" label="Adresse complète URSSAF" required/><br/>
                <AutoCompleteAddress error={!!error.address} className={classes.addressWidth} id="addressPro" name="addressPro" label="Adresse professionnelle" setNewAddress={setNewAddress} setNewCoordonnate={setNewCoordonnate} required={true} />
                <FormHelperText style={{color: 'red', marginBottom: '40px'}}>{error.address}</FormHelperText>
                <ButtonGroup disableElevation variant="contained" color="primary" className={classes.buttonGroup}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={disable}
                    >
                        Enregistrer
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        Retour
                    </Button>
                </ButtonGroup>
            </Grid>                
            </Grid>
            </form></>}
        </Paper>
    )
}

function formatBody(data) {
    const body = {
        adeli: data.adeli,
        photo: data.photo,
        urssafAddress: data.urssafAddress,
        user: {
            address: formatAddress(data.address, data.coordonate),
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
        },
    }

    for (const property in body) {
        if (property === 'user') {
            for (const props in body[property]) {
                if (!body[property][props]) {
                    delete body[property][props]
                }
            }
        }
    }

    return body
}