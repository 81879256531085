import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/apiFetch"

const FETCHING_VISIO = 'FETCHING_VISIO'
const SET_VISIO = 'SET_VISIO'
const ADD_VISIO = 'ADD_VISIO'
const UPDATE_VISIO = 'UPDATE_VISIO'
const DELETE_VISIO = 'DELETE_VISIO'

function visioReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_VISIO:
            return { ...state, loading: true }
        case SET_VISIO:
            return {...state, visio: action.payload['hydra:member'], loading: false, totalVisioItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_VISIO:
            return {...state, visio: [action.payload, ...state.visio]}
        case UPDATE_VISIO:
            return {...state, visio: action.payload }
        case DELETE_VISIO:
            return {...state, visio: state.visio.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useVisio() {
    const [state, dispatch] = useReducer(visioReducer, {   
        view: null,
        totalVisioItems: 0, 
        visio: null,
        loading: false,
    })

    return {
        visio: state.visio,
        visioView: state.view,
        totalVisioItems: state.totalVisioItems,
        fetchVisios: useCallback(async function (route = null) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_VISIO' })
            const visio = await apiFetch(route || '/interventions?type=Visio&order[startDate]=desc', {method: 'GET'})
            if (visio['hydra:member']) {
                dispatch({type: 'SET_VISIO', payload: visio})
            }
        }, [state]),
        createVisios: useCallback(async function(data, forceUpdate) {
            const newVisios = await apiFetch('/visio', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceUpdate)
            if (!newVisios.violations) {
                dispatch({type: 'SET_VISIO', payload: newVisios})
            }
            return newVisios
        }, []),
        updateVisios: useCallback(async function(visio, data, forceUpdate = false) {
            const newVisios = await apiFetch('/visio/' + visio, {
                method: 'PUT',
                body: data,
            }, forceUpdate)
            dispatch({type: 'UPDATE_intervention', payload: newVisios, target: visio })
            return newVisios
        }, []),
        deleteVisios: useCallback(async function (consultationId) {
            const discussionDelete = await apiFetch('/visio/' + consultationId, {
                method: 'DELETE'
            })
            if (discussionDelete) {
                dispatch({type: 'DELETE_intervention', payload: consultationId})
            }
            return discussionDelete
        }, []),
    }
}